import React from 'react';
import { Button, Form } from 'react-bootstrap';
import { preventDefaultSubmit } from 'client/utils/form';
import { TableFilterInputGroup } from 'client/table/TableUtils';
import RefreshButton from 'client/buttons/RefreshButton';
import { ColumnDefinition } from 'client/table/types';
import { IEmail } from 'client/email/types';
import TableSettingsColumnOrderButton from 'client/table/TableSettingsColumnOrderButton';

interface EmailTableFilterParams {
  id?: string;
  user_id?: string;
  customer_id?: string;
  view?: string;
  to_address?: string;
  created_by_user_id?: string;
  customer_survey_id?: string;
  company_event_customer_batch_id?: string;
}

interface EmailTableFilterFormProps {
  filterParams: EmailTableFilterParams;
  setFilterParams: React.Dispatch<React.SetStateAction<EmailTableFilterParams>>;
  onRefetch: () => void;
  onReset: () => void;
  isLoading: boolean;
  columnOrder: string[];
  setColumnOrder: React.Dispatch<React.SetStateAction<string[]>>;
  columnDefinitions: ColumnDefinition<IEmail>[];
}

export default function EmailTableFilterForm (props: EmailTableFilterFormProps) {
  const {
    isLoading,
    onRefetch,
    onReset,
    filterParams,
    setFilterParams,
    columnDefinitions,
    columnOrder,
    setColumnOrder,
  } = props;

  const onChange = React.useCallback((ev: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const { name, value } = ev.target;
    setFilterParams({[name]: value});
  }, [filterParams, setFilterParams]);

  return (
    <Form className="d-flex flex-wrap gap-3" onSubmit={preventDefaultSubmit}>
      <TableFilterInputGroup name="user_id" label="Användar" className="flex-grow-1">
        <Form.Control
          isValid={Boolean(filterParams.user_id)}
          name="user_id"
          onChange={onChange}
          placeholder="Oavsett användar-ID"
          value={filterParams.user_id ?? ''}
        />
      </TableFilterInputGroup>
      <TableFilterInputGroup name="customer_id" label="Kund" className="flex-grow-1">
        <Form.Control
          isValid={Boolean(filterParams.customer_id)}
          name="customer_id"
          onChange={onChange}
          placeholder="Oavsett kund-ID"
          value={filterParams.customer_id ?? ''}
        />
      </TableFilterInputGroup>
      <TableFilterInputGroup name="to_address" label="Mottagare" className="flex-grow-1">
        <Form.Control
          isValid={Boolean(filterParams.to_address)}
          name="to_address"
          onChange={onChange}
          placeholder="Oavsett mottagande e-postadress"
          value={filterParams.to_address ?? ''}
        />
      </TableFilterInputGroup>
      <TableFilterInputGroup name="created_by_user_id" label="Skapare" className="flex-grow-1">
        <Form.Control
          isValid={Boolean(filterParams.created_by_user_id)}
          name="created_by_user_id"
          onChange={onChange}
          placeholder="Oavsett skapar-ID"
          value={filterParams.created_by_user_id ?? ''}
        />
      </TableFilterInputGroup>
      <TableFilterInputGroup name="customer_survey_id" label="Kundundersökning" className="flex-grow-1">
        <Form.Control
          isValid={Boolean(filterParams.customer_survey_id)}
          name="customer_survey_id"
          onChange={onChange}
          placeholder="Oavsett kundundersökning-ID"
          value={filterParams.customer_survey_id ?? ''}
        />
      </TableFilterInputGroup>
      <TableFilterInputGroup name="company_event_customer_batch_id" label="Händelsebatch" className="flex-grow-1">
        <Form.Control
          isValid={Boolean(filterParams.company_event_customer_batch_id)}
          name="company_event_customer_batch_id"
          onChange={onChange}
          placeholder="Oavsett händelsebatch-ID"
          value={filterParams.company_event_customer_batch_id ?? ''}
        />
      </TableFilterInputGroup>
      <TableFilterInputGroup name="view" label="Mall" className="flex-grow-1">
        <Form.Select
          isValid={Boolean(filterParams.view)}
          name="view"
          onChange={onChange}
          value={filterParams.view ?? ''}
        >
          <option value="">Oavsett e-postmall</option>
          <option value="email.common">Vanlig</option>
          <option value="email.customerSurvey">Kundundersökning</option>
          <option value="email.companyEventCustomerBatch">Kundbatch</option>
          <option value="email.companyEventUserBatch">Användarbatch</option>
          <option value="email.notifyNewReport">Ny Rapport</option>
          <option value="email.resetPassword">Återställ Lösenord</option>
        </Form.Select>
      </TableFilterInputGroup>
      <TableSettingsColumnOrderButton
        columnDefinitions={columnDefinitions}
        columnOrder={columnOrder}
        setColumnOrder={setColumnOrder}
      />
      <Button
        title="Ta bort alla filtreringar"
        onClick={onReset}
        variant="outline-primary"
        className="d-flex align-items-center justify-content-center"
        disabled={isLoading}
      >
        Återställ
      </Button>
      <RefreshButton onClick={onRefetch} disabled={isLoading} />
    </Form>
  );
}
