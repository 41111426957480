import { useCallback, useState, useEffect } from 'react';

interface UseRemount {
  mounted: boolean;
  remount: () => void;
}

// hackish hook to reload a react component temporarily
// may be used to reset the default value of uncontrolled inputs
export default function useRemount (defaultMounted: boolean = true): UseRemount {
  const [mounted, setMounted] = useState<boolean>(defaultMounted);

  useEffect(() => {
    if (!mounted) {
      setMounted(true);
    }
  }, [mounted, setMounted]);

  const remount = useCallback(() => {
    setMounted(false);
  }, [setMounted]);

  return { mounted, remount };
}
