import { useContext } from 'react';
import { AuthContextType, AuthContext } from 'client/contexts/AuthContext';

const useAuth = () => {
  const context = useContext<AuthContextType>(AuthContext);
  if (!context) {
    throw new Error('AuthContext must be placed within AuthProvider');
  }
  return context;
};

export default useAuth;
