import React from 'react';
import ErrorAlert from 'client/components/ErrorAlert';
import { useQuery } from '@tanstack/react-query';
import SettingsForm, { SettingsFormData } from 'client/settings/SettingsForm';
import { Helmet } from 'react-helmet';
import PageHeader from 'client/components/PageHeader';
import {Card, Spinner} from 'react-bootstrap';
import {Link} from 'react-router-dom';

const SettingsPage: React.FC = React.memo(function SettingsPage () {

  const listSettingsQuery = useQuery<SettingsFormData, Error>({
    queryKey: ['/api/settings'],
  });

  return (
    <div className="container-sm p-4">
      <Helmet>
        <title>Systeminställningar</title>
      </Helmet>
      <PageHeader>
        <span className="d-flex align-items-baseline gap-3">
          Systeminställningar
          {listSettingsQuery.isLoading && (
            <span>
              <Spinner />
            </span>
          )}
        </span>
      </PageHeader>
      <ErrorAlert className="mt-3 mb-0" error={listSettingsQuery.error} />
      {listSettingsQuery.data && (
        <SettingsForm
          isLoading={listSettingsQuery.isLoading}
          initialFormValues={listSettingsQuery.data}
        />
      )}
      <Card className="mt-3">
        <Card.Body>
          <Link to="/pdf">PDF-demo &raquo;</Link>
        </Card.Body>
      </Card>
    </div>
  );
});

export default SettingsPage;
