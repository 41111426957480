import React from 'react';
import { ICsBasicExtendedWithRelations, ICsUnit } from 'client/cs/types';
import { CompanyInfoUnitCard } from 'client/company/CompanyInfoCards';
import {Col, Row} from 'react-bootstrap';

interface CompanyUnitsListProps {
  company: ICsBasicExtendedWithRelations;
  units: ICsUnit[];
}

export default function CompanyUnitsList (props: CompanyUnitsListProps) {
  const { units } = props;
  return (
    <div>
      {units.length > 0 ? (
        <Row>
          {units.map(unit => (
            <Col lg={6} key={unit.cfar}>
              <CompanyInfoUnitCard unit={unit} className="mb-4" />
            </Col>
          ))}
        </Row>
      ) : (
        <p>Inga driftställen tillgängliga</p>
      )}
    </div>
  );
}
