import React from 'react';
import { isFiniteNumber } from 'client/utils/number';

interface AmountFormatProps {
  value?: null | string | number;
}

const AmountFormat: React.FC<AmountFormatProps> = React.memo(function AmountFormat (props: AmountFormatProps) {
  const { value } = props;
  if (value === null || typeof value === 'undefined') return null;
  const number = typeof value === 'string' ? parseInt(value, 10) : value;
  if (!isFiniteNumber(number)) return  null;
  return (
    <>{number} st</>
  );
});
export default AmountFormat;
