import React, {useMemo, useState} from 'react';
import { Alert, Badge, Card } from 'react-bootstrap';
import classNames from 'classnames';
import { CustomerCompanyMonitoredRowWithRelations } from 'client/customerCompanyMonitored/types';
import RefreshButton from 'client/buttons/RefreshButton';
import { CardBodyErrorAlert } from 'client/card/CardHelpers';
import { TableSpinningOverlay  } from 'client/table/TableUtils';
import { useMutation, useQuery } from '@tanstack/react-query';
import CompanySearch from 'client/components/CompanySearch';
import useRemount from 'client/hooks/useRemount';
import * as columnDefs from 'client/table/commonColumnDefinitions';
import TableVirtual from 'client/table/TableVirtual';
import * as requestCallbacks from 'client/utils/requestCallbacks';
import LoadingButton from 'client/buttons/LoadingButton';
import * as CompanyFormatters from 'client/company/CompanyFormatters';
import axios from 'client/axios';
import {ColumnDefinition} from 'client/table/types';
import ConfirmDeleteButton from 'client/buttons/ConfirmDeleteButton';
import {Link} from 'react-router-dom';

interface CustomerDashboardCompanyMonitoringCardProps {
  className?: string;
}

interface CustomerCompanyMonitoredQueryResponse {
  rows: CustomerCompanyMonitoredRowWithRelations[];
  limit: number;
  email: string;
}

interface TableRow extends CustomerCompanyMonitoredRowWithRelations {
  id: string;
}

export default function CustomerDashboardCompanyMonitoringCard (props: CustomerDashboardCompanyMonitoringCardProps) {
  const { className } = props;
  const [orgNumber, setOrgNumber] = useState<string>('');

  const orgNumberRemount = useRemount();

  const query = useQuery<CustomerCompanyMonitoredQueryResponse, Error>({
    queryKey: ['/api/as_customer/monitoring'],
  });

  const data = query.data;

  const rows: TableRow[] = useMemo(() => {
    if (!query.data?.rows) return [];
    return query.data.rows.map(row => ({...row, id: row.org_number}));
  }, [query.data?.rows]);

  const deleteMutation = useMutation<void, Error, string>({
    mutationFn: orgNumber => axios.delete(`/api/as_customer/monitoring/${orgNumber}`),
    onSuccess: () => {
      requestCallbacks.onSuccess('Bevakningen har tagits bort');
      query.refetch();
    },
  });

  const createMutation = useMutation<unknown, Error, string>({
    mutationFn: orgNumber => axios.put(`/api/as_customer/monitoring/${orgNumber}`),
    onSuccess: () => {
      requestCallbacks.onSuccess('Bevakningen har lagts till');
      setOrgNumber('');
      orgNumberRemount.remount();
      query.refetch();
    },
  });

  const onSubmitCreate = (ev: React.FormEvent<HTMLFormElement>) => {
    ev.preventDefault();
    return createMutation.mutateAsync(orgNumber);
  };
  
  const orgNumberErrorExists = useMemo(() => rows.some(item => {
    if (!orgNumber) return false;
    return item.org_number === orgNumber;
  }), [rows, orgNumber]);

  const columns = columnFactory(deleteMutation.mutateAsync);

  const limitReached = data && rows ? data.limit - rows.length <= 0 : false;

  return (
    <Card className={classNames(className, 'border-0')}>
      <Card.Header className="border border-bottom-0 px-3 py-2">
        <Card.Title as="h6" className="mb-0 p-0 d-flex justify-content-between align-items-center">
          <span>
            Företagsbevakning{' '}
            {data && rows && (
              <Badge bg="secondary">{Math.max(0, data.limit - rows.length)}/{data.limit} företag kvar</Badge>
            )}
          </span>
          <RefreshButton
            onClick={query.refetch}
            disabled={query.isLoading || query.isRefetching}
            className="px-2"
          />
        </Card.Title>
      </Card.Header>
      <CardBodyErrorAlert error={query.error} />
      {query.isSuccess && data && (
        <Card.Body className="border border-bottom-0">
          <p className="mb-0">
            Ni kan lägga till företag för bevakning i listan nedan.
            Vi kommer att maila er på {data.email ? <a href={`mailto:${data.email}`}>{data.email}</a> : '(Ingen e-post angiven)'}{' '}
            varje gång de bevakade företagens rating, status eller skuldsaldo förändras, dock max en gång om dagen totalt. Om ni vill
            byta e-postadress så kan ni göra det på sidan för <Link to="/account">Kontoinställningar</Link>.
          </p>
          {!data.email && (
            <Alert className="mt-3 mb-1">En e-postadress måste anges i <Link to="/account">Kontoinställningar</Link> innan bevakningsmail börjar skickas ut.</Alert>
          )}
          {limitReached && (
            <Alert className="mt-3 mb-1">
              Maxgränsen för hur många företag som går att bevaka är nådd. Ta bort några företag från listan nedan eller kontakta oss på Calculate om ni vill öka er begränsning.
            </Alert>
          )}
        </Card.Body>
      )}
      <TableSpinningOverlay isLoading={query.isRefetching}>
        <div className="table-responsive">
          <TableVirtual
            isInitialLoading={query.isLoading}
            className="mb-0 border align-middle"
            columns={columns}
            rows={rows}
            emptyMessage="Inga företag är bevakade"
          />
        </div>
      </TableSpinningOverlay>
      <Card.Footer className="border border-top-0 py-3">
        <form className="d-flex gap-2" onSubmit={onSubmitCreate}>
          {orgNumberRemount.mounted && (
            <CompanySearch
              className="w-auto flex-grow-1"
              onSelect={setOrgNumber}
              defaultInputValue={orgNumber}
              searchUrl="/api/as_customer/monitoring/company/search"
            />
          )}
          <LoadingButton
            type="submit"
            disabled={!orgNumber || createMutation.isPending || orgNumberErrorExists || limitReached}
            isLoading={createMutation.isPending}
          >
            Lägg till bevakning
          </LoadingButton>
        </form>
      </Card.Footer>
    </Card>
  );
}

type ColumnFactory = (deleteMutateAsync: (orgNumber: string) => Promise<any>) => ColumnDefinition[];

const columnFactory: ColumnFactory = deleteMutateAsync => [
  columnDefs.cell(['name', 'Företag'], props => (
    <span>{props.row.cs_basic.company_name ?? props.row.org_number}</span>
  )),
  columnDefs.cell(['status', 'Företagsstatus'], props => (
    <CompanyFormatters.StatusLabel value={props.row.cs_basic.status_text_high} />
  )),
  columnDefs.date(['created_at', 'Datum bevakat']),
  columnDefs.cell(['actions', 'Åtgärd'], props => (
    <div className="d-flex gap-1 flex-wrap justify-content-end align-items-center">
      <ConfirmDeleteButton
        className="px-2"
        onConfirmedDelete={() => deleteMutateAsync(props.row.org_number)}
        confirmMessage="Är du säker på att du vill ta bort bevakningen av detta företag?"
        buttonTitle="Ta bort bevakningen"
        buttonLabel=""
      />
    </div>
  ), {
    Head: () => <span className="text-end d-block">Åtgärd</span>,
  }),
];
