import axios from 'axios';

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

instance.interceptors.request.use(
  function (config) {
    if (!config.headers) config.headers = {};
    config.headers['Accept'] = 'application/json';
    return config;
  },
  function (error) {
    return Promise.reject(error);
  },
);

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    // TODO fix this, this is a weak implementation of "user needs to login" logic
    if (error?.response?.status === 401) {
      window.location.replace('/login');
      return;
    }
    throw error;
  },
);

export default instance;
