import React from 'react';
import { Table } from 'react-bootstrap';
import Pluralizer from 'client/components/Pluralizer';
import DateFormat from 'client/components/DateFormat';
import InspectObjectModalButton from 'client/buttons/InspectObjectModalButton';
import ModalOpeningButton from 'client/buttons/ModalOpeningButton';
import { TableCardFooter, TableSpinningOverlay  } from 'client/table/TableUtils';
import EmailTemplateRenderModal from 'client/emailTemplate/EmailTemplateRenderModal';
import * as EmailTemplateFormatters from 'client/emailTemplate/EmailTemplateFormatters';
import { IEmailTemplate } from 'client/emailTemplate/types';
import { Mail } from 'react-feather';

export interface IEmailTemplatesTable {
  list: IEmailTemplate[];
  isLoading: boolean;
  isSuccess: boolean;
}

const EmailTemplatesTable: React.FC<IEmailTemplatesTable> = React.memo(function EmailTemplatesTable (props: IEmailTemplatesTable) {
  const { list, isLoading, isSuccess } = props;

  return (
    <div>
      <TableSpinningOverlay isLoading={isLoading}>
        <div className="table-responsive">
          <Table className="mb-0">
            <thead>
              <tr>
                <th>Namn</th>
                <th>Ämne</th>
                <th>Uppdaterad</th>
                <th className="text-end">Åtgärd</th>
              </tr>
            </thead>
            <tbody>
              {list.map(item => (
                <tr key={item.id}>
                  <td><EmailTemplateFormatters.EmailTemplateAnchor value={item} /></td>
                  <td>{item.subject}</td>
                  <td><DateFormat value={item.updated_at} format="YYYY-MM-DD" /></td>
                  <td>
                    <div className="d-flex gap-1 flex-wrap align-items-center justify-content-end">
                      <ModalOpeningButton
                        variant="outline-primary"
                        className="d-flex gap-1 align-items-center px-1 py-0"
                        Modal={EmailTemplateRenderModal as React.FC}
                        modalProps={{emailTemplateId: item.id}}
                        size="sm"
                      >
                        <Mail size={14} />
                        Rendera
                      </ModalOpeningButton>
                      <InspectObjectModalButton object={item} />
                    </div>
                  </td>
                </tr>
              ))}
              {isSuccess && !list.length && (
                <tr>
                  <td colSpan={11} className="p-2">Det finns ingenting här</td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      </TableSpinningOverlay>
      <TableCardFooter>
        <p className="mb-0">
          Totalt {list.length}{' '}
          <Pluralizer
            count={list.length}
            zero="mallar"
            one="mall"
            more="mallar"
          /> i denna tabell
        </p>
      </TableCardFooter>
    </div>
  );
});
export default EmailTemplatesTable;
