import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { Card, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import axios from 'client/axios';
import InspectObjectModalButton from 'client/buttons/InspectObjectModalButton';
import { TableSpinningOverlay  } from 'client/table/TableUtils';
import { IIndustryReportWithRelations } from 'client/industryReport/types';
import { IValuationReportWithRelations } from 'client/valuationReport/types';
import NumberFormat from 'client/components/NumberFormat';
import RefreshButton from 'client/buttons/RefreshButton';
import { ReportQueueAnchor } from 'client/reportQueue/ReportQueueFormatters';
import classNames from 'classnames';
import * as IndustryReportFormatters from 'client/industryReport/IndustryReportFormatters';
import * as ReportFormatters from 'client/report/ReportFormatters';
import { AccountInterval, AccountYear } from 'client/accounts/AccountsFormatters';
import DateFormat from 'client/components/DateFormat';
import ErrorAlert from 'client/components/ErrorAlert';
import { CustomerAnchor } from 'client/customer/CustomerFormatters';

interface CompanyReportTableProps {
  orgNumber: string;
  className?: string;
}

export const CompanyIndustryReportTable: React.FC<CompanyReportTableProps> = React.memo(function CompanyIndustryReportTable (props: CompanyReportTableProps) {
  const { className, orgNumber } = props;

  const query = useQuery<IIndustryReportWithRelations[], Error>({
    queryKey: ['CompanyIndustryReportTable', orgNumber],
    queryFn: () => axios.get(`/api/industry_reports/company/${orgNumber}`).then(r => r.data),
  });

  const list = query.data ?? [];
  const error = query.error;

  return (
    <Card className={classNames(className, 'border-0')}>
      <Card.Header className="border border-bottom-0 px-3 py-2">
        <Card.Title as="h6" className="mb-0 p-0 d-flex justify-content-between align-items-center">
          Företagets branschrapporter
          <RefreshButton
            onClick={query.refetch}
            disabled={query.isLoading || query.isRefetching}
            className="p-1"
            size="sm"
          />
        </Card.Title>
      </Card.Header>
      {error && (
        <Card.Body className="border border-bottom-0">
          <ErrorAlert className="mb-0" error={error} />
        </Card.Body>
      )}
      <TableSpinningOverlay isLoading={query.isLoading || query.isRefetching}>
        <div className="table-responsive">
          <Table className="mb-0 border align-middle" size="sm">
            <thead>
              <tr>
                <th>Kund</th>
                <th>Kö</th>
                <th>Skapad</th>
                <th>Bokslutsår</th>
                <th>Antal företag</th>
                <th>Flaggor</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {query.isSuccess && !list.length && (
                <tr>
                  <td colSpan={11} className="p-2">
                    Företaget har inga branschrapporter
                  </td>
                </tr>
              )}
              {list.map(item => (
                <tr key={item.id}>
                  <td>
                    {item.customer ? (
                      <CustomerAnchor value={item.customer} />
                    ) : '-'}
                  </td>
                  <td>
                    {item.report_queue_id ? (
                      <ReportQueueAnchor
                        value={{
                          id: item.report_queue_id,
                          report_type: 'industry_report',
                        }}
                      />
                    ) : '-'}
                  </td>
                  <td>
                    <DateFormat format="YYYY-MM-DD" value={item.created_at} />
                  </td>
                  <td><AccountYear from={item.account_date_from} to={item.account_date_to} /></td>
                  <td>
                    <IndustryReportFormatters.IndustryReportCompanyCounts title="" value={item} />
                  </td>
                  <td>
                    <ReportFormatters.ReportFlags value={item} />
                  </td>
                  <td>
                    <div className="d-flex gap-1 flex-wrap justify-content-end align-items-center">
                      <ReportFormatters.ReportDownloadPDFLink
                        type="industry_report"
                        report={item}
                      />
                      <InspectObjectModalButton object={item} />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </TableSpinningOverlay>
      <Card.Footer className="d-flex align-items-center border border-top-0 p-2">
        <Link className="btn btn-outline-primary btn-sm" to={`/industry_reports/create/${orgNumber}`}>
          Ny branschrapport &raquo;
        </Link>
      </Card.Footer>
    </Card>
  );
});

export const CompanyValuationReportTable: React.FC<CompanyReportTableProps> = React.memo(function CompanyValuationReportTable (props: CompanyReportTableProps) {
  const { orgNumber, className } = props;

  const query = useQuery<IValuationReportWithRelations[], Error>({
    queryKey: ['CompanyValuationReportTable', orgNumber],
    queryFn: () => axios.get(`/api/valuation_reports/company/${orgNumber}`).then(r => r.data),
  });

  const list = query.data ?? [];

  const error = query.error;

  return (
    <Card className={classNames(className, 'border-0')}>
      <Card.Header className="border border-bottom-0 px-3 py-2">
        <Card.Title as="h6" className="mb-0 p-0 d-flex justify-content-between align-items-center">
          Företagets värderingsrapporter
          <RefreshButton
            onClick={query.refetch}
            disabled={query.isLoading || query.isRefetching}
            className="p-1"
            size="sm"
          />
        </Card.Title>
      </Card.Header>
      {error && (
        <Card.Body className="border border-bottom-0">
          <ErrorAlert className="mb-0" error={error} />
        </Card.Body>
      )}
      <TableSpinningOverlay isLoading={query.isLoading || query.isRefetching}>
        <div className="table-responsive">
          <Table className="mb-0 border align-middle" size="sm">
            <thead>
              <tr>
                <th>Kund</th>
                <th>Kö</th>
                <th>Skapad</th>
                <th>Bokslut</th>
                <th>Värdering</th>
                <th>Flaggor</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {query.isSuccess && !list.length && (
                <tr>
                  <td colSpan={11} className="p-2">
                    Företaget har inga värderingsrapporter
                  </td>
                </tr>
              )}
              {list.map(item => (
                <tr key={item.id}>
                  <td>
                    {item.customer ? (
                      <CustomerAnchor value={item.customer} />
                    ) : '-'}
                  </td>
                  <td>
                    {item.report_queue_id ? (
                      <ReportQueueAnchor
                        value={{
                          id: item.report_queue_id,
                          report_type: 'valuation_report',
                        }}
                      />
                    ) : '-'}
                  </td>
                  <td>
                    <DateFormat format="YYYY-MM-DD" value={item.created_at} />
                  </td>
                  <td>
                    <AccountInterval to={item.report_accountdate} />
                  </td>
                  <td>
                    <NumberFormat value={item.report_valuation ?? 0} format="currency" />
                  </td>
                  <td>
                    <ReportFormatters.ReportFlags value={item} />
                  </td>
                  <td>
                    <div className="d-flex gap-1 flex-wrap justify-content-end align-items-center">
                      <ReportFormatters.ReportDownloadPDFLink
                        type="valuation_report"
                        report={item}
                      />
                      <InspectObjectModalButton object={item} />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </TableSpinningOverlay>
      <Card.Footer className="d-flex align-items-center border border-top-0 p-2">
        <Link className="btn btn-outline-primary btn-sm" to={`/valuation_reports/create/${orgNumber}`}>
          Ny företagsvärdering &raquo;
        </Link>
      </Card.Footer>
    </Card>
  );
});
