import { Card } from 'react-bootstrap';
import Logo from 'client/components/Logo';

export function Centered (props: React.PropsWithChildren) {
  const { children } = props;
  return (
    <div className="d-flex align-items-center justify-content-center p-2" style={{height: '100vh'}}>
      {children}
    </div>
  );
}

export function AuthWrapCard (props: React.PropsWithChildren) {
  const { children } = props;
  return (
    <div style={{width: '450px'}}>
      <Logo className="mb-4 d-flex justify-content-center"/>
      <Card>
        {children}
      </Card>
    </div>
  );
}
