import { useMemo, useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import PageHeader from 'client/components/PageHeader';
import ErrorAlert from 'client/components/ErrorAlert';
import { ColumnDefinition } from 'client/table/types';
import useTableState, { OrderDirection } from 'client/hooks/useTableState';
import useTableSelectRows from 'client/hooks/useTableSelectRows';
import * as commonColumnDefinitions from 'client/table/commonColumnDefinitions';
import * as CompanyFormatters from 'client/company/CompanyFormatters';
import { CompanyMonitoredWithRelationsRow } from 'client/companyMonitored/types';
import { TableSpinningOverlay  } from 'client/table/TableUtils';
import TableVirtual from 'client/table/TableVirtual';
import { TableCardFooter } from 'client/table/TableUtils';
import Pagination from 'client/table/TablePagination';
import Pluralizer from 'client/components/Pluralizer';
import NumberFormat from 'client/components/NumberFormat';
import CompanyMonitoredTableFilterForm from 'client/companyMonitored/CompanyMonitoredTableFilterForm';
import * as CompanyMonitoredFormatters from 'client/companyMonitored/CompanyMonitoredFormatters';
import { Search } from 'react-feather';
import { TableProvider } from 'client/contexts/TableContext';

export interface CompanyMonitoredTableFilterParams {
  org_number?: string;
  portfolio_name?: string;
}

interface CompanyMonitoredWithId extends CompanyMonitoredWithRelationsRow {
  id: string;
}

interface ListData {
  rows: CompanyMonitoredWithRelationsRow[];
  total_rows: number;
}

const defaultFilterParams = {};

const defaultState = {
  orderBy: 'created_at',
  orderDirection: OrderDirection.DESC,
  ...defaultFilterParams,
};

export default function CompanyMonitoredTablePage () {
  const {
    setStateMap,
    tablePagination,
    tableSort,
    filterParams,
    filterReset,
    params,
  } = useTableState({
    defaultState,
  });

  const query = useQuery<ListData, Error>({
    queryKey: ['/api/company_monitored/list', params],
    placeholderData: keepPreviousData,
  });

  const rows: CompanyMonitoredWithId[] = useMemo(() => {
    if (!Array.isArray(query.data?.rows)) return [];
    return (query.data?.rows || []).map(row => ({...row, id: row.org_number}));
  }, [query.data]);

  const [selectedRows, setSelectedRows] = useState<Record<string, boolean>>({});
  const allIds = useMemo(() => rows.map(item => item.id), [rows]);
  const tableSelectRows = useTableSelectRows(allIds, selectedRows, setSelectedRows);

  useEffect(() => {
    tablePagination.onChangeTotalCountOfRows(query.data?.total_rows ?? 0);
  }, [query.data?.total_rows]);

  const columnDefinitions = useMemo(columnDefinitionFactory, []);
  const [columnOrder, setColumnOrder] = useState(() => columnDefinitions.filter(c => c.show).map(c => c.id));

  return (
    <TableProvider tableSelectRows={tableSelectRows} tableSort={tableSort}>
      <div className="container-fluid p-4">
        <Helmet>
          <title>Bevakade företag</title>
        </Helmet>
        <PageHeader>
          <span>
            Bevakade företag
          </span>
        </PageHeader>
        <ErrorAlert className="my-3" error={query.error} />
        <Card>
          <Card.Header className="p-3">
            <CompanyMonitoredTableFilterForm
              isLoading={query.isLoading || query.isRefetching}
              filterParams={filterParams}
              setFilterParams={setStateMap}
              onRefetch={query.refetch}
              onReset={filterReset}
              columnDefinitions={columnDefinitions}
              columnOrder={columnOrder}
              setColumnOrder={setColumnOrder}
            />
          </Card.Header>
          <TableSpinningOverlay isLoading={query.isRefetching}>
            <div className="table-responsive">
              <TableVirtual
                className="mb-0 align-middle"
                rows={rows}
                columns={columnDefinitions}
                columnOrder={columnOrder}
                isInitialLoading={query.isLoading}
              />
            </div>
          </TableSpinningOverlay>
          <TableCardFooter>
            <p className="mb-0">
              Totalt <NumberFormat value={tablePagination?.totalCountOfRows || rows.length} />{' '}
              <Pluralizer
                count={tablePagination?.totalCountOfRows || rows.length}
                zero="bevakade företag"
                one="bevakat företag"
                more="bevakade företag"
              /> i denna tabell
            </p>
            {tablePagination && (
              <Pagination withRowsPerPage {...tablePagination} />
            )}
          </TableCardFooter>
        </Card>
      </div>
    </TableProvider>
  );
}

const defaultTableColumns = [
  'select',
  'org_number',
  'status',
  'portfolio_name',
  'synced_at',
  'created_at',
  'updated_at',
  'actions',
];

const columnSortable = [
  'org_number',
  'portfolio_name',
  'synced_at',
  'created_at',
  'updated_at',
];

type ColumnDefinitionFactory = () => ColumnDefinition<CompanyMonitoredWithId>[];

const columnDefinitionFactory: ColumnDefinitionFactory = () => [
  commonColumnDefinitions.select(),
  commonColumnDefinitions.cell(['org_number', 'Företag'], props => (
    <CompanyFormatters.CompanyAnchor value={props.row.cs_basic ?? props.row.org_number} />
  )),
  commonColumnDefinitions.cell(['status', 'Status'], props => (
    <CompanyMonitoredFormatters.StatusLabel value={props.row} />
  )),
  commonColumnDefinitions.simple(['portfolio_name', 'Portfölj']),

  commonColumnDefinitions.date(['synced_at', 'Synkad']),
  commonColumnDefinitions.date(['created_at', 'Skapad']),
  commonColumnDefinitions.date(['updated_at', 'Uppdaterad']),

  commonColumnDefinitions.actions(props => (
    <>
      <div className="d-flex gap-1 flex-wrap align-items-center justify-content-end">
        <Link
          className="btn btn-outline-primary btn-sm py-0 d-inline-flex gap-1 align-items-center px-1"
          to={`/company_monitored_update?org_number=${props.row.org_number}`}
        >
          <Search size={14} />
          Uppdateringar
        </Link>
      </div>
    </>
  )),
].map(obj => ({
  ...obj,
  show: defaultTableColumns.includes(obj.id),
})).map(obj => columnSortable.includes(obj.id) ? commonColumnDefinitions.sortable(obj) : obj);
