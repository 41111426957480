import React, { useCallback } from 'react';
import { preventDefaultSubmit } from 'client/utils/form';
import { Button, Form } from 'react-bootstrap';
import TableSettingsColumnOrderButton, { TableSettingsColumnOrderButtonProps } from 'client/table/TableSettingsColumnOrderButton';
import {FilterDefinition} from 'client/table/types';
import RefreshButton from 'client/buttons/RefreshButton';

// FIXME better type
type FilterParams = Record<string, any>;

interface TableFilterVirtualProps extends Partial<Pick<TableSettingsColumnOrderButtonProps, 'columnOrder' | 'setColumnOrder' | 'columnDefinitions'>> {
  filterDefinitions: FilterDefinition[];
  filterParams: FilterParams;
  setFilterParams: React.Dispatch<React.SetStateAction<FilterParams>>;

  onReload?: () => void;
  onReset?: () => void;
  isLoading: boolean;
  size?: 'sm';
}

export default function TableFilterVirtual (props: TableFilterVirtualProps) {
  const {
    filterParams,
    setFilterParams,
    filterDefinitions,
    isLoading,
    onReload,
    onReset,
    columnDefinitions,
    columnOrder,
    setColumnOrder,
    size,
  } = props;

  const onChange = useCallback((id: string, value: any) => {
    setFilterParams(prevValue => ({...prevValue, [id]: value}));
  }, [filterParams, setFilterParams]);

  return (
    <Form className="d-flex justify-content-between gap-3" onSubmit={preventDefaultSubmit}>
      {filterDefinitions.map(filterDefinition => (
        <TableFilterRenderDefinition
          value={filterParams[filterDefinition.id]}
          key={filterDefinition.id}
          filterDefinition={filterDefinition}
          onChange={onChange}
          size={size}
        />
      ))}
      <div className="d-flex gap-3">
        {onReset && (
          <Button
            title="Ta bort alla filtreringar"
            onClick={onReset}
            variant="outline-primary"
            className="d-flex align-items-center justify-content-center"
            size={size}
            disabled={isLoading}
          >
            Återställ
          </Button>
        )}
        {columnOrder && setColumnOrder && columnDefinitions && (
          <TableSettingsColumnOrderButton
            columnDefinitions={columnDefinitions}
            columnOrder={columnOrder}
            setColumnOrder={setColumnOrder}
            size={size}
          />
        )}
        {onReload && (
          <RefreshButton size={size} onClick={onReload} disabled={isLoading} />
        )}
      </div>
    </Form>
  );
}

interface TableFilterRenderProps {
  value?: any;
  filterDefinition: FilterDefinition<any>;
  onChange: (id: string, value: any) => void;
  size?: 'sm';
}

function TableFilterRenderDefinition (props: TableFilterRenderProps) {
  const { value, filterDefinition, size, onChange } = props;
  const { id, label, validate, Render, extraProps = {} } = filterDefinition;
  return (
    <Render
      extraProps={extraProps}
      value={value}
      id={id}
      label={label ?? id}
      size={size}
      onChange={onChange}
      validate={validate}
    />
  );
}
