import { IValuationReportFormData } from 'client/valuationReport/types';
import { IIndustryReportFormData } from 'client/industryReport/types';
import { IReportQueueWithRelations } from 'client/reportQueue/types';

// used to recall to desired settings from a previous report queue
export function mergeIntoFormDataFromPreviousValuationReportQueue (formData: IValuationReportFormData, previousReportQueue?: null | IReportQueueWithRelations): IValuationReportFormData {
  if (!previousReportQueue?.report_settings) return formData;

  // set some defaults here in case some types change
  const {
    language = 'sv',
    deliver_email = true,
    deliver_printed = false,
    report_settings: {
      number_of_accounts,
      company_use_group_accounts,
      sni_code,
      nbr_employees_interval,
    },
  } = previousReportQueue;

  const recalled: any = {
    number_of_accounts,
    company_use_group_accounts,
    sni_code,
  };
  // may not exist in old valuations
  if (nbr_employees_interval) recalled.nbr_employees_interval = nbr_employees_interval;

  return {
    ...formData,
    language,
    deliver_email,
    deliver_printed,
    report_settings: {
      ...formData.report_settings,
      ...recalled,
    },
  };
}

// used to recall to desired settings from a previous report queue
export function mergeIntoFormDataFromPreviousIndustryReportQueue (formData: IIndustryReportFormData, previousReportQueue?: null | IReportQueueWithRelations): IIndustryReportFormData {
  if (!previousReportQueue?.report_settings) return formData;

  // set some defaults here in case some types change
  const {
    language = 'sv',
    deliver_email = true,
    deliver_printed = false,
    report_settings: {
      include_companies = [],
      company_use_group_accounts = false,
      company_exclude = false,
      minimum_number_of_companies_required = 0,
      sni_codes = [],
      branch_id = '',
    },
  } = previousReportQueue;

  return {
    ...formData,
    language,
    deliver_email,
    deliver_printed,
    report_settings: {
      ...formData.report_settings,
      include_companies,
      company_use_group_accounts,
      company_exclude,
      minimum_number_of_companies_required,
      sni_codes,
      branch_id,
    },
  };
}
