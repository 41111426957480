import { CustomerLogType, ICustomerLog } from 'client/customer/types';

interface ICustomerLogDescription {
  value: ICustomerLog;
}
export function CustomerLogDescription (props: ICustomerLogDescription) {
  const { value:log } = props;
  return <>{logTypeToDescription(log.type)}</>;
}

export function logTypeToDescription (type: CustomerLogType): string {
  switch (type) {
    default: return `Okänd loggtyp: ${String(type)}`;
    case 'created': return 'Kund skapad';
    case 'updated': return 'Kund uppdaterad';
    case 'report_downloaded': return 'Rapport nedladdad';
    case 'login_failed': return 'Misslyckad inloggning';
    case 'logout': return 'Utloggad';
    case 'login': return 'Inloggad';
  }
}
