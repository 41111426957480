import React, { useMemo } from 'react';
import TableColumnsSettingsModal, { SelectableColumn, columnDefinitionsToSelectableColumns } from 'client/modals/TableColumnsSettingsModal';
import { Settings } from 'react-feather';
import ModalOpeningButton from 'client/buttons/ModalOpeningButton';
import classNames from 'classnames';
import { isEqual } from 'lodash';

type TableColumnOrderDefinition = {
  id: any;
  label?: string;
  show?: (() => boolean) | boolean;
}

export interface TableSettingsColumnOrderButtonProps<T = string> {
  columnOrder: T[];
  setColumnOrder: React.Dispatch<React.SetStateAction<T[]>>;
  columnDefinitions: TableColumnOrderDefinition[];
  size?: 'sm' | 'lg';
  disabled?: boolean;
}

export default function TableSettingsColumnOrderButton (props: TableSettingsColumnOrderButtonProps) {
  const {
    columnOrder,
    setColumnOrder,
    columnDefinitions,
    size,
    disabled,
  } = props;

  let iconSize = 18, paddingClassName = '';
  if (size === 'sm') {
    paddingClassName = 'px-1';
    iconSize = 12;
  }
  if (size === 'lg') {
    paddingClassName = 'px-2';
    iconSize = 24;
  }

  const columnSettingsOrder = columnDefinitionsToSelectableColumns(columnDefinitions, columnOrder);

  const onSubmitColumnSettingsOrder = (columns: SelectableColumn[]) => {
    const newColumnOrder = columns.filter(c => c.show).map(c => c.id);
    setColumnOrder(newColumnOrder);
  };

  const onResetColumnSettingsOrder = () => {
    const defaultColumnOrder = columnDefinitions.filter(c => c.show).map(c => c.id);
    setColumnOrder(defaultColumnOrder);
  };

  const columnOrderIsDirty = useMemo(() => {
    const defaultColumnOrder = columnDefinitions.filter(c => c.show).map(c => c.id);
    return !isEqual([...columnOrder].sort(), [...defaultColumnOrder].sort());
  }, [columnDefinitions, columnOrder]);

  const modalProps = {
    onSubmit: onSubmitColumnSettingsOrder,
    onReset: onResetColumnSettingsOrder,
    columns: columnSettingsOrder,
  };

  return (
    <ModalOpeningButton
      title="Visa kolumninställningar"
      Modal={TableColumnsSettingsModal}
      modalProps={modalProps}
      variant="outline-primary"
      size={size}
      className={classNames(paddingClassName, 'd-flex gap-1 align-items-center position-relative', {
        'button-with-notify': columnOrderIsDirty,
      })}
      disabled={disabled}
    >
      <Settings size={iconSize} />
    </ModalOpeningButton>
  );
}
