import React from 'react';
import { BadgeProps } from 'react-bootstrap';
import { CustomerSurveyRow } from 'client/customerSurvey/types';
import StatusBadge from 'client/components/StatusBadge';
import { IdShort } from 'client/components/MiscFormatters';
import Anchor from 'client/anchors/Anchor';

interface CustomerSurveyAnchorProps {
  value: string;
}

export function CustomerSurveyAnchor (props: CustomerSurveyAnchorProps) {
  const { value:customerSurveyId } = props;
  return (
    <Anchor to={`/customer_survey/${customerSurveyId}/overview`}>
      <IdShort value={customerSurveyId} />
    </Anchor>
  );
}

interface StatusProps {
  value: CustomerSurveyRow['status'];
}

export function Status (props: StatusProps) {
  const { value } = props;
  switch (value) {
    default: return value;
    case 'unsent': return <>Oskickad</>;
    case 'sent': return <>Skickad</>;
    case 'opened': return <>Öppnad</>;
    case 'answered': return <>Besvarad</>;
    case 'expired': return <>Utgången</>;
  }
}

interface StatusLabelProps extends BadgeProps {
  value: CustomerSurveyRow['status'];
}

export function StatusLabel (props: StatusLabelProps) {
  const { value, ...restOfProps } = props;
  switch (value) {
    default: return <StatusBadge {...restOfProps}>{String(value)}</StatusBadge>;
    case 'unsent': return <StatusBadge bg="secondary" {...restOfProps}><Status value={value} /></StatusBadge>;
    case 'sent': return <StatusBadge bg="warning" {...restOfProps}><Status value={value} /></StatusBadge>;
    case 'opened': return <StatusBadge bg="warning" {...restOfProps}><Status value={value} /></StatusBadge>;
    case 'answered': return <StatusBadge bg="success" {...restOfProps}><Status value={value} /></StatusBadge>;
    case 'expired': return <StatusBadge bg="danger" {...restOfProps}><Status value={value} /></StatusBadge>;
  }
}
