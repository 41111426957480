import React from 'react';
import { Table, Col, Card, Spinner, Alert } from 'react-bootstrap';
import ErrorAlert from 'client/components/ErrorAlert';
import axios from 'client/axios';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import NumberFormat from 'client/components/NumberFormat';
import DateFormat from 'client/components/DateFormat';
import { ICsImported } from 'client/cs/types';
import moment from 'moment';
import RefreshButton from 'client/buttons/RefreshButton';
import { AlertCircle, ThumbsUp } from 'react-feather';

interface ResponseData {
  last: ICsImported | null;
  last_inc: ICsImported | null;
  last_base: ICsImported | null;
}

const DashboardCreditsafeStatisticCards: React.FC = React.memo(function DashboardCreditsafeStatisticCards () {

  const query = useQuery<ResponseData>({
    queryKey: ['DashboardCreditsafeStatisticCards'],
    queryFn: () => axios.get('/api/dashboard/statistics/cs_imports').then(r => r.data),
    placeholderData: keepPreviousData,
  });

  const data = query.data;

  return (
    <>
      {query.isError && (
        <Col sm={12}>
          <ErrorAlert error={query.error} />
        </Col>
      )}
      {query.isSuccess && (
        <Card className="mb-4">
          <Card.Header className="p-3">
            <Card.Title className="mb-0 d-flex justify-content-between" style={{fontSize: '0.9rem'}}>
              <div className="d-flex align-items-center gap-2">
                Senaste import av Creditsafe-data
                {(query.isLoading || query.isRefetching) && (
                  <Spinner size="sm" />
                )}
              </div>
              <RefreshButton
                size="sm"
                onClick={query.refetch}
                disabled={query.isLoading || query.isRefetching}
                className="px-1"
              />
            </Card.Title>
          </Card.Header>
          <div className="table-responsive">
            <Table size="sm" className="mb-0">
              <tbody>
                {data?.last_inc && (
                  <tr>
                    <th>Senaste inkrementell fil</th>
                    <td>{data?.last_inc?.zip_name}</td>
                    <td>
                      <AgeWarning
                        date={data.last_inc?.zip_date}
                        warningAge={2}
                        dangerAge={3}
                      />
                    </td>
                    <td>
                      <NumberFormat value={countRows(data.last_inc as ICsImported)} />
                      {' '}rader
                    </td>
                  </tr>
                )}
                {data?.last_base && (
                  <tr>
                    <th className="border-bottom-0">Senaste basfil</th>
                    <td className="border-bottom-0">{data?.last_base?.zip_name}</td>
                    <td className="border-bottom-0">
                      <AgeWarning
                        date={data.last_base?.zip_date}
                        warningAge={31}
                        dangerAge={33}
                      />
                    </td>
                    <td className="border-bottom-0">
                      <NumberFormat value={countRows(data.last_base as ICsImported)} />
                      {' '}rader
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
        </Card>
      )}
    </>
  );
});

export default DashboardCreditsafeStatisticCards;

interface AgeWarningProps {
  date?: string | Date;
  warningAge: number;
  dangerAge: number;
}

function AgeWarning (props: AgeWarningProps) {
  const { date, warningAge, dangerAge } = props;
  if (!date) return null;
  const daysAgo = moment().diff(date, 'days');
  const isWarning = moment(date).isBefore(moment().subtract(warningAge, 'days'));
  const isDanger = moment(date).isBefore(moment().subtract(dangerAge, 'days'));

  let variant = 'success';
  if (isDanger) variant = 'danger';
  else if (isWarning) variant = 'warning';

  return (
    <Alert variant={variant} className="px-1 py-0 d-inline-flex align-items-center gap-1 mb-0">
      {variant === 'success' ? (
        <ThumbsUp size={14} />
      ) : (
        <AlertCircle size={14} />
      )}
      <DateFormat value={date} format="YYYY-MM-DD" />
      <small>({daysAgo} dagar sedan)</small>
    </Alert>
  );
}

function countRows (row: ICsImported): number {
  const { result } = row;
  if (!result) return 0;
  let count = 0;
  for (const key in result) {
    const item = result[key];
    count += item.rowCount;
  }
  return count;
}
