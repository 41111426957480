import React from 'react';
import { Table } from 'react-bootstrap';
import Pluralizer from 'client/components/Pluralizer';
import DateFormat from 'client/components/DateFormat';
import InspectObjectModalButton from 'client/buttons/InspectObjectModalButton';
import * as MiscFormatters from 'client/components/MiscFormatters';
import { TableCardFooter, TableSpinningOverlay  } from 'client/table/TableUtils';
import { ICorporateTaxRate } from 'client/corporateTaxRate/types';
import NumberFormat from 'client/components/NumberFormat';

export interface CorporateTaxRateTableProps {
  list: ICorporateTaxRate[];
  isLoading: boolean;
  isSuccess: boolean;
}

const CorporateTaxRateTable: React.FC<CorporateTaxRateTableProps> = React.memo(function CorporateTaxRateTable (props: CorporateTaxRateTableProps) {
  const { list, isLoading, isSuccess } = props;

  return (
    <div>
      <TableSpinningOverlay isLoading={isLoading}>
        <div className="table-responsive">
          <Table className="mb-0">
            <thead>
              <tr>
                <th>Datum</th>
                <th>Skattesats</th>
                <th>Skapad</th>
                <th className="text-end">Åtgärd</th>
              </tr>
            </thead>
            <tbody>
              {list.map(item => (
                <tr key={item.id}>
                  <td><MiscFormatters.PgRange value={item.range} /></td>
                  <td><NumberFormat value={item.rate} /> %</td>
                  <td><DateFormat value={item.created_at} format="YYYY-MM-DD" /></td>
                  <td>
                    <div className="d-flex gap-1 flex-wrap align-items-center justify-content-end">
                      <InspectObjectModalButton object={item} />
                    </div>
                  </td>
                </tr>
              ))}
              {isSuccess && !list.length && (
                <tr>
                  <td colSpan={5} className="p-2">Det finns ingenting här</td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      </TableSpinningOverlay>
      <TableCardFooter>
        <p className="mb-0">
          Totalt {list.length}{' '}
          <Pluralizer
            count={list.length}
            zero="rader"
            one="rad"
            more="rader"
          /> i denna tabell
        </p>
      </TableCardFooter>
    </div>
  );
});
export default CorporateTaxRateTable;
