import React, { useMemo, useState, useId, useRef } from 'react';
import CompanyNameAndAddress from 'client/company/CompanyNameAndAddress';
import ErrorAlert from 'client/components/ErrorAlert';
import { TagFormList, TagFormAddOrgNumber } from 'client/components/TagFormTools';
import InspectObjectModalButton from 'client/buttons/InspectObjectModalButton';
import { OrgNumberToCompanyName } from 'client/components/OrgNumberInput';
import {
  ReportQueueMetaFields,
  SaveOnCustomerCard,
  ReportQueueUpdateAlert,
} from 'client/reportQueue/ReportQueueFormHelpers';
import RoleGuard from 'client/guards/RoleGuard';
import LoadingButton from 'client/buttons/LoadingButton';
import IndustryReportBranchAndSNICodeSelector from 'client/industryReport/IndustryReportBranchAndSNICodeSelector';
import useAuth from 'client/hooks/useAuth';
import IndustryReportCompanyAccountsEditor from 'client/industryReport/IndustryReportCompanyAccountsEditor';
import IndustryReportMinimumNumberOfCompaniesRequiredInput from 'client/industryReport/IndustryReportMinimumNumberOfCompaniesRequiredInput';
import * as reportQueueUtils from 'client/reportQueue/utils';
import {
  AccountDateInputGroup,
  BooleanFormCheck,
  RecallPreviousReportQueueFormDataNotice,
  LanguageChooser,
  DeliveryPreferences,
  CompanyExcludeCheckbox,
  IsDemoCheckbox,
  CompanyAccountsOverrideCheckbox,
} from 'client/components/ReportFormHelpers';
import {
  IIndustryReportFormData,
  IIndustryReportsGetCompanyInfoData,
  IIndustryReportsGetReportQueueInfoData,
} from 'client/industryReport/types';
import { Card, Form } from 'react-bootstrap';
import { ICustomer } from 'client/customer/types';
import useReportQueueFormTools from 'client/hooks/useReportQueueFormTools';
import {
  ICsAccountsExtendedIndustryReport,
  ICsGroupAccountsExtendedIndustryReport,
} from 'client/cs/types';
import { TErrorLike } from 'client/utils/errors';
import HelperTooltip from 'client/components/HelperTooltip';

interface IIndustryReportForm {
  isEditForm?: boolean;
  data: IIndustryReportsGetCompanyInfoData | IIndustryReportsGetReportQueueInfoData;
  form: IIndustryReportFormData;
  setForm: React.Dispatch<React.SetStateAction<IIndustryReportFormData>>;
  onSubmitForm: () => void;
  submitError?: TErrorLike;
  submitIsLoading?: boolean;
}

type TIndustryReportAccounts = ICsAccountsExtendedIndustryReport[] | ICsGroupAccountsExtendedIndustryReport[] | null;

const IndustryReportForm: React.FC<IIndustryReportForm> = React.memo(function IndustryReportForm (props: IIndustryReportForm) {
  const {
    data,
    form,
    setForm,
    submitError,
    submitIsLoading,
    onSubmitForm,
    isEditForm,
  } = props;

  const {
    customer,
    branches,
    company,
    groupAccounts,
    groupStructure,
  } = data;
  const previousReportQueue = (data as IIndustryReportsGetCompanyInfoData).previousReportQueue;

  const {
    onChangeValue,
    onChangeAccountsOverrideValue,
    onChangeUseGroupAccounts,
    accounts,
    originalAccounts,
    onChangeAccountsTableSettings,
  } = useReportQueueFormTools({
    data,
    form,
    setForm,
    isEditForm,
  });

  const [customerData, setCustomerData] = useState<Partial<ICustomer> | null>(customer);

  const onSubmit = (ev: React.FormEvent<HTMLFormElement>) => {
    ev.preventDefault();
    onSubmitForm();
  };
  
  const formId = useId();
  const formRef = useRef<HTMLFormElement>(null);

  const addOrganisationNumberId = useId();

  // recalls the customer id from a previously created report queue/industry report
  const onClickRecallCustomerId = () => {
    if (!previousReportQueue?.customer_id) return;
    const { customer_id } = previousReportQueue;
    setCustomerData(previousReportQueue.customer as ICustomer);
    setForm(prevForm => ({
      ...prevForm,
      customer_id,
    }));
  };

  // recalls some chosen form data from a previously __created__ report queue/industry report
  const onClickRecallFormData = () => {
    setForm(prevForm => {
      return reportQueueUtils.mergeIntoFormDataFromPreviousIndustryReportQueue(prevForm, previousReportQueue);
    });
  };

  const defaultBranchIdValue = useMemo(() => {
    if (isEditForm) {
      const { reportQueue } = (data as IIndustryReportsGetReportQueueInfoData);
      return reportQueue?.report_settings?.branch_id ?? null;
    }
    const { form } = (data as IIndustryReportsGetCompanyInfoData);
    return form?.report_settings?.branch_id ?? null;
  }, [data, isEditForm]);

  const auth = useAuth();

  return (
    <Form
      id={formId}
      onSubmit={onSubmit}
      ref={formRef}
    >
      <Card>
        <Card.Header className="py-3">
          <CompanyNameAndAddress company={company} />

          {previousReportQueue && (
            <RecallPreviousReportQueueFormDataNotice
              title="branschrapport"
              previousReportQueue={previousReportQueue}
              currentCustomerId={form.customer_id}
              onClickRecallCustomerId={onClickRecallCustomerId}
              onClickRecallFormData={onClickRecallFormData}
            />
          )}

        </Card.Header>
        <Card.Body className="border-bottom">

          <SaveOnCustomerCard
            name="customer_id"
            companyData={company}
            customerId={form.customer_id}
            onChangeValue={onChangeValue}
            customerRequired={!auth.isUserRole('admin')}
            customerEmailRequired={form.deliver_email}
            customerData={customerData}
            setCustomerData={setCustomerData}
          />

        </Card.Body>

        <Card.Body className="border-bottom d-flex">
          <LanguageChooser
            name="language"
            value={form.language}
            onChangeValue={onChangeValue}
          />
        </Card.Body>

        <Card.Body className="border-bottom d-flex">
          <DeliveryPreferences
            customerData={customerData}
            form={form}
            onChangeValue={onChangeValue}
          />
        </Card.Body>

        <Card.Body className="border-bottom">
          <IndustryReportBranchAndSNICodeSelector
            branches={branches}
            branchIdValue={form.report_settings.branch_id}
            defaultBranchIdValue={defaultBranchIdValue}
            sniCodesValue={form.report_settings.sni_codes}
            onChangeValue={onChangeValue}
          />
        </Card.Body>

        <Card.Body className="border-bottom">
          <Form.Label htmlFor={addOrganisationNumberId}>
            Inkludera ytterligare företag i branschrapporten
          </Form.Label>
          <div className="d-flex flex-wrap gap-3">
            <TagFormList
              name="report_settings.include_companies"
              values={form.report_settings.include_companies}
              onChangeValues={onChangeValue}
              valueFormatter={OrgNumberToCompanyName}
            />
            <TagFormAddOrgNumber
              id={addOrganisationNumberId}
              name="report_settings.include_companies"
              values={form.report_settings.include_companies}
              onChangeValues={onChangeValue}
              blockedValues={[form.report_org_number]}
            />
          </div>
        </Card.Body>

        <Card.Body className="d-flex gap-3 border-bottom flex-wrap">
          <AccountDateInputGroup
            form={form}
            onChangeValue={onChangeValue}
          />
        </Card.Body>

        <Card.Body className="border-bottom d-flex">
          <IndustryReportMinimumNumberOfCompaniesRequiredInput
            onChangeValue={onChangeValue}
            form={form}
          />
        </Card.Body>

        <Card.Body>

          <CompanyExcludeCheckbox
            form={form}
            onChangeValue={onChangeValue}
          />

          <IsDemoCheckbox
            form={form}
            onChangeValue={onChangeValue}
          />

          <CompanyAccountsOverrideCheckbox
            form={form}
            onChangeAccountsOverrideValue={onChangeAccountsOverrideValue}
          />

          {groupStructure && groupAccounts && (
            <>
              <BooleanFormCheck
                label={<>Använd koncerndata från {groupStructure.company_name} ({groupStructure.org_number}) <HelperTooltip>Istället för företagets bokslut används koncernens bokslut i branschrapporten. Övriga bolag i branschrapporten påverkas ej och kommer fortfarande använda sina normala bokslut.</HelperTooltip></>}
                name="report_settings.company_use_group_accounts"
                checked={form.report_settings.company_exclude ? false : form.report_settings.company_use_group_accounts}
                onChangeValue={onChangeUseGroupAccounts}
                disabled={form.report_settings.company_exclude}
              />
              {form.report_settings.company_accounts_override && (
                <div className="alert alert-warning small mb-0 mt-2 p-1">
                  <strong>OBS!</strong>{' '}
                  Ändring av inställningen för koncerndata kommer att ladda in nya bokslut och skriva över dina ändringar av företagets bokslut.
                </div>
              )}
            </>
          )}

        </Card.Body>

        <RoleGuard role={null}>
          {form.report_settings.company_accounts_override && (
            <Card.Body className="p-0">
              <IndustryReportCompanyAccountsEditor
                name="report_settings.company_accounts"
                accounts={(accounts as TIndustryReportAccounts) ?? []}
                originalAccounts={(originalAccounts as TIndustryReportAccounts) ?? []}
                onChangeValue={onChangeValue}
                settings={form.form_options?.accounts_table}
                onChangeSetting={onChangeAccountsTableSettings}
                templateDateFrom={form.report_settings.accounts_from}
              />
            </Card.Body>
          )}
        </RoleGuard>

        <div className="p-0 border-top">
          <Card.Title className="mx-3 mt-3 mb-0 border-bottom pb-1">Inställningar för rapportkö</Card.Title>
          <div className="px-3">
            <ReportQueueMetaFields
              isEditForm={isEditForm}
              form={form}
              onChangeValue={onChangeValue}
            />
          </div>
        </div>

        <Card.Footer className="p-2">
          <ErrorAlert error={submitError} className="mb-2" />
          {isEditForm && Boolean((data as IIndustryReportsGetReportQueueInfoData).reportQueue.industry_report) && (
            <ReportQueueUpdateAlert />
          )}
          <div className="d-flex justify-content-end gap-2">
            <InspectObjectModalButton
              object={{form, data}}
              className="px-2 d-flex align-items-center"
              size="lg"
            />
            <LoadingButton
              type="submit"
              isLoading={submitIsLoading}
              disabled={submitIsLoading}
            >
              {isEditForm ? 'Uppdatera branschrapport' : 'Skapa branschrapport'}
            </LoadingButton>
          </div>
        </Card.Footer>
      </Card>
    </Form>
  );
});
export default IndustryReportForm;
