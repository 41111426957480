import React from 'react';
import { IIndustryReportFormData } from 'client/industryReport/types';
import { NumberSuffixInput } from 'client/components/ReportFormHelpers';
import HelperTooltip from 'client/components/HelperTooltip';

interface IndustryReportMinimumNumberOfCompaniesRequiredInputProps {
  onChangeValue: (name: string, value: any) => void;
  form: IIndustryReportFormData;
}

const IndustryReportMinimumNumberOfCompaniesRequiredInput: React.FC<IndustryReportMinimumNumberOfCompaniesRequiredInputProps> = React.memo(function IndustryReportMinimumNumberOfCompaniesRequiredInput (props: IndustryReportMinimumNumberOfCompaniesRequiredInputProps) {
  const { form, onChangeValue } = props;

  return (
    <div className="d-flex">
      <NumberSuffixInput
        label={
          <>
            Minsta antal filtrerade företag{' '}
            <HelperTooltip>
              Anger det minsta antalet företag som måste kvala in i branschrapporten för att rapporten ska skapas.
              <br /><br />
              Är antalet inkvalade företag färre så kommer produktion av rapporten att skjutas fram.
            </HelperTooltip>
          </>
        }
        suffix="st"
        min={0}
        value={form.report_settings.minimum_number_of_companies_required}
        name="report_settings.minimum_number_of_companies_required"
        onChangeValue={onChangeValue}
      />
    </div>
  );
});
export default IndustryReportMinimumNumberOfCompaniesRequiredInput;
