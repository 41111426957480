import { PdfView } from 'client/pdf/PdfView';

export default function PdfPage () {
  return (
    <div className="clc-pdf-player">
      <div className="container-sm p-4 d-flex justify-content-center">
        <PdfView file={'/report.pdf'} />
      </div>
    </div>
  );
}
