import React from 'react';
import { Card, Table, Spinner } from 'react-bootstrap';
import ErrorAlert from 'client/components/ErrorAlert';
import axios from 'client/axios';
import { UserAnchor } from 'client/user/UserFormatters';
import { ReportQueueAnchor } from 'client/reportQueue/ReportQueueFormatters';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import InspectObjectModalButton from 'client/buttons/InspectObjectModalButton';
import { IReportQueueWithRelations } from 'client/reportQueue/types';
import RefreshButton from 'client/buttons/RefreshButton';

const DashboardReportQueueErrorTableCard: React.FC = React.memo(function DashboardReportQueueErrorTableCard () {

  const query = useQuery<IReportQueueWithRelations[]>({
    queryKey: ['DashboardReportQueueErrorTableCard'],
    queryFn: () => axios.get('/api/dashboard/report_queue/error').then(r => r.data),
    placeholderData: keepPreviousData,
  });

  const data = query?.data || [];

  return (
    <Card>
      <Card.Header className="p-3">
        <Card.Title className="mb-0 d-flex justify-content-between" style={{fontSize: '0.9rem'}}>
          <div className="d-flex align-items-center gap-2">
            10 senaste rapporterna med problem
            {(query.isLoading || query.isRefetching) && (
              <Spinner size="sm" />
            )}
          </div>
          <RefreshButton
            size="sm"
            onClick={query.refetch}
            disabled={query.isLoading || query.isRefetching}
            className="px-1"
          />
        </Card.Title>
      </Card.Header>
      <ErrorAlert error={query.error} className="m-0" />
      {!query.isFetched && <Card.Body></Card.Body>}
      {query.isSuccess && (
        <div className="table-responsive">
          <Table size="sm" className="mb-0">
            <thead>
              <tr>
                <th>ID</th>
                <th>Felmeddelande</th>
                <th>Skapad av</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {!data.length && (
                <tr>
                  <td colSpan={4} className="border-bottom-0">Det finns ingenting här</td>
                </tr>
              )}
              {data.map((item, index) => (
                <tr key={item.id}>
                  <td className={index + 1 === data.length ? 'border-bottom-0' : ''}>
                    <ReportQueueAnchor value={item} />
                  </td>
                  <td className={index + 1 === data.length ? 'border-bottom-0' : ''}>
                    <small>{item.error_message}</small>
                  </td>
                  <td className={index + 1 === data.length ? 'border-bottom-0' : ''}>
                    {item.creator && <UserAnchor value={item.creator} />}
                  </td>
                  <td className={index + 1 === data.length ? 'border-bottom-0' : ''}>
                    <div className="d-flex gap-1 justify-content-end flex-wrap align-items-center">
                      <InspectObjectModalButton object={item} />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      )}
    </Card>
  );
});
export default DashboardReportQueueErrorTableCard;
