import React from 'react';
import { Alert, AlertProps } from 'react-bootstrap';
import { errorToMessage, TErrorLike } from 'client/utils/errors';

// TODO errorToDetails function and button to open it

interface IProps extends AlertProps {
  error?: TErrorLike;
}

const ErrorAlert: React.FC<IProps> = React.memo(function ErrorAlert (props: IProps) {
  const { error, ...restOfProps } = props;
  if (!error) return null;
  const message = typeof error === 'string' ? error : errorToMessage(error);
  return (
    <Alert variant="danger" {...restOfProps}>
      {message}
    </Alert>
  );
});

export default ErrorAlert;
