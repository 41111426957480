import React from 'react';
import countries, { CountryCode } from 'client/utils/countries';

const UNICODE_OFFSET = 127397;

export function SwedishFlag (props: any) {
  return (
    <FlagContainer {...props}>🇸🇪</FlagContainer>
  );
}

export function EnglishFlag (props: any) {
  return (
    <FlagContainer {...props}>🇬🇧</FlagContainer>
  );
}

interface FlagProps {
  language: 'sv' | 'en';
}

export function LanguageFlag (props: FlagProps) {
  const { language } = props;
  if (language === 'sv') return <SwedishFlag />;
  if (language === 'en') return <EnglishFlag />;
  return null;
}

interface FlagContainerProps extends React.HTMLAttributes<HTMLSpanElement> {
  size: number;
}

export function FlagContainer (props: FlagContainerProps) {
  const { size, style:outerStyle = {}, children, ...restOfProps } = props;
  const style = {...outerStyle, lineHeight: `${size}px`, fontSize: `${size}px`};
  return (
    <span className="flag" {...restOfProps} style={style}>{children}</span>
  );
}

interface CountryFlagProps {
  countryCode: CountryCode;
}

export function CountryFlag (props: CountryFlagProps) {
  const { countryCode } = props;
  if (!countryCode) return null;
  return (
    <abbr style={{textDecoration: 'none'}} title={countryCodeToName(countryCode)}>
      {countryCodeToFlag(countryCode)}
    </abbr>
  );
}

function countryCodeToFlag (countryCode: CountryCode): string {
  if (typeof countryCode !== 'string' || countryCode.length !== 2) {
    return '';
  }
  const codePoints: number[] = countryCode.split('').map(c => {
    return (c.codePointAt(0) ?? 0) + UNICODE_OFFSET;
  }).filter(v => v);
  return String.fromCodePoint(...codePoints);
}

function countryCodeToName (countryCode: CountryCode | CountryCode[]): string {
  if (Array.isArray(countryCode)) return countryCode.map(c => countries[c]).join(', ');
  return countries[countryCode];
}
