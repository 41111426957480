import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { Modal } from 'react-bootstrap';
import { IEmail } from 'client/email/types';
import ErrorAlert from 'client/components/ErrorAlert';
import BlockSpinner from 'client/spinners/BlockSpinner';

type EmailQueryResponse = {
  email: IEmail;
}

export interface EmailRenderModalProps {
  emailId: string;
  show: boolean;
  onHide: () => void;
  onExited: () => void;
}

const EmailRenderModal: React.FC<EmailRenderModalProps> = React.memo(function InspectObjectModal (props: EmailRenderModalProps) {
  const {
    show,
    emailId,
    onHide,
    onExited,
  } = props;

  const query = useQuery<EmailQueryResponse, Error>({
    queryKey: [`/api/emails/${emailId}/render`],
  });

  const data = query.data || null;

  return (
    <Modal show={show} onHide={onHide} onExited={onExited} size="lg">
      <Modal.Header>
        {data && <Modal.Title>{data.email.subject}</Modal.Title>}
      </Modal.Header>
      <Modal.Body className="m-2">
        <ErrorAlert error={query.error} />
        <BlockSpinner isLoading={query.isLoading}  />
        {query.isSuccess && data && (
          <div dangerouslySetInnerHTML={{__html: data.email.html ?? ''}} />
        )}
      </Modal.Body>
    </Modal>
  );
});
export default EmailRenderModal;
