import React, { useCallback } from 'react';
import { Button, Form } from 'react-bootstrap';
import { preventDefaultSubmit } from 'client/utils/form';
import { CompanyMonitoredUpdateTableFilterParams } from 'client/companyMonitoredUpdate/CompanyMonitoredUpdateTablePage';
import RefreshButton from 'client/buttons/RefreshButton';
import TableSettingsColumnOrderButton, { TableSettingsColumnOrderButtonProps } from 'client/table/TableSettingsColumnOrderButton';
import { TableFilterInputGroup } from 'client/table/TableUtils';

interface CompanyMonitoredUpdateTableFilterFormProps extends Pick<TableSettingsColumnOrderButtonProps, 'columnOrder' | 'setColumnOrder' | 'columnDefinitions'> {
  filterParams: CompanyMonitoredUpdateTableFilterParams;
  setFilterParams: React.Dispatch<React.SetStateAction<CompanyMonitoredUpdateTableFilterParams>>;
  onRefetch: () => void;
  onReset: () => void;
  isLoading: boolean;
}

export default function CompanyMonitoredUpdateTableFilterForm (props: CompanyMonitoredUpdateTableFilterFormProps) {
  const {
    isLoading,
    onRefetch,
    onReset,
    filterParams,
    setFilterParams,
    columnDefinitions,
    columnOrder,
    setColumnOrder,
  } = props;

  const onChange = useCallback((ev: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const { name, value } = ev.target;
    setFilterParams({[name]: value});
  }, [filterParams, setFilterParams]);

  return (
    <Form className="d-flex justify-content-between gap-3" onSubmit={preventDefaultSubmit}>
      <TableFilterInputGroup name="org_number" label="Företag" className="flex-grow-1">
        <Form.Control
          isValid={Boolean(filterParams.org_number)}
          name="org_number"
          onChange={onChange}
          placeholder="Oavsett organisationsnummer"
          value={filterParams.org_number ?? ''}
        />
      </TableFilterInputGroup>
      <TableFilterInputGroup name="change_code" label="Kod" className="flex-grow-1">
        <Form.Control
          isValid={Boolean(filterParams.change_code)}
          name="change_code"
          onChange={onChange}
          placeholder="Oavsett kod"
          value={filterParams.change_code ?? ''}
        />
      </TableFilterInputGroup>
      <TableFilterInputGroup name="change_date_from" label="Datum från" className="flex-grow-1">
        <Form.Control
          isValid={Boolean(filterParams.change_date_from)}
          name="change_date_from"
          type="date"
          onChange={onChange}
          placeholder="Oavsett datum från"
          value={filterParams.change_date_from ?? ''}
        />
      </TableFilterInputGroup>
      <TableFilterInputGroup name="change_date_to" label="Datum till" className="flex-grow-1">
        <Form.Control
          isValid={Boolean(filterParams.change_date_to)}
          name="change_date_to"
          type="date"
          onChange={onChange}
          placeholder="Oavsett datum till"
          value={filterParams.change_date_to ?? ''}
        />
      </TableFilterInputGroup>
      <div className="d-flex gap-3">
        <Button
          title="Ta bort alla filtreringar"
          onClick={onReset}
          variant="outline-primary"
          className="d-flex align-items-center justify-content-center"
          disabled={isLoading}
        >
          Återställ
        </Button>
        <TableSettingsColumnOrderButton
          columnDefinitions={columnDefinitions}
          columnOrder={columnOrder}
          setColumnOrder={setColumnOrder}
        />
        <RefreshButton onClick={onRefetch} disabled={isLoading} />
      </div>
    </Form>
  );
}

