import React, { useState, useId, useEffect, useRef } from 'react';
import { AsyncTypeahead, TypeaheadComponentProps } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { ICompaniesSearchDTO, useCompanySearch } from 'client/components/OrgNumberInput';
import {Option} from 'react-bootstrap-typeahead/types/types';

interface CompanySearchProps {
  defaultInputValue?: string;
  className?: string;
  onSelect: (orgNumber: string) => void;
  searchUrl?: string;
  disabled?: boolean;
}

export default React.memo(function CompanySearch (props: CompanySearchProps) {
  const { onSelect, disabled, className, searchUrl, defaultInputValue = '' } = props;
  const ref = useRef<any>();
  const id = useId();
  const [value, setValue] = useState<string>(defaultInputValue);

  const { data: searchResults, isFetching } = useCompanySearch({ 
    search: value,
    url: searchUrl,
  });

  const onInvalidValue = () => onSelect('');

  useEffect(() => {
    if (isFetching) return;
    if (!searchResults || searchResults.length === 0) {
      onInvalidValue();
    } else if (searchResults) {
      const selected = searchResults.find(c => c.org_number === value);
      if (selected) {
        onSelect(selected.org_number);
      }
    }
  }, [searchResults, isFetching]);

  const onPaste = (ev: React.ClipboardEvent<HTMLInputElement>) => {
    let text = ev.clipboardData.getData('text');
    // if the users pastes a string of numbers and dashes, we remove all dashes
    if (/^[-\d\s]+$/.test(text)) {
      ev.preventDefault();
      text = text.replace(/\D/g, '');
      ev.currentTarget.value = text;

      ref.current.setState({
        ...ref.current.state,
        text,
        selected: [],
      });
      // onSearch is not called in this case, so setValue manually
      onSearch(text);
    }
  };

  const onChange = (selected: ICompaniesSearchDTO[] | TypeaheadComponentProps['options']) => {
    const option = selected[0];
    if (!option || typeof option === 'string') return;
    onSelect(option.org_number);
  };

  const onSearch = (query: string) => {
    setValue(query);
    onInvalidValue();
  };

  const onInputChange = (query: string) => {
    if (!query || query === '') onInvalidValue();
  };

  const inputProps = {
    onPaste,
    name: 'org_number',
  };

  return (
    <AsyncTypeahead
      ref={ref}
      id={id}
      delay={200}
      className={className}
      filterBy={() => true}
      useCache={false}
      labelKey="org_number"
      disabled={disabled}
      searchText="Söker…"
      placeholder="Sök på orgnr/namn"
      promptText="Skriv något för att söka"
      emptyLabel="Inga sökresultat"
      options={searchResults || []}
      isLoading={isFetching}
      onChange={onChange}
      onSearch={onSearch}
      onInputChange={onInputChange}
      defaultInputValue={defaultInputValue}
      renderMenuItemChildren={MenuItemChildren}
      inputProps={inputProps}
      highlightOnlyResult
    />
  );
});

function MenuItemChildren (props: Option) {
  return (
    <div className="py-1">
      <div style={{fontWeight: 500}}>{(props as ICompaniesSearchDTO).org_number}</div>
      <div>{(props as ICompaniesSearchDTO).company_name}</div>
    </div>
  );
}
