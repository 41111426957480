import React, { useState, useId } from 'react';
import axios from 'client/axios';
import { useMutation } from '@tanstack/react-query';
import moment from 'moment';
import LoadingButton from 'client/buttons/LoadingButton';
import ErrorAlert from 'client/components/ErrorAlert';
import { isFiniteNumber } from 'client/utils/number';
import { Form } from 'react-bootstrap';

interface ICountCompaniesMutationVars {
  year: number;
  branch_ids: string[];
}

export interface ICountCompaniesMutationDataItem {
  branch_id: string;
  branch_name: string;
  count_companies: number;
  count_companies_filtered: null | number;
}

export type TCountCompaniesMutationData = ICountCompaniesMutationDataItem[];

interface IBranchTableCountCompaniesForm {
  branchIds: string[];
  onCompaniesCounted: (result: TCountCompaniesMutationData) => void;
}

const BranchTableCountCompaniesForm: React.FC<IBranchTableCountCompaniesForm> = React.memo(function BranchTableCountCompaniesForm (props: IBranchTableCountCompaniesForm) {
  const { branchIds, onCompaniesCounted } = props;

  const thisYear = moment().subtract(1, 'years').format('YYYY');

  const yearId = useId();
  const [year, setYear] = useState<string>(thisYear);

  const countCompaniesMutation = useMutation<TCountCompaniesMutationData, Error, ICountCompaniesMutationVars>({
    mutationFn: vars => axios.post('/api/branch/count_companies', vars).then(r => r.data),
    onSuccess: data => onCompaniesCounted(data),
  });

  const onChangeYear = (ev: React.ChangeEvent<HTMLInputElement>) => {
    setYear(ev.target.value);
  };

  const onSubmit = (ev: React.FormEvent<HTMLFormElement>) => {
    ev.preventDefault();
    countCompaniesMutation.mutate({
      year: parseInt(year, 10),
      branch_ids: branchIds,
    });
  };

  return (
    <Form onSubmit={onSubmit} className="d-flex gap-3">
      <Form.Group className="d-flex gap-3 align-items-baseline">
        <Form.Label htmlFor={yearId}>Bokslutsår</Form.Label>
        <Form.Control
          id={yearId}
          type="number"
          value={year}
          onChange={onChangeYear}
          htmlSize={4}
          step={1}
          required
          max={thisYear}
        />
      </Form.Group>
      <LoadingButton
        type="submit"
        variant="outline-primary"
        disabled={countCompaniesMutation.isPending || !isFiniteNumber(parseInt(year, 10))}
        isLoading={countCompaniesMutation.isPending}
      >
        Räkna företag
      </LoadingButton>
      <ErrorAlert error={countCompaniesMutation.error} />
    </Form>
  );
});
export default BranchTableCountCompaniesForm;
