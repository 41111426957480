import React from 'react';
import { useQuery } from '@tanstack/react-query';
import ErrorAlert from 'client/components/ErrorAlert';
import { useParams } from 'react-router-dom';
import { Card, Tab, Nav, Row, Col } from 'react-bootstrap';
import TabNavLink from 'client/tab/TabHelpers';
import OverlaySpinner from 'client/spinners/OverlaySpinner';
import { Helmet } from 'react-helmet';
import { CardHeaderObject } from 'client/card/CardHelpers';
import CompanySNICodeEditor from 'client/company/CompanySNICodeEditor';
import InspectObjectModalButton from 'client/buttons/InspectObjectModalButton';
import { ICsBasicExtendedWithRelations } from 'client/cs/types';
import PageHeader from 'client/components/PageHeader';
import * as CompanyInfoCards from 'client/company/CompanyInfoCards';
import { CompanyValuationReportTable, CompanyIndustryReportTable } from 'client/company/CompanyReportTables';
import CompanyCreditReportTable from 'client/company/CompanyCreditReportTable';
import CompanyAccountsTable from 'client/company/CompanyAccountsTable';
import CompanyAccountsChartCard from 'client/company/CompanyAccountsChartCard';
import CompanyAsCustomer from 'client/company/CompanyAsCustomer';
import CompanyUnitsList from 'client/company/CompanyUnitsList';
import CompanyGroupStructureCard from 'client/company/CompanyGroupStructureCard';
import { StatusLabel } from 'client/company/CompanyFormatters';
import RoleGuard from 'client/guards/RoleGuard';
import CompanyMonitoredCard from 'client/companyMonitored/CompanyMonitoredCard';
import CompanyCommentCard from 'client/companyComment/CompanyCommentCard';

interface CompanyPageParams {
  orgNumber: string;
  tab?: string;
  subTab?: string;
}

interface CompanyPageReadRelationCountResponse {
  comments: number;
  credit_reports: number;
  valuation_reports: number;
  industry_reports: number;
  report_queues: number;
  cs_accounts: number;
  cs_groupaccounts: number;
  cs_units: number;
}

const CompanyPage: React.FC = React.memo(function CompanyPage () {
  const { orgNumber, tab = 'overview', subTab } = useParams<CompanyPageParams>();

  const readQuery = useQuery<ICsBasicExtendedWithRelations, Error>({
    queryKey: [`/api/companies/${orgNumber}`],
  });

  const readRelationCountsQuery = useQuery<CompanyPageReadRelationCountResponse, Error>({
    queryKey: [`/api/companies/${orgNumber}/relationCounts`],
  });

  const company = readQuery.data;
  const counts = readRelationCountsQuery.data;
  const isLoading = readQuery.isLoading || readQuery.isRefetching || readRelationCountsQuery.isLoading || readRelationCountsQuery.isRefetching;

  const hasGroupAccounts = (counts?.cs_groupaccounts ?? 0) > 0;
  const requiresCreditReportInquiryCopy = ['EF', 'HB/KB'].includes(company?.legalgroup_code ?? '');

  return (
    <div className="container-sm p-4">
      <Helmet>
        <title>Företag "{orgNumber}"</title>
      </Helmet>
      <PageHeader breadcumbs={[{title: 'Alla företag', url: '/companies'}, {title: orgNumber}]}>
        Företag
      </PageHeader>
      <Card>
        <CardHeaderObject
          objectId={orgNumber}
          extra={company?.company_name ?? ''}
          onRefresh={readQuery.refetch}
          status={<>
            {company && (
              <StatusLabel value={company.status_text_high} />
            )}
          </>}
        />
        <OverlaySpinner isLoading={isLoading} />
        <ErrorAlert error={readQuery.error} className="m-3" />
        <ErrorAlert error={readRelationCountsQuery.error} className="m-3" />
        {readQuery.isSuccess && company && (
          <>
            <CompanyAsCustomer company={company} />
            <Tab.Container
              defaultActiveKey="overview"
              activeKey={tab}
              transition={false}
              mountOnEnter
              unmountOnExit
            >
              <Nav className="nav-tabs pt-3 px-3">
                <TabNavLink eventKey="overview" title="Översikt" urlParamNullKeys={['subTab']} />
                <TabNavLink
                  eventKey="comments"
                  title="Kommentarer"
                  count={counts?.comments}
                  urlParamNullKeys={['subTab']}
                />
                <TabNavLink
                  eventKey="units"
                  title="Driftställen"
                  count={counts?.cs_units}
                  urlParamNullKeys={['subTab']}
                />
                <TabNavLink
                  eventKey="accounts"
                  title="Bokslut"
                  count={counts?.cs_accounts}
                  urlParamNullKeys={['subTab']}
                />
                {hasGroupAccounts && (
                  <TabNavLink
                    eventKey="groupaccounts"
                    title="Koncernbokslut"
                    count={counts?.cs_groupaccounts}
                    urlParamNullKeys={['subTab']}
                  />
                )}
                <TabNavLink
                  eventKey="valuation_reports"
                  title="Värderingar"
                  count={counts?.valuation_reports}
                  urlParamNullKeys={['subTab']}
                />
                <TabNavLink
                  eventKey="industry_reports"
                  title="Branschrapporter"
                  count={counts?.industry_reports}
                  urlParamNullKeys={['subTab']}
                />
                {!requiresCreditReportInquiryCopy && (
                  <TabNavLink
                    eventKey="credit_reports"
                    title="Kreditupplysningar"
                    count={counts?.credit_reports}
                    urlParamNullKeys={['subTab']}
                  />
                )}
                {!requiresCreditReportInquiryCopy && (
                  <RoleGuard role="admin">
                    <TabNavLink eventKey="monitored" title="Bevakning" />
                  </RoleGuard>
                )}
                <TabNavLink eventKey="settings" title="Inställningar" urlParamNullKeys={['subTab']} />
              </Nav>
              <Tab.Content>
                <Tab.Pane eventKey="overview" className="px-4 pt-4">
                  <Row>
                    <Col lg={4}>
                      <CompanyInfoCards.CompanyInfoCardOverview company={company} className="mb-4" />
                      <CompanyInfoCards.CompanyInfoCardContactDetails company={company} className="mb-4" />
                      <CompanyInfoCards.CompanyInfoPostAddressCard company={company} className="mb-4" />
                      <CompanyInfoCards.CompanyInfoRegisteredAddressCard company={company} className="mb-4" />
                      <CompanyInfoCards.CompanyInfoVisitingAddressCard company={company} className="mb-4" />
                    </Col>
                    <Col lg={8}>
                      <CompanyAccountsChartCard orgNumber={company.org_number} className="mb-4" />
                      <Row>
                        <Col lg={6}>
                          <CompanyInfoCards.CompanyInfoMomsCard company={company} className="mb-4" />
                        </Col>
                        <Col lg={6}>
                          <CompanyInfoCards.CompanyInfoFtaxCard company={company} className="mb-4" />
                        </Col>
                      </Row>
                      <CompanyGroupStructureCard orgNumber={company.org_number} className="mb-4" />
                    </Col>
                  </Row>
                </Tab.Pane>
                <Tab.Pane eventKey="comments" className="p-4">
                  <Row>
                    <Col md={12} lg={6}>
                      <CompanyCommentCard orgNumber={orgNumber} />
                    </Col>
                  </Row>
                </Tab.Pane>
                <Tab.Pane eventKey="units" className="pt-4 px-4">
                  <CompanyUnitsList
                    company={company}
                    units={company.cs_units}
                  />
                </Tab.Pane>
                <Tab.Pane eventKey="accounts" className="p-4">
                  <CompanyAccountsTable orgNumber={company.org_number} />
                </Tab.Pane>
                {hasGroupAccounts && (
                  <Tab.Pane eventKey="groupaccounts" className="p-4">
                    <CompanyAccountsTable orgNumber={company.org_number} groupAccounts />
                  </Tab.Pane>
                )}
                <Tab.Pane eventKey="valuation_reports" className="p-4">
                  <CompanyValuationReportTable orgNumber={orgNumber} />
                </Tab.Pane>
                <Tab.Pane eventKey="industry_reports" className="p-4">
                  <CompanyIndustryReportTable orgNumber={orgNumber} />
                </Tab.Pane>
                <Tab.Pane eventKey="credit_reports" className="p-4">
                  <CompanyCreditReportTable orgNumber={orgNumber} />
                </Tab.Pane>
                <Tab.Pane eventKey="monitored" className="p-4">
                  <CompanyMonitoredCard orgNumber={orgNumber} tab={subTab} />
                </Tab.Pane>
                <Tab.Pane eventKey="settings" className="p-4">
                  <Card>
                    <Card.Header>
                      SNI-kod
                    </Card.Header>
                    <Card.Body>
                      <CompanySNICodeEditor
                        orgNumber={readQuery.data.org_number}
                        sniCode={readQuery.data.sni_code_overridden ? (readQuery.data.sni_code || '') : ''}
                        sniCodeOriginal={readQuery.data.sni_code_original}
                      />
                    </Card.Body>
                  </Card>
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </>
        )}
        <Card.Footer className="p-3">
          <div className="d-flex gap-2">
            <InspectObjectModalButton object={company} size="lg" className="px-2" />
          </div>
        </Card.Footer>
      </Card>
    </div>
  );
});
export default CompanyPage;
