import React from 'react';

interface IProps {
  count: number;
  zero?: React.ReactNode;
  one?: React.ReactNode;
  more?: React.ReactNode;
}

const Pluralizer: React.FC<IProps> = React.memo(function Pluralizer (props: IProps) {
  const { count, zero, one, more } = props;
  if (!count) return <>{zero}</>;
  if (count === 1) return <>{one}</>;
  return <>{more}</>;
});

export default Pluralizer;
