import React from 'react';
import { Form } from 'react-bootstrap';

interface ErrorFormControlFeedbackProps extends React.HTMLAttributes<HTMLDivElement> {
  error?: string;
  touched?: boolean;
}

// this should be used for all instances where a password is required to be SET
// it should NOT be used for the login password field
export default function ErrorFormControlFeedback (props: ErrorFormControlFeedbackProps) {
  const { error, touched, ...restOfProps } = props;
  if (!touched) return null;
  if (!error) return null;
  return (
    <Form.Control.Feedback type="invalid" {...restOfProps}>
      {error}
    </Form.Control.Feedback>
  );
}
