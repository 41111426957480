import React from 'react';
import { Button as BsButton, ButtonProps as BsButtonProps } from 'react-bootstrap';
import { Copy } from 'react-feather';
import classNames from 'classnames';
import { toast } from 'react-toastify';

export interface CopyToClipboardButtonProps extends BsButtonProps {
  data: string | number | { [key: string]: any };
  successMessage?: React.ReactNode;
}

const CopyToClipboardButton: React.FC<CopyToClipboardButtonProps> = React.memo(function CopyToClipboardButton (props: CopyToClipboardButtonProps) {
  const {
    children,
    data,
    variant = 'outline-secondary',
    size = 'sm',
    className,
    successMessage = 'Kopierat',
    ...restOfProps
  } = props;
  const onClick: React.MouseEventHandler<HTMLButtonElement> = (ev) => {
    const isJSON = typeof data !== 'number' && typeof data !== 'string';
    const text = isJSON ? JSON.stringify(data, null, 2) : `${data}`;
    
    navigator.clipboard.writeText(text);
    toast.success(<>{successMessage}</>);
    restOfProps.onClick?.(ev);
  };

  return (
    <BsButton
      variant={variant}
      size={size}
      className={classNames('d-flex align-items-center gap-1', className)}
      {...restOfProps}
      onClick={onClick}
    >
      {children || <Copy size={18} />}
    </BsButton>
  );
});

export default CopyToClipboardButton;
