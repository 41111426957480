import React, {useMemo, useState} from 'react';
import { useQuery } from '@tanstack/react-query';
import * as CompanyEventFormatters from 'client/companyEvent/CompanyEventFormatters';
import * as columnDefs from 'client/table/commonColumnDefinitions';
import { CompanyEventTimelineBatchRow } from 'client/companyEvent/types';
import {ColumnDefinition, FilterDefinition} from 'client/table/types';
import * as filterDefs from 'client/table/commonFilterDefinitions';
import CardSimpleTable from 'client/card/CardSimpleTable';
import moment from 'moment';

interface CompanyEventTimelineTableByCompanyCardProps {
  orgNumber: string;
  className?: string;
}

interface CompanyEventTimelineTableByCompanyCardFilter {
  date_since: string;
  date_until: string;
}

type ResponseData = {
  events: CompanyEventTimelineBatchRow[];
}

type CompanyEventTimelineBatchByCompanyRowWithId = CompanyEventTimelineBatchRow & {
  id: string;
}

function CompanyEventTimelineTableByCompanyCard (props: CompanyEventTimelineTableByCompanyCardProps) {
  const { className, orgNumber } = props;

  const [filter, setFilter] = useState<CompanyEventTimelineTableByCompanyCardFilter>({
    date_since: moment().subtract(1, 'month').format('YYYY-MM-DD'),
    date_until: moment().format('YYYY-MM-DD'),
  });

  const query = useQuery<ResponseData, Error>({
    queryKey: [`/api/companies/${orgNumber}/company_event_timeline_batch`, filter],
  });

  const rows: CompanyEventTimelineBatchByCompanyRowWithId[] = useMemo(() => {
    if (!query.data) return [];
    return query.data.events.map(event => ({
      ...event,
      id: [event.org_number, event.event_type, event.event_type_code].join('-'),
    }));
  }, [query.data]);

  const columns = columnDefinitionFactory(filter);

  return (
    <div>
      <CardSimpleTable
        className={className}
        filters={filters}
        filter={filter}
        setFilter={setFilter}
        query={query}
        title="Företagets händelsebatch"
        columns={columns}
        rows={rows}
      />
    </div>
  );
}
export default React.memo(CompanyEventTimelineTableByCompanyCard);

const filters: FilterDefinition[] = [
  filterDefs.date(['date_since', 'Start']),
  filterDefs.date(['date_until', 'Slut']),
];

const columnDefinitionFactory = (filter: CompanyEventTimelineTableByCompanyCardFilter): ColumnDefinition<CompanyEventTimelineBatchByCompanyRowWithId>[] => [
  columnDefs.cell(['event_type', 'Typ'], props => (
    <CompanyEventFormatters.CompanyEventType value={props.row.event_type} code={props.row.event_type_code} />
  )),
  columnDefs.cell(['start', filter.date_since || 'Start'], props => (
    <CompanyEventFormatters.CompanyEventTimelineBatchStart value={props.row} />
  )),
  columnDefs.cell(['end', filter.date_until || 'Slut'], props => (
    <CompanyEventFormatters.CompanyEventTimelineBatchEnd value={props.row} />
  )),
  columnDefs.cell(['diff', 'Differens'], props => (
    <CompanyEventFormatters.CompanyEventValueDiff
      prev={props.row.value_start}
      curr={props.row.value_end}
      type={props.row.event_type}
    />
  )),
  columnDefs.actions(),
];
