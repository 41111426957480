import React from 'react';
import axios from 'client/axios';
import { Card } from 'react-bootstrap';
import { useQuery } from '@tanstack/react-query';
import ErrorAlert from 'client/components/ErrorAlert';
import { Helmet } from 'react-helmet';
import { ICorporateTaxRate } from 'client/corporateTaxRate/types';
import CorporateTaxRateTable from 'client/corporateTaxRate/CorporateTaxRateTable';
import RefreshButton from 'client/buttons/RefreshButton';
import PageHeader from 'client/components/PageHeader';

const CorporateTaxRateTablePage: React.FC = React.memo(function CorporateTaxRateTablePage () {

  const query = useQuery<ICorporateTaxRate[], Error>({
    queryKey: ['CorporateTaxRateTablePage'],
    queryFn: () => axios.get('/api/corporate_tax_rate').then(r => r.data),
  });


  const list = query.data ?? [];

  return (
    <div className="container-lg p-4">
      <Helmet>
        <title>Bolagsskattesatser</title>
      </Helmet>
      <PageHeader>Bolagsskattesatser</PageHeader>
      <ErrorAlert className="my-3" error={query.error} />
      <Card>
        <Card.Header className="d-flex flex-wrap gap-3 justify-content-between align-items-center">
          Översta raden visar den nuvarande bolagsskatten.
          <RefreshButton onClick={query.refetch} disabled={query.isLoading || query.isRefetching} />
        </Card.Header>
        <CorporateTaxRateTable
          list={list}
          isLoading={query.isLoading || query.isRefetching}
          isSuccess={query.isSuccess}
        />
      </Card>
    </div>
  );
});

export default CorporateTaxRateTablePage;
