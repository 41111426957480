import React, { useMemo, useCallback, useState } from 'react';
import { find, isUndefined, keyBy } from 'lodash';
import { useDebounce } from 'use-debounce';
import { Card, Table, Form } from 'react-bootstrap';
import { Edit, Trash } from 'react-feather';
import { TableSpinningOverlay  } from 'client/table/TableUtils';
import { EnglishFlag, SwedishFlag } from 'client/components/Flags';
import LoadingButton from 'client/buttons/LoadingButton';
import BranchModal from 'client/branches/BranchModal';
import { useBranch } from 'client/branches/hooks';
import * as BranchFormatters from 'client/branches/BranchFormatters';
import { ConfirmActionModal, useConfirmModalState } from 'client/modals/ConfirmModal';
import BranchTableCountCompaniesForm, { ICountCompaniesMutationDataItem, TCountCompaniesMutationData } from 'client/branches/BranchTableCountCompaniesForm';
import { useQuery } from '@tanstack/react-query';
import { Helmet } from 'react-helmet';
import ErrorAlert from 'client/components/ErrorAlert';
import PageHeader from 'client/components/PageHeader';
import IdProvider from 'client/components/IdProvider';
import { IBranch } from 'client/branches/types';
import Pluralizer from 'client/components/Pluralizer';
import RefreshButton from 'client/buttons/RefreshButton';
import { SniCodeRow } from 'client/sni/types';
import { SniCodeAnchor } from 'client/sni/SniCodeFormatters';

import 'client/branches/styles.scss';

type TBranchCountsById = {
  [branchId: string]: ICountCompaniesMutationDataItem;
}

const BranchTablePage: React.FC = React.memo(function BranchTablePage () {
  const [searchTerm, setSearchTerm] = useState('');
  const [showCodes, setShowCodes] = useState(true);
  const [editId, setEditId] = useState<undefined | null | string>(undefined);
  const { confirmAction, props: deleteModalProps } = useConfirmModalState();

  const { listQuery, deleteMutation } = useBranch();

  const sniCodesQuery = useQuery<SniCodeRow[], Error>({
    queryKey: ['/api/sni'],
  });

  const sniTextByCode: Record<string, SniCodeRow> = useMemo(() => {
    return sniCodesQuery.isSuccess ? keyBy(sniCodesQuery.data, 'sni_code') : {};
  }, [sniCodesQuery.isSuccess, sniCodesQuery.data]);

  const branches = listQuery?.data ?? [];

  const nextBranchId = useMemo(() => {
    const maxIdNumber = branches.reduce<number>((maxId, branch) => {
      const id = parseInt(branch.id.slice(1), 10) || -Infinity;
      return Math.max(maxId, id);
    }, 0);
    return String(maxIdNumber + 1).padStart(4, 'M000');
  }, [listQuery.data]);

  const onSearchTermInputChange = useCallback((ev: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(ev.target.value);
  }, [setSearchTerm]);

  const [debouncedSearchTerm] = useDebounce(searchTerm, 500);

  const [branchCountsById, setBranchCountsById] = useState<TBranchCountsById>({});
  const onCompaniesCounted = (result: TCountCompaniesMutationData) => {
    const byId = result.reduce<TBranchCountsById>((obj, item) => {
      obj[item.branch_id] = item;
      return obj;
    }, {});
    setBranchCountsById(byId);
  };

  const branchIds = useMemo(() => branches.map(b => b.id), [branches]);

  const onClickNew = useCallback(() => {
    setEditId(null);
  }, [setEditId]);

  const onClickReset = useCallback(() => {
    setSearchTerm('');
    setShowCodes(true);
  }, [setSearchTerm, setShowCodes]);

  const onDelete = useCallback((id: string) => {
    return confirmAction(() => deleteMutation.mutateAsync(id));
  }, [confirmAction, deleteMutation.mutateAsync]);

  const onEdit = useCallback((id: string) => {
    setEditId(id);
  }, [setEditId]);

  const isLoading = listQuery.isLoading || listQuery.isRefetching || sniCodesQuery.isLoading || sniCodesQuery.isRefetching;

  return (
    <div className="container-sm p-4">
      <Helmet>
        <title>Branscher</title>
      </Helmet>
      <PageHeader>
        Branscher
        <LoadingButton variant="outline-primary" onClick={onClickNew}>
          Ny bransch
        </LoadingButton>
      </PageHeader>
      <ErrorAlert className="my-3" error={sniCodesQuery.error} />
      <ErrorAlert className="my-3" error={listQuery.error} />
      <ConfirmActionModal {...deleteModalProps} message="Är du säker på att du vill radera branschen?" />
      <BranchModal
        isOpened={!isUndefined(editId)}
        isEditMode={Boolean(editId)}
        initialValues={find(listQuery.data, { id: editId } as any) || { id: nextBranchId }}
        onClose={() => setEditId(undefined)}
      />
      <Card>
        <Card.Header className="d-flex justify-content-between align-items-center p-3 gap-3 flex-wrap">
          <Form.Group className="flex-grow-1">
            <Form.Control
              name="searchTerm"
              onChange={onSearchTermInputChange}
              value={searchTerm}
              placeholder="Sök på branschnamn eller SNI-kod"
            />
          </Form.Group>
          <Form.Group>
            <IdProvider>
              {id => (
                <Form.Check
                  id={id}
                  className="flex-shrink-0"
                  label="Visa SNI-koder"
                  checked={showCodes}
                  onChange={() => setShowCodes(!showCodes)}
                />
              )}
            </IdProvider>
          </Form.Group>
          <LoadingButton
            title="Ta bort alla filtreringar"
            onClick={onClickReset}
            variant="outline-primary"
            className="d-flex align-items-center justify-content-center"
          >
            Återställ
          </LoadingButton>
          <RefreshButton
            className="py-2"
            onClick={listQuery.refetch}
            disabled={isLoading}
          />
        </Card.Header>
        <Card.Body className="border-bottom">
          <BranchTableCountCompaniesForm
            branchIds={branchIds}
            onCompaniesCounted={onCompaniesCounted}
          />
        </Card.Body>
        <TableSpinningOverlay isLoading={isLoading}>
          <div className="table-responsive">
            <Table className="mb-0">
              <thead>
                <tr>
                  <th>Bransch</th>
                  <th>Språk</th>
                  <th>SNI-koder</th>
                  <th>Antal företag</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {branches.map(branch => (
                  <React.Fragment key={branch.id}>
                    {branchIsVisible(branch, debouncedSearchTerm) && (
                      <BranchTableRow
                        branch={branch}
                        counts={branchCountsById[branch.id]}
                        onEdit={onEdit}
                        onDelete={onDelete}
                        showCodes={showCodes}
                        sniTextByCode={sniTextByCode}
                      />
                    )}
                  </React.Fragment>
                ))}
                {listQuery.isSuccess && branches.length < 1 && (
                  <tr>
                    <td colSpan={5}>Det finns ingenting här</td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
        </TableSpinningOverlay>
        <Card.Footer className="border-top-0">
          Totalt {branches.length ?? 0}{' '}
          <Pluralizer
            count={branches.length ?? 0}
            zero="branscher"
            one="bransch"
            more="branscher"
          />
        </Card.Footer>
      </Card>
    </div>
  );
});
export default BranchTablePage;

interface BranchTableRowProps {
  branch: IBranch;
  counts?: ICountCompaniesMutationDataItem;
  onEdit: (branchId: string) => void;
  onDelete: (branchId: string) => void;
  showCodes: boolean;
  sniTextByCode: Record<string, SniCodeRow>;
}

const BranchTableRow: React.FC<BranchTableRowProps> = React.memo(function BranchTableRow (props: BranchTableRowProps) {
  const { branch, counts, onEdit, showCodes, onDelete, sniTextByCode } = props;
  const { id, branch_name, branch_name_en, sni_codes } = branch;

  const onClickEdit = useCallback(() => {
    onEdit(branch.id);
  }, [branch.id, onEdit]);

  const onClickDelete = useCallback(() => {
    onDelete(branch.id);
  }, [branch.id, onDelete]);

  return (
    <>
      <tr key={id} className="align-middle">
        <td>
          <strong>{id} — {branch_name}</strong>
        </td>
        <td>
          {branch_name && <SwedishFlag />}{' '}
          {branch_name_en && <EnglishFlag />}
        </td>
        <td><span className="small">{sni_codes.length} st</span></td>
        <td>
          {counts && (
            <BranchFormatters.BranchCompanyCounts
              countCompanies={counts.count_companies}
              countCompaniesFiltered={counts.count_companies_filtered}
            />
          )}
        </td>
        <td>
          <div className="d-flex justify-content-end align-items-center">
            <div className="d-flex gap-2">
              <LoadingButton
                size="sm"
                variant="outline-secondary"
                onClick={onClickEdit}
                title="Redigera"
              >
                <Edit size={18} />
              </LoadingButton>
              <LoadingButton
                size="sm"
                variant="outline-danger"
                onClick={onClickDelete}
                title="Radera"
              >
                <Trash size={18} />
              </LoadingButton>
            </div>
          </div>
        </td>
      </tr>
      {showCodes && (
        <>
          {sni_codes.map(code => (
            <tr key={code} className="bg-light bg-opacity-50">
              <td colSpan={5}>
                <SniCodeAnchor sniCode={code} />
                {sniTextByCode[code] && (
                  <><small>{' — '} {sniTextByCode[code].sni_text}</small></>
                )}
              </td>
            </tr>
          ))}
        </>
      )}
    </>
  );
});

function branchIsVisible (branch: IBranch, searchTerm: string): boolean {
  const { id, branch_name, branch_name_en, sni_codes } = branch;
  const exp = new RegExp(searchTerm.trim(), 'i');
  if (!searchTerm.length) return true;
  return [
    exp.test(id),
    exp.test(branch_name),
    exp.test(branch_name_en),
    sni_codes.includes(searchTerm),
  ].some(v => v);
}
