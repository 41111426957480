import React, { useCallback, useState } from 'react';
import axios from 'client/axios';
import { AxiosResponse } from 'axios';
import ButtonSpinner from 'client/buttons/LoadingButton';
import { IUseTableSelectRows } from 'client/hooks/useTableSelectRows';
import Pluralizer from 'client/components/Pluralizer';
import { Form } from 'react-bootstrap';
import { Zap } from 'react-feather';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import * as requestCallbacks from 'client/utils/requestCallbacks';
import { BrowserRouter } from 'react-router-dom';
import { createRoot } from 'react-dom/client';
import { flushSync } from 'react-dom';
import moment from 'moment';
import { ColumnDefinition } from 'client/table/types';
import { CsBasicExtendedRow } from 'client/company/CompanyTablePage';

interface CompanyTableBatchFormProps {
  tableSelectRows: IUseTableSelectRows;
  columnOrder: string[];
  columnDefinitions: ColumnDefinition<CsBasicExtendedRow>[];
}

interface ICompanyTableBatchForm {
  action: string;
}

interface CompanyTableRowsByIdsMutationVars {
  org_number: string[];
}

// TODO This component may be able to be broken out into a separate table utility with just
// some customisable form fields
const CompanyTableBatchForm: React.FC<CompanyTableBatchFormProps> = React.memo(function CompanyTableBatchForm (props: CompanyTableBatchFormProps) {
  const { tableSelectRows, columnOrder, columnDefinitions } = props;

  const queryClient = useQueryClient();
  // TOOD this should probably be moved to another action/link
  const [batchForm, setBatchForm] = useState<ICompanyTableBatchForm>({action: ''});
  const rowsByIdsMutation = useMutation<AxiosResponse<{ rows: CsBasicExtendedRow[] }>, Error, CompanyTableRowsByIdsMutationVars>({
    mutationKey: ['CompanyTableRowsByIdsMutation'],
    mutationFn: data => {
      return axios.post('/api/companies/export', {
        org_number: data.org_number,
        order_by: 'org_number',
        order_direction: 'desc',
      });
    },
  });

  const onChangeBatchFormData = useCallback((ev: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = ev.target;
    setBatchForm({ ...batchForm, [name]: value });
  }, [setBatchForm, batchForm]);

  const onClickSubmitBatch = useCallback(() => {
    rowsByIdsMutation.mutate(
      { org_number: tableSelectRows.selectedRowIds.map(String) },
      {
        onError: requestCallbacks.onError,
        onSuccess: ({ data }) => {
          try {
            downloadCSV({ rows: data.rows, columnOrder, columnDefinitions });
          } catch (err) {
            requestCallbacks.onError(err as Error);
            return;
          }
          requestCallbacks.onSuccess('Åtgärden har utförts');
          tableSelectRows.setSelectedRows({});
          queryClient.invalidateQueries({ queryKey: ['/api/companies/list'] });
        },
      },
    );
  }, [rowsByIdsMutation, tableSelectRows, queryClient]);

  const batchFormIsValid = Boolean(batchForm.action);
  const isExecutingExportMutation = rowsByIdsMutation.isPending;
  const batchFormSubmitDisabled = tableSelectRows.selectedRowCount < 1 || !batchFormIsValid || isExecutingExportMutation;

  return (
    <div className="border-bottom p-3 d-flex flex-wrap align-items-center gap-2">
      <div>
        Med {tableSelectRows.selectedRowCount}{' '}
        <Pluralizer
          count={tableSelectRows.selectedRowCount}
          zero="markerade rader"
          one="markerad rad"
          more="markerade rader"
        />:
      </div>
      <div>
        <Form.Select
          name="action"
          value={batchForm.action}
          onChange={onChangeBatchFormData}
          size="sm"
        >
          <option value="">Välj åtgärd</option>
          <option value="export">Exportera som CSV</option>
        </Form.Select>
      </div>
      <ButtonSpinner
        size="sm"
        variant="outline-primary"
        className="d-flex align-items-center"
        disabled={batchFormSubmitDisabled}
        onClick={onClickSubmitBatch}
        isLoading={isExecutingExportMutation}
      >
        <Zap size={14} className="me-1" />Utför
      </ButtonSpinner>
    </div>
  );
});
export default CompanyTableBatchForm;

interface DownloadCSVParams extends Pick<CompanyTableBatchFormProps, 'columnOrder' | 'columnDefinitions'> {
  rows: CsBasicExtendedRow[];
}

function downloadCSV (params: DownloadCSVParams) {
  const filterColumnById = (columnId: string) => !['select', 'actions'].includes(columnId);
  const { rows, columnOrder, columnDefinitions } = params;
  const columns = columnOrder.filter(filterColumnById);
  const div = document.createElement('div');
  const root = createRoot(div);
  const columnSeparator = '\x1F';
  const rowSeparator = '\x1E';

  flushSync(() => {
    root.render(
      <BrowserRouter>
        {rows.map((row, rowIndex) => {
          return (
            <div key={row.org_number}>
              {columns.map((columnId, columnIndex) => {
                const column = columnDefinitions.find(column => column.id === columnId);
                if (!column || typeof row[columnId as keyof CsBasicExtendedRow] === 'undefined') {
                  throw new Error(`Kan ej hitta kolumnen med ID "${columnId}"`);
                }

                return (
                  <>
                    <column.Body row={row} key={columnId} />
                    {columnIndex < columns.length - 1 && columnSeparator}
                  </>
                );
              })}
              {rowIndex < rows.length - 1 && rowSeparator}
            </div>
          );
        })}
      </BrowserRouter>,
    );
  });

  const delimiter = ',';
  const escapeValue = (value: string) => value.includes(delimiter) ? `"${value}"` : value;
  const firstRow = columnOrder
    .filter(filterColumnById)
    .map(columnId => columnDefinitions.find(column => column.id === columnId)?.label as string)
    .map(escapeValue)
    .join(delimiter);

  const formattedRows = div.innerText
    .split(rowSeparator)
    .map(row => row.split(columnSeparator)
      .map(escapeValue)
      .join(delimiter),
    );

  if (formattedRows.length !== rows.length) {
    throw new Error('Det går inte att exportera de valda raderna');
  }

  const formattedAsCSV = [firstRow, ...formattedRows].join('\n');
  const blob = new Blob([formattedAsCSV], { type: 'text/csv;charset=utf-8;' });

  const link = document.createElement('a');
  const url = URL.createObjectURL(blob);
  link.setAttribute('href', url);
  link.setAttribute('download', `companies_export_${moment().format('YYYY-MM-DD_HH:mm:ss')}.csv`);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}
