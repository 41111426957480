import React, { useMemo, useState, useEffect, useRef } from 'react';
import { Card, Form, Spinner } from 'react-bootstrap';
import ErrorAlert from 'client/components/ErrorAlert';
import IdProvider from 'client/components/IdProvider';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import RefreshButton from 'client/buttons/RefreshButton';
import * as numberUtils from 'client/utils/number';
import * as Chartist from 'chartist';
import 'chartist/dist/index.scss';
import tooltip from 'bootstrap/js/dist/tooltip';

interface CreatedObjectsBarChartCardProps {
  title: string;
  dataUrl: string;
}

interface CreatedObjectsBarChartCardFilter {
  group: 'month' | 'week';
}

const DashboardCreatedObjectsBarChartCard: React.FC<CreatedObjectsBarChartCardProps> = React.memo(function DashboardCreatedObjectsBarChartCard (props: CreatedObjectsBarChartCardProps) {
  const { title, dataUrl } = props;

  const [filter, setFilter] = useState<CreatedObjectsBarChartCardFilter>({
    group: 'month',
  });

  const query = useQuery<QueryDataRow[]>({
    queryKey: [dataUrl, filter],
    placeholderData: keepPreviousData,
  });

  const onChange = (ev: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = ev.target;
    setFilter(prevFilter => ({...prevFilter, [name]: value}));
  };

  const data = useMemo(() => (
    query.data ? queryDataToBarChartData(query.data) : null
  ), [query.data]);

  return (
    <Card className="mt-1">
      <Card.Header className="p-3">
        <Card.Title className="mb-0 d-flex justify-content-between" style={{fontSize: '0.9rem'}}>
          <div className="d-flex align-items-center gap-2">
          {title}
          {(query.isLoading || query.isRefetching) && (
            <Spinner size="sm" />
          )}
          </div>
          <RefreshButton size="sm" onClick={query.refetch} disabled={query.isLoading || query.isRefetching} />
        </Card.Title>
      </Card.Header>
      <Form className="p-2">
        <IdProvider>
          {id => (
            <Form.Group>
              <Form.Select
                id={id}
                name="group"
                size="sm"
                value={filter.group}
                onChange={onChange}
              >
                <option value="month">Visa månadsvis</option>
                <option value="week">Visa veckovis</option>
              </Form.Select>
            </Form.Group>
          )}
        </IdProvider>
      </Form>
      {query.isFetched && (
        <Card.Body className="border-top p-2">
          <ErrorAlert error={query.error} className="m-0" />
          {data && (
            <>
              <CreatedObjectsBarChart data={data} />
            </>
          )}
        </Card.Body>
      )}
    </Card>
  );
});

export default DashboardCreatedObjectsBarChartCard;

type QueryDataRow = {date: string, count: number};

interface CreatedObjectsBarChartProps {
  data: Chartist.BarChartData;
}

function CreatedObjectsBarChart (props: CreatedObjectsBarChartProps) {
  const { data } = props;
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!data || !ref.current) return;
    
    let labelYMaxWidth = 10;
    let intermediateRender = true;

    ref.current.classList.add('opacity-0');

    const chart = new Chartist.BarChart(ref.current, data, {
      height: 255,
      chartPadding: {
        right: 0,
        left: 0,
      },
      axisY: {
        onlyInteger: true,
        labelInterpolationFnc: numberUtils.numberFormatter.format,
      },
      axisX: {
        offset: 40,
      },
    },[
      ['screen and (max-width: 576px)', {
        classNames: {
          chart: 'ct-chart-bar ct-chart-bar-sm',
        },
        height: 150,
      }],
    ]).on('draw', (event) => {
      if (event.type === 'bar') {
        const title = numberUtils.numberFormatter.format((event as any).value.y);
        event.element.attr({
          ['data-bs-toggle']: 'tooltip',
          ['data-bs-placement']: 'top',
          title,
        });
      }

      if (event.type === 'label') {
        if (event.axis.units.pos === 'y') {
          labelYMaxWidth = Math.max(labelYMaxWidth, getTextWidth(event.text, '12px sans-serif'));
        } else {
          event.element.addClass('ct-label-rotate');
        }
      }
    }).on('created', () => {
      if (ref.current && !intermediateRender) {
        ref.current.querySelectorAll('[data-bs-toggle="tooltip"]').forEach((el) => {
          tooltip.getInstance(el) || new tooltip(el);
        });
      }

      if (intermediateRender) {
        intermediateRender = false;
        setTimeout(() => {
          chart.update(undefined, {
            axisY: {
              offset: labelYMaxWidth + 15,
            },
          }, true);
          ref.current?.classList.remove('opacity-0');
        }, 0);
      }
    });

    return () => {
      chart.detach();
    };
  }, [data, ref.current]);
  
  return <div ref={ref} />;
}

function queryDataToBarChartData (queryData: QueryDataRow[]): Chartist.BarChartData {
  return {
    labels: queryData.map(a => a.date),
    series: [
      { data: queryData.map(a => a.count), className: 'ct-series ct-series-c' },
    ],
  };
}

interface GetTextWidth {
  (value: string | number | Date, font: string): number;
  canvas?: HTMLCanvasElement;
}

const getTextWidth: GetTextWidth = (value, font) => {
  const canvas = getTextWidth.canvas || (getTextWidth.canvas = document.createElement('canvas'));
  const context = canvas.getContext('2d');
  if (!context) return 0;
  context.font = font;
  return context.measureText(`${value}`).width;
};
