import React from 'react';
import { Col, Card, Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ErrorAlert from 'client/components/ErrorAlert';
import axios from 'client/axios';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import Pluralizer from 'client/components/Pluralizer';

interface ReportQueueStatisticCardsResponseData {
  [key: string]: number;
}

const DashboardReportQueueStatisticCards: React.FC = React.memo(function DashboardReportQueueStatisticCards () {

  const query = useQuery<ReportQueueStatisticCardsResponseData>({
    queryKey: ['DashboardReportQueueStatisticCards'],
    queryFn: () => axios.get('/api/dashboard/statistics/report_queues').then(r => r.data),
    placeholderData: keepPreviousData,
  });

  const titles:({[key: string]: string}) = {
    NEW: 'Väntar på datum',
    WAITING: 'Väntar på bokslut',
    ERROR: 'Problem',
    READY: 'Ej hanterade',
  };

  const urls:({[key: string]: string}) = {
    NEW: '/report_queue/new',
    WAITING: '/report_queue/waiting',
    ERROR: '/report_queue/error',
    READY: '/report_queue/ready_not_done',
  };

  return (
    <>
      {query.isError && (
        <Col sm={12}>
          <ErrorAlert error={query.error} />
        </Col>
      )}
      {['NEW', 'WAITING', 'ERROR', 'READY'].map(key => (
        <Col key={key} lg={3} md={4} sm={12}>
          <Card className="d-flex justify-content-center text-center p-3 mb-3">
            <Card.Title>{titles[key] ?? ''}</Card.Title>
            <Card.Subtitle>
              {query.isLoading && (
                <Spinner size="sm" />
              )}
              {query.isSuccess && (
                <Link to={urls[key] ?? ''} style={{textDecoration: 'none'}}>
                  {query?.data[key] ?? 0}{' '}
                  <Pluralizer
                    count={query?.data[key] ?? 0}
                    zero="rapporter"
                    one="rapport"
                    more="rapporter"
                  />
                </Link>
              )}
              {query.isError && <span>-</span>}
            </Card.Subtitle>
          </Card>
        </Col>
      ))}
    </>
  );
});

export default DashboardReportQueueStatisticCards;
