import React from 'react';
import { Field, FormikProps } from 'formik';
import IdProvider from 'client/components/IdProvider';
import ErrorFormControlFeedback from 'client/form/ErrorFormControlFeedback';
import { Row, Col, Form } from 'react-bootstrap';
import * as CustomerSurveyFormatters from 'client/customerSurvey/CustomerSurveyFormatters';
import { CustomerSurveyRow } from 'client/customerSurvey/types';

export interface UpdateCustomerSurveyFormFields {
  status: CustomerSurveyRow['status'];
}

interface CustomerSurveyFormProps {
  disabled?: boolean;
  formikBag: FormikProps<UpdateCustomerSurveyFormFields>;
}

const statusValues: CustomerSurveyRow['status'][] = [
  'unsent',
  'sent',
  'opened',
  'answered',
  'expired',
];

export default function CustomerSurveyForm (props: CustomerSurveyFormProps) {
  const {
    disabled = false,
    formikBag,
  } = props;

  const colProps = {lg: 6, md: 12};
  return (
    <Row>
      <Col {...colProps}>
        <IdProvider>
          {id => (
            <Form.Group className="mb-3">
              <Form.Label htmlFor={id}>
                Status
              </Form.Label>
              <Field
                as={Form.Select}
                disabled={disabled}
                id={id}
                name="status"
                placeholder="Ange en status"
                isInvalid={Boolean(formikBag.errors.status)}
                required
              >
                <option value="">Ange en status</option>
                {statusValues.map(status => (
                  <option value={status} key={status}>
                    <CustomerSurveyFormatters.Status value={status} />
                  </option>
                ))}
              </Field>
              <ErrorFormControlFeedback
                error={formikBag.errors.status}
                touched={formikBag.touched.status}
              />
            </Form.Group>
          )}
        </IdProvider>
      </Col>
    </Row>
  );
}
