import React, { useCallback } from 'react';
import { Button, Form } from 'react-bootstrap';
import { preventDefaultSubmit } from 'client/utils/form';
import { CsImportedTableFilterParams } from 'client/cs/CsImportedTablePage';
import RefreshButton from 'client/buttons/RefreshButton';

interface ICsImportedTableFilterForm {
  filterParams: CsImportedTableFilterParams;
  setFilterParams: React.Dispatch<React.SetStateAction<CsImportedTableFilterParams>>;
  onRefetch: () => void;
  onReset: () => void;
  isLoading: boolean;
}

const CsImportedTableFilterForm: React.FC<ICsImportedTableFilterForm> = React.memo(function CsImportedTableFilterForm (props: ICsImportedTableFilterForm) {
  const {
    isLoading,
    onRefetch,
    onReset,
    filterParams,
    setFilterParams,
  } = props;

  const onChange = useCallback((ev: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const { name, value } = ev.target;
    setFilterParams({[name]: value});
  }, [filterParams, setFilterParams]);

  return (
    <Form className="d-flex flex-wrap gap-3" onSubmit={preventDefaultSubmit}>
      <Form.Group className="flex-grow-1">
        <Form.Control
          isValid={Boolean(filterParams.search_term)}
          name="search_term"
          onChange={onChange}
          placeholder="Oavsett sökterm"
          value={filterParams.search_term ?? ''}
        />
      </Form.Group>
      <Button
        title="Ta bort alla filtreringar"
        onClick={onReset}
        variant="outline-primary"
        className="d-flex align-items-center justify-content-center"
        disabled={isLoading}
      >
        Återställ
      </Button>
      <RefreshButton onClick={onRefetch} disabled={isLoading} />
    </Form>
  );
});
export default CsImportedTableFilterForm;
