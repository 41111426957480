import React from 'react';
import { useMutation } from '@tanstack/react-query';
import axios from 'client/axios';
import LoadingButton, { LoadingButtonProps } from 'client/buttons/LoadingButton';
import * as requestCallbacks from 'client/utils/requestCallbacks';
import * as errorUtils from 'client/utils/errors';
import { IEmail } from 'client/email/types';

export interface SendEmailButtonProps extends LoadingButtonProps {
  objectType: 'customer' | 'user' | 'customerSurvey' | 'companyEventBatch';
  objectId: string | number;
  emailType: string;
  onSuccess?: () => void;
}

type SendEmailResponseData = null | IEmail;

const SendEmailButtonProps: React.FC<SendEmailButtonProps> = React.memo(function SendEmailButton (props: SendEmailButtonProps) {
  const { emailType, objectType, objectId, onSuccess, ...restOfProps } = props;

  const mutation = useMutation<SendEmailResponseData, Error>({
    mutationFn: () => axios.post(`/api/emails/${objectType}/${objectId}/${emailType}`).then(r => r.data),
    onSuccess: () => {
      requestCallbacks.onSuccess('E-postmeddelandet har skickats');
      onSuccess?.();
    },
    onError: err => {
      requestCallbacks.onError(errorUtils.errorToMessage(err, 'Okänt fel vid skickningen'));
    },
  });

  const onClick = () => {
    mutation.mutate();
  };

  return (
    <LoadingButton
      onClick={onClick}
      isLoading={mutation.isPending}
      {...restOfProps}
    />
  );
});
export default SendEmailButtonProps;
