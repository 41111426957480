import { useLocation } from 'react-router-dom';

interface IUseLastLocationHook {
  saveLastLocation: () => void;
  getLastLocation: () => string | undefined;
  clearLastLocation: () => void;
}

const STORAGE_KEY = 'LastLocation';

function useLastLocation (): IUseLastLocationHook {
  const location = useLocation();

  const saveLastLocation = () => {
    try {
      sessionStorage.setItem(STORAGE_KEY, location.pathname + location.search);
    } catch (e) {
      console.error(e); // eslint-disable-line no-console
    }
  };
  const getLastLocation = () => {
    try {
      return sessionStorage.getItem(STORAGE_KEY) || undefined;
    } catch (e) {
      console.error('error on parsing', e); // eslint-disable-line no-console
    }
  };
  const clearLastLocation = () => {
    try {
      sessionStorage.removeItem(STORAGE_KEY);
    } catch (e) {
      console.error(e); // eslint-disable-line no-console
    }
  };

  return {
    saveLastLocation,
    getLastLocation,
    clearLastLocation,
  };
}

export default useLastLocation;
