import React, { useState } from 'react';
import LoadingButton from 'client/buttons/LoadingButton';
import { ButtonProps } from 'react-bootstrap';

interface ILoadingPromiseButton extends ButtonProps {
  onClick: () => Promise<any>;
}

const LoadingPromiseButton: React.FC<ILoadingPromiseButton> = function LoadingPromiseButton (props: ILoadingPromiseButton) {
  const { children, onClick:onClickOuter, ...restOfProps } = props;

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onClick = () => {
    setIsLoading(true);
    return onClickOuter().finally(() => {
      setIsLoading(false);
    });
  };

  return (
    <LoadingButton
      {...restOfProps}
      isLoading={isLoading}
      onClick={onClick}
    >
      {children}
    </LoadingButton>
  );
};

export default LoadingPromiseButton;
