import { Button as BsButton, ButtonProps, ModalProps } from 'react-bootstrap';
import { TComponentProps, TAnyComponent } from 'client/utils/react';
import { useModalState } from 'client/hooks/useModalState';

export interface ModalOpeningButtonProps<T extends React.ComponentType<ModalProps>> extends ButtonProps {
  Modal: T;
  modalProps: TComponentProps<T>;
  buttonAs?: React.ElementType<any> | undefined;
}

export default function ModalOpeningButton<T extends TAnyComponent> (props: ModalOpeningButtonProps<T>) {
  const {
    children,
    Modal,
    modalProps = {},
    buttonAs: Button = BsButton,
    ...buttonProps
  } = props;

  const {
    show,
    mounted,
    onShow,
    onExited,
    onHide,
  } = useModalState();

  const onClick = (ev: React.MouseEvent) => {
    ev.preventDefault();
    onShow();
  };

  return (
    <>
      <Button {...buttonProps} onClick={onClick}>
        {children}
      </Button>
      {mounted && (
        <Modal
          {...(modalProps as TComponentProps<T>)}
          show={show}
          onHide={onHide}
          onExited={onExited}
        />
      )}
    </>
  );
}
