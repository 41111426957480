import { Badge, BadgeProps } from 'react-bootstrap';
import { Variant } from 'react-bootstrap/esm/types';
import classNames from 'classnames';

interface StatusBadgeProps extends BadgeProps {
  bg?: Variant;
}

export default function StatusBadge (props: StatusBadgeProps) {
  const { children, className:outerClassName, bg, ...restOfProps } = props;
  const bgClass = bg ? `bg-${bg}` : 'bg-transparent';
  const className = classNames(bgClass, outerClassName, 'text-uppercase px-2');
  return (
    <Badge className={className} {...restOfProps}>
      {children}
    </Badge>
  );
}
