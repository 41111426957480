import React, { useState, useEffect } from 'react';
import axios from 'client/axios';
import { useQuery } from '@tanstack/react-query';
import { useLocalStorage } from 'client/hooks/useStorage';
import { useHistory } from 'react-router-dom';
import { TUserRole } from 'client/user/types';
import * as requestCallbacks from 'client/utils/requestCallbacks';
import * as errorUtils from 'client/utils/errors';
import useLastLocation from 'client/hooks/useLastLocation';

export type AuthUserTypes = 'customer' | 'user';

interface AuthUser {
  id: string;
  name: string;
  email: string;
  role: TUserRole;
  user_type: 'user';
  company_monitored_limit: null | number;
  previous_user_id: null | string;
}

interface AuthCustomer {
  id: string;
  name: string;
  email: string;
  user_type: 'customer';
  company_monitored_limit: null | number;
  previous_user_id: null | string;
}

export interface Credentials {
  username: string;
  password: string;
  remember?: boolean;
}

export type AccountResponseBody = AuthUser | AuthCustomer;

interface AuthState {
  isAuthenticated: boolean;
  isInitialized: boolean;
  isCustomer: boolean;
  isUser: boolean;
  userType: AuthUserTypes | null;
  customer: null | AuthCustomer;
  customerId: string; // will be empty when not authenticated
  user: null | AuthUser;
  userId: string; // will be empty when not authenticated
  hasSwitched: boolean;
  previousUserId: string | null; // will be null when not switched to other
}

export interface AuthContextType extends AuthState {
  login: (credentials: Credentials) => Promise<boolean>;
  logout: () => Promise<void>;
  accessToken: string | null;
  isUserRole: (role:(TUserRole | TUserRole[])) => boolean;
  isUserType: (type: AuthUserTypes) => boolean;
  setStateByLogin: (result: LoginResponseBody) => void;
  setAccount: (newAccount: AccountResponseBody) => void;
  switchTo: (type: string, id: string) => Promise<any>;
  switchBack: () => Promise<any>;
}

interface LoginCustomerResponseBody {
  access_token: string;
  user_type: 'customer';
  customer: AuthCustomer;
}

interface LoginUserResponseBody {
  access_token: string;
  user_type: 'user';
  role: string;
  user: AuthUser;
}

type LoginResponseBody = LoginUserResponseBody | LoginCustomerResponseBody;

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  isCustomer: false,
  isUser: false,
  userType: null,
  user: null,
  userId: '',
  customer: null,
  customerId: '',
  previousUserId: null,
  hasSwitched: false,
};

const AuthContext = React.createContext<AuthContextType>({
  ...initialState,
  accessToken: null,
  login: async () => false,
  logout: async () => {},
  isUserRole: () => false,
  isUserType: () => false,
  setAccount: () => {},
  setStateByLogin: () => {},
  switchTo: async () => false,
  switchBack: async () => {},
});

const AuthProvider: React.FC<React.PropsWithChildren> = React.memo(function AuthProvider (props: React.PropsWithChildren) {
  const { children } = props;

  const history = useHistory();

  const [state, setState] = useState<AuthState>({
    ...initialState,
  });

  const [accessToken, setAccessToken] = useLocalStorage<string | null>('CalculateAccessToken', null);

  const { saveLastLocation } = useLastLocation();

  // this keeps the axios default authorization header up to date
  useEffect(() => {
    if (accessToken) {
      const header = `Bearer ${accessToken}`;
      axios.defaults.headers.common['Authorization'] = header;
    } else {
      delete axios.defaults.headers.common['Authorization'];
    }
  }, [accessToken]);

  const initializedButNotLoggedIn = () => {
    setAccessToken(null);
    setState({...initialState, isInitialized: true});
  };

  const setAccount = (account: AccountResponseBody) => {
    const { user_type:userType } = account;
    setState({
      ...state,
      isInitialized: true,
      isAuthenticated: true,
      isCustomer: userType === 'customer',
      isUser: userType === 'user',
      userType,
      user: userType === 'user' ? account : null,
      userId: userType === 'user' ? account.id : '',
      customer: userType === 'customer' ? account : null,
      customerId: userType === 'customer' ? account.id : '',
      previousUserId: account.previous_user_id || null,
      hasSwitched: Boolean(account.previous_user_id),
    });
  };

  const accountQuery = useQuery<AccountResponseBody | null>({
    queryKey: ['account'],
    queryFn: () => accessToken ? axios.get('/api/account').then(r => r.data) : null,
  });

  useEffect(() => {
    if (accountQuery.data === null) {
      initializedButNotLoggedIn();
      return;
    }
    if (!accountQuery.data) return;
    setAccount(accountQuery.data);
  }, [accountQuery.data]);

  useEffect(() => {
    if (!accountQuery.isError) return;

    saveLastLocation();
    // clear token here, we were obviously not logged in
    initializedButNotLoggedIn();
  }, [accountQuery.isError]);

  const setStateByLogin = (result: LoginResponseBody, extendWith: Partial<AuthState> = {}) => {
    const userType = result.user_type;
    setAccessToken(result.access_token);
    setState({
      ...state,
      isInitialized: true,
      isAuthenticated: true,
      isCustomer: userType === 'customer',
      isUser: userType === 'user',
      userType,
      user: userType === 'user' ? result.user : null,
      userId: userType === 'user' ? result.user.id : '',
      customer: userType === 'customer' ? result.customer : null,
      customerId: userType === 'customer' ? result.customer.id : '',
      ...extendWith,
    });
  };

  const login = async (credentials: Credentials) => {
    return await axios.post<LoginResponseBody>('/api/auth/login', credentials).then(result => {
      setStateByLogin(result.data);
      return true;
    }, err => {
      if (err.response.status === 400) return false;
      throw err;
    });
  };

  const logout = async () => {
    await axios.post('/api/logout');
    initializedButNotLoggedIn();
    history.push('/login');
  };

  // switches to another user or customer by their id (implemented as a new login)
  const switchTo = async (type: string, id: string) => {
    const url = `/api/auth/switch/${type}/${id}`;
    return await axios.post<LoginResponseBody>(url, {})
    .catch(err => {
      requestCallbacks.onError(errorUtils.errorToMessage(err, 'Byte misslyckades'));
      throw err;
    })
      .then(result => result.data)
      .then(data => {
        setStateByLogin(data, {
          hasSwitched: true,
          previousUserId: state.user?.id as string,
        });
        return history.push('/login');
      });
  };

  // returns to the original user (also implemented as a new login on the server)
  const switchBack = async () => {
    await axios.delete<LoginResponseBody>('/api/auth/switch')
    .catch(err => {
      requestCallbacks.onError(errorUtils.errorToMessage(err, 'Återgång misslyckades'));
      throw err;
    })
    .then(result => result.data)
    .then(data => {
      setStateByLogin(data, {
        hasSwitched: false,
        previousUserId: null,
      });
      return history.push('/');
    });
  };

  const isUserRole = (role: (TUserRole | TUserRole[])): boolean => {
    if (!state.isAuthenticated) return false;
    if (!state.isUser) return false;
    const userRole = state.user?.role;
    if (!userRole) return false;
    if (Array.isArray(role)) {
      return role.includes(userRole);
    }
    return userRole === role;
  };

  const isUserType = (type: AuthUserTypes): boolean => {
    if (!state.isAuthenticated) return false;
    if (type === 'user') return state.isUser;
    if (type === 'customer') return state.isCustomer;
    return false;
  };

  const value = {
    ...state,
    accessToken,
    login,
    logout,
    setAccount,
    setStateByLogin,
    switchTo,
    switchBack,
    isUserRole,
    isUserType,
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
});

export { AuthContext, AuthProvider };
