import React, { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { UserAnchor } from 'client/user/UserFormatters';
import { CompanyMonitoredLogRowWithRelations, CompanyMonitoredLogType } from 'client/companyMonitored/types';
import * as columnDefs from 'client/table/commonColumnDefinitions';
import * as filterDefs from 'client/table/commonFilterDefinitions';
import CardSimpleTable from 'client/card/CardSimpleTable';
import * as CompanyMonitoredLogFormatters from 'client/companyMonitored/CompanyMonitoredLogFormatters';
import {CustomerAnchor} from 'client/customer/CustomerFormatters';
import {ColumnDefinition, FilterDefinition} from 'client/table/types';

interface CompanyMonitoredLogFilter {
  type: '' | CompanyMonitoredLogType;
}

interface CompanyMonitoredLogTableProps {
  orgNumber: string;
  className?: string;
}

function CompanyMonitoredLogTable (props: CompanyMonitoredLogTableProps) {
  const { className, orgNumber } = props;

  const [filter, setFilter] = useState<CompanyMonitoredLogFilter>({
    type: '',
  });

  const query = useQuery<CompanyMonitoredLogRowWithRelations[], Error>({
    queryKey: [`/api/company_monitored/${orgNumber}/log`, filter],
  });

  const rows = query.data ?? [];

  return (
    <CardSimpleTable
      className={className}
      query={query}
      title="Bevakningslogg"
      filters={filters}
      filter={filter}
      setFilter={setFilter}
      columns={columns}
      rows={rows}
    />
  );
}
export default React.memo(CompanyMonitoredLogTable);

const logTypeOptionList = [
  {value: 'monitored', label: 'Bevakning tillagd'},
  {value: 'unmonitored', label: 'Bevakning borttagen'},
  {value: 'created', label: 'Skapad'},
  {value: 'updated', label: 'Uppdaterad'},
];

const filters: FilterDefinition[] = [
  filterDefs.select(['type', 'Typ'], logTypeOptionList, {
    extraProps: {placeholder: 'Oavsett loggtyp'},
  }),
];

const columns: ColumnDefinition<CompanyMonitoredLogRowWithRelations>[] = [
  columnDefs.date(['date', 'Datum']),
  columnDefs.cell(['description', 'Meddelande'], props => (
    <CompanyMonitoredLogFormatters.CompanyMonitoredLogDescription value={props.row} />
  )),
  columnDefs.cell(['customer_id', 'Kund'], props => (
    <div>
      {props.row.by_customer_id && (
        <CustomerAnchor value={props.row.by_customer || props.row.by_customer_id} />
      )}
    </div>
  )),
  columnDefs.cell(['user_id', 'Utförd av'], props => (
    <div>
      {props.row.by_user_id && (
        <UserAnchor value={props.row.by_user || props.row.by_user_id} />
      )}
    </div>
  )),
  columnDefs.actions(),
];
