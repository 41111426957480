import React from 'react';
import DateFormat from 'client/components/DateFormat';

interface AccountIntervalProps extends React.HTMLProps<HTMLDivElement> {
  from?: null | Date | string;
  to?: null | Date | string;
}

export const AccountInterval = function AccountInterval (props: AccountIntervalProps) {
  const { from, to, ...restOfProps } = props;
  if (!from && !to) return null;
  return (
    <div {...restOfProps}>
      {from && (<><DateFormat value={from} format="YYYY-MM" /> –{' '}</>)}
      {to && (<DateFormat value={to} format="YYYY-MM" />)}
    </div>
  );
};

interface AccountYearProps extends Omit<React.HTMLProps<HTMLDivElement>, 'value'> {
  from?: null | Date | string;
  to?: null | Date | string;
}

export const AccountYear = function AccountYear (props: AccountYearProps) {
  const { from, to, ...restOfProps } = props;
  if (!from && !to) return null;
  return (
    <div {...restOfProps}>
      {to && (<DateFormat value={to} format="YYYY" />)}
    </div>
  );
};
