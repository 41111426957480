import React, { useCallback, useRef, useState, useId, useEffect } from 'react';
import { Modal, Form, ModalProps, Button } from 'react-bootstrap';
import { useInterval } from '@restart/hooks';
import { useDrop, useDrag, useDragDropManager } from 'react-dnd';
import { move } from 'formik';
import classNames from 'classnames';
import { ColumnDefinition } from 'client/table/types';
import { cloneDeep } from 'lodash';

export interface SelectableColumn {
  id: string;
  label: string;
  show?: boolean;
}

interface TableColumnsSettingsModal extends ModalProps {
  onSubmit: (columns: SelectableColumn[]) => void;
  onReset: () => void;
  columns: SelectableColumn[];
}

export default function TableColumnsSettingsModal (props: TableColumnsSettingsModal) {
  const { show, onHide, onExited, onSubmit, onReset, columns:outerColumns } = props;

  const [scrollDir, setScrolldir] = useState<number>(0);
  const [columns, setColumns] = useState<SelectableColumn[]>(cloneDeep(outerColumns));
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [filter, setFilter] = useState<string>('');

  const deltaRef = useRef<number>(1);
  const scrollEl = useRef<HTMLDivElement>(null);

  const monitor = useDragDropManager().getMonitor();

  const getIsTop = () => scrollEl.current!.scrollTop === 0;

  const getIsBottom = () => {
    const treshold = 1; // dimensions can be off by ~1px due browser's zoom
    return scrollEl.current && scrollEl.current.clientHeight + scrollEl.current.scrollTop >= scrollEl.current.scrollHeight - treshold;
  };

  useInterval(() => {
    if (scrollDir < 0 && getIsTop() || scrollDir > 0 && getIsBottom()) {
      setScrolldir(0);
      return;
    }

    const SCROLL_SPEED = 20;
    scrollEl.current?.scrollBy(0, SCROLL_SPEED * scrollDir * Math.abs(deltaRef.current));
  }, 16, scrollDir === 0);

  useEffect(() => {
    const TRESHOLD = 80;
    const ITEM_HEIGHT = 33;

    return monitor.subscribeToOffsetChange(() => {
      const offset = monitor.getSourceClientOffset()?.y;
      const rect = scrollEl.current?.getBoundingClientRect();
      if (!offset || !rect) {
        setScrolldir(0);
        return;
      }

      let direction = 0;
      deltaRef.current = 1;
      if (offset > rect.bottom - (TRESHOLD + ITEM_HEIGHT)) {
        deltaRef.current = (offset - (rect.bottom - (TRESHOLD + ITEM_HEIGHT))) / TRESHOLD;
        direction = 1;
      } else if (offset < rect.top + TRESHOLD) {
        deltaRef.current = (offset - (rect.top + TRESHOLD)) / TRESHOLD;
        direction = -1;
      }

      setScrolldir(direction);
    });
  }, [monitor]);

  const onChangePosition = useCallback((from: number, to: number) => {
    if (scrollDir !== 0) return true;
    setColumns(prev => move(prev, from, to) as SelectableColumn[]);
    setIsDirty(true);
  }, [setColumns, setIsDirty, scrollDir]);

  const onToggleVisibility = useCallback((id: string) => {
    setColumns(prev => prev.map(c => ({
      ...c,
      show: c.id === id ? !c.show : c.show,
    })));
    setIsDirty(true);
  }, [setColumns, setIsDirty]);

  const onSubmitClick = useCallback(() => {
    onSubmit(columns);
    onHide?.();
  }, [onSubmit, onHide, columns]);

  const onVisibleAllClick = useCallback(() => {
    setColumns(prev => prev.map(column => {
      if (columnHiddenByFilter(column, filter)) return column;
      return {...column, show: true};
    }));
    setIsDirty(true);
  }, [setIsDirty, setColumns, filter]);

  const onHideAllClick = useCallback(() => {
    setColumns(prev => prev.map(column => {
      if (columnHiddenByFilter(column, filter)) return column;
      return {...column, show: false};
    }));
    setIsDirty(true);
  }, [setIsDirty, setColumns, filter]);

  const onResetClick = useCallback(() => {
    onReset();
    onHide?.();
  }, [onReset, onHide]);

  const onChangeFilter = useCallback((ev: React.ChangeEvent<HTMLInputElement>) => {
    setFilter(ev.target.value);
  }, [setFilter]);

  const onClickResetFilter = useCallback(() => {
    setFilter('');
  }, [setFilter]);

  return (
    <Modal show={show} onHide={onHide} onExited={onExited} scrollable>
      <Modal.Header>
        <Modal.Title>
          Kolumnväljare
        </Modal.Title>
      </Modal.Header>
      <Modal.Body ref={scrollEl} className="pb-1">
        <div className="d-flex align-items-start gap-1">
          <Form.Group className="mb-3 flex-grow-1">
            <Form.Control
              placeholder="Inget filter"
              name="filter"
              value={filter}
              onChange={onChangeFilter}
              size="sm"
            />
          </Form.Group>
          <Button
            variant="outline-primary"
            size="sm"
            disabled={!filter}
            onClick={onClickResetFilter}
          >
            Töm
          </Button>
        </div>
        <div className="d-flex flex-column gap-2 align-items-baseline p-0 pb-2">
          {columns.map((column, index) => (
            <ColumnBar
              index={index}
              filter={filter}
              key={column.id}
              column={column}
              onChangePosition={onChangePosition}
              onToggleVisibility={onToggleVisibility}
            />
          ))}
        </div>
      </Modal.Body>
      <Modal.Footer className="p-2">
        <Button variant="outline-primary" size="sm" onClick={onResetClick}>
          Återställ val
        </Button>
        <Button variant="outline-primary" size="sm" onClick={onVisibleAllClick}>
          Markera urval
        </Button>
        <Button variant="outline-primary" size="sm" onClick={onHideAllClick}>
          Göm urval
        </Button>
        <Button
          type="button"
          size="sm"
          onClick={onSubmitClick}
          disabled={!isDirty}
        >
          Spara
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export function columnDefinitionsToSelectableColumns (definitions: Pick<ColumnDefinition<any>, 'id' | 'label' | 'show'>[], columnOrder: string[]): SelectableColumn[] {
  const columnsShown: SelectableColumn[] = (columnOrder.map(columnId => {
    const definition = definitions.find(c => c.id === columnId);
    if (!definition) return null;
    const { id, label } = definition;
    return { id, label, show: true };
  }).filter(v => v)) as SelectableColumn[];
  return [
    ...columnsShown,
    // all unshown columns go in their "definition" order at the end
    ...definitions.filter(c => {
      return !columnsShown.find(d => d.id === c.id);
    }).map(c => {
      const { id, label } = c;
      return {id, label: label ?? id, show: false};
    }),
  ];
}

interface ColumnDragObject {
  index: number;
}

interface ColumnDragCollectedProps {
  isDragging: boolean;
}

interface ColumnDropCollectedProps {
  handlerId: string | symbol | null;
}

interface ColumnBarProps {
  column: SelectableColumn;
  onChangePosition: (fromIndex: number, toIndex: number) => true | void;
  onToggleVisibility: (id: string) => void;
  index: number;
  filter: string;
}

function ColumnBar (props: ColumnBarProps) {
  const { column, onChangePosition, onToggleVisibility, index, filter } = props;
  const ref = useRef<HTMLDivElement>(null);

  const [dropProps, drop] = useDrop<ColumnDragObject, unknown, ColumnDropCollectedProps>({
    accept: 'column',
    collect: (monitor) => ({
      handlerId: monitor.getHandlerId(),
    }),
    hover: (hoverItem, monitor) => {
      if (!ref.current) {
        return;
      }
      const dragPosition = hoverItem.index || 0;
      const hoverPosition = index || 0;

      if (dragPosition === hoverPosition) {
        return;
      }

      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      if (!clientOffset) {
        return;
      }

      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      if (dragPosition < hoverPosition && hoverClientY < hoverMiddleY) {
        return;
      }
      if (dragPosition > hoverPosition && hoverClientY > hoverMiddleY) {
        return;
      }
      
      const cancelled = onChangePosition(dragPosition, hoverPosition);
      if (!cancelled) {
        hoverItem.index = hoverPosition;
      }
    },
  });

  const [{ isDragging }, drag] = useDrag<ColumnDragObject, unknown, ColumnDragCollectedProps>({
    type: 'column',
    // important to have a function here
    item: () => ({
      index,
    }),
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  });

  drag(drop(ref));

  const className = classNames('d-flex w-100 align-items-center justify-content-start cursor-grab border rounded p-1 px-2', {
    'opacity-50': isDragging,
    'text-muted bg-light': !column.show,
  });

  const id = useId();

  const onChange = useCallback(() => {
    onToggleVisibility(column.id);
  }, [onToggleVisibility, column.id]);

  if (columnHiddenByFilter(column, filter)) {
    return null;
  }

  return (
    <div
      ref={ref}
      className={className}
      data-handler-id={dropProps.handlerId}
    >
      <Form.Check
        className="me-2"
        id={id}
        inline
        checked={column.show}
        onChange={onChange}
        label={column.label}
      />
    </div>
  );
}

function columnHiddenByFilter (column: SelectableColumn, filter: string): boolean {
  const { id, label } = column;
  if (!filter) return false;
  const regexp = new RegExp(filter, 'i');
  return !regexp.test(id) && !regexp.test(label);
}
