import React from 'react';
import { Col, Card, Spinner, Alert, AlertProps } from 'react-bootstrap';
import ErrorAlert from 'client/components/ErrorAlert';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import RefreshButton from 'client/buttons/RefreshButton';
import {ThumbsDown, ThumbsUp} from 'react-feather';
import { Link } from 'react-router-dom';

interface ResponseData {
  token_exists: boolean;
  access_token_valid: null | boolean;
  refresh_token_valid: null | boolean;
}

const DashboardFortnoxTokenStatusCard: React.FC = React.memo(function DashboardFortnoxTokenStatusCard () {

  const query = useQuery<ResponseData>({
    queryKey: ['/api/dashboard/fortnox_token_status'],
    placeholderData: keepPreviousData,
  });

  const data = query.data;

  return (
    <>
      {query.isError && (
        <Col sm={12}>
          <ErrorAlert error={query.error} />
        </Col>
      )}
      {query.isSuccess && (
        <Card>
          <Card.Header className="p-3">
            <Card.Title className="mb-0 d-flex justify-content-between" style={{fontSize: '0.9rem'}}>
              <div className="d-flex align-items-center gap-2">
                Fortnox Token-status
                {(query.isLoading || query.isRefetching) && (
                  <Spinner size="sm" />
                )}
              </div>
              <RefreshButton
                size="sm"
                onClick={query.refetch}
                disabled={query.isLoading || query.isRefetching}
                className="px-1"
              />
            </Card.Title>
          </Card.Header>
          <Card.Body className="d-flex gap-3">
            {query.isSuccess && data && (
              <>
                <AlertStatus
                  value={data.token_exists}
                  good="Token finns"
                  bad="Token saknas"
                />
                {data.token_exists && (
                  <>
                    <AlertStatus
                      value={Boolean(data.refresh_token_valid)}
                      good="Refresh-Token giltig"
                      bad="Refresh-Token saknas eller utgången"
                    />
                  </>
                )}
              </>
            )}
            <Link to="/settings">Gå till systeminställningar &raquo;</Link>
          </Card.Body>
        </Card>
      )}
    </>
  );
});

export default DashboardFortnoxTokenStatusCard;

interface AlertStatusProps extends AlertProps {
  value: boolean;
  good: React.ReactNode;
  bad: React.ReactNode;
}
 
function AlertStatus (props: AlertStatusProps) {
  const { value, good, bad } = props;
  const variant = value ? 'success' : 'danger';
  return (
    <Alert variant={variant} className="px-1 py-0 d-inline-flex align-items-center gap-1 mb-0">
      {value ? <ThumbsUp size={14} /> : <ThumbsDown size={14} />}
      {value ? good : bad}
    </Alert>
  );
}
