import React from 'react';
import { Redirect, RouteProps } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import BlockSpinner from 'client/spinners/BlockSpinner';
import ErrorAlert from 'client/components/ErrorAlert';

interface IReportsRoute extends RouteProps {
  // internally react-router-dom will always provide this prop
  // needs to be optional cause missing property error in `client/router/Router.tsx`
  computedMatch?: {
    params: {
      reportId: string;
      tab: string;
    };
  };
}

interface IReportData {
  report_type: 'industry_report' | 'valuation_report';
}

const ReportsRoute: React.FC<IReportsRoute> = React.memo(function ReportsRoute (props: IReportsRoute) {
  const { reportId, tab } = props.computedMatch!.params;
  const query = useQuery<IReportData>({
    queryKey: [`/api/report_queue/${reportId}`],
  });

  if (query.isError) {
    return <ErrorAlert error={query.error} className="m-3" />;
  }

  if (query.data?.report_type) {
    const redirectPath = ({
      industry_report: '/industry_reports',
      valuation_report: '/valuation_reports',
    })[query.data.report_type];

    if (!redirectPath) {
      return <ErrorAlert error="Ingen giltig rapport hittades" className="m-3" />;
    }

    return <Redirect to={[redirectPath, reportId, tab].filter(Boolean).join('/')} />;
  }

  return <BlockSpinner isLoading className='m-3'/>;
});

export default ReportsRoute;
