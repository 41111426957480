import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import {
  NumericFormat,
  NumberFormatValues,
} from 'react-number-format';

interface AccountsTableCellProps <ValueType = string> {
  name: string;
  index: number;
  disabled?: boolean;
  initialValue: ValueType | null;
  onCommit: (index: number, key: string, value: ValueType) => void;
  derived?: boolean;
}

export function Date (props: AccountsTableCellProps<string>) {
  const { name, index, disabled, derived, initialValue:rawInitialValue, onCommit } = props;

  let initialValue: string;
  if (rawInitialValue === null) initialValue = '';
  else if (typeof rawInitialValue === 'string') initialValue = rawInitialValue;
  else initialValue = '';

  const [value, setValue] = useState<string>(initialValue);

  const onChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    setValue(ev.target.value);
  };

  const onBlur = () => {
    onCommit(index, name, value);
  };

  return (
    <Form.Control
      type="date"
      name={`${name}_${index}`}
      disabled={disabled}
      value={derived ? initialValue : value}
      onChange={onChange}
      onBlur={onBlur}
    />
  );
}

export function String (props: AccountsTableCellProps<string>) {
  const { name, index, derived, disabled, initialValue:rawInitialValue, onCommit } = props;

  let initialValue: string;
  if (rawInitialValue === null) initialValue = '';
  else if (typeof rawInitialValue === 'string') initialValue = rawInitialValue;
  else initialValue = '';

  const [value, setValue] = useState<string>(initialValue);

  const onChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    setValue(ev.target.value);
  };

  const onBlur = () => {
    onCommit(index, name, value);
  };

  return (
    <Form.Control
      type="text"
      name={`${name}_${index}`}
      disabled={disabled}
      value={derived ? initialValue : value}
      onChange={onChange}
      onBlur={onBlur}
    />
  );
}

interface AccountsTableCellNumericProps extends AccountsTableCellProps<number> {
  type: 'integer' | 'percent' | 'numeric';
}

export function Numeric (props: AccountsTableCellNumericProps) {
  const {
    type,
    name,
    disabled,
    index,
    derived,
    initialValue:rawInitialValue,
    onCommit,
  } = props;

  let initialValue: number;
  if (rawInitialValue === null) initialValue = 0;
  else if (typeof rawInitialValue === 'string') initialValue = parseFloat(rawInitialValue);
  else if (typeof rawInitialValue === 'number' && isFinite(rawInitialValue)) initialValue = rawInitialValue;
  else initialValue = 0;

  const [value, setValue] = useState<number>(initialValue);

  const onValueChange = (values: NumberFormatValues) => {
    const value = typeof values.floatValue === 'undefined' ? 0 : values.floatValue;
    setValue(value);
  };

  const onBlur = () => {
    onCommit(index, name, value);
  };

  if (type === 'percent') {
    return (
      <NumericFormat
        decimalScale={2}
        fixedDecimalScale
        customInput={Form.Control}
        onBlur={onBlur}
        onValueChange={onValueChange}
        value={derived ? initialValue : value}
        disabled={disabled}
      />
    );
  } else if (type === 'integer') {
    return (
      <NumericFormat
        name={`${name}_${index}`}
        decimalScale={0}
        allowNegative
        thousandSeparator=" "
        customInput={Form.Control}
        value={derived ? initialValue : value}
        onValueChange={onValueChange}
        onBlur={onBlur}
        disabled={disabled}
      />
    );
  }
  return (
    <NumericFormat
      name={`${name}_${index}`}
      decimalScale={2}
      allowNegative
      thousandSeparator=" "
      customInput={Form.Control}
      value={derived ? initialValue : value}
      onValueChange={onValueChange}
      onBlur={onBlur}
      disabled={disabled}
    />
  );
}
