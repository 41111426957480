import React, {useMemo} from 'react';
import { useQuery } from '@tanstack/react-query';
import { Card } from 'react-bootstrap';
import { TableSpinningOverlay  } from 'client/table/TableUtils';
import RefreshButton from 'client/buttons/RefreshButton';
import classNames from 'classnames';
import * as CompanyFormatters from 'client/company/CompanyFormatters';
import { UserCompanyMonitoredRowWithRelations } from 'client/userCompanyMonitored/types';
import { CardBodyErrorAlert } from 'client/card/CardHelpers';
import {ColumnDefinition} from 'client/table/types';
import TableVirtual from 'client/table/TableVirtual';
import * as columnDefs from 'client/table/commonColumnDefinitions';

interface UserCompanyMonitoredTableProps {
  userId: string;
  className?: string;
}

interface UserCompanyMonitoredQueryResponse {
  rows: UserCompanyMonitoredRowWithRelations[];
  limit: number;
}

const columns: ColumnDefinition[] = [
  columnDefs.cell(['name', 'Företag'], props => (
    <CompanyFormatters.CompanyAnchor value={props.row.cs_basic ?? props.row.org_number} />
  )),
  columnDefs.date(['created_at', 'Datum bevakat']),
  columnDefs.actions(),
];

const UserCompanyMonitoredTable: React.FC<UserCompanyMonitoredTableProps> = React.memo(function UserCompanyMonitoredTable (props: UserCompanyMonitoredTableProps) {
  const { className, userId } = props;

  const query = useQuery<UserCompanyMonitoredQueryResponse, Error>({
    queryKey: [`/api/user_company_monitored/${userId}`],
  });

  const rows = useMemo(() => {
    return (query.data?.rows ?? []).map(row => ({
      ...row,
      id: [row.org_number, row.user_id].join('-'),
    }));
  }, [query.data]);

  return (
    <Card className={classNames(className, 'border-0')}>
      <Card.Header className="border border-bottom-0 px-3 py-2">
        <Card.Title as="h6" className="mb-0 p-0 d-flex justify-content-between align-items-center">
          Användarens bevakade företag
          <div>
            <RefreshButton
              onClick={query.refetch}
              disabled={query.isLoading || query.isRefetching}
              className="px-2"
            />
          </div>
        </Card.Title>
      </Card.Header>
      <CardBodyErrorAlert error={query.error} />
      <TableSpinningOverlay isLoading={query.isLoading || query.isRefetching}>
        <div className="table-responsive">
          <TableVirtual
            isInitialLoading={query.isLoading}
            className="mb-0 border align-middle"
            columns={columns}
            rows={rows}
            emptyMessage="Inga företag är bevakade"
          />
        </div>
      </TableSpinningOverlay>
    </Card>
  );
});
export default UserCompanyMonitoredTable;
