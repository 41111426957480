import React from 'react';
import { useQuery } from '@tanstack/react-query';
import ErrorAlert from 'client/components/ErrorAlert';
import { Centered } from 'client/login/LoginUtils';
import CustomerSurveyRespondForm from 'client/customerSurvey/CustomerSurveyRespondForm';
import OverlaySpinner from 'client/spinners/OverlaySpinner';
import {useParams} from 'react-router-dom';
import useParsedSearch from 'client/hooks/useParsedSearch';

interface ReadResponse {
  id: string;
}

interface PageQuery {
  open?: '0' | '1';
  rating?: string;
}

const CustomerSurveyRespondPage: React.FC = React.memo(function CustomerSurveyRespondPage () {
  const { customerSurveyId } = useParams<{customerSurveyId: string}>();
  const { open, rating = '' } = useParsedSearch<PageQuery>();

  const query = useQuery<ReadResponse, Error>({
    queryKey: [getUrl(customerSurveyId, open)],
  });

  return (
    <Centered>
      <div className="position-relative">
        <OverlaySpinner isLoading={query.isLoading} />
        <ErrorAlert error={query.error} />
        {query.isSuccess && (
          <CustomerSurveyRespondForm
            customerSurveyId={query.data.id}
            defaultRating={rating}
          />
        )}
      </div>
    </Centered>
  );
});
export default CustomerSurveyRespondPage;

function getUrl (customerSurveyId: string, open?: PageQuery['open']): string {
  if (open !== '0') return `/api/customer_survey_respond/${customerSurveyId}`;
  return `/api/customer_survey_respond/${customerSurveyId}?open=0`;
}
