import React from 'react';
import { ButtonProps } from 'react-bootstrap';
import LoadingPromiseButton from 'client/buttons/LoadingPromiseButton';
import useAuth from 'client/hooks/useAuth';
import RoleGuard from 'client/guards/RoleGuard';

interface AuthSwitchButtonProps extends ButtonProps {
  objectId: string;
  objectType: 'user' | 'customer';
}
const AuthSwitchButton: React.FC<AuthSwitchButtonProps> = React.memo(function AuthSwitchButton (props: AuthSwitchButtonProps) {
  const { objectId, objectType, ...restOfProps } = props;

  const auth = useAuth();

  const onClick = () => {
    return auth.switchTo(objectType, objectId);
  };

  if (auth.hasSwitched) {
    return null;
  }

  if (objectType === 'user' && objectId === auth.userId) {
    return null;
  }

  return (
    <RoleGuard role="admin">
      <LoadingPromiseButton {...restOfProps} onClick={onClick}>
        Logga in
      </LoadingPromiseButton>
    </RoleGuard>
  );
});
export default AuthSwitchButton;
