import React, { useCallback } from 'react';
import { Button, Form } from 'react-bootstrap';
import { preventDefaultSubmit } from 'client/utils/form';
import { CustomerTableFilterParams } from 'client/customer/CustomerTablePage';
import RefreshButton from 'client/buttons/RefreshButton';

interface ICustomerTableFilterForm {
  filterParams?: CustomerTableFilterParams;
  setFilterParams: React.Dispatch<React.SetStateAction<CustomerTableFilterParams>>;
  onRefetch: () => void;
  onReset: () => void;
  isLoading: boolean;
}

const CustomerTableFilterForm: React.FC<ICustomerTableFilterForm> = React.memo(function CustomerTableFilterForm (props: ICustomerTableFilterForm) {
  const {
    isLoading,
    onRefetch,
    onReset,
    filterParams = {},
    setFilterParams,
  } = props;

  const onChange = useCallback((ev: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const { name, value } = ev.target;
    setFilterParams({[name]: value});
  }, [filterParams, setFilterParams]);

  return (
    <Form className="d-flex flex-wrap gap-3" onSubmit={preventDefaultSubmit}>
      <Form.Group className="flex-grow-1">
        <Form.Control
          isValid={Boolean(filterParams.search_term)}
          name="search_term"
          onChange={onChange}
          placeholder="Oavsett sökterm"
          value={filterParams.search_term ?? ''}
        />
      </Form.Group>
      <Form.Group className="flex-grow-1">
        <Form.Select
          isValid={Boolean(filterParams.include_inactive)}
          name="include_inactive"
          onChange={onChange}
          value={String(filterParams.include_inactive ?? '')}
        >
          <option value="">Exkludera inaktiva</option>
          <option value="1">Inkludera inaktiva</option>
        </Form.Select>
      </Form.Group>
      <Button
        title="Ta bort alla filtreringar"
        onClick={onReset}
        variant="outline-primary"
        className="d-flex align-items-center justify-content-center"
        disabled={isLoading}
      >
        Återställ
      </Button>
      <RefreshButton onClick={onRefetch} disabled={isLoading} />
    </Form>
  );
});
export default CustomerTableFilterForm;
