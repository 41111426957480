import React from 'react';
import { Card, Container } from 'react-bootstrap';
import ErrorAlert from 'client/components/ErrorAlert';
import { useQuery } from '@tanstack/react-query';
import AccountForm from 'client/account/AccountForm';
import { AccountResponseBody  } from 'client/contexts/AuthContext';
import BlockSpinner from 'client/spinners/BlockSpinner';
import { Helmet } from 'react-helmet';

export default function AccountPage () {
  const accountPageQuery = useQuery<AccountResponseBody, Error>({
    queryKey: ['/api/account'],
  });

  return (
    <Container fluid="sm" className="p-4">
      <Helmet>
        <title>Kontoinställningar</title>
      </Helmet>
      <Card>
        <Card.Header>
          <Card.Title className="py-3 mb-0">
            Kontoinställningar
          </Card.Title>
        </Card.Header>
        <BlockSpinner isLoading={accountPageQuery.isLoading} className="m-3" />
        <ErrorAlert className="mt-3 mb-0" error={accountPageQuery.error} />
        {accountPageQuery.isSuccess && (
          <AccountForm
            initialSettings={accountPageQuery.data}
          />
        )}
      </Card>
    </Container>
  );
}
