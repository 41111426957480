import React from 'react';
import moment from 'moment';

const defaultInputFormats = [
  'YYYY-MM-DD',
  'YYYY-MM-DD HH:mm:ss',
  'YYYY-MM-DD HH:mm:ssZ',
  'YYYY-MM-DDTHH:mm:ss.SSSZ',
  'YYYY-MM-DDTHH:mm:ssZ',
  'YYYY-MM-DDTHH:mm:ss.SSSZ',
  'YYYY-MM-DDTHH:mm:ss.SSSSSSZ',
];

interface DateFormatProps extends Omit<React.HTMLAttributes<HTMLTimeElement>, 'defaultValue'> {
  value: any;
  defaultValue?: React.ReactNode;
  format?: string;
}

const DateFormat: React.FC<DateFormatProps> = React.memo(function DateFormat (props: DateFormatProps) {
  const {
    value,
    defaultValue = '',
    format = 'YYYY-MM-DD HH:mm',
    ...otherProps
  } = props;
  const mom = moment(value, defaultInputFormats, true);
  if (!mom.isValid()) return <>{defaultValue}</>;
  return (
    <time {...otherProps} dateTime={value} title={mom.format('YYYY-MM-DD HH:mm:ss')}>
      {mom.format(format)}
    </time>
  );
});
export default DateFormat;
