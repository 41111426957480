import React from 'react';
import { urlPathWithQuery } from 'client/utils/misc';
import { Link } from 'react-router-dom';

interface SniCodeAnchorProps {
  sniCode: string;
}

export const SniCodeAnchor: React.FC<SniCodeAnchorProps> = React.memo(function SniCodeAnchor (props: SniCodeAnchorProps) {
  const { sniCode } = props;
  const to = urlPathWithQuery('/companies', {
    legalgroup_code: 'AB',
    sni_code: sniCode,
  });
  return (
    <Link to={to} className="font-monospace border rounded small text-decoration-none p-1">
      {sniCode}
    </Link>
  );
});
