import React, { useMemo, useCallback } from 'react';
import omit from 'lodash/omit';

type IdType = string | number;

export type SelectedRows = Record<IdType, boolean>;

export type SetSelectedRowsFn = React.Dispatch<React.SetStateAction<SelectedRows>>;

enum CheckboxStates {
  UNCHECKED = 0,
  CHECKED = 1,
  INDETERMINATE = 2
}

export interface IUseTableSelectRows {
  selectedRows: SelectedRows;
  setSelectedRows: SetSelectedRowsFn;
  allRowSelectedChecked: boolean;
  allRowSelectedIndeterminated: boolean;
  onChangeSelectRowInput: React.ChangeEventHandler<HTMLInputElement>;
  onChangeSelectAllRowsInput: () => void;
  selectedRowCount: number;
  selectedRowIds: IdType[];
}

const defaultSelectedRows = {};
function useTableSelectRows (currentPageRowIds: IdType[], selectedRows: SelectedRows = defaultSelectedRows, setSelectedRows: SetSelectedRowsFn): IUseTableSelectRows {
  const selectAllRows = useCallback(() => {
    setSelectedRows(state => currentPageRowIds.reduce((selected, id) => {
        selected[id] = true;
        return selected;
      }, {...state}),
    );
  }, [currentPageRowIds, setSelectedRows]);

  const setSelectedRow = useCallback((id: IdType, selected: boolean) => {
    if (!selected) setSelectedRows(state => omit(state, id));
    else setSelectedRows(state => ({...state, [id]: true}));
  }, [setSelectedRows]);

  const onChangeSelectRowInput = useCallback((ev: React.ChangeEvent<HTMLInputElement>) => {
    const { value:id, checked:selected } = ev.currentTarget;
    if (!id) return;
    setSelectedRow(id, selected);
  }, [setSelectedRow]);

  const [selectedRowIds, selectedRowCount] = useMemo(() => {
    const ids = Object.keys(selectedRows).filter(id => selectedRows[id] === true);
    return [ids, ids.length];
  }, [selectedRows, setSelectedRows]);

  const allRowSelectedState = useMemo(() => {
    if (currentPageRowIds.length && currentPageRowIds.every((id) => selectedRowIds.includes(String(id)))) {
      return CheckboxStates.CHECKED;
    }
    if (selectedRowCount > 0) {
      return CheckboxStates.INDETERMINATE;
    }

    return CheckboxStates.UNCHECKED;
  }, [currentPageRowIds, selectedRowIds, selectedRowCount]);

  const onChangeSelectAllRowsInput = useCallback(() => {
    switch (allRowSelectedState) {
      case CheckboxStates.CHECKED:
        setSelectedRows({});
        break;
      case CheckboxStates.INDETERMINATE:
      case CheckboxStates.UNCHECKED:
        selectAllRows();
        break;
    }
  }, [setSelectedRows, selectAllRows, allRowSelectedState]);

  return {
    selectedRows,
    setSelectedRows,
    allRowSelectedChecked: Boolean(allRowSelectedState),
    allRowSelectedIndeterminated: allRowSelectedState === CheckboxStates.INDETERMINATE,
    onChangeSelectRowInput,
    onChangeSelectAllRowsInput,
    selectedRowCount,
    selectedRowIds,
  };
}

export default useTableSelectRows;
