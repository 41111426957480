import { FocusEventHandler, MouseEventHandler, WheelEventHandler } from 'react';

interface State {
  zoom: number;
  leftDrag: number;
  topDrag: number;
  enabled: boolean;
}

interface MakeManipulationHandlersParameters extends Partial<State> {
  innerEl: HTMLElement;
  onChange: (state: State) => void;
  minZoom?: number;
  maxZoom?: number;
  zoomPerScroll?: number;
}

export const makeManipulationHandlers = () => (params: MakeManipulationHandlersParameters) => {
  let { zoom = 100, leftDrag = 0, topDrag = 0, enabled = true } = params;
  const { innerEl, onChange, minZoom = 100, maxZoom = 300, zoomPerScroll = 10 } = params;

  const on = () => {
    enabled = true;
  };

  const off = () => {
    enabled = false;
  };

  const doTransform = () => {
    if (!enabled) return;
    // TODO: handle a smooth transition for zoom only. Maybe on view level
    innerEl.style.transform = `scale(${zoom}%) translate(${leftDrag * (100 / zoom)}px, ${topDrag * (100 / zoom)}px)`;

    onChange({ zoom, leftDrag, topDrag, enabled });
  };

  const setZoom = (value: number) => {
    zoom = Math.min(Math.max(minZoom, value), maxZoom);
    doTransform();
  };

  const setDrag = (newLeftDrag: number, newTopDrag: number) => {
    if (!enabled) return;

    topDrag = newTopDrag;
    leftDrag = newLeftDrag ?? leftDrag;

    doTransform();
  };

  let isDragging = false;

  const onMouseDown: MouseEventHandler<HTMLElement> = (event) => {
    if (event.button !== 0) return;
    isDragging = true;
  };

  const onMouseMove: MouseEventHandler<HTMLElement> = (event) => {
    if (!isDragging) return;
    setDrag(leftDrag + event.movementX, topDrag + event.movementY);
  };

  const onMouseUp: MouseEventHandler<HTMLElement> = () => {
    isDragging = false;
  };

  const onBlur: FocusEventHandler<HTMLElement> = () => {
    isDragging = false;
  };

  const onMouseLeave: MouseEventHandler<HTMLElement> = () => {
    isDragging = false;
  };

  const onWheel: WheelEventHandler<HTMLElement> = (event) => {
    // TODO: adjust zoom to the center of viewport
    const newZoom = zoom + (event.deltaY > 0 ? -1 : 1) * zoomPerScroll;
    setZoom(newZoom);
  };

  return {
    setZoom,
    setDrag,
    on,
    off,
    callbacks: {
      onMouseDown, onMouseMove, onMouseUp, onMouseLeave, onBlur, onWheel,
    },
  };
};

export const makePdfWrapperManipulationHandlers = makeManipulationHandlers();
