
export const numberFormatter = new Intl.NumberFormat('sv-SE', {
  useGrouping: true,
});

export const currencyFormatter = new Intl.NumberFormat('sv-SE', {
  style: 'currency',
  currency: 'SEK',
  maximumFractionDigits: 0,
  useGrouping: true,
});

export const percentageFormatter = new Intl.NumberFormat('sv-SE', {
  style: 'percent',
  maximumFractionDigits: 2,
});

export const scaleFormatter = {
  format: (num: number): string => {
    let suffix = '';

    const scale = Math.floor((Math.abs(num).toString().length - 1) / 3);
    for (let i = 0; i < scale; i++) {
      num /= 1000;
    }

    if (num !== 0) {
      suffix = [' tkr', ' mnkr', ' mdkr'][scale];
    }

    return numberFormatter.format(num) + suffix;
  },
};

export type NumberFormatType = 'number' | 'currency' | 'percentage' | 'scale';

export function getFormatter (format: NumberFormatType) {
  switch (format) {
    default: return numberFormatter;
    case 'number': return numberFormatter;
    case 'percentage': return percentageFormatter;
    case 'currency': return currencyFormatter;
    case 'scale': return scaleFormatter;
  }
}

export function isFiniteNumber (num: any): num is number {
  return typeof num === 'number' && isFinite(num) && !isNaN(num);
}
