import React from 'react';
import { Table, TableProps } from 'react-bootstrap';
import { IReportQueueWithRelations } from 'client/reportQueue/types';
import { UserAnchor } from 'client/user/UserFormatters';
import HelperTooltip from 'client/components/HelperTooltip';
import DateFormat from 'client/components/DateFormat';
import * as ReportQueueFormatters from 'client/reportQueue/ReportQueueFormatters';
import { CustomerAnchor } from 'client/customer/CustomerFormatters';
import { CompanyAnchor } from 'client/company/CompanyFormatters';

interface ReportQueueOverviewTableProps extends TableProps {
  reportQueue: IReportQueueWithRelations;
}

const ReportQueueOverviewTable: React.FC<ReportQueueOverviewTableProps> = React.memo(function ReportQueueOverviewTable (props: ReportQueueOverviewTableProps) {
  const { reportQueue, ...restOfProps } = props;
  return (
    <Table {...restOfProps}>
      <tbody>
        <tr>
          <th>Typ</th>
          <td>
            <ReportQueueFormatters.ReportsType
              type={reportQueue.report_type}
              language={reportQueue.language}
              isGroup={reportQueue.report_settings.company_use_group_accounts}
            />
          </td>
        </tr>
        <tr>
          <th>Bokslut</th>
          <td>
            {reportQueue.report_settings?.accounts_from && reportQueue.report_settings?.accounts_to ? (
              <ReportQueueFormatters.AccountInterval value={reportQueue} />
            ) : '-'}
          </td>
        </tr>
        <tr>
          <th>
            Skapad{' '}
            <HelperTooltip>
              Tidpunkten då rapportkön skapades.
            </HelperTooltip>
          </th>
          <td>
            <DateFormat value={reportQueue.created_at} format="YYYY-MM-DD" />
          </td>
        </tr>
        {reportQueue?.creator && (
          <tr>
            <th>Skapad av</th>
            <td>
              <UserAnchor value={reportQueue.creator} />
            </td>
          </tr>
        )}
        <tr>
          <th>Företag</th>
          <td>
            <CompanyAnchor value={reportQueue.report_org_number} />
          </td>
        </tr>
        {reportQueue?.customer && (
          <tr>
            <th>Kund</th>
            <td>
              <CustomerAnchor value={reportQueue.customer} />
            </td>
          </tr>
        )}
        <tr>
          <th>Status</th>
          <td>
            <ReportQueueFormatters.StatusLabel
              value={reportQueue.status}
              done={reportQueue.is_done}
            />
          </td>
        </tr>
        {['ERROR', 'WAITING'].includes(reportQueue.status) && (
          <tr>
            <th>{reportQueue.status === 'ERROR' ? 'Felorsak' : 'Statusorsak'}</th>
            <td>{reportQueue.error_message}</td>
          </tr>
        )}
        <tr>
          <th>Statusändring</th>
          <td>
            <DateFormat value={reportQueue.status_date} format="YYYY-MM-DD" />
          </td>
        </tr>
      </tbody>
    </Table>
  );
});
export default ReportQueueOverviewTable;
