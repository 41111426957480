import { ICsBasic } from 'client/cs/types';
import { CompanyMonitoredRow } from 'client/companyMonitored/types';

export interface CompanyEventRow {
 id: string;
 org_number: string;
 event_type: EventType;
 event_type_code: null | string;
 event_date: null | Date | string;
 event_data: Record<string, any>;
 value_prev: null | string; // this is always returned as string from the server even when a number
 value_curr: null | string; // this is always returned as string from the server even when a number
 text_prev: null | string;
 text_curr: null | string;
 created_at: Date | string;
 updated_at: Date | string;
}

export interface CompanyEventRowWithRelations extends CompanyEventRow {
  cs_basic?: ICsBasic;
  company_monitored?: CompanyMonitoredRow;
}

export interface CompanyEventTimelineBatchRow {
  row_number: number;
  org_number: string;
  company_name?: string;
  event_type: EventType;
  event_type_code: null | string;
  value_start: null | string; // this is always returned as string from the server even when a number
  value_end: null | string; // this is always returned as string from the server even when a number
  text_start: null | string;
  text_end: null | string;
}

export interface CompanyEventTimelineBatchByUserRow extends CompanyEventTimelineBatchRow {
  user_id: string;
}

export interface CompanyEventTimelineBatchByCustomerRow extends CompanyEventTimelineBatchRow {
  customer_id: string;
}

export type EventType = 
  | 'credit_report_rating'
  | 'credit_report_kf_debt'
  | 'credit_report_kf_debt_nr_amal'
  | 'credit_report_kf_debt_nr_emal'
  | 'credit_report_kf_debt_sum_amal'
  | 'credit_report_kf_debt_sum_emal'
  | 'credit_report_antal_anm'
  | 'credit_report_antal_anm_amal1'
  | 'credit_report_antal_anm_amal'
  | 'credit_report_antal_anm_emal1'
  | 'credit_report_antal_anm_emal'
  | 'credit_report_sum_anm'
  | 'credit_report_sum_anm_amal'
  | 'credit_report_sum_anm_emal'
  | 'credit_report_sum_ansokan'
  | 'credit_report_antal_ansokan'
  | 'credit_report_limit'
  | 'credit_report_risk_prognosis'
  | 'credit_report_risk_check_activity'
  | 'credit_report_risk_check_address'
  | 'credit_report_risk_check_auditor'
  | 'credit_report_risk_check_board'
  | 'credit_report_risk_check_name'
  | 'credit_report_kf_debt_date'
  | 'credit_report_rating_text'
  | 'credit_report_company_status'
  | 'credit_report_company_status_code'
  | 'credit_report_company_status_date'
  | 'credit_report_company_name'
  | 'credit_report_bankruptcy_date'
  | 'credit_report_bankruptcy_text'
  | 'credit_report_bankruptcy_type'
  | 'credit_report_moms'
  | 'credit_report_moms_nr'
  | 'credit_report_moms_start_date'
  | 'credit_report_ftax_deregistration_code'
  | 'credit_report_ftax_deregistration_reason_text'
  | 'credit_report_ftax_end_date'
  | 'credit_report_ftax_start_date'
  | 'credit_report_rating_history_rating'
  | 'credit_report_rating_history_text'
  | 'credit_report_directors_changed'
  | 'credit_report_directors_added'
  | 'credit_report_directors_removed'
  | 'credit_report_commentary_changed'
  | 'credit_report_commentary_added'
  | 'credit_report_commentary_removed'
;

export const eventTypes: EventType[] = [
  'credit_report_rating',
  'credit_report_kf_debt',
  'credit_report_kf_debt_nr_amal',
  'credit_report_kf_debt_nr_emal',
  'credit_report_kf_debt_sum_amal',
  'credit_report_kf_debt_sum_emal',
  'credit_report_antal_anm',
  'credit_report_antal_anm_amal1',
  'credit_report_antal_anm_amal',
  'credit_report_antal_anm_emal1',
  'credit_report_antal_anm_emal',
  'credit_report_sum_anm',
  'credit_report_sum_anm_amal',
  'credit_report_sum_anm_emal',
  'credit_report_sum_ansokan',
  'credit_report_antal_ansokan',
  'credit_report_limit',
  'credit_report_risk_prognosis',
  'credit_report_risk_check_activity',
  'credit_report_risk_check_address',
  'credit_report_risk_check_auditor',
  'credit_report_risk_check_board',
  'credit_report_risk_check_name',
  'credit_report_kf_debt_date',
  'credit_report_rating_text',
  'credit_report_company_status',
  'credit_report_company_status_code',
  'credit_report_company_status_date',
  'credit_report_company_name',
  'credit_report_bankruptcy_date',
  'credit_report_bankruptcy_text',
  'credit_report_bankruptcy_type',
  'credit_report_moms',
  'credit_report_moms_nr',
  'credit_report_moms_start_date',
  'credit_report_ftax_deregistration_code',
  'credit_report_ftax_deregistration_reason_text',
  'credit_report_ftax_end_date',
  'credit_report_ftax_start_date',
  'credit_report_rating_history_rating',
  'credit_report_rating_history_text',
];
