import React, { useCallback, useId } from 'react';
import axios from 'client/axios';
import { range } from 'lodash';
import moment from 'moment';
import ErrorAlert from 'client/components/ErrorAlert';
import NumberFormat from 'client/components/NumberFormat';
import { useMutation } from '@tanstack/react-query';
import { Form, Card, Table } from 'react-bootstrap';
import LoadingButton from 'client/buttons/LoadingButton';
import { TChangeValue } from 'client/utils/form';
import { TNumberOfEmployeesIntervals } from 'client/accounts/types';
import { NumberOfEmployeesSelect } from 'client/components/ReportFormHelpers';
import CompanySNICodeEditor from 'client/company/CompanySNICodeEditor';
import { ICsBasicExtended } from 'client/cs/types';
import HelperTooltip from 'client/components/HelperTooltip';

interface IValuationReportIndustryDataSubForm {
  company: ICsBasicExtended;
  orgNumber: string;
  sniCode: string;
  nbrEmployeesInterval: TNumberOfEmployeesIntervals;
  onChangeValue: TChangeValue;
  mostRecentAccountDate: string;
}

interface IGetIndustryDataVars {
  exclude_org_number: string;
  sni_code: string;
  nbr_employees_interval: TNumberOfEmployeesIntervals;
  comparison_years: string[];
}

interface IIndustryDataYear {
  year: string;
  company_count: number;
  kr_change_in_turnover_percent: number;
  kr_return_on_capital_percent: number;
  kr_return_on_op_capital: number;
  kr_profit_margin_percent: number;
  kr_solidity_percent: number;
  kr_operating_margin_percent: number;
  kr_capital_turnover_times: number;
  kr_turnover_per_employee: number;
  kr_opp_result_number_of_employees: number;
}

type TGetIndustryDataResponse = IIndustryDataYear[];

const ValuationReportIndustryDataSubForm: React.FC<IValuationReportIndustryDataSubForm> = React.memo(function ValuationReportIndustryDataSubForm (props: IValuationReportIndustryDataSubForm) {
  const {
    orgNumber,
    company,
    sniCode,
    nbrEmployeesInterval,
    onChangeValue,
    mostRecentAccountDate,
  } = props;

  const onChange = useCallback((ev: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = ev.target;
    onChangeValue(name, value);
  }, [onChangeValue]);

  const sniCodeId = useId();
  const nbrOfEmployeesIntervalId = useId();

  const getIndustryDataMutation = useMutation<TGetIndustryDataResponse, Error, IGetIndustryDataVars>({
    mutationKey: ['ValuationReportIndustryDataSubForm'],
    mutationFn: vars => axios.post('/api/valuation_reports/industry_data', vars).then(result => result.data),
  });

  const onClick = useCallback(() => {
    const vars = {
      exclude_org_number: orgNumber,
      sni_code: sniCode,
      nbr_employees_interval: nbrEmployeesInterval,
      comparison_years: range(5).map(index => {
        return moment(mostRecentAccountDate, 'YYYY-MM-DD').subtract(index, 'years').format('YYYY');
      }),
    };
    getIndustryDataMutation.mutate(vars);
  }, [orgNumber, sniCode, nbrEmployeesInterval, mostRecentAccountDate, getIndustryDataMutation]);

  return (
    <Card.Body className="p-0 border-top">
      <Card.Title className="mx-3 mt-4 mb-0 border-bottom pb-1">Inställningar för företagsvärderingens branschjämförelse</Card.Title>
      <ErrorAlert error={getIndustryDataMutation.error} className="mx-3 mt-3 mb-0" />
      <div className="d-flex gap-2 p-3 align-items-end flex-wrap">
        <CompanySNICodeEditor
          orgNumber={orgNumber}
          sniCode={company.sni_code as string}
          sniCodeOriginal={company.sni_code_original}
        />
        <Form.Group className="border-start ps-2 ms-0">
          <Form.Label htmlFor={sniCodeId}>
            SNI-kod i jämförelse{' '}
            <HelperTooltip>
              Anger prefixet för SNI-kod som branschjämförelsen i företagsvärderingen använder sig av.<br /><br />
              Man behöver inte ange en fullständig SNI-kod. Om man exempelvis anger "72"
              så kommer alla företag vars SNI-kod börjar på 72 att användas.
            </HelperTooltip>
          </Form.Label>
          <Form.Control
            id={sniCodeId}
            name="report_settings.sni_code"
            value={sniCode}
            onChange={onChange}
            minLength={1}
            maxLength={5}
            required
            isInvalid={sniCode.length < 1 || sniCode.length > 5 || /\D/.test(sniCode)}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label htmlFor={nbrOfEmployeesIntervalId}>
            Intervall i jämförelse{' '}
            <HelperTooltip>
              Anger antal anställda hos de företag som hamnar i värderingens branschjämförelse. Värdet har automatiskt valts
              baserat på antalet anställda värderingsföretaget har det senaste bokslutsåret.
            </HelperTooltip>
          </Form.Label>
          <NumberOfEmployeesSelect
            id={nbrOfEmployeesIntervalId}
            name="report_settings.nbr_employees_interval"
            value={nbrEmployeesInterval}
            onChangeValue={onChangeValue}
          />
        </Form.Group>
        <LoadingButton
          onClick={onClick}
          variant="outline-primary"
          isLoading={getIndustryDataMutation.isPending}
        >
          Ladda jämförelsedata
        </LoadingButton>
      </div>
      {getIndustryDataMutation.isSuccess && (
        <div className="border-top p-4">
          <IndustryDataTable
            data={getIndustryDataMutation.data}
          />
        </div>
      )}
    </Card.Body>
  );
});
export default ValuationReportIndustryDataSubForm;

interface IIndustryDataTable {
  data: IIndustryDataYear[];
}

function IndustryDataTable (props: IIndustryDataTable) {
  const { data } =props;
  const years = data.map(row => row.year);
  return (
    <Table bordered className="mb-0" striped>
      <thead>
        <tr>
          <th></th>
          {years.map(year => (
            <th key={year}>{year}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        <tr>
          <th className="text-end">Antal företag</th>
          {data.map(row => (
            <td key={row.year}>{row.company_count}</td>
          ))}
        </tr>
        <tr>
          <th className="text-end">Omsättningstillväxt (%)</th>
          {data.map(row => (
            <td key={row.year}>
              <NumberFormat value={row.kr_change_in_turnover_percent} />
            </td>
          ))}
        </tr>
        <tr>
          <th className="text-end">Avkastning på eget kapital (%)</th>
          {data.map(row => (
            <td key={row.year}>
              <NumberFormat value={row.kr_return_on_capital_percent} />
            </td>
          ))}
        </tr>
        <tr>
          <th className="text-end">Rörelsemarginal (%)</th>
          {data.map(row => (
            <td key={row.year}>
              <NumberFormat value={row.kr_operating_margin_percent} />
            </td>
          ))}
        </tr>
        <tr>
          <th className="text-end">Soliditet (%)</th>
          {data.map(row => (
            <td key={row.year}>
              <NumberFormat value={row.kr_solidity_percent} />
            </td>
          ))}
        </tr>
        <tr>
          <th className="text-end">Kapitalomsättningshastighet (ggr)</th>
          {data.map(row => (
            <td key={row.year}>
              <NumberFormat value={row.kr_capital_turnover_times} />
            </td>
          ))}
        </tr>
        <tr>
          <th className="text-end">Omsättning per anställd (kr)</th>
          {data.map(row => (
            <td key={row.year}>
              <NumberFormat value={row.kr_turnover_per_employee} />
            </td>
          ))}
        </tr>
        <tr>
          <th className="text-end">Rörelseresultat per anställd (kr)</th>
          {data.map(row => (
            <td key={row.year}>
              <NumberFormat value={row.kr_opp_result_number_of_employees} />
            </td>
          ))}
        </tr>
      </tbody>
    </Table>
  );
}
