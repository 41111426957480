import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Centered, AuthWrapCard } from 'client/login/LoginUtils';
import { Formik, Form as FormikForm, Field, FormikHelpers } from 'formik';
import PasswordFormControl, { PasswordFormText, passwordRegexp } from 'client/form/PasswordFormControl';
import { Link } from 'react-router-dom';
import { errorToMessage } from 'client/utils/errors';
import axios from 'client/axios';
import ErrorAlert from 'client/components/ErrorAlert';
import IdProvider from 'client/components/IdProvider';
import LoadingButton from 'client/buttons/LoadingButton';
import ErrorFormControlFeedback from 'client/form/ErrorFormControlFeedback';
import { Card, Form } from 'react-bootstrap';
import { useMutation } from '@tanstack/react-query';

interface IResetPasswordPageParams {
  token: string;
}

interface IResetPasswordFormData {
  password: string; 
  password_repeat: string;
}

interface IResetPasswordMutationResponseBody {
  ok: boolean;
  error?: string;
}

interface IResetPasswordPage {

}

const ResetPasswordPage: React.FC<IResetPasswordPage> = React.memo(function ResetPasswordPage (props: IResetPasswordPage) {
  const params = useParams<IResetPasswordPageParams>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [formError, setFormError] = useState<string>('');

  const history = useHistory();

  const resetPasswordMutation = useMutation<IResetPasswordMutationResponseBody, Error, IResetPasswordFormData>({
    mutationKey: ['ResetPasswordMutation'],
    mutationFn: form => axios.post(`/api/password_reset/execute/${params.token}`, form).then(r => r.data),
    onError: err => {
      setFormError(errorToMessage(err));
    },
    onSuccess: body => {
      const { ok, error } = body;
      if (!ok) {
        setFormError(error ? error : 'Okänt fel');
      } else {
        history.push('/login?password_reset=true');
      }
    },
  });

  const initialFormValues = {password: '', password_repeat: ''};

  const validateForm = (values: IResetPasswordFormData) => {
    const errors: any = {};
    const { password, password_repeat } = values;
    if (!passwordRegexp.test(password)) {
      errors.password = 'Följ villkoren';
    }
    if (password && password_repeat && password !== password_repeat) {
      errors.password_repeat = 'Lösenordet stämmer inte';
    }
    return errors;
  };

  const onSubmit = (form: IResetPasswordFormData, helpers: FormikHelpers<IResetPasswordFormData>) => {
    setFormError('');
    setIsLoading(true);
    return resetPasswordMutation.mutateAsync(form).then(() => {
      helpers.resetForm();
    }).finally(() => {
      setIsLoading(false);
    });
  };

  return (
    <Centered>
      <Formik
        initialValues={initialFormValues}
        onSubmit={onSubmit}
        validate={validateForm}
      >
        {formikBag => (
          <FormikForm>
            <AuthWrapCard>
              <Card.Body className="pb-1">
                <Card.Title className="border-bottom pb-2 mb-3">
                  Formulär för att återställa lösenord
                </Card.Title>
                <IdProvider>
                  {id => (
                    <Form.Group className="mb-3">
                      <Form.Label htmlFor={id}>
                        Nytt lösenord
                      </Form.Label>
                      <Field
                        as={PasswordFormControl}
                        isOwnPassword
                        id={id}
                        type="password"
                        name="password"
                        minLength="8"
                        placeholder="Ange ditt lösenord"
                        isInvalid={Boolean(formikBag.errors.password)}
                        isValid={!formikBag.errors.password && formikBag.touched.password}
                        required
                      />
                      <PasswordFormText />
                    </Form.Group>
                  )}
                </IdProvider>
                <IdProvider>
                  {id => (
                    <Form.Group className="mb-3">
                      <Form.Label htmlFor={id}>
                        Repetera lösenordet
                      </Form.Label>
                      <Field
                        as={PasswordFormControl}
                        isOwnPassword
                        id={id}
                        type="password"
                        minLength="8"
                        name="password_repeat"
                        placeholder="Ange ditt lösenord igen"
                        isInvalid={Boolean(formikBag.errors.password_repeat)}
                        isValid={!formikBag.errors.password_repeat && formikBag.touched.password_repeat}
                        required
                      />
                      <ErrorFormControlFeedback
                        error={formikBag.errors.password_repeat}
                        touched={formikBag.touched.password_repeat}
                      />
                    </Form.Group>
                  )}
                </IdProvider>
                <ErrorAlert error={formError} />
              </Card.Body>
              <Card.Footer className="d-flex justify-content-between align-items-baseline py-3">
                <Link to="/login">&laquo; Gå till inloggningen</Link>
                <LoadingButton
                  type="submit"
                  variant="outline-primary"
                  isLoading={isLoading}
                  disabled={!formikBag.isValid || formikBag.isSubmitting}
                >
                  Byt lösenord
                </LoadingButton>
              </Card.Footer>
            </AuthWrapCard>
          </FormikForm>
        )}
      </Formik>
    </Centered>
  );
});

export default ResetPasswordPage;
