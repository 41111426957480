import { ICustomer, ICustomerWithReportCounts } from 'client/customer/types';
import StatusBadge from 'client/components/StatusBadge';
import { OverlayTrigger, Tooltip, BadgeProps } from 'react-bootstrap';
import AnchorWithFlag from 'client/anchors/AnchorWithFlag';

interface CustomerAnchorProps {
  value?: null | string | Partial<ICustomer>;
  inactivated?: boolean;
}

export function CustomerAnchor (props: CustomerAnchorProps) {
  const { value, inactivated } = props;
  if (value === null || typeof value === 'undefined') return null;
  if (typeof value === 'string') {
    return (
      <AnchorWithFlag to={`/customer/${value}/form`} inactivated={inactivated}>
        {value}
      </AnchorWithFlag>
    );
  }

  const { id, name, inactivated_at } = value as Partial<ICustomer>;
  return (
    <AnchorWithFlag to={`/customer/${id}/form`} inactivated={Boolean(inactivated_at)}>
      {name ?? id}
    </AnchorWithFlag>
  );
}

interface ICustomerString {
  value: Partial<ICustomer>;
}
export function CustomerString (props: ICustomerString) {
  const { value: { id, name } } = props;
  if (!id) return null;
  if (!name) return <>{id}</>;
  return <>{id} - {name}</>;
}

// FIXME find new icons?

interface ICustomerReportCounts {
  value: ICustomerWithReportCounts;
}
export function CustomerReportCounts (props: ICustomerReportCounts) {
  const { value:customer } = props;
  return (
    <div className="d-flex align-items-center gap-1">
      <OverlayTrigger
        placement="top"
        overlay={<Tooltip style={{position: 'fixed'}}>Företagsvärderingar</Tooltip>}
      >
        <span className="badge border border-primary text-primary">
          V: {customer.valuation_reports_count ?? 0}
        </span>
      </OverlayTrigger>
      <OverlayTrigger
        placement="top"
        overlay={<Tooltip style={{position: 'fixed'}}>Branschrapporter</Tooltip>}
      >
        <span className="badge border border-warning text-warning">
          B: {customer.industry_reports_count ?? 0}
        </span>
      </OverlayTrigger>
      <OverlayTrigger
        placement="top"
        overlay={<Tooltip style={{position: 'fixed'}}>Köade rapporter</Tooltip>}
      >
        <span className="badge border border-secondary text-secondary">
          R: {customer.report_queues_count}
        </span>
      </OverlayTrigger>
    </div>
  );
}

interface StatusLabelProps extends BadgeProps {
  value: ICustomer['inactivated_at'];
}

export function StatusLabel (props: StatusLabelProps) {
  const { value, ...restOfProps } = props;
  return value === null ? (
    <StatusBadge bg="success" {...restOfProps}>Aktiv</StatusBadge>
  ) : (
    <StatusBadge bg="warning" {...restOfProps}>Inaktiverad</StatusBadge>
  );
}
