import React from 'react';
import SendEmailButton, { SendEmailButtonProps } from 'client/email/SendEmailButton';

interface SendCompanyEventBatchEmailButtonProps extends Omit<SendEmailButtonProps, 'objectType' | 'objectId' | 'emailType'> {
  companyEventBatchId: string;
  onSuccess?: () => void;
}

const SendCompanyEventBatchEmailButtonProps: React.FC<SendCompanyEventBatchEmailButtonProps> = React.memo(function SendCompanyEventBatchEmailButton (props: SendCompanyEventBatchEmailButtonProps) {
  const { companyEventBatchId, ...restOfProps } = props;


  return (
    <SendEmailButton
      objectType="companyEventBatch"
      objectId={companyEventBatchId}
      {...restOfProps}
      emailType="companyEventBatch"
    />
  );
});
export default SendCompanyEventBatchEmailButtonProps;

