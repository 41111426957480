import React from 'react';
import { Field, FormikProps } from 'formik';
import IdProvider from 'client/components/IdProvider';
import ErrorFormControlFeedback from 'client/form/ErrorFormControlFeedback';
import { Row, Col, Form } from 'react-bootstrap';
import { numberOfLines } from 'client/utils/form';
import FormControlTextarea from 'client/form/FormControlTextarea';

export interface UpdateEmailTemplateFormFields {
  name: string;
  view: string;
  subject: string;
  header: string;
  body: string;
  footer: string;
}

interface EmailTemplateFormProps {
  disabled?: boolean;
  formikBag: FormikProps<UpdateEmailTemplateFormFields>;
}

const EmailTemplateForm: React.FC<EmailTemplateFormProps> = React.memo(function EmailTemplateForm (props: EmailTemplateFormProps) {
  const { disabled = false, formikBag } = props;
  return (
    <Row>
      <Col md={12} lg={6}>
        <IdProvider>
          {id => (
            <Form.Group className="mb-3">
              <Form.Label htmlFor={id}>
                Mallnamn
              </Form.Label>
              <Field
                as={Form.Control}
                disabled={disabled}
                id={id}
                name="name"
                placeholder="Ange mallens namn"
                isInvalid={Boolean(formikBag.errors.name)}
                required
              />
              <ErrorFormControlFeedback
                error={formikBag.errors.name}
                touched={formikBag.touched.name}
              />
              <Form.Text>
                Mallnamnet används internt. Byt bara namn om du är säker på konsekvenserna.
              </Form.Text>
            </Form.Group>
          )}
        </IdProvider>
      </Col>
      <Col md={12} lg={6}>
        <IdProvider>
          {id => (
            <Form.Group className="mb-3">
              <Form.Label htmlFor={id}>
                Vynamn
              </Form.Label>
              <Field
                as={Form.Control}
                disabled={disabled}
                id={id}
                name="view"
                placeholder="Ange vyns namn"
                isInvalid={Boolean(formikBag.errors.view)}
                required
              />
              <ErrorFormControlFeedback
                error={formikBag.errors.view}
                touched={formikBag.touched.view}
              />
              <Form.Text>
                Vynamnet används internt. Byt bara namn om du är säker på konsekvenserna.
              </Form.Text>
            </Form.Group>
          )}
        </IdProvider>
      </Col>
      <Col md={12}>
        <IdProvider>
          {id => (
            <Form.Group className="mb-3">
              <Form.Label htmlFor={id}>
                Ämnesrad
              </Form.Label>
              <Field
                as={Form.Control}
                disabled={disabled}
                id={id}
                name="subject"
                placeholder="Ange en ämnesrad"
                isInvalid={Boolean(formikBag.errors.subject)}
                required
              />
              <ErrorFormControlFeedback
                error={formikBag.errors.subject}
                touched={formikBag.touched.subject}
              />
            </Form.Group>
          )}
        </IdProvider>
      </Col>
      <Col md={12}>
        <IdProvider>
          {id => (
            <Form.Group className="mb-3">
              <Form.Label htmlFor={id}>
                Huvud
              </Form.Label>
              <Field
                as={FormControlTextarea}
                disabled={disabled}
                id={id}
                name="header"
                isInvalid={Boolean(formikBag.errors.header)}
                rows={numberOfLines(formikBag.values.header)}
              />
              <ErrorFormControlFeedback
                error={formikBag.errors.header}
                touched={formikBag.touched.header}
              />
            </Form.Group>
          )}
        </IdProvider>
      </Col>
      <Col md={12}>
        <IdProvider>
          {id => (
            <Form.Group className="mb-3">
              <Form.Label htmlFor={id}>
                Text
              </Form.Label>
              <Field
                as={FormControlTextarea}
                disabled={disabled}
                id={id}
                name="body"
                isInvalid={Boolean(formikBag.errors.body)}
                rows={numberOfLines(formikBag.values.body)}
              />
              <ErrorFormControlFeedback
                error={formikBag.errors.body}
                touched={formikBag.touched.body}
              />
            </Form.Group>
          )}
        </IdProvider>
      </Col>
      <Col md={12}>
        <IdProvider>
          {id => (
            <Form.Group className="mb-3">
              <Form.Label htmlFor={id}>
                Fot
              </Form.Label>
              <Field
                as={FormControlTextarea}
                disabled={disabled}
                id={id}
                name="footer"
                isInvalid={Boolean(formikBag.errors.footer)}
                rows={numberOfLines(formikBag.values.footer)}
              />
              <ErrorFormControlFeedback
                error={formikBag.errors.footer}
                touched={formikBag.touched.footer}
              />
            </Form.Group>
          )}
        </IdProvider>
      </Col>
    </Row>
  );
});

export default EmailTemplateForm;
