import React, { useState, useEffect } from 'react';
import { useMutation } from '@tanstack/react-query';
import * as requestCallbacks from 'client/utils/requestCallbacks';
import * as errorUtils from 'client/utils/errors';
import useParsedSearch from 'client/hooks/useParsedSearch';
import { FortnoxToken } from 'client/fortnox/types';
import LoadingButton from 'client/buttons/LoadingButton';
import axios from 'client/axios';
import {useHistory} from 'react-router-dom';

const REDIRECT_URI = process.env.REACT_APP_FORTNOX_AUTH_REDIRECT_URL;

interface FortnoxIncomingQuery {
  code?: string;
  error?: string;
  error_description?: string;
  state?: string;
}

interface FortnoxTokenResponse {
  token: FortnoxToken;
}

interface FortnoxTokenButtonProps {
  onNewAccessToken: (token: FortnoxToken) => void;
}

export function FortnoxTokenByAuthCodeButton (props: FortnoxTokenButtonProps) {
  const { onNewAccessToken } = props;
  const { code, error, error_description } = useParsedSearch<FortnoxIncomingQuery>();

  const [authCode, setAuthCode] = useState<string>('');
  const history = useHistory();

  const mutation = useMutation<FortnoxTokenResponse, Error, string>({
    mutationFn: authorization_code => axios.post('/api/fortnox/authorization_code', {
      redirect_uri: REDIRECT_URI,
      authorization_code,
    }).then(r => r.data),
    onSuccess: body => {
      requestCallbacks.onSuccess('En ny token har hämtats och lagts in i formuläret');
      setAuthCode('');
      onNewAccessToken(body.token);
    },
    onError: err => {
      requestCallbacks.onError(errorUtils.errorToMessage(err, 'Okänt fel vid hämtningen'));
    },
  });

  const onClick = () => {
    mutation.mutateAsync(authCode);
  };

  const disabled = !authCode || mutation.isSuccess;

  // when a code or error comes in on the query string, we remove the code from the query string and
  // trigger the fetching of the token
  useEffect(() => {
    if (!error && !code) return;
    if (error) {
      const msg = [error, error_description].filter(v => v).join(': ');
      requestCallbacks.onError(msg);
    } else if (code) {
      setAuthCode(code);
      mutation.mutateAsync(code);
    }
    history.replace({search: ''});
  }, [code, error]);

  return (
    <LoadingButton
      className="flex-shrink-0"
      variant="outline-primary"
      isLoading={mutation.isPending}
      disabled={disabled}
      onClick={onClick}
    >
      Hämta ny token (auth)
    </LoadingButton>
  );
}

interface FortnoxTokenByRefreshCodeButtonProps extends FortnoxTokenButtonProps {
  refreshCode?: string;
}

export function FortnoxTokenByRefreshCodeButton (props: FortnoxTokenByRefreshCodeButtonProps) {
  const { refreshCode, onNewAccessToken } = props;

  const mutation = useMutation<FortnoxTokenResponse, Error>({
    mutationFn: () => axios.post('/api/fortnox/refresh_code', {
      refresh_code: refreshCode,
    }).then(r => r.data),
    onSuccess: body => {
      requestCallbacks.onSuccess('En uppdaterad token har hämtats och lagts in i formuläret');
      onNewAccessToken(body.token);
    },
    onError: err => {
      requestCallbacks.onError(errorUtils.errorToMessage(err, 'Okänt fel vid hämtningen'));
    },
  });

  const onClick = () => {
    mutation.mutateAsync();
  };

  return (
    <LoadingButton
      className="flex-shrink-0"
      variant="outline-primary"
      isLoading={mutation.isPending}
      disabled={!refreshCode}
      onClick={onClick}
    >
      Uppdatera token (refresh)
    </LoadingButton>
  );
}
