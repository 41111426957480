import React from 'react';
import SendEmailButton, { SendEmailButtonProps } from 'client/email/SendEmailButton';

interface SendUserEmailButtonProps extends Omit<SendEmailButtonProps, 'objectType' | 'objectId'>  {
  emailType: 'resetPassword';
  userId: string;
  onSuccess: () => void;
}

const SendUserEmailButtonProps: React.FC<SendUserEmailButtonProps> = React.memo(function SendUserEmailButton (props: SendUserEmailButtonProps) {
  const { userId, ...restOfProps } = props;


  return (
    <SendEmailButton
      objectType="user"
      objectId={userId}
      {...restOfProps}
    />
  );
});
export default SendUserEmailButtonProps;
