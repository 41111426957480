import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import useAuth from 'client/hooks/useAuth';
import useLastLocation from 'client/hooks/useLastLocation';

interface IPrivateRoute extends RouteProps {
  component: any;
}

const PublicRoute: React.FC<IPrivateRoute> = React.memo(function PublicRoute (props: IPrivateRoute) {
  const { component: Component, ...rest } = props;
  const auth = useAuth();
  const { clearLastLocation, getLastLocation } = useLastLocation();
  return (
    <Route
      {...rest}
      render={props => {
        if (auth.isAuthenticated) {
          const redirectTo = getLastLocation() || '/';
          clearLastLocation();
          return <Redirect to={redirectTo} />;
        }
        return <Component {...props} />;
      }}
    />
  );
});

export default PublicRoute;
