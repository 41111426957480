import { useQuery } from '@tanstack/react-query';
import { CreditReportWithRelationsRow } from 'client/creditReport/types';
import { Button, Modal, ModalProps } from 'react-bootstrap';
import BlockSpinner from 'client/spinners/BlockSpinner';
import ErrorAlert from 'client/components/ErrorAlert';
import CreditReportModalBody from 'client/creditReport/CreditReportModalBody';
import * as CreditReportFormatters from 'client/creditReport/CreditReportFormatters';

interface CreditReportLoadingModalProps extends ModalProps {
  creditReportId: string;
}

export default function CreditReportLoadingModal (props: CreditReportLoadingModalProps) {
  const { creditReportId, onHide, ...restOfProps } = props;

  const query = useQuery<CreditReportWithRelationsRow, Error>({
    queryKey: [`/api/credit_report/${creditReportId}`],
  });

  const creditReport = query.data;

  return (
    <Modal
      {...restOfProps}
      onHide={onHide}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <div>
            <div className="mb-1">
              Kreditupplysning{' '}
              {creditReport && (
                <CreditReportFormatters.StatusLabel value={creditReport.status} />
              )}
            </div>
          </div>
          {creditReport && (
            <div className="small" style={{fontSize: '65%'}}>{creditReport.id}</div>
          )}
        </Modal.Title>
      </Modal.Header>
      <div className="position-relative">
        <ErrorAlert error={query.error} className="m-3" />
        <BlockSpinner className="m-3" isLoading={query.isLoading} />
        {creditReport && (
          <CreditReportModalBody
            creditReport={creditReport}
          />
        )}
      </div>
      <Modal.Footer>
        <Button variant="outline-primary" onClick={onHide}>
          Stäng
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
