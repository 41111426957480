import { toast } from 'react-toastify';
import { errorToMessage } from 'client/utils/errors'; 

type HandledError = Error | string | Record<string, string[]>

export const parseError = (error: HandledError) => {
  if (typeof error === 'string') {
    return error;
  }

  if (error instanceof Error) {
     return errorToMessage(error);
  }

  if (Object.entries(error).every(([, value]) => Array.isArray(value))) {
    return Object.entries(error).map(([key, value]: any) => `${key}: ${value.join(', ')}`).join('\n');
  }

  // not sure about this and if we will ever end up here?
  return 'Internt fel: ' + error;
};

export const onError = (error: HandledError = 'Fel') => {
  const msg = parseError(error);
  toast.error(msg, {
    pauseOnFocusLoss: false,
    pauseOnHover: false,
  });
};

export const onSuccess: typeof toast.success = (content, options) => {
  return toast.success(content, {
    ...(options || {}),
    pauseOnFocusLoss: false,
    pauseOnHover: false,
  });
};
