import React, { useCallback } from 'react';
import { ICsAccountsExtendedIndustryReport, ICsGroupAccountsExtendedIndustryReport } from 'client/cs/types';
import {
  TAccountsTableRowDefinition,
  IAccounts,
  IAccountsTableSettings,
  TChangeAccountsTableSetting,
} from 'client/accounts/types';
import {
  defaultTableRowByKey as defaults,
  derivedTableRowByKey as derived,
  spacer,
} from 'client/accounts/utils';
import AccountsTable from 'client/accounts/AccountsTable';

// these rows are in the same order that they appear in the industry report
const accountRows: TAccountsTableRowDefinition[] = [
  defaults.date_to,
  derived.n_months,
  spacer,
  defaults.pl_net_sales,
  derived.kr_change_in_turnover_percent,
  derived.pl_operating_result,
  derived.pl_operating_result_change,
  derived.kr_ebitda,
  derived.pl_prof_loss_after_fin_items,
  derived.kr_operating_margin_percent,
  derived.kr_net_margin_percent,
  spacer,
  defaults.n_no_employees,
  derived.kr_change_in_number_of_employees,
  defaults.pl_personal_costs,
  derived.pl_personal_costs_change,
  derived.kr_turnover_per_employee,
  derived.kr_opp_result_number_of_employees,
  derived.kr_cost_per_employee,
  derived.kr_personal_costs_per_net_sales,
  spacer,
  derived.bs_tot_equity,
  derived.bs_adjusted_equity,
  derived.bs_adjusted_equity_change,
  spacer,
  defaults.n_dividend,
  derived.kr_solidity_percent,
  derived.kr_quick_ratio_percent,
];

interface IndustryReportCompanyAccountsEditorProps {
  name: string;
  accounts: ICsAccountsExtendedIndustryReport[] | ICsGroupAccountsExtendedIndustryReport[];
  originalAccounts: ICsAccountsExtendedIndustryReport[] | ICsGroupAccountsExtendedIndustryReport[];
  onChangeValue: (key: string, newAccounts: ICsAccountsExtendedIndustryReport[]) => void;
  settings: Partial<IAccountsTableSettings>;
  onChangeSetting: TChangeAccountsTableSetting;
  templateDateFrom?: string;
}
const IndustryReportCompanyAccountsEditor: React.FC<IndustryReportCompanyAccountsEditorProps> = React.memo(function IndustryReportCompanyAccountsEditor (props: IndustryReportCompanyAccountsEditorProps) {
  const {
    name,
    accounts,
    originalAccounts,
    onChangeValue,
    onChangeSetting,
    settings:outerSettings = {},
    templateDateFrom,
  } = props;

  // make sure we have some defaults
  const settings = {
    advanced_editor: false,
    // empty_year: null,
    add_years: [],
    remove_years: 0,
    ...outerSettings,
  };

  // migrate old "empty_year" settings to the new "add_years" setting
  if (settings.empty_year && !settings.add_years?.length) {
    settings.add_years = [settings.empty_year];
    delete settings.empty_year;
  }

  const onCommit = useCallback((newAccounts: IAccounts[]) => {
    onChangeValue(name, newAccounts as unknown as ICsAccountsExtendedIndustryReport[]);
  }, [onChangeValue]);

  return (
    <AccountsTable
      rows={accountRows}
      accounts={accounts as unknown as IAccounts[]}
      originalAccounts={originalAccounts as unknown as IAccounts[]}
      onCommit={onCommit}
      settings={settings}
      onChangeSetting={onChangeSetting}
      limit={4}
      templateDateFrom={templateDateFrom}
    />
  );
});
export default IndustryReportCompanyAccountsEditor;
