import React from 'react';
import useAuth from 'client/hooks/useAuth';
import { Spinner } from 'react-bootstrap';

function AuthGuardPage (props: React.PropsWithChildren) {
  const { children } = props;
  const { isInitialized } = useAuth();
  if (!isInitialized) {
    return (
      <div className="container p-4 d-flex justify-content-center">
        <Spinner animation="border" variant="primary" />
      </div>
    );
  }
  return (
    <>
      {children}
    </>
  );
}

export default AuthGuardPage;
