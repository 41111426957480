import { IEmailTemplate } from 'client/emailTemplate/types';
import { Link } from 'react-router-dom';

interface IEmailTemplateAnchor {
  value: Partial<IEmailTemplate>;
}

export const EmailTemplateAnchor = (props: IEmailTemplateAnchor) => {
  const { value: { id, name } } = props;
  if (!id) return null;
  return (
    <Link className="border p-1 rounded" to={`/email_template/${id}`}>
      {name || id}
    </Link>
  );
};
