import React from 'react';
import { Spinner, Button as BsButton, ButtonProps as BsButtonProps } from 'react-bootstrap';
import classNames from 'classnames';

export interface LoadingButtonProps extends BsButtonProps {
  isLoading?: boolean;
}

const LoadingButton: React.FC<LoadingButtonProps> = React.memo(function LoadingButton (props: LoadingButtonProps) {
  const {
    children,
    isLoading,
    className:outerClassName,
    disabled,
    size,
    ...restOfProps
  } = props;
  const className = classNames('d-inline-flex align-items-center', size === 'sm' ? 'gap-1' : 'gap-2', outerClassName);
  return (
    <BsButton
      size={size}
      className={className}
      {...restOfProps}
      disabled={disabled || isLoading}
    >
      {children}
      {isLoading ? (
        <Spinner size="sm" animation="border" />
      ) : null}
    </BsButton>
  );
});

export default LoadingButton;
