import { useQueryClient, QueryKey } from '@tanstack/react-query';
import * as immer from 'immer';

type IdType = string | number;

interface IOptions {
  subKey?: string;
}

interface IWithId {
  id: IdType;
  [key: string]: any;
}

interface IUseQueryClientUtils<T extends IWithId> {
  applyDeletionsById: (queryKey: QueryKey, deletedIds: IdType[], options?: IOptions) => void;
  applyUpdates: (queryKey: QueryKey, updates: T[], options?: IOptions) => void;
  applyInserts: (queryKey: QueryKey, inserts: T[], options?: IOptions) => void;
}

export default function useQueryClientUtils<T extends IWithId> (): IUseQueryClientUtils<T> {
  const queryClient = useQueryClient();

  return {

    applyDeletionsById: (queryKey: QueryKey, deletedIds: IdType[], options: IOptions = {}) => {
      const { subKey } = options;
      if (!deletedIds.length) {
        return;
      }

      queryClient.setQueriesData({queryKey}, (previousData: any) => {
        if (!previousData) return;
        const updatedData = immer.produce<{[id: string]: any}>(previousData, draft => {
          if (!draft) return;
          if (subKey) {
            draft[subKey] = draft[subKey].filter((row: any) => {
              return !deletedIds.includes(row.id);
            });
          } else if (Array.isArray(draft)) {
            return draft.filter(row  => {
              return !deletedIds.includes(row.id);
            });
          }
        });
        return updatedData;
      });
    },

    applyUpdates: (queryKey: QueryKey, updates: T[], options: IOptions = {}) => {
      const { subKey } = options;
      if (!updates.length) {
        return;
      }

      const updatedRowById = updates.reduce<{[id: string]: any}>((map, update) => {
        map[update.id] = update;
        return map;
      }, {});

      queryClient.setQueriesData({queryKey}, (previousData: any) => {
        if (!previousData) return;
        const updatedData = immer.produce<{[id: string]: any}>(previousData, draft => {
          if (!draft) return draft;

          let list: T[] = draft as T[];
          if (subKey) {
            if (!draft[subKey]) return;
            list = draft[subKey] as T[];
          }

          if (!Array.isArray(list)) return;

          for (const row of list) {
            const updatedRow = updatedRowById[row.id];
            if (updatedRow) {
              for (const key in updatedRow) {
                (row as any)[key] = updatedRow[key];
              }
            }
          }
        });
        return updatedData;
      });
    },

    applyInserts: (queryKey: QueryKey, inserts: T[], options: IOptions = {}) => {
      const { subKey } = options;
      if (!inserts.length) {
        return;
      }
      queryClient.setQueriesData({queryKey}, (previousData: any) => {
        const updatedData = immer.produce<{[id: string]: any}>(previousData, draft => {
          if (!draft) return;
          if (subKey) {
            if (!Array.isArray(draft[subKey])) return;
            draft[subKey].push(...inserts);
          } else {
            if (!Array.isArray(draft)) return;
            draft.push(...inserts);
          }
        });
        return updatedData;
      });

    },

  };
}
