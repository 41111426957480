import React from 'react';
import { Spinner } from 'react-bootstrap';
import styled from 'styled-components';

const SpinningOverlayBackground = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1px;
  z-index: 99;
`;

interface OverlaySpinnerProps {
  isLoading: boolean;
}

const OverlaySpinner: React.FC<OverlaySpinnerProps> = function OverlaySpinner (props: OverlaySpinnerProps) {
  const { isLoading } = props;
  if (!isLoading) return null;
  return (
    <SpinningOverlayBackground>
      <Spinner variant="primary" />
    </SpinningOverlayBackground>
  );
};

export default OverlaySpinner;
