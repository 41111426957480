import React from 'react';
import {IdShort} from 'client/components/MiscFormatters';
import Anchor from 'client/anchors/Anchor';

interface CompanyEventCustomerBatchAnchorProps {
  value: string;
}

export function CompanyEventCustomerBatchAnchor (props: CompanyEventCustomerBatchAnchorProps) {
  const { value:companyEventCustomerBatchId } = props;
  return (
    <Anchor to={`/company_event_customer_batch?id=${companyEventCustomerBatchId}`}>
      <IdShort value={companyEventCustomerBatchId} />
    </Anchor>
  );
}

interface CompanyEventUserBatchAnchorProps {
  value: string;
}

export function CompanyEventUserBatchAnchor (props: CompanyEventUserBatchAnchorProps) {
  const { value:companyEventUserBatchId } = props;
  return (
    <Anchor to={`/company_event_user_batch?id=${companyEventUserBatchId}`}>
      <IdShort value={companyEventUserBatchId} />
    </Anchor>
  );
}
