import React, { useCallback } from 'react';
import { Button, Form } from 'react-bootstrap';
import { preventDefaultSubmit } from 'client/utils/form';
import { CompanyTableFilterParams } from 'client/company/CompanyTablePage';
import RefreshButton from 'client/buttons/RefreshButton';
import { ColumnDefinition } from 'client/table/types';
import TableSettingsColumnOrderButton from 'client/table/TableSettingsColumnOrderButton';
import { numberOfEmployeesIntervals } from 'client/accounts/types';
import { CsBasicExtendedRow } from 'client/company/CompanyTablePage';

interface CompanyTableFilterFormProps {
  filterParams: CompanyTableFilterParams;
  setFilterParams: React.Dispatch<React.SetStateAction<CompanyTableFilterParams>>;
  onRefetch: () => void;
  onReset: () => void;
  isLoading: boolean;
  columnOrder: string[];
  setColumnOrder: React.Dispatch<React.SetStateAction<string[]>>;
  columnDefinitions: ColumnDefinition<CsBasicExtendedRow>[];
}

const CompanyTableFilterForm: React.FC<CompanyTableFilterFormProps> = React.memo(function CompanyTableFilterForm (props: CompanyTableFilterFormProps) {
  const {
    isLoading,
    onRefetch,
    onReset,
    filterParams,
    setFilterParams,
    columnDefinitions,
    columnOrder,
    setColumnOrder,
  } = props;

  const onChange = useCallback((ev: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const { name, value } = ev.target;
    setFilterParams({[name]: value});
  }, [filterParams, setFilterParams]);

  return (
    <Form className="d-flex flex-wrap gap-3" onSubmit={preventDefaultSubmit}>
      <Form.Group className="flex-grow-1">
        <Form.Control
          isValid={Boolean(filterParams.search_term)}
          name="search_term"
          onChange={onChange}
          placeholder="Oavsett sökterm"
          value={filterParams.search_term ?? ''}
        />
      </Form.Group>
      <Form.Group className="flex-grow-1">
        <Form.Control
          isValid={Boolean(filterParams.sni_code)}
          name="sni_code"
          onChange={onChange}
          placeholder="Oavsett SNI-kod"
          value={filterParams.sni_code ?? ''}
        />
      </Form.Group>
      <Form.Group className="flex-grow-1">
        <Form.Select
          isValid={Boolean(filterParams.legalgroup_code)}
          name="legalgroup_code"
          onChange={onChange}
          value={filterParams.legalgroup_code ?? ''}
        >
          <option value="">Oavsett bolagsform</option>
          <option value="AB">Aktiebolag</option>
          <option value="EF">Enskild firma</option>
          <option value="HB/KB">Handels/kommanditbolag</option>
          <option value="OVR">Övrigt</option>
        </Form.Select>
      </Form.Group>
      <Form.Group className="flex-grow-1">
        <Form.Select
          isValid={Boolean(filterParams.nbr_employees_interval)}
          name="nbr_employees_interval"
          onChange={onChange}
          value={filterParams.nbr_employees_interval ?? ''}
        >
          <option value="">Oavsett anställda intervall</option>
          {numberOfEmployeesIntervals.map(interval => (
            <option value={interval} key={interval}>{interval}</option>
          ))}
        </Form.Select>
      </Form.Group>
      <TableSettingsColumnOrderButton
        columnDefinitions={columnDefinitions}
        columnOrder={columnOrder}
        setColumnOrder={setColumnOrder}
      />
      <Button
        title="Ta bort alla filtreringar"
        onClick={onReset}
        variant="outline-primary"
        className="d-flex align-items-center justify-content-center"
        disabled={isLoading}
      >
        Återställ
      </Button>
      <RefreshButton onClick={onRefetch} disabled={isLoading} />
    </Form>
  );
});
export default CompanyTableFilterForm;
