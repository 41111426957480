import { useMemo, useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Card } from 'react-bootstrap';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import PageHeader from 'client/components/PageHeader';
import ErrorAlert from 'client/components/ErrorAlert';
import { ColumnDefinition } from 'client/table/types';
import useTableState, { OrderDirection } from 'client/hooks/useTableState';
import useTableSelectRows from 'client/hooks/useTableSelectRows';
import * as commonColumnDefinitions from 'client/table/commonColumnDefinitions';
import { CustomerSurveyWithRelationsRow } from 'client/customerSurvey/types';
import * as CustomerFormatters from 'client/customer/CustomerFormatters';
import { TableSpinningOverlay  } from 'client/table/TableUtils';
import TableVirtual from 'client/table/TableVirtual';
import { TableCardFooter } from 'client/table/TableUtils';
import Pagination from 'client/table/TablePagination';
import Pluralizer from 'client/components/Pluralizer';
import NumberFormat from 'client/components/NumberFormat';
import * as CustomerSurveyFormatters from 'client/customerSurvey/CustomerSurveyFormatters';
import CustomerSurveyTableFilterForm from 'client/customerSurvey/CustomerSurveyTableFilterForm';
import { TableProvider } from 'client/contexts/TableContext';

export interface CustomerSurveyTableFilterParams {
  id?: string;
  customer_id?: string;
  status?: string;
}

interface ListData {
  rows: CustomerSurveyWithRelationsRow[];
  total_rows: number;
}

const defaultFilterParams = {};

const defaultState = {
  orderBy: 'created_at',
  orderDirection: OrderDirection.DESC,
  ...defaultFilterParams,
};

export default function CustomerSurveyTablePage () {
  const {
    setStateMap,
    tablePagination,
    tableSort,
    filterParams,
    filterReset,
    params,
  } = useTableState({
    defaultState,
  });

  const query = useQuery<ListData, Error>({
    queryKey: ['/api/customer_surveys/list', params],
    placeholderData: keepPreviousData,
  });

  const rows = query.data?.rows ?? [];

  const [selectedRows, setSelectedRows] = useState<Record<string, boolean>>({});
  const allIds = useMemo(() => rows.map(item => item.id), [rows]);
  const tableSelectRows = useTableSelectRows(allIds, selectedRows, setSelectedRows);

  useEffect(() => {
    tablePagination.onChangeTotalCountOfRows(query.data?.total_rows ?? 0);
  }, [query.data?.total_rows]);

  const columnDefinitions = useMemo(columnDefinitionFactory, []);
  const [columnOrder, setColumnOrder] = useState(() => columnDefinitions.filter(c => c.show).map(c => c.id));

  return (
    <TableProvider tableSelectRows={tableSelectRows} tableSort={tableSort}>
      <div className="container-fluid p-4">
        <Helmet>
          <title>Undersökningar</title>
        </Helmet>
        <PageHeader>
          <span>
            Undersökningar
          </span>
        </PageHeader>
        <ErrorAlert className="my-3" error={query.error} />
        <Card>
          <Card.Header className="p-3">
            <CustomerSurveyTableFilterForm
              isLoading={query.isLoading || query.isRefetching}
              filterParams={filterParams}
              setFilterParams={setStateMap}
              onRefetch={query.refetch}
              onReset={filterReset}
              columnDefinitions={columnDefinitions}
              columnOrder={columnOrder}
              setColumnOrder={setColumnOrder}
            />
          </Card.Header>
          <TableSpinningOverlay isLoading={query.isRefetching}>
            <div className="table-responsive">
              <TableVirtual
                className="mb-0 align-middle"
                rows={rows}
                columns={columnDefinitions}
                columnOrder={columnOrder}
                isInitialLoading={query.isLoading}
              />
            </div>
          </TableSpinningOverlay>
          <TableCardFooter>
            <p className="mb-0">
              Totalt <NumberFormat value={tablePagination?.totalCountOfRows || rows.length} />{' '}
              <Pluralizer
                count={tablePagination?.totalCountOfRows || rows.length}
                zero="kundundersökningar"
                one="kundundersökning"
                more="kundundersökningar"
              /> i denna tabell
            </p>
            {tablePagination && (
              <Pagination withRowsPerPage {...tablePagination} />
            )}
          </TableCardFooter>
        </Card>
      </div>
    </TableProvider>
  );
}

const defaultTableColumns = [
  'select',
  'id',
  'status',
  'customer_id',
  'rating',
  'opened_at',
  'answered_at',
  'created_at',
  'actions',
];

const columnSortable = [
  'id',
  'status',
  'customer_id',
  'rating',
  'opened_at',
  'answered_at',
  'created_at',
];

type ColumnDefinitionFactory = () => ColumnDefinition<CustomerSurveyWithRelationsRow>[];

const columnDefinitionFactory: ColumnDefinitionFactory = () => [
  commonColumnDefinitions.select(),
  commonColumnDefinitions.cell(['id', 'ID'], props => (
    <CustomerSurveyFormatters.CustomerSurveyAnchor value={props.row.id} />
  )),
  commonColumnDefinitions.cell(['customer_id', 'Kund'], props => (
    <CustomerFormatters.CustomerAnchor value={props.row.customer} />
  )),
  commonColumnDefinitions.cell(['status', 'Status'], props => (
    <CustomerSurveyFormatters.StatusLabel value={props.row.status} />
  )),
  commonColumnDefinitions.date(['status_updated_at', 'Statusuppdatering']),
  commonColumnDefinitions.cell(['rating', 'Betyg'], props => (
    <>{props.row.rating !== null ? `${props.row.rating}/5` : '-'}</>
  )),
  commonColumnDefinitions.date(['opened_at', 'Öppnad']),
  commonColumnDefinitions.date(['answered_at', 'Besvarad']),
  commonColumnDefinitions.date(['created_at', 'Skapad']),
  commonColumnDefinitions.date(['updated_at', 'Uppdaterad']),

  commonColumnDefinitions.actions(),
].map(obj => ({
  ...obj,
  show: defaultTableColumns.includes(obj.id),
})).map(obj => columnSortable.includes(obj.id) ? commonColumnDefinitions.sortable(obj) : obj);
