import React from 'react';
import { Breadcrumb } from 'react-bootstrap';
import { Link } from 'react-router-dom';

interface IPageHeader extends React.PropsWithChildren {
  breadcumbs?: {title: string; url?: string}[];
}

const PageHeader: React.FC<IPageHeader> = React.memo(function PageHeader (props: IPageHeader) {
  const { children, breadcumbs } = props;
  return (
    <div className="mb-4">
      <h2 className="d-flex align-items-center mb-1 justify-content-between">
        {children}
      </h2>
      {breadcumbs?.length && (
        <Breadcrumb>
          {breadcumbs.map(({ url, title }, index) => (
            <Breadcrumb.Item
              key={index}
              linkAs={Link}
              linkProps={{to: url}}
              active={!url}
            >
              {title}
            </Breadcrumb.Item>
          ))}
        </Breadcrumb>
      )}
    </div>
  );
});

export default PageHeader;
