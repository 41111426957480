import React, { useState, useId, useRef } from 'react';
import axios from 'client/axios';
import ErrorAlert from 'client/components/ErrorAlert';
import { Form, Spinner } from 'react-bootstrap';
import { useMutation } from '@tanstack/react-query';
import HelperTooltip from 'client/components/HelperTooltip';

interface ICompanySNICodeEditor {
  orgNumber: string;
  sniCode: string;
  sniCodeOriginal: string;
}

interface ICompanySNICodeEditorMutationVars {
  sniCode: string;
}

const CompanySNICodeEditor: React.FC<ICompanySNICodeEditor> = React.memo(function CompanySNICodeEditor (props: ICompanySNICodeEditor) {
  const { orgNumber, sniCode:initialSniCode, sniCodeOriginal } = props;
  const [sniCode, setSniCode] = useState<string>(initialSniCode);
  const [sniCodeChanged, setSniCodeChanged] = useState<boolean>(false);
  const [sniCodeInvalid, setSniCodeInvalid] = useState<boolean>(false);

  const onChangeSniCode = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const newSniCode = ev.target.value;
    if (sniCode === newSniCode) return;
    setSniCode(newSniCode);
    setSniCodeChanged(true);
    setSniCodeInvalid(!ev.target.checkValidity());
  };

  const onBlurSniCode = () => {
    if (!sniCodeChanged || !inputRef.current) return;
    if (!inputRef.current.checkValidity()) return;
    setCompanySNICodeMutation.mutate({sniCode});
  };

  const setCompanySNICodeMutation = useMutation<unknown, Error, ICompanySNICodeEditorMutationVars>({
    mutationKey: ['CompanySNICodeMutation'],
    mutationFn: ({sniCode}) => axios.put(`/api/sni/company/${orgNumber}`, {sniCode: sniCode || null}),
    onSuccess: () => {
      setSniCodeChanged(false);
    },
  });

  const inputRef = useRef<HTMLInputElement>(null);
  const sniCodeOriginalControlId = useId();
  const sniCodeControlId = useId();

  return (
    <div>
      <div className="d-flex">
        <Form.Group className="me-3">
          <Form.Label htmlFor={sniCodeOriginalControlId}>
            Creditsafe SNI-kod{' '}
            <HelperTooltip>Den ursprungliga SNI-koden företaget har hos Creditsafe</HelperTooltip>
          </Form.Label>
          <Form.Control
            id={sniCodeOriginalControlId}
            name="sni_code_original"
            value={sniCodeOriginal}
            disabled
          />
        </Form.Group>
        <Form.Group>
          <Form.Label htmlFor={sniCodeControlId}>
            Calculate SNI-kod{' '}
            <HelperTooltip>Den SNI-kod företaget har hos Calculate</HelperTooltip>
          </Form.Label>
          <div className="d-flex align-items-center">
            <Form.Control
              isInvalid={sniCodeInvalid}
              id={sniCodeControlId}
              name="sni_code"
              value={sniCode}
              onChange={onChangeSniCode}
              onBlur={onBlurSniCode}
              ref={inputRef}
              pattern="^\d{5}$"
            />
            {setCompanySNICodeMutation.isPending && (
              <div className="ms-2">
                <Spinner size="sm" />
              </div>
            )}
          </div>
        </Form.Group>
      </div>
      <ErrorAlert className="mb-0 mt-3" error={setCompanySNICodeMutation.error} />
    </div>
  );
});
export default CompanySNICodeEditor;
