import { FC, useMemo } from 'react';
import { Form, Pagination as BsPagination } from 'react-bootstrap';
import { IUseTablePagination } from 'client/hooks/useTablePagination';

const PAD_COUNT_DEFAULT = 1;

interface TablePaginationProps extends IUseTablePagination {
  withRowsPerPage?: boolean;
  padCount?: number;
}

const Pagination: FC<TablePaginationProps> = ({
  currentPage,
  countOfPages,
  onChangePage,
  withRowsPerPage,
  onChangeRowsPerPage,
  rowsPerPage,
  isFirstPage,
  isLastPage,
  padCount = PAD_COUNT_DEFAULT,
  rowsPerPageSeries,
}) => {

  const onSelectRowsPerPage = (ev: React.ChangeEvent<HTMLSelectElement>) => {
    const value = parseInt(ev.target.value, 10);
    if (!isNaN(value)) {
      onChangeRowsPerPage(value);
    }
  };

  const pageElements = useMemo(() => {
    let isPageNumberOutOfRange = true;

    return [...new Array(countOfPages)].map((_, index) => {
      const pageNumber = index + 1;
      const isPageNumberFirst = pageNumber === 1;
      const isPageNumberLast = pageNumber === countOfPages;
      const isCurrentPageWithinThreshold =
        Math.abs(pageNumber - currentPage) <= padCount
        || (padCount * 2) >= (countOfPages / 2);

      if (
        isPageNumberFirst ||
        isPageNumberLast ||
        isCurrentPageWithinThreshold
      ) {
        isPageNumberOutOfRange = false;

        return pageNumber;
      }

      if (!isPageNumberOutOfRange) {
        isPageNumberOutOfRange = true;

        return false;
      }

      return null;
    });
  }, [countOfPages, currentPage, padCount]);

  const nextPageHandler = () => {
    if (isLastPage(currentPage)) {
      return;
    }

    onChangePage(currentPage + 1);
  };

  const prevPageHandler = () => {
    if (isFirstPage(currentPage)) {
      return;
    }

    onChangePage(currentPage - 1);
  };

  if (!countOfPages) return null;

  return (
    <div className="d-flex gap-4">
      {withRowsPerPage && (
        <div className="d-flex align-items-center gap-2">
          <span>Visa</span>
          <Form.Select
            onChange={onSelectRowsPerPage}
            value={rowsPerPage}
          >
            {rowsPerPageSeries.map(value => (
              <option key={value} value={value}>{value}</option>
            ))}
          </Form.Select>
          <span className="text-nowrap">per sida</span>
        </div>
      )}
      <BsPagination className="m-0">
        <BsPagination.Prev
          onClick={prevPageHandler}
          disabled={isFirstPage(currentPage)}
        />
        {pageElements.map((elem, key) => {
          if (elem === null) return null;

          if (elem === false) {
            return <BsPagination.Ellipsis disabled key={key} />;
          }

          return (
            <BsPagination.Item
              key={key}
              active={elem == currentPage}
              onClick={() => onChangePage(elem)}
            >
              {elem}
            </BsPagination.Item>
          );
        })}
        <BsPagination.Next
          onClick={nextPageHandler}
          disabled={isLastPage(currentPage)}
        />
      </BsPagination>
    </div>
  );
};

export default Pagination;
