import { useMemo } from 'react';
import qs from 'qs';
import { useLocation } from 'react-router-dom';

function useParsedSearch<T> (): T {
  const location = useLocation();
  const parsedSearch = useMemo<T>(() => qs.parse(location.search.slice(1)) as any, [location]);
  return parsedSearch;
}

export default useParsedSearch;
