import { BadgeProps } from 'react-bootstrap';
import StatusBadge from 'client/components/StatusBadge';
import { LanguageFlag } from 'client/components/Flags';
import * as AccountsFormatters from 'client/accounts/AccountsFormatters';
import Anchor from 'client/anchors/Anchor';
import {
  IReportQueue,
  ReportQueueStatusEnum,
  ReportQueueReportType,
  ReportQueueStatus,
  ReportQueueLanguage,
} from 'client/reportQueue/types';
import { Link, LinkProps } from 'react-router-dom';

interface ReportsTypeProps {
  type: ReportQueueReportType;
  language: ReportQueueLanguage;
  isGroup: boolean;
}

export function ReportsType (props: ReportsTypeProps) {
  const { type, language, isGroup } = props;
  if (type === 'valuation_report') {
    return (
      <span>
        <LanguageFlag language={language} />{' '}
        {isGroup ? 'Koncernvärdering' : 'Företagsvärdering'}
      </span>
    );
  } else if (type === 'industry_report') {
    return (
      <span>
        <LanguageFlag language={language} />{' '}
        Branschrapport
      </span>
    );
  }
  return null;
}

interface ReportQueueAnchorProps extends Pick<LinkProps, 'target'> {
  value: Partial<IReportQueue>;
  className?: string;
}

export function ReportQueueAnchor (props: ReportQueueAnchorProps) {
  const { value: { id, report_type }, className, target } = props;
  if (!id) return null;
  if (report_type === 'industry_report') {
    return (
      <Anchor className={className} target={target} to={`/industry_reports/${id}/properties`}>
        {id}
      </Anchor>
    );
  } else if (report_type === 'valuation_report') {
    return (
      <Anchor className={className} target={target} to={`/valuation_reports/${id}/properties`}>
        {id}
      </Anchor>
    );
  }
  return null;
}

interface ReportQueueLinkProps extends Partial<LinkProps> {
  value: Partial<IReportQueue>;
  children?: React.ReactNode;
}

export function ReportQueueLink (props: ReportQueueLinkProps) {
  const { className, children, value: { id, report_type } } = props;
  if (!id) return null;
  if (report_type === 'industry_report') {
    return (
      <Link className={className} to={`/industry_reports/${id}/properties`}>
        {children}
      </Link>
    );
  } else if (report_type === 'valuation_report') {
    return (
      <Link className={className} to={`/valuation_reports/${id}/properties`}>
        {children}
      </Link>
    );
  }
  return null;
}

interface StatusProps {
  value: ReportQueueStatus;
  done?: boolean;
}

export function Status (props: StatusProps) {
  const { value, done = false } = props;
  if (value === 'READY' && done) return <>Hanterad</>;
  if (value in ReportQueueStatusEnum) return <>{ReportQueueStatusEnum[value]}</>;
  return <>{value}</>;
}

interface StatusLabelProps extends BadgeProps {
  value: ReportQueueStatus;
  done?: boolean;
}

export function StatusLabel (props: StatusLabelProps) {
  const { value, done = false, ...restOfProps } = props;
  switch (value) {
    default: return <StatusBadge {...restOfProps}>{String(value)}</StatusBadge>;
    case 'NEW': return <StatusBadge bg="secondary" {...restOfProps}><Status value={value} /></StatusBadge>;
    case 'WAITING': return <StatusBadge bg="warning" {...restOfProps}><Status value={value} /></StatusBadge>;
    case 'READY': return <StatusBadge bg="success" {...restOfProps}><Status value={value} done={done} /></StatusBadge>;
    case 'ERROR': return <StatusBadge bg="danger" {...restOfProps}><Status value={value} /></StatusBadge>;
  }
}

interface IAccountInterval extends Omit<React.HTMLProps<HTMLDivElement>, 'value'> {
  value: IReportQueue;
}

export const AccountInterval = (props: IAccountInterval) => {
  const { value, ...restOfProps } = props;
  const from = value.report_settings?.accounts_from;
  const to = value.report_settings?.accounts_to;
  return <AccountsFormatters.AccountInterval {...restOfProps} from={from} to={to} />;
};

interface DeliveryPreferenceProps {
  deliverEmail?: boolean;
  deliverPrinted?: boolean;
}

export function DeliveryPreference (props: DeliveryPreferenceProps) {
  const { deliverEmail, deliverPrinted } = props;
  if (!deliverEmail && !deliverPrinted) return <>-</>;
  if (deliverEmail && !deliverPrinted) return <>Digitalt</>;
  if (!deliverEmail && deliverPrinted) return <>Tryckt</>;
  return <>Tryckt + Digitalt</>;
}
