import React from 'react';
import { Field, FormikErrors, FormikProps } from 'formik';
import IdProvider from 'client/components/IdProvider';
import ErrorFormControlFeedback from 'client/form/ErrorFormControlFeedback';
import { Row, Col, Form } from 'react-bootstrap';
import AccountChangePasswordFormFields, { AccountChangePasswordFormFieldsType } from 'client/account/AccountChangePasswordFormFields';
import PasswordFormControl, {PasswordFormText, PasswordRandomButton, passwordRegexp} from 'client/form/PasswordFormControl';
import { EnglishFlag, SwedishFlag } from 'client/components/Flags';
import HelperTooltip from 'client/components/HelperTooltip';

export interface CreateCustomerFormFields {
  id: string;
  name: string;
  email: string;
  password?: string;
  contact_person: string;
  language: string;
}

export interface UpdateCustomerFormFields {
  name: string;
  email: string;
  change_password: boolean;
  password: string;
  contact_person: string;
  active: boolean;
  language: string;
  company_monitored_limit: '' | number;
}

type CustomerFormProps = {
  hidePasswordForm?: boolean;
  vertical?: boolean;
  disabled?: boolean;
  isCreateForm?: boolean;
  formikBag: FormikProps<CreateCustomerFormFields> | FormikProps<UpdateCustomerFormFields>;
}

const CustomerForm: React.FC<CustomerFormProps> = React.memo(function CustomerForm (props: CustomerFormProps) {
  const {
    isCreateForm = false,
    hidePasswordForm = false,
    disabled = false,
    vertical = false,
    formikBag,
  } = props;
  const colProps = vertical ? {sm: 12} : {lg: 6, md: 12};
  return (
    <Row>
      {isCreateForm && (
        <Col {...colProps}>
          <IdProvider>
            {id => (
              <Form.Group className="mb-3">
                <Form.Label htmlFor={id}>
                  ID/Organisationsnummer
                </Form.Label>
                <Field
                  as={Form.Control}
                  disabled={disabled}
                  id={id}
                  name="id"
                  placeholder="Ange ett inloggningsnamn"
                  isInvalid={Boolean((formikBag as FormikProps<CreateCustomerFormFields>).errors.id)}
                  validate={validateId}
                  autoComplete="username"
                  required
                />
                <ErrorFormControlFeedback
                  error={(formikBag as FormikProps<CreateCustomerFormFields>).errors.id}
                  touched={(formikBag as FormikProps<CreateCustomerFormFields>).touched.id}
                />
              </Form.Group>
            )}
          </IdProvider>
        </Col>
      )}
      <Col {...colProps}>
        <IdProvider>
          {id => (
            <Form.Group className="mb-3">
              <Form.Label htmlFor={id}>
                Namn
              </Form.Label>
              <Field
                as={Form.Control}
                disabled={disabled}
                id={id}
                name="name"
                autoComplete="name"
                placeholder="Ange ett namn"
                isInvalid={Boolean(formikBag.errors.name)}
                required
              />
              <ErrorFormControlFeedback
                error={formikBag.errors.name}
                touched={formikBag.touched.name}
              />
            </Form.Group>
          )}
        </IdProvider>
      </Col>
      <Col {...colProps}>
        <IdProvider>
          {id => (
            <Form.Group className="mb-3">
              <Form.Label htmlFor={id}>
                E-post
              </Form.Label>
              <Field
                as={Form.Control}
                disabled={disabled}
                id={id}
                name="email"
                autoComplete="email"
                validate={validateEmail}
                placeholder="Ange en e-postadress"
                isInvalid={Boolean(formikBag.errors.email)}
              />
              <ErrorFormControlFeedback
                error={formikBag.errors.email}
                touched={formikBag.touched.email}
              />
              <Form.Text>
                Bara små bokstäver.
              </Form.Text>
            </Form.Group>
          )}
        </IdProvider>
      </Col>
      <Col {...colProps}>
        <IdProvider>
          {id => (
            <Form.Group className="mb-3">
              <Form.Label htmlFor={id}>
                Kontaktperson
              </Form.Label>
              <Field
                as={Form.Control}
                disabled={disabled}
                id={id}
                name="contact_person"
                placeholder="Ingen kontaktperson"
                isInvalid={Boolean(formikBag.errors.contact_person)}
              />
              <ErrorFormControlFeedback
                error={formikBag.errors.contact_person}
                touched={formikBag.touched.contact_person}
              />
            </Form.Group>
          )}
        </IdProvider>
      </Col>
      <Col {...colProps}>
        <Form.Group className="mb-3">
          <Form.Label>
            Föredraget språk
          </Form.Label>
          <div className="d-flex align-items-center mt-1 gap-3">
            <IdProvider>
              {id => (
                <Field
                  id={id}
                  type="radio"
                  as={Form.Check}
                  disabled={disabled}
                  name="language"
                  value="sv"
                  label={<><SwedishFlag /> Svenska</>}
                  isInvalid={Boolean(formikBag.errors.language)}
                />
              )}
            </IdProvider>
            <IdProvider>
              {id => (
                <Field
                  id={id}
                  type="radio"
                  as={Form.Check}
                  disabled={disabled}
                  name="language"
                  value="en"
                  label={<><EnglishFlag /> Engelska</>}
                  isInvalid={Boolean(formikBag.errors.language)}
                />
              )}
            </IdProvider>
          </div>
          <ErrorFormControlFeedback
            error={formikBag.errors.language}
            touched={formikBag.touched.language}
          />
        </Form.Group>
      </Col>
      {!isCreateForm && (
        <Col {...colProps}>
          <IdProvider>
            {id => (
              <Form.Group className="mb-3">
                <Form.Label htmlFor={id}>
                  Status
                </Form.Label>
                <Field
                  className="mb-0"
                  as={Form.Select}
                  disabled={disabled}
                  value={String((formikBag.values as UpdateCustomerFormFields).active)}
                  id={id}
                  name="active"
                >
                  <option value="false">Kunden är inaktiv och kan inte logga in</option>
                  <option value="true">Kunden är aktiv och kan logga in</option>
                </Field>
              </Form.Group>
            )}
          </IdProvider>
        </Col>
      )}
      {!isCreateForm && (
        <Col {...colProps}>
          <IdProvider>
            {id => (
              <Form.Group className="mb-3">
                <Form.Label htmlFor={id}>
                  Max antal bevakade företag{' '}
                  <HelperTooltip>Högsta antal bevakade företag som kunden själv kan lägga in på sitt konto. Tomt värde inaktiverar tillgången till bevakningsfunktionen. Om ett lägre värde än kundens nuvarande antal bevakade företag väljs så kommer de äldsta inlagda företagen tas bort från kundens bevakningslista.</HelperTooltip>
                </Form.Label>
                <Field
                  as={Form.Control}
                  disabled={disabled}
                  id={id}
                  type="number"
                  name="company_monitored_limit"
                  placeholder="Ingen tillgång till bevakningsfunktionen"
                  isInvalid={Boolean((formikBag.errors as FormikErrors<UpdateCustomerFormFields>).company_monitored_limit)}
                  step="1"
                  min="0"
                  max="1000"
                />
              </Form.Group>
            )}
          </IdProvider>
        </Col>
      )}
      {!hidePasswordForm && (
        <>
          {isCreateForm ? (
            <Col {...colProps}>
              <IdProvider>
                {id => (
                  <Form.Group className="mb-3">
                    <Form.Label htmlFor={id}>
                      Lösenord
                    </Form.Label>
                    <div className="d-flex gap-2">
                      <Field
                        as={PasswordFormControl}
                        disabled={disabled}
                        id={id}
                        name="password"
                        placeholder="Ange ett lösenord"
                        isInvalid={Boolean(formikBag.errors.password)}
                        validate={validatePassword}
                        required
                      />
                      <PasswordRandomButton
                        onGenerated={password => formikBag.setFieldValue('password', password)}
                      />
                    </div>
                    <ErrorFormControlFeedback
                      error={formikBag.errors.password}
                      touched={formikBag.touched.password}
                    />
                    <PasswordFormText />
                  </Form.Group>
                )}
              </IdProvider>
            </Col>
          ) : (
            <Col sm={12}>
              <AccountChangePasswordFormFields
                disabled={disabled}
                formikBag={formikBag as unknown as FormikProps<AccountChangePasswordFormFieldsType>}
                checkboxLabel="Ändra kundens lösenord"
              />
            </Col>
          )}
        </>
      )}
    </Row>
  );
});

export default CustomerForm;

function validateId (value: string) {
  if (!value) return 'Ange ett värde';
  if (!/^\d+$/.test(value)) {
    return 'Värdet får endast bestå av siffror';
  }
}

function validateEmail (value: string) {
  if (!value) return;
  if (/[A-ZÅÖÄ]/.test(value)) {
    return 'Värdet är ogiltigt';
  }
}

function validatePassword (value: string) {
  if (!value) return;
  if (!passwordRegexp.test(value)) {
    return 'Lösenordet uppfyller inte kraven';
  }
}
