import React from 'react';
import { YesNo } from 'client/cs/types';

interface CsYesNoProps {
  value: YesNo | null;
}

export function CsYesNo (props: CsYesNoProps) {
  const { value } = props;
  if (!value) return null;
  return <>{value === 'YES' ? 'Ja' : 'Nej'}</>;
}

interface CsActiveProps {
  value: '0' | '1' | null;
}

export function CsActive (props: CsActiveProps) {
  const { value } = props;
  if (!value) return null;
  return <>{value === '1' ? 'Aktiv' : 'Inaktiv'}</>;
}
