import React, {useMemo, useState} from 'react';
import { useQuery } from '@tanstack/react-query';
import * as CompanyEventFormatters from 'client/companyEvent/CompanyEventFormatters';
import * as CompanyFormatters from 'client/company/CompanyFormatters';
import * as columnDefs from 'client/table/commonColumnDefinitions';
import { CompanyEventTimelineBatchByCustomerRow } from 'client/companyEvent/types';
import {ColumnDefinition, FilterDefinition} from 'client/table/types';
import * as filterDefs from 'client/table/commonFilterDefinitions';
import CardSimpleTable from 'client/card/CardSimpleTable';
import moment from 'moment';

interface CompanyEventTimelineTableByCustomerCardProps {
  customerId: string;
  className?: string;
}

interface CompanyEventTimelineTableByCustomerCardFilter {
  date_since: string;
  date_until: string;
}

type ResponseData = {
  events: CompanyEventTimelineBatchByCustomerRow[];
}

type CompanyEventTimelineBatchByCustomerRowWithId = CompanyEventTimelineBatchByCustomerRow & {
  id: string;
}

function CompanyEventTimelineTableByCustomerCard (props: CompanyEventTimelineTableByCustomerCardProps) {
  const { className, customerId } = props;

  const [filter, setFilter] = useState<CompanyEventTimelineTableByCustomerCardFilter>({
    date_since: moment().subtract(1, 'day').format('YYYY-MM-DD'),
    date_until: moment().format('YYYY-MM-DD'),
  });

  const query = useQuery<ResponseData, Error>({
    queryKey: [`/api/customers/${customerId}/company_event_timeline_batch`, filter],
  });

  const rows: CompanyEventTimelineBatchByCustomerRowWithId[] = useMemo(() => {
    if (!query.data) return [];
    return query.data.events.map(event => ({
      ...event,
      id: [event.org_number, event.event_type].join('-'),
    }));
  }, [query.data]);

  return (
    <div>
      <CardSimpleTable
        className={className}
        filters={filters}
        filter={filter}
        setFilter={setFilter}
        query={query}
        title="Kundens bevakningshändelser"
        columns={columns}
        rows={rows}
      />
    </div>
  );
}
export default React.memo(CompanyEventTimelineTableByCustomerCard);

const filters: FilterDefinition[] = [
  filterDefs.date(['date_since', 'Från']),
  filterDefs.date(['date_until', 'Till']),
];

const columns: ColumnDefinition<CompanyEventTimelineBatchByCustomerRowWithId>[] = [
  columnDefs.cell(['company', 'Företag'], props => (
    <CompanyFormatters.CompanyAnchor value={props.row} />
  )),
  columnDefs.cell(['event_type', 'Typ'], props => (
    <CompanyEventFormatters.CompanyEventType value={props.row.event_type} code={props.row.event_type_code} />
  )),
  columnDefs.cell(['start', 'Start'], props => (
    <CompanyEventFormatters.CompanyEventTimelineBatchStart value={props.row} />
  )),
  columnDefs.cell(['end', 'Slut'], props => (
    <CompanyEventFormatters.CompanyEventTimelineBatchEnd value={props.row} />
  )),
  columnDefs.cell(['diff', 'Differens'], props => (
    <CompanyEventFormatters.CompanyEventValueDiff
      prev={props.row.value_start}
      curr={props.row.value_end}
      type={props.row.event_type}
    />
  )),
  columnDefs.actions(),
];
