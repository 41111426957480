import React from 'react';
import classNames from 'classnames';

interface ObjectIdProps extends React.HTMLAttributes<HTMLElement> {
  fontSize?: number;
  as?: React.ElementType<any> | undefined;
}

export default function ObjectId (props: ObjectIdProps) {
  const {
    as:Component = 'span',
    fontSize = 90,
    className:outerClassName,
    children,
    ...restOfProps
  } = props;
  const style = {fontSize: `${fontSize}%`};
  const className = classNames('border px-1 align-items-baseline rounded font-monospace d-inline-flex gap-1 text-decoration-none', outerClassName);
  return (
    <Component className={className} style={style} {...restOfProps}>
      {children}
    </Component>
  );
}
