import React from 'react';
import SendEmailButton, { SendEmailButtonProps } from 'client/email/SendEmailButton';

interface SendCustomerSurveyEmailButtonProps extends Omit<SendEmailButtonProps, 'objectType' | 'objectId'> {
  emailType: 'customerSurvey' | 'customerSurveyReview';
  customerSurveyId: string;
  onSuccess: () => void;
}

const SendCustomerSurveyEmailButtonProps: React.FC<SendCustomerSurveyEmailButtonProps> = React.memo(function SendCustomerSurveyEmailButton (props: SendCustomerSurveyEmailButtonProps) {
  const { customerSurveyId, ...restOfProps } = props;


  return (
    <SendEmailButton
      objectType="customerSurvey"
      objectId={customerSurveyId}
      {...restOfProps}
    />
  );
});
export default SendCustomerSurveyEmailButtonProps;
