import React, { useId } from 'react';
import { Form } from 'react-bootstrap';
import { IFilterParams } from 'client/reportQueue/ReportQueueTablePage';
import { preventDefaultSubmit } from 'client/utils/form';
import useFocusControl from 'client/hooks/useFocusControl';

interface IReportQueueTableHandledFilterForm {
  filterParams: IFilterParams;
  setFilterParams: React.Dispatch<React.SetStateAction<IFilterParams>>;
}

const ReportQueueTableHandledFilterForm: React.FC<IReportQueueTableHandledFilterForm> = React.memo(function ReportQueueTableDoneForm (props: IReportQueueTableHandledFilterForm) {
  const { filterParams, setFilterParams } = props;

  const onChangeValue = (name: string, value: string) => {
    setFilterParams({...filterParams, [name]: value});
  };

  const productionDateFromProps = useFocusControl<string>(filterParams.production_date_from ?? '', onChangeValue);
  const productionDateToProps = useFocusControl<string>(filterParams.production_date_to ?? '', onChangeValue);

  const productionDateFromId = useId();
  const productionDateToId = useId();

  return (
    <Form className="p-3 border-bottom d-flex flex-wrap gap-3" onSubmit={preventDefaultSubmit}>
      <Form.Group className="d-flex align-items-baseline gap-2">
        <Form.Label htmlFor={productionDateFromId} className="flex-shrink-0">Produktionsdatum från</Form.Label>
        <Form.Control
          {...productionDateFromProps}
          id={productionDateFromId}
          name="production_date_from"
          type="date"
          placeholder="Oavsett datum från"
          required
        />
      </Form.Group>
      <Form.Group className="d-flex align-items-baseline gap-2">
        <Form.Label htmlFor={productionDateToId} className="flex-shrink-0">Produktionsdatum till</Form.Label>
        <Form.Control
          {...productionDateToProps}
          id={productionDateToId}
          name="production_date_to"
          type="date"
          placeholder="Oavsett datum till"
          required
        />
      </Form.Group>
    </Form>
  );
});

export default ReportQueueTableHandledFilterForm;
