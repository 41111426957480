import React from 'react';
import useAuth from 'client/hooks/useAuth';
import { AuthUserTypes } from 'client/contexts/AuthContext';

interface IUserTypeGuard extends React.PropsWithChildren {
  type: AuthUserTypes;
}

const UserTypeGuard: React.FC<IUserTypeGuard> = React.memo(function UserTypeGuard (props: IUserTypeGuard) {
  const { children, type } = props;
  const auth = useAuth();
  if (!auth.isUserType(type)) {
    return null;
  }
  return (
    <>
      {children}
    </>
  );
});

export default UserTypeGuard;
