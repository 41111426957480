import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { NavDropdown, Nav, Spinner } from 'react-bootstrap';
import { LogOut, User } from 'react-feather';
import useAuth from 'client/hooks/useAuth';

interface NavAccountTitleProps {
  title: string;
}

function NavAccountTitle (props: NavAccountTitleProps) {
  const { title } = props;
  return (
    <span className="text-white">
      <User className="me-1 border" size={18} style={{position: 'relative', top: '-2px', borderRadius: '50%', padding: '2px'}} />
      {title}
    </span>
  );
}

export default function NavbarAccount () {
  const auth = useAuth();
  const [isSwitchingBack, setIsSwitchingBack] = useState<boolean>(false);

  const onClickSwitchBack = () => {
    setIsSwitchingBack(true);
    return auth.switchBack().finally(() => {
      setIsSwitchingBack(false);
    });
  };

  const title = auth.isCustomer ? auth.customer?.name : auth.user?.name;

  return (
    <Nav>
      <NavDropdown title={<NavAccountTitle title={title ?? ''} />} align="end" menuVariant="dark">
        <NavDropdown.Item as={NavLink} to="/account">
          Kontoinställningar
        </NavDropdown.Item>
        {auth.hasSwitched && auth.previousUserId ? (
          <NavDropdown.Item onClick={onClickSwitchBack}>
            {isSwitchingBack && <><Spinner size="sm" />{' '}</>}
            Återgå till {auth.previousUserId}
          </NavDropdown.Item>
        ) : ''}
        <NavDropdown.Divider />
        <NavDropdown.Item onClick={auth.logout}>
          <span className="d-inline-flex align-items-center gap-1">
            Logga ut
            {' '}<LogOut size={18} />
          </span>
        </NavDropdown.Item>
      </NavDropdown>
    </Nav>
  );
}
