import React, { useState } from 'react';
import LoadingButton from 'client/buttons/LoadingButton';
import { ConfirmActionModal, useConfirmModalState } from 'client/modals/ConfirmModal';
import { Trash } from 'react-feather';
import { ButtonProps } from 'react-bootstrap';

interface ConfirmDeleteButtonProps extends ButtonProps {
  onConfirmedDelete: () => Promise<any>;
  confirmMessage?: string;
  confirmArgs?: any[];
  buttonLabel?: string;
  buttonTitle?: string;
}

const ConfirmDeleteButton: React.FC<ConfirmDeleteButtonProps> = React.memo(function ConfirmDeleteButton (props: ConfirmDeleteButtonProps) {
  const {
    onConfirmedDelete,
    confirmMessage = 'Är du säker på att du vill radera objektet?',
    buttonLabel = 'Radera',
    buttonTitle,
    size,
    ...restOfButtonProps
  } = props;

  const { confirmAction, props:deleteModalProps } = useConfirmModalState();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const onClick = () => {
    confirmAction(() => {
      setIsLoading(true);
      return onConfirmedDelete().finally(() => {
        setIsLoading(false);
      });
    });
  };

  return (
    <>
      <ConfirmActionModal {...deleteModalProps} message={confirmMessage} />
      <LoadingButton
        variant="outline-danger"
        onClick={onClick}
        disabled={isLoading}
        title={buttonTitle || buttonLabel}
        {...restOfButtonProps}
        size={size}
      >
        {buttonLabel}
        {' '}<Trash size={size === 'sm' ? 14 : 18} />
      </LoadingButton>
    </>
  );
});
export default ConfirmDeleteButton;
