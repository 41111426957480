import { IdShort } from 'client/components/MiscFormatters';
import { BadgeProps } from 'react-bootstrap';
import Anchor from 'client/anchors/Anchor';
import StatusBadge from 'client/components/StatusBadge';
import {CompanyMonitoredRow} from 'client/companyMonitored/types';

interface CompanyWatchedAnchorProps {
  value: string;
}

export function CompanyWatchedAnchor (props: CompanyWatchedAnchorProps) {
  const { value:companyWatchedOrgNumber } = props;
  return (
    <Anchor to={`/company/${companyWatchedOrgNumber}/overview`}>
      <IdShort value={companyWatchedOrgNumber} />
    </Anchor>
  );
}

interface StatusLabelProps extends BadgeProps {
  value?: null | Pick<CompanyMonitoredRow, 'is_active'>;
}

export function StatusLabel (props: StatusLabelProps) {
  const { value, ...restOfProps } = props;
  if (typeof value === 'undefined') return null;
  if (value === null) return <StatusBadge bg="secondary">Obevakat</StatusBadge>;
  const { is_active = false } = value || {};
  return (
    <StatusBadge bg={is_active ? 'success' : 'danger'} {...restOfProps}>
      {is_active ? 'Aktiverad' : 'Inaktiverad'}
    </StatusBadge>
  );
}
