import React, { useId } from 'react';

interface IIdProvider  {
  children: (id: string) => React.ReactElement;
}

const IdProvider: React.FC<IIdProvider> = React.memo(function IdProvider (props: IIdProvider) {
  const { children } = props;
  const id = useId();
  return children(id);
});

export default IdProvider;
