import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import useAuth from 'client/hooks/useAuth';
import useLastLocation from 'client/hooks/useLastLocation';

interface IPrivateRoute extends RouteProps {
  component: any;
}

const PrivateRoute: React.FC<IPrivateRoute> = React.memo(function PrivateRoute (props: IPrivateRoute) {
  const { component:Component, ...rest } = props;
  const { saveLastLocation } = useLastLocation();
  const auth = useAuth();
  return (
    <Route
      {...rest}
      render={props => {
        if (!auth.isAuthenticated) {
          saveLastLocation();
          return <Redirect to="/login" />;
        }
        return <Component {...props} />;
      }}
    />
  );
});

export default PrivateRoute;
