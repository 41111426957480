import React from 'react';
import { IValuationReportFormData } from 'client/valuationReport/types';
import { NumberSuffixInput } from 'client/components/ReportFormHelpers';
import { ICsAccountsExtended, ICsGroupAccountsExtended } from 'client/cs/types';
import { isFiniteNumber } from 'client/utils/number';
import HelperTooltip from 'client/components/HelperTooltip';

interface ValuationReportNumberOfAccountsInputProps {
  onChangeValue: (name: string, value: any) => void;
  form: IValuationReportFormData;
  accounts: ICsAccountsExtended[] | ICsGroupAccountsExtended[] | null;
}

const ValuationReportNumberOfAccountsInput: React.FC<ValuationReportNumberOfAccountsInputProps> = React.memo(function ValuationReportNumberOfAccountsInput (props: ValuationReportNumberOfAccountsInputProps) {
  const { form, onChangeValue:onChangeValueOuter, accounts } = props;

  const onChangeValue = (name: string, rawValue: number) => {
    if (!isFiniteNumber(rawValue)) return onChangeValueOuter(name, 0);
    const value = Math.max(1, Math.min(5, rawValue));
    onChangeValueOuter(name, value);
  };

  return (
    <>
      <div className="d-flex">
        <NumberSuffixInput
          label={
            <>
              Bokslut att visa i rapporten{' '}
              <HelperTooltip>
                Antal bokslut som presenteras i rapporten. Företagsvärderingen behöver minst 1 och kan visa som flest 5.<br /><br />
                Det går att välja fler bokslut än företaget har i dagsläget, men då kommer rapporten att skjutas upp tills rätt antal finns tillgängligt.
              </HelperTooltip>
            </>
          }
          suffix="senaste"
          min={1}
          max={5}
          required
          value={form.report_settings.number_of_accounts ?? ''}
          name="report_settings.number_of_accounts"
          onChangeValue={onChangeValue}
        />
      </div>

      {((accounts?.length ?? 1) < form.report_settings.number_of_accounts) ? (
        <div className="alert alert-warning small p-1 mb-0 mt-3">
          <strong>OBS!</strong> Detta är fler bokslut än de {accounts?.length ?? 1} st som finns tillgängligt på bolaget i dagsläget.
          Rapporten kommer inte kunna produceras förrän {form.report_settings.number_of_accounts} st bokslut finns tillgängliga.
        </div>
      ) : null}
    </>
  );
});
export default ValuationReportNumberOfAccountsInput;
