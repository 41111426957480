import { useState, useEffect, useMemo } from 'react';

/**
 * Well known as 1-2-5 progression 
 * https://en.wikipedia.org/wiki/Preferred_number
 */
type RowsPerPageSeries = 10 | 20 | 50 | 100 | 200 | 500 | 1000 | 2000;

const ROWS_PER_PAGE_SERIES: RowsPerPageSeries[] = [10, 20, 50, 100, 200, 500, 1000];
const ROWS_PER_PAGE_DEFAULT: RowsPerPageSeries = 20;

export interface IUseTablePagination {
  currentPage: number;
  rowsPerPage: number;
  rowsPerPageSeries: RowsPerPageSeries[];
  countOfPages: number;
  totalCountOfRows: number;
  onChangeRowsPerPage: (rowsPerPage: number) => void;
  onChangeTotalCountOfRows: (totalCountOfRows: number) => void;
  onChangePage: (page: number) => void;
  isFirstPage: (page: number) => boolean;
  isLastPage: (page: number) => boolean;
  onChangeTotalCountOfPages: (count: number) => void;
  params: {page: number; limit: number};
}

export interface IUseTablePaginationProps {
  rowsPerPage?: number;
  rowsPerPageMax?: RowsPerPageSeries;
  page?: number;
  onChangePage?: (page: number) => void;
  onChangeRowsPerPage?: (rowsPerPages: number) => void;
}

const useTablePagination = (props?: IUseTablePaginationProps): IUseTablePagination => {
  const [currentPage, setCurrentPage] = useState(props?.page ?? 1);
  const [rowsPerPage, setRowsPerPage] = useState(props?.rowsPerPage ?? ROWS_PER_PAGE_DEFAULT);
  const [totalCountOfRows, setTotalCountOfRows] = useState(0);

  useEffect(() => {
    if (props?.page && currentPage !== props?.page) {
      onChangePage(props?.page);
    }
  }, [props?.page]);

  const onChangePage = (page: number) => {
    setCurrentPage(page);
    props?.onChangePage?.(page);
  };

  const isLastPage = (page: number) => {
    return totalCountOfRows <= (page * rowsPerPage);
  };

  const isFirstPage = (page: number) => {
    return page === 1;
  };

  const onChangeRowsPerPage = (value: number) => {
    onChangePage(1);
    setRowsPerPage(value);
    props?.onChangeRowsPerPage?.(value);
  };

  const onChangeTotalCountOfRows = (value: number) => {
    setTotalCountOfRows(value);
  };

  // for the old API format with `last_page`
  const onChangeTotalCountOfPages = (count: number) => {
    onChangeTotalCountOfRows(rowsPerPage * count);
  };

  // this returns an object that is compatible with the /list endpoints on the server
  // it can be passed straight into "params" when querying the server
  const params = useMemo(() => ({
    page: currentPage,
    limit: rowsPerPage,
  }), [currentPage, rowsPerPage]);

  const rowsPerPageSeries = useMemo(() => {
    const max: RowsPerPageSeries = props?.rowsPerPageMax || 50;
    return ROWS_PER_PAGE_SERIES.slice(0, ROWS_PER_PAGE_SERIES.indexOf(max) + 1);
  }, [props?.rowsPerPageMax]);

  return {
    currentPage,
    rowsPerPage,
    rowsPerPageSeries,
    countOfPages: Math.ceil(totalCountOfRows / rowsPerPage),
    totalCountOfRows,
    onChangeRowsPerPage,
    onChangeTotalCountOfRows,
    onChangePage,
    isLastPage,
    isFirstPage,
    onChangeTotalCountOfPages,
    params,
  };
};

export default useTablePagination;
