import { useState, useCallback, useMemo, useEffect } from 'react';

export enum OrderDirection {
  ASC = 'asc',
  DESC = 'desc'
}

export interface IUseTableSorting {
  currentOrderBy?: string;
  currentOrderDirection: OrderDirection;
  onSetOrder: (column: string, direction?: OrderDirection) => void;
  isOrderedBy: (column: string) => boolean;
  toggleDirection: () => void;
  params: {order_by?: string; order_direction?: OrderDirection};
  isAsc: boolean;
  isDesc: boolean;
  reset: () => void;
}

interface IUseTableSortingProps {
  onChange?: (order?: string, direction?: OrderDirection) => void;
  initialOrderBy?: string;
  initialOrderDirection?: OrderDirection;
}

const useTableSorting = (props?: IUseTableSortingProps): IUseTableSorting => {
  const {
    onChange = () => {},
    initialOrderBy,
    initialOrderDirection,
  } = props ?? {};

  const [currentOrderBy, setCurrentOrderBy] = useState<string | undefined>(initialOrderBy);
  const [currentOrderDirection, setCurrentOrderDirection] = useState<OrderDirection>(initialOrderDirection || OrderDirection.ASC);

  const onSetOrder = useCallback((column: typeof currentOrderBy, direction: OrderDirection = OrderDirection.ASC) => {
    setCurrentOrderBy(column);
    setCurrentOrderDirection(direction);
  }, [setCurrentOrderBy, setCurrentOrderDirection]);

  const reset = useCallback(() => {
    onSetOrder(initialOrderBy, initialOrderDirection);
  }, [initialOrderBy, initialOrderDirection, onSetOrder]);

  const isOrderedBy = useCallback((column: string) => {
    return column === currentOrderBy;
  }, [currentOrderBy]);

  const toggleDirection = useCallback(() => {
    setCurrentOrderDirection(state => state === OrderDirection.DESC ? OrderDirection.ASC : OrderDirection.DESC);
  }, [setCurrentOrderDirection]);

  useEffect(() => {
    onChange(currentOrderBy, currentOrderDirection);
  }, [currentOrderBy, currentOrderDirection]);

  // this returns an object that is compatible with the /list endpoints on the server
  // it can be passed straight into "params" when querying the server
  const params = useMemo(() => ({
    order_by: currentOrderBy,
    order_direction: currentOrderDirection,
  }), [currentOrderBy, currentOrderDirection]);

  return {
    currentOrderBy,
    isAsc: currentOrderDirection === OrderDirection.ASC,
    isDesc: currentOrderDirection === OrderDirection.DESC,
    currentOrderDirection,
    onSetOrder,
    isOrderedBy,
    toggleDirection,
    reset,
    params,
  };
};

export default useTableSorting;
