import { keyBy, round, cloneDeep, range } from 'lodash';
import * as immer from 'immer';
import moment from 'moment';
import {
  IAccountsTableRowDefinition,
  IAccountsTableDerivedRowDefinition,
  TAccountsTableDerivedRowCalculateFn,
  TAccountsTableRowType,
  TAccountsTableRowDefinition,
  IAccounts,
} from 'client/accounts/types';
import { ICsAccounts } from 'client/cs/types';

/**
 * Key figure definitions from creditsafe
 * @see https://www.creditsafe.com/se/sv/product/nyckeltal.html
 */

export const spacer: IAccountsTableRowDefinition = {
  key: 'spacer',
  type: 'spacer',
};

export const header = (title?: string): IAccountsTableRowDefinition => ({
  key: 'header',
  type: 'header',
  title,
});

const date = (key: string, title?: string): IAccountsTableRowDefinition => type('date', key, title);

const string = (key: string, title?: string): IAccountsTableRowDefinition => type('string', key, title);

const percent = (key: string, title?: string): IAccountsTableRowDefinition => type('percent', key, title);

const integer = (key: string, title?: string): IAccountsTableRowDefinition => type('integer', key, title);

const numeric = (key: string, title?: string): IAccountsTableRowDefinition => type('numeric', key, title);

const derive = (key: string, type: TAccountsTableRowType, calculate: TAccountsTableDerivedRowCalculateFn, title?: string): IAccountsTableDerivedRowDefinition => ({
  key,
  type,
  title,
  derived: true,
  calculate,
});

const type = (type: TAccountsTableRowType, key: string, title?: string): IAccountsTableRowDefinition  => ({
  key,
  type,
  title,
});

// default creditsafe account keys
export const defaultTableRowDefinitionsList: IAccountsTableRowDefinition[] = [
  date('date_from', 'Datum från'),
  date('date_to', 'Datum till'),
  string('year', 'År'),
  string('year_from', 'År från'),
  string('year_to', 'År till'),
  percent('kr_return_on_capital_percent', 'Avkastning på eget kapital'),
  percent('kr_return_on_cap_emp_percent', 'Avkastning på totalt kapital'),
  percent('kr_avg_debt_eq_ratio_percent', 'Skuldränta'),
  numeric('kr_risk_buffer', 'Riskbuffert totalt kapital'),
  numeric('kr_ebitda', 'EBITDA'),
  percent('kr_ebitida_margin_percent', 'EBITDA-marginal'),
  numeric('kr_interest_coverage_ebitda', 'Räntetäckningsgrad EBITDA'),
  percent('kr_return_on_total_capital_ebitda_percent', 'Avkastning på totalt kapital EBITDA'),
  percent('kr_return_on_working_capital_percent', 'Avkastning på sysselsatt kapital'),
  numeric('kr_degree_of_debt_interest_bearing', 'Skuldsättningsgrad Räntebärande'),
  percent('kr_operating_margin_percent', 'Rörelsemarginal'),
  percent('kr_profit_margin_percent', 'Vinstmarginal'),
  percent('kr_gross_profit_margin_percent', 'Bruttovinstmarginal'),
  numeric('kr_turnover_per_employee', 'Omsättning per anställd'),
  percent('kr_solidity_percent', 'Soliditet'),
  numeric('kr_capital_turnover_times', 'Kapitalets omsättningshastighet'),
  numeric('kr_working_capital', 'Rörelsekapital'),
  percent('kr_opp_profit_total_net_opp_income_percent', 'Rörelsekapital/omsättning'),
  percent('kr_quick_ratio_percent', 'Kassalikviditet'),
  percent('kr_change_in_turnover_percent', 'Omsättningstillväxt'),
  numeric('kr_opp_result_number_of_employees', 'Rörelseresultat per anställd'),
  numeric('kr_cost_per_employee', 'Personalkostnader per anställd'),
  percent('kr_change_in_number_of_employees', 'Förändring av antal anställda'),
  percent('kr_inventories_turnover_percent', 'Lager mm/omsättning'),
  percent('kr_accounts_receivable_turnover_percent', 'Kundfordringar/omsättning'),
  percent('kr_liquid_assets_turnover_percent', 'Likvida medel/omsättning'),
  percent('kr_current_liabilites_turnover_percent', 'Kortfristiga skulder/omsättning'),
  numeric('kr_degree_of_debt', 'Skuldsättningsgrad'),
  numeric('kr_rate_of_return_times', 'Räntetäckningsgrad'),
  percent('kr_return_on_op_capital', 'Avkastning operativt kapital'),
  numeric('kr_risk_buffer_on_operation_capital_percent', 'Riskbuffert sysselsatt kapital'),
  numeric('kr_inventory_turnover_times', 'Varulagrets omsättningshastighet'),
  percent('kr_du_pont_model_percent', 'Du Pont-modellen'),
  integer('pl_net_operating_income', 'Omsättning'),
  integer('pl_net_sales', 'Nettoomsättning'),
  integer('pl_cost_of_sold_goods', 'Kostnad sålda varor'),
  integer('pl_gross_profit_loss', 'Bruttoresultat'),
  integer('pl_selling_expenses', 'Försäljningskostnader'),
  integer('pl_admin_costs', 'Administrationskostnader'),
  integer('pl_research_and_dev_costs', 'FoU-kostnader'),
  integer('pl_change_invent_work_prog', 'Förändring av lager mm'),
  integer('pl_work_perf_own_use_capital', 'Aktiverat arbete egen räkning'),
  integer('pl_raw_mat_and_cons', 'Råvaror & förnödenheter'),
  integer('pl_goods_for_resale', 'Handelsvaror'),
  integer('pl_personal_costs', 'Personalkostnader'),
  integer('pl_depr_and_write_downs', 'Avskrivningar'),
  integer('pl_item_affect_compare', 'Jämförelsestörande poster'),
  integer('pl_other_opp_income', 'Övriga rörelseintäkter'),
  integer('pl_other_opp_expenses', 'Övriga rörelsekostnader'),
  integer('pl_other_ext_costs', 'Övriga externa kostnader'),
  integer('pl_operating_result', 'Rörelseresultat (EBIT)'),
  integer('pl_result_participation_group', 'Resultat från andelar i koncern- och intresseföretag'),
  integer('pl_fin_int_group_inc', 'Ränteintäkter koncern'),
  integer('pl_ext_interest_inc', 'Ränteintäkter externa'),
  integer('pl_interest_exp_int', 'Räntekostnader koncern'),
  integer('pl_interest_exp_ext', 'Räntekostnader externa'),
  integer('pl_other_fin_inc', 'Övriga finansiella intäkter'),
  integer('pl_other_fin_costs', 'Övriga finansiella kostnader'),
  integer('pl_prof_loss_after_fin_items', 'Resultat efter finansnetto'),
  integer('pl_extraordinary_inc', 'Extraordinära intäkter'),
  integer('pl_extraordinary_exp', 'Extraordinära kostnader'),
  integer('pl_group_contrib', 'Koncernbidrag'),
  integer('pl_shareholder_contrib', 'Aktieägartillskott'),
  integer('pl_other_appropriations', 'Bokslutsdispositioner'),
  integer('pl_tax', 'Skatt'),
  integer('pl_minority_intrest_and_prof', 'Minoritetsintressen'),
  integer('pl_net_profit_loss', 'Årets resultat'),
  integer('bs_subscribed_capital_unpaid', 'Tecknat ej inbetalt kapital'),
  integer('bs_capt_exp_for_res_and_dev', 'Balanserade utgifter FoU'),
  integer('bs_patents', 'Patent, licenser mm'),
  integer('bs_goodwill', 'Goodwill'),
  integer('bs_other_intangible_ass', 'Övriga immateriella anläggningstillg.'),
  integer('bs_total_intangible_assets', 'Summa immateriella anläggningstillgångar'),
  integer('bs_land_build', 'Byggnader och mark'),
  integer('bs_plant_machine', 'Maskiner'),
  integer('bs_equip_tools_fix_and_fit', 'Inventarier'),
  integer('bs_machines_inventory', 'Maskiner och inventarier'),
  integer('bs_other_tang_ass_non_dep', 'Övriga materiella anläggnings tillgångar, ej avskrivningsbara'),
  integer('bs_other_mat_depreciation', 'Övriga materiella anläggningstillg., avskr.'),
  integer('bs_total_tangible_assets', 'Summa materiella anläggningstillgångar'),
  integer('bs_group_share', 'Andelar i koncern- och intresseföretag'),
  integer('bs_acc_recieve_corp_group', 'Fordr. på koncern- och intresseföretag'),
  integer('bs_loan_co_owners', 'Lån till delägare och närstående'),
  integer('bs_other_fin_assets', 'Övriga finansiella anläggningstillgångar'),
  integer('bs_total_financial_assets', 'Summa finansiella anläggningstillgångar'),
  integer('bs_tot_fix_assets', 'Summa anläggningstillgångar'),
  integer('bs_work_on_contract', 'Pågående arbeten för annans räkning'),
  integer('bs_inventories', 'Övrigt varulager'),
  integer('bs_total_inventories', 'Summa varulager'),
  integer('bs_total_inventories_prev', 'Summa varulager (föregående år)'),
  integer('bs_total_inventories_change', 'Förändring av varulager'),
  integer('bs_accounts_receivable_trade', 'Kundfordringar'),
  integer('bs_acc_recieve_group', 'Fordr. hos koncern- och intresseföretag'),
  integer('bs_other_acc_recieve', 'Övriga kortfristiga fordringar'),
  integer('bs_total_accounts_receivable', 'Summa kortfristiga fordringar'),
  integer('bs_short_investments', 'Kortfristiga placeringar'),
  integer('bs_cash_and_bank_balances', 'Kassa och bank'),
  integer('bs_other_cur_assets', 'Övriga omsättningstillgångar'),
  integer('bs_total_turnover_assets', 'Summa omsättningstillgångar'),
  integer('bs_tot_assets', 'Totala tillgångar'),
  integer('bs_issued_share_capital', 'Aktiekapital'),
  integer('bs_share_premium_reserve', 'Överkursfond'),
  integer('bs_revaluation_reserve', 'Uppskrivningsfond'),
  integer('bs_other_stockholder_equity', 'Övrigt bundet eget kapital'),
  integer('bs_profit_loss_brought_fwd', 'Balanserat resultat'),
  integer('bs_group_contribution', 'Erhållet/lämnat koncernbidrag'),
  integer('bs_shareholder_contribution', 'Erhållet/lämnat aktieägartillskott'),
  integer('bs_net_profit_loss', 'Årets resultat'),
  integer('bs_total_dividens_capacity', 'Summa Utdelningsbart utrymme'),
  integer('bs_tot_equity', 'Summa eget kapital'),
  integer('bs_untaxed_reserves', 'Obeskattade reserver'),
  numeric('corporate_tax_rate', 'Bolagsskattesats'),
  integer('bs_minority_interests', 'Minoritetsintressen samt vinst/förlust i dotterbolag'),
  integer('bs_provisions', 'Avsättningar'),
  integer('bs_lt_bond_loan', 'Obligationslån'),
  integer('bs_lt_liab_to_credit_instit', 'Skulder till kreditinstitut, långa'),
  integer('bs_lt_liab_group_ass_comp', 'Skulder till koncern- och intresseföretag, långa'),
  integer('bs_other_long_term_liab', 'Övriga långfristiga skulder'),
  integer('bs_tot_long_term_debts', 'Summa långfristiga skulder'),
  integer('bs_liab_credit_inst', 'Skulder till kreditinstitut, korta'),
  integer('bs_acc_pay_trade', 'Leverantörsskulder'),
  integer('bs_st_liab_group_ass_comp', 'Skulder till koncern- och intresseföretag, korta'),
  integer('bs_other_short_term_liab', 'Övriga kortfristiga skulder'),
  integer('bs_tot_current_liabilities', 'Summa kortfristiga skulder'),
  integer('bs_tot_liabilities', 'Summa skulder'),
  integer('bs_tot_equity_and_liab', 'Summa eget kapital och skulder'),
  integer('n_months', 'Månader'),
  integer('n_no_employees', 'Antal anställda'),
  integer('n_salary_board', 'Löner till styrelse & VD'),
  integer('n_tantiem_board', 'Varav tantiem till styrelse & VD'),
  integer('n_employee_salary', 'Löner till övriga anställda'),
  integer('n_performance_bonus_to_other_employees', 'Varav resultatlön till övriga anställda'),
  integer('n_payroll_overhead', 'Sociala kostnader'),
  integer('n_floating_charge', 'Företagsinteckningar'),
  integer('n_real_estate_mortgage', 'Fastighetsinteckningar'),
  integer('n_other_collateral', 'Övriga säkerheter'),
  integer('n_tot_collateral', 'Summa säkerheter'),
  integer('n_conditional_equity', 'Villkorat aktieägartillskott'),
  integer('n_other_contingent_liab', 'Övriga ansvarsförbindelser'),
  integer('n_tot_contingent_liab', 'Summa ansvarsförbindelser'),
  integer('n_dividend', 'Utdelning'),
  integer('n_accepted_bank_overdraft', 'Beviljad checkräkningskredit'),
  integer('n_not_used_bank_overdraft', 'Utnyttjad checkräkningskredit'),
  integer('n_net_interest_finance', 'Räntenetto finansbolag'),
  integer('n_depr_goods', 'Avskrivningar varor'),
  integer('n_depr_sales', 'Avskrivning försäljning'),
  integer('n_depr_admin', 'Avskrivning administrativ'),
  integer('n_depr_rod', 'Avskrivning för avkastning på skuld'),
  integer('n_depr_other', 'Annan avskrivning'),
  integer('n_depr_unspec', 'Ospeficerad avskrivning'),
];

export const defaultTableRowByKey = keyBy<IAccountsTableRowDefinition>(defaultTableRowDefinitionsList, 'key');

// extended/derived keys calculated at various places in the backend, typically views
export const extendedTableRowDefinitionsList: IAccountsTableRowDefinition[] = [
  numeric('bs_adjusted_equity', 'Justerat eget kapital'),
  percent('bs_adjusted_equity_change', 'Förändring av justerat eget kapital'),
  percent('kr_net_margin_percent', 'Nettomarginal'),
  numeric('kr_personal_costs_per_net_sales', 'Personalkostnader/nettoomsättning'),
  percent('pl_operating_result_change', 'Förändring av rörelseresultat'),
  percent('pl_personal_costs_change', 'Förändring av personalkostnader'),
];

export const extendedTableRowByKey = keyBy<IAccountsTableRowDefinition>(extendedTableRowDefinitionsList, 'key');

const summers: Record<string, string[]> = {};

summers.pl_net_operating_income = [
  'pl_net_sales',
  'pl_change_invent_work_prog',
  'pl_work_perf_own_use_capital',
  'pl_other_opp_income',
];

summers.pl_gross_profit_loss = [
  // ...summers.pl_net_operating_income,
  // 'pl_cost_of_sold_goods',
];

summers.pl_tot_opp_exp = [
  // 'pl_gross_profit_loss',
  'pl_cost_of_sold_goods',
  'pl_raw_mat_and_cons',
  'pl_goods_for_resale',
  'pl_other_ext_costs',
  'pl_personal_costs',
  'pl_depr_and_write_downs',
  'pl_selling_expenses',
  'pl_admin_costs',
  'pl_research_and_dev_costs',
  'pl_item_affect_compare',
  'pl_other_opp_expenses',
];

summers.pl_operating_result = [
  ...summers.pl_net_operating_income,
  ...summers.pl_tot_opp_exp,
];

summers.pl_prof_loss_after_fin_items = [
  ...summers.pl_operating_result,
  'pl_result_participation_group',
  'pl_ext_interest_inc',
  'pl_fin_int_group_inc',
  'pl_other_fin_inc',
  'pl_interest_exp_ext',
  'pl_interest_exp_int',
  'pl_other_fin_costs',
];

summers.pl_net_profit_loss = [
  ...summers.pl_prof_loss_after_fin_items,
  'pl_extraordinary_inc',
  'pl_extraordinary_exp',
  'pl_group_contrib',
  // 'pl_shareholder_contrib',
  'pl_other_appropriations',
  'pl_tax',
  'pl_minority_intrest_and_prof',
];

summers.bs_total_intangible_assets = [
  'bs_capt_exp_for_res_and_dev',
  'bs_patents',
  'bs_goodwill',
  'bs_other_intangible_ass',
];

summers.bs_total_tangible_assets = [
  'bs_land_build',
  'bs_plant_machine',
  'bs_equip_tools_fix_and_fit',
  'bs_other_mat_depreciation',
  'bs_other_tang_ass_non_dep',
];

summers.bs_total_financial_assets = [
  'bs_group_share',
  'bs_acc_recieve_corp_group',
  'bs_loan_co_owners',
  'bs_other_fin_assets',
];

summers.bs_total_inventories = [
  'bs_work_on_contract',
  'bs_inventories',
];

summers.bs_total_accounts_receivable = [
  'bs_accounts_receivable_trade',
  'bs_acc_recieve_group',
  'bs_other_acc_recieve',
];

summers.bs_total_turnover_assets = [
  ...summers.bs_total_inventories,
  ...summers.bs_total_accounts_receivable,
  'bs_short_investments',
  'bs_cash_and_bank_balances',
  'bs_other_cur_assets',
];

summers.bs_tot_fix_assets = [
  ...summers.bs_total_tangible_assets,
  ...summers.bs_total_intangible_assets,
  ...summers.bs_total_financial_assets,
];

summers.bs_tot_assets = [
  'bs_subscribed_capital_unpaid',
  ...summers.bs_total_tangible_assets,
  ...summers.bs_total_intangible_assets,
  ...summers.bs_total_financial_assets,
  ...summers.bs_total_turnover_assets,
];

summers.bs_net_profit_loss = [
  ...summers.pl_net_profit_loss,
];

summers.bs_tot_equity = [
  ...summers.bs_net_profit_loss,
  'bs_issued_share_capital',
  'bs_share_premium_reserve',
  'bs_revaluation_reserve',
  'bs_other_stockholder_equity',
  'bs_profit_loss_brought_fwd',
  'bs_group_contribution',
  'bs_shareholder_contribution',
  // 'bs_untaxed_reserves',
  'bs_minority_interests',
];

summers.bs_tot_long_term_debts = [
  'bs_lt_bond_loan',
  'bs_lt_liab_to_credit_instit',
  'bs_lt_liab_group_ass_comp',
  'bs_other_long_term_liab',
];

summers.bs_tot_current_liabilities = [
  'bs_liab_credit_inst',
  'bs_acc_pay_trade',
  'bs_st_liab_group_ass_comp',
  'bs_other_short_term_liab',
];

summers.bs_tot_liabilities = [
  ...summers.bs_tot_long_term_debts,
  ...summers.bs_tot_current_liabilities,
];

summers.bs_tot_equity_and_liab = [
  'bs_provisions',
  'bs_untaxed_reserves',
  ...summers.bs_tot_equity,
  ...summers.bs_tot_long_term_debts,
  ...summers.bs_tot_current_liabilities,
];

export const derivedTableRowDefinitionsList: IAccountsTableDerivedRowDefinition[] = [

  derive('bs_tot_equity_and_liab', 'integer', calculateSum(
    ...summers.bs_tot_equity_and_liab,
  ), 'Summa eget kapital och skulder'),

  derive('bs_tot_long_term_debts', 'integer', calculateSum(
    ...summers.bs_tot_long_term_debts,
  ), 'Summa långfristiga skulder'),

  derive('bs_tot_current_liabilities', 'integer', calculateSum(
    ...summers.bs_tot_current_liabilities,
  ), 'Summa kortfristiga skulder'),

  derive('bs_tot_liabilities', 'integer', calculateSum(
    ...summers.bs_tot_liabilities,
  ), 'Summa skulder'),

  derive('bs_tot_equity', 'integer', calculateSum(
    ...summers.bs_tot_equity,
  ), 'Summa eget kapital'),

  derive('bs_profit_loss_brought_fwd', 'integer', (index, ...accounts) => {
    const bs_profit_loss_brought_fwd = accountNumeric('bs_profit_loss_brought_fwd', accounts[index]);
    if (!accounts[index + 1]) return bs_profit_loss_brought_fwd;

    const prev_bs_profit_loss_brought_fwd = accountNumeric('bs_profit_loss_brought_fwd', accounts[index + 1]);
    const prev_bs_net_profit_loss = accountNumeric('bs_net_profit_loss', accounts[index + 1]);
    const prev_n_dividend = accountNumeric('n_dividend', accounts[index + 1]);

    const result = prev_bs_profit_loss_brought_fwd + prev_bs_net_profit_loss - prev_n_dividend;
    return result;
  }, 'Balanserat resultat'),

  derive('bs_net_profit_loss', 'integer', calculateSum(
    ...summers.bs_net_profit_loss,
  ), 'Årets resultat'),

  derive('bs_tot_assets', 'integer', calculateSum(
    ...summers.bs_tot_assets,
  ), 'Summa tillgångar'),

  derive('bs_total_inventories', 'integer', calculateSum(
    ...summers.bs_total_inventories,
  ), 'Summa varulager'),

  derive('bs_total_accounts_receivable', 'integer', calculateSum(
    ...summers.bs_total_accounts_receivable,
  ), 'Summa kortfristiga fordringar'),

  derive('bs_total_turnover_assets', 'integer', calculateSum(
    ...summers.bs_total_turnover_assets,
  ), 'Summa omsättningstillgångar'),

  derive('bs_total_financial_assets', 'integer', calculateSum(
    ...summers.bs_total_financial_assets,
  ), 'Summa finansiella anläggningstillgångar'),

  derive('bs_total_intangible_assets', 'integer', calculateSum(
    ...summers.bs_total_intangible_assets,
  ), 'Summa immateriella anläggningstillgångar'),

  derive('bs_total_tangible_assets', 'integer', calculateSum(
    ...summers.bs_total_tangible_assets,
  ), 'Summa materiella anläggningstillgångar'),

  derive('bs_tot_fix_assets', 'integer', calculateSum(
    ...summers.bs_tot_fix_assets,
  ), 'Summa anläggningstillgångar'),

  derive('pl_net_operating_income', 'integer', calculateSum(
    ...summers.pl_net_operating_income,
  ), 'Summa intäkter'),

  derive('pl_gross_profit_loss', 'integer', calculateSum(
    ...summers.pl_gross_profit_loss,
  ), 'Bruttoresultat'),

  derive('pl_tot_opp_exp', 'integer', calculateSum(
    ...summers.pl_tot_opp_exp,
  ), 'Summa rörelsens kostnader'),

  derive('pl_operating_result', 'integer', calculateSum(
    ...summers.pl_operating_result,
  ), 'Rörelseresultat (EBIT)'),

  derive('kr_ebitda', 'integer', (index, ...accounts) => {
    const pl_operating_result  = accountNumeric('pl_operating_result', accounts[index]);
    const pl_depr_and_write_downs  = accountNumeric('pl_depr_and_write_downs', accounts[index]);
    return pl_operating_result - pl_depr_and_write_downs;
  }, 'EBITDA'),

  derive('pl_prof_loss_after_fin_items', 'integer', calculateSum(
    ...summers.pl_prof_loss_after_fin_items,
  ), 'Resultat efter finansnetto'),

  derive('pl_net_profit_loss', 'integer', calculateSum(
    ...summers.pl_net_profit_loss,
  ), 'Årets resultat'),

  derive('kr_change_in_turnover_percent', 'percent', calculateChange('pl_net_sales', 'kr_change_in_turnover_percent'), 'Omsättningstillväxt'),

  derive('pl_operating_result_change', 'percent', calculateChange('pl_operating_result', 'pl_operating_result_change'), 'Förändring av rörelseresultat'),

  derive('bs_adjusted_equity', 'integer', (index, ...accounts) => {
    const bs_tot_equity = accountNumeric('bs_tot_equity', accounts[index]);
    const bs_untaxed_reserves = accountNumeric('bs_untaxed_reserves', accounts[index]);
    const corporate_tax_rate = accountCorporateTaxRate(accounts[index]);
    return Math.round(bs_tot_equity + bs_untaxed_reserves * (1 - (corporate_tax_rate / 100)));
  }, 'Justerat eget kapital'),

  derive('bs_adjusted_equity_change', 'percent', (index, ...accounts) => {
    const curr = accounts[index];
    const prev = accounts[index + 1];
    if (!prev) return 0;
    const curr_corporate_tax_rate = accountCorporateTaxRate(curr);
    const prev_corporate_tax_rate = accountCorporateTaxRate(prev);

    const curr_bs_tot_equity = accountNumeric('bs_tot_equity', curr);
    const curr_bs_untaxed_reserves = accountNumeric('bs_untaxed_reserves', curr);

    const prev_bs_tot_equity = accountNumeric('bs_tot_equity', prev);
    const prev_bs_untaxed_reserves = accountNumeric('bs_untaxed_reserves', prev);

    const currv = (curr_bs_tot_equity ?? 0) + (curr_bs_untaxed_reserves ?? 0) * (1 - (curr_corporate_tax_rate / 100));
    const prevv = (prev_bs_tot_equity ?? 0) + (prev_bs_untaxed_reserves ?? 0) * (1 - (prev_corporate_tax_rate / 100));
    if (prevv === 0) return 0;
    return ((currv / prevv) - 1) * 100;
  }, 'Förändring av justerat eget kapital'),

  derive('kr_net_margin_percent', 'percent', (index, ...accounts) => {
    const pl_prof_loss_after_fin_items = accountNumeric('pl_prof_loss_after_fin_items', accounts[index]);
    const pl_net_sales = accountNumeric('pl_net_sales', accounts[index]);
    const pl_other_opp_income  = accountNumeric('pl_other_opp_income', accounts[index]);
    const bs_total_inventories_change  = accountNumeric('bs_total_inventories_change', accounts[index]);
    const pl_work_perf_own_use_capital = accountNumeric('pl_work_perf_own_use_capital', accounts[index]);
    const denominator = pl_net_sales + pl_work_perf_own_use_capital + pl_other_opp_income + bs_total_inventories_change;
    if (denominator === 0) return 0;
    return round((pl_prof_loss_after_fin_items / denominator) * 100, 2);
  }, 'Nettomarginal'),

  derive('kr_operating_margin_percent', 'percent', (index, ...accounts) => {
    const pl_operating_result  = accountNumeric('pl_operating_result', accounts[index]);
    const pl_net_sales = accountNumeric('pl_net_sales', accounts[index]);
    if (pl_net_sales === 0) return 0;
    return round((pl_operating_result / pl_net_sales) * 100, 2);
  }, 'Rörelsemarginal'),

  derive('kr_change_in_number_of_employees', 'percent', calculateChange('n_no_employees', 'kr_change_in_number_of_employees'), 'Förändring av antal anställda'),

  derive('kr_personal_costs_per_net_sales', 'percent', (index, ...accounts) => {
    const pl_personal_costs  = accountNumeric('pl_personal_costs', accounts[index]);
    const pl_net_sales = accountNumeric('pl_net_sales', accounts[index]);
    if (pl_net_sales === 0) return 0;
    return round((-pl_personal_costs / pl_net_sales) * 100, 2);
  }, 'Personalkostnader/nettoomsättning'),

  derive('kr_solidity_percent', 'percent', (index, ...accounts) => {
    const bs_tot_assets  = accountNumeric('bs_tot_assets', accounts[index]);
    const bs_adjusted_equity = accountNumeric('bs_adjusted_equity', accounts[index]);
    if (bs_tot_assets === 0) return 0;
    return round(bs_adjusted_equity / bs_tot_assets * 100, 2);
  }, 'Soliditet'),

  derive('kr_gross_profit_margin_percent', 'percent', (index, ...accounts) => {
    const { n_type_of_statement } = accounts[index] ?? {};
    const pl_gross_profit_loss = accountNumeric('pl_gross_profit_loss', accounts[index]);
    const pl_goods_for_resale  = accountNumeric('pl_goods_for_resale', accounts[index]);
    const pl_raw_mat_and_cons  = accountNumeric('pl_raw_mat_and_cons', accounts[index]);
    const pl_net_sales = accountNumeric('pl_net_sales', accounts[index]);
    if (pl_net_sales === 0) return 0;
    if (n_type_of_statement === 'K') {
      return round(((pl_net_sales + pl_raw_mat_and_cons + pl_goods_for_resale) / pl_net_sales) * 100, 2);
    } else { // statement type F
      return round((pl_gross_profit_loss / pl_net_sales) * 100, 2);
    }
  }, 'Bruttovinstmarginal'),

  derive('kr_profit_margin_percent', 'percent', (index, ...accounts) => {
    const pl_net_sales = accountNumeric('pl_net_sales', accounts[index]);
    const pl_prof_loss_after_fin_items = accountNumeric('pl_prof_loss_after_fin_items', accounts[index]);
    const pl_interest_exp_int  = accountNumeric('pl_interest_exp_int', accounts[index]);
    const pl_interest_exp_ext  = accountNumeric('pl_interest_exp_ext', accounts[index]);
    const pl_other_fin_costs = accountNumeric('pl_other_fin_costs', accounts[index]);
    
    const sum_fin_exp = -pl_interest_exp_int + -pl_interest_exp_ext + -pl_other_fin_costs;
    const isum = pl_prof_loss_after_fin_items + sum_fin_exp;
    if (pl_net_sales === 0) return 0;
    return round(isum / pl_net_sales * 100, 2);
  }, 'Vinstmarginal'),

  derive('kr_return_on_capital_percent', 'percent', (index, ...accounts) => {
    const bs_adjusted_equity = accountNumeric('bs_adjusted_equity', accounts[index]);
    const pl_prof_loss_after_fin_items = accountNumeric('pl_prof_loss_after_fin_items', accounts[index]);
    if (bs_adjusted_equity === 0) return 0;
    return round(pl_prof_loss_after_fin_items / bs_adjusted_equity * 100, 2);
  }, 'Avkastning på eget kapital'),

  derive('kr_return_on_cap_emp_percent', 'percent', (index, ...accounts) => {
    return get_kr_return_on_cap_emp_percent(index, ...accounts);
  }, 'Avkastning på totalt kapital'),

  // FIXME not correct even according to CS
  derive('kr_return_on_op_capital', 'percent', (index, ...accounts) => {
    // [((Summa omsättningstillgångar - Summa kortfristiga skulder) * 100) / (Nettoomsättning + Förändring av varulager + Aktiverat arbete för egen räkning + Övriga rörelseintäkter)]
    // / (Summa anläggningstillgångar + Summa omsättningstillgångar + Kundfordringar - Leverantörsskulder- Summa kortfristiga skulder)
    const bs_total_turnover_assets = accountNumeric('bs_total_turnover_assets', accounts[index]);
    const bs_tot_current_liabilities = accountNumeric('bs_tot_current_liabilities', accounts[index]);
    const pl_net_sales = accountNumeric('pl_net_sales', accounts[index]);
    const bs_total_inventories = accountNumeric('bs_total_inventories', accounts[index]);
    const pl_work_perf_own_use_capital = accountNumeric('pl_work_perf_own_use_capital', accounts[index]);
    const pl_other_opp_income = accountNumeric('pl_other_opp_income', accounts[index]);

    const bs_tot_fix_assets = accountNumeric('bs_tot_fix_assets', accounts[index]);
    const bs_accounts_receivable_trade = accountNumeric('bs_accounts_receivable_trade', accounts[index]);
    const bs_acc_pay_trade = accountNumeric('bs_acc_pay_trade', accounts[index]);

    const bs_total_inventories_prev  = accountNumeric('bs_total_inventories_prev', accounts[index + 1]);
    const bs_total_inventories_change = bs_total_inventories - bs_total_inventories_prev;

    const numerator_1 = (bs_total_turnover_assets - bs_tot_current_liabilities) * 100;
    const numerator_2 = (pl_net_sales + bs_total_inventories_change + pl_work_perf_own_use_capital + pl_other_opp_income);
    const numerator_final = numerator_1 - numerator_2;

    const denominator = bs_tot_fix_assets + bs_total_turnover_assets + bs_accounts_receivable_trade - bs_acc_pay_trade - bs_tot_current_liabilities;
    const result = numerator_final / denominator;
    return round(result * 100, 2);
  }, 'Avkastning operativt kapital'),

  // @see https://www.bjornlunden.se/bokf%C3%B6ring/r%C3%A4ntet%C3%A4ckningsgrad__205
  derive('kr_rate_of_return_times', 'percent', (index, ...accounts) => {
    const pl_operating_result  = accountNumeric('pl_operating_result', accounts[index]);
    const pl_fin_int_group_inc = accountNumeric('pl_fin_int_group_inc', accounts[index]);
    const pl_ext_interest_inc  = accountNumeric('pl_ext_interest_inc', accounts[index]);
    const pl_other_fin_inc = accountNumeric('pl_other_fin_inc', accounts[index]);
    const pl_interest_exp_int  = accountNumeric('pl_interest_exp_int', accounts[index]);
    const pl_interest_exp_ext  = accountNumeric('pl_interest_exp_ext', accounts[index]);
    const pl_other_fin_costs = accountNumeric('pl_other_fin_costs', accounts[index]);
    const numerator = pl_operating_result + pl_fin_int_group_inc + pl_ext_interest_inc + pl_other_fin_inc;
    const denominator = -pl_interest_exp_int + -pl_interest_exp_ext + -pl_other_fin_costs;
    if (denominator === 0) return 0;
    return round(numerator / denominator, 2);
  }, 'Räntetäckningsgrad'),

  derive('kr_balance_liquidity_percent', 'percent', (index, ...accounts) => {
    const bs_total_turnover_assets = accountNumeric('bs_total_turnover_assets', accounts[index]);
    const bs_tot_current_liabilities = accountNumeric('bs_tot_current_liabilities', accounts[index]);
    if (bs_tot_current_liabilities === 0) return 0;
    return round(bs_total_turnover_assets / bs_tot_current_liabilities * 100, 2);
  }, 'Balanslikviditet'),

  derive('kr_quick_ratio_percent', 'percent', (index, ...accounts) => {
    const bs_total_turnover_assets = accountNumeric('bs_total_turnover_assets', accounts[index]);
    const bs_total_inventories = accountNumeric('bs_total_inventories', accounts[index]);
    const bs_tot_current_liabilities = accountNumeric('bs_tot_current_liabilities', accounts[index]);
    if (bs_tot_current_liabilities === 0) return 0;
    return round((bs_total_turnover_assets - bs_total_inventories) / bs_tot_current_liabilities * 100, 2);
  }, 'Kassalikviditet'),

  derive('kr_working_capital', 'numeric', (index, ...accounts) => {
    const bs_total_turnover_assets = accountNumeric('bs_total_turnover_assets', accounts[index]);
    const bs_tot_current_liabilities = accountNumeric('bs_tot_current_liabilities', accounts[index]);
    return round(bs_total_turnover_assets - bs_tot_current_liabilities, 2);
  }, 'Rörelsekapital'),

  derive('kr_risk_buffer', 'percent', (index, ...accounts) => {
    // [(Rörelseresultat + Övriga finansiella intäkter+ Ränteintäkter koncern + Ränteintäkter externa +Resultat från andelar koncern-/intresseföretag) / Summa tillgångar)]
    // -
    // [(Räntekostnader externa + Räntekostnader koncern + Övriga finansiella kostnader)/ (Summa långfristiga skulder + Summa kortfristiga skulder + (Aktuell bolagsskatt för räkenskapsåret * Obeskattade reserver))]

    const pl_operating_result  = accountNumeric('pl_operating_result', accounts[index]);
    const pl_other_fin_inc = accountNumeric('pl_other_fin_inc', accounts[index]);
    const pl_fin_int_group_inc = accountNumeric('pl_fin_int_group_inc', accounts[index]);
    const pl_ext_interest_inc  = accountNumeric('pl_ext_interest_inc', accounts[index]);
    const pl_result_participation_group  = accountNumeric('pl_result_participation_group', accounts[index]);
    const bs_tot_assets  = accountNumeric('bs_tot_assets', accounts[index]);
    const pl_interest_exp_ext  = accountNumeric('pl_interest_exp_ext', accounts[index]);
    const pl_interest_exp_int  = accountNumeric('pl_interest_exp_int', accounts[index]);
    const pl_other_fin_costs = accountNumeric('pl_other_fin_costs', accounts[index]);
    const bs_tot_long_term_debts = accountNumeric('bs_tot_long_term_debts', accounts[index]);
    const bs_tot_current_liabilities = accountNumeric('bs_tot_current_liabilities', accounts[index]);
    const bs_untaxed_reserves  = accountNumeric('bs_untaxed_reserves', accounts[index]);

    const corporateTaxRate = accountCorporateTaxRate(accounts[index]);

    const income_1 = pl_operating_result + pl_other_fin_inc + pl_fin_int_group_inc + pl_ext_interest_inc + pl_result_participation_group;
    if (bs_tot_assets === 0) return 0;
    const income_final = income_1 / bs_tot_assets;

    const expense_1 = -pl_interest_exp_ext + -pl_interest_exp_int + -pl_other_fin_costs;
    const expense_2 = bs_tot_long_term_debts + bs_tot_current_liabilities + bs_untaxed_reserves * (corporateTaxRate / 100);
    if (expense_2 === 0) return 0;
    const expense_final = expense_1 / expense_2;

    return round((income_final - expense_final) * 100, 2);
  }, 'Riskbuffert totalt kapital'),

  derive('kr_avg_debt_eq_ratio_percent', 'percent', (index, ...accounts) => {
    return get_kr_avg_debt_eq_ratio_percent(index, ...accounts);
  }, 'Skuldränta'),

  // TODO about 99 % sure about this calculation
  derive('kr_degree_of_debt', 'numeric', (index, ...accounts) => {
    const bs_adjusted_equity = accountNumeric('bs_adjusted_equity', accounts[index]);
    const bs_untaxed_reserves  = accountNumeric('bs_untaxed_reserves', accounts[index]);
    const bs_tot_current_liabilities = accountNumeric('bs_tot_current_liabilities', accounts[index]);
    const bs_tot_long_term_debts = accountNumeric('bs_tot_long_term_debts', accounts[index]);
    if (bs_adjusted_equity === 0) return 0;
    const corporateTaxRate = accountCorporateTaxRate(accounts[index]);
    const bs_adjusted_debt = bs_tot_current_liabilities + bs_tot_long_term_debts + (bs_untaxed_reserves * ((corporateTaxRate ?? 0) / 100));
    return round(bs_adjusted_debt / bs_adjusted_equity, 2);
  }, 'Skuldsättningsgrad'),

  derive('pl_personal_costs_change', 'percent', calculateChange('pl_personal_costs', 'pl_personal_costs_change'), 'Förändring av personalkostnader'),

  derive('kr_cost_per_employee', 'numeric', (index, ...accounts) => {
    const pl_personal_costs  = accountNumeric('pl_personal_costs', accounts[index]);
    const n_no_employees = accountNumeric('n_no_employees', accounts[index]);
    if (n_no_employees === 0) return 0;
    return round((-pl_personal_costs / n_no_employees), 2);
  }, 'Personalkostnader per anställd'),

  derive('kr_inventory_turnover_times', 'numeric', (index, ...accounts) => {
    const { n_type_of_statement } = accounts[index] ?? {};
    const pl_raw_mat_and_cons  = accountNumeric('pl_raw_mat_and_cons', accounts[index]);
    const pl_goods_for_resale  = accountNumeric('pl_goods_for_resale', accounts[index]);
    const bs_total_inventories = accountNumeric('bs_total_inventories', accounts[index]);
    const pl_cost_of_sold_goods  = accountNumeric('pl_cost_of_sold_goods', accounts[index]);

    const prev_n_type_of_statement = accounts[index + 1]?.['n_type_of_statement'];
    const prev_bs_total_inventories = accountNumeric('bs_total_inventories', accounts[index + 1]);

    const denominator = (bs_total_inventories + prev_bs_total_inventories) / 2;
    if (denominator === 0) return 0;

    if (n_type_of_statement === 'K' && prev_n_type_of_statement === 'K') {
      const numerator = pl_raw_mat_and_cons + pl_goods_for_resale;
      return round(numerator / denominator, 2);
    }

    return round(-pl_cost_of_sold_goods / denominator, 2);
  }, 'Varulagrets omsättningshastighet'),

  derive('kr_capital_turnover_times', 'numeric', (index, ...accounts) => {
    const pl_net_sales = accountNumeric('pl_net_sales', accounts[index]);
    const bs_tot_assets  = accountNumeric('bs_tot_assets', accounts[index]);
    if (bs_tot_assets === 0) return 0;
    return round(pl_net_sales / bs_tot_assets, 2);
  }, 'Kapitalets omsättningshastighet'),

  derive('kr_accounts_receivable_turnover_percent', 'percent', (index, ...accounts) => {
    const pl_net_sales = accountNumeric('pl_net_sales', accounts[index]);
    const bs_accounts_receivable_trade = accountNumeric('bs_accounts_receivable_trade', accounts[index]);
    if (pl_net_sales === 0) return 0;
    return round(bs_accounts_receivable_trade / pl_net_sales * 100, 2);
  }, 'Kundfordringar/omsättning'),

  derive('kr_turnover_per_employee', 'numeric', (index, ...accounts) => {
    const pl_net_sales = accountNumeric('pl_net_sales', accounts[index]);
    const n_no_employees = accountNumeric('n_no_employees', accounts[index]);
    if (n_no_employees === 0) return 0;
    return round(pl_net_sales / n_no_employees, 2);
  }, 'Omsättning per anställd'),

  derive('kr_opp_result_number_of_employees', 'numeric', (index, ...accounts) => {
    const pl_operating_result  = accountNumeric('pl_operating_result', accounts[index]);
    const n_no_employees = accountNumeric('n_no_employees', accounts[index]);
    if (n_no_employees === 0) return 0;
    return round(pl_operating_result / n_no_employees, 2);
  }, 'Rörelseresultat per anställd'),

  derive('date_from', 'date', (index, ...accounts) => {
    const curr = accounts[index];
    const prev = accounts[index + 1];
    if (!prev) return curr.date_from;
    const { date_to } = prev;
    return moment(date_to).add(1, 'days').format('YYYY-MM-DD');
  }, 'Datum från'),

  derive('year_from', 'string', (index, ...accounts) => {
    const { date_from } = accounts[index];
    return moment(date_from).format('YYYY');
  }, 'År från'),

  derive('year_to', 'string', (index, ...accounts) => {
    const { date_to } = accounts[index];
    return moment(date_to).format('YYYY');
  }, 'År till'),

  // always the same as year_to
  derive('year', 'string', (index, ...accounts) => {
    const { date_to } = accounts[index];
    return moment(date_to).format('YYYY');
  }, 'År'),

  derive('n_months', 'integer', (index, ...accounts) => {
    const { date_to, date_from } = accounts[index];
    if (!date_to || !date_from) return accounts[index].n_months;
    return getNumberOfMonths(date_from as string, date_to as string);
  }, 'Antal månader'),

  derive('accounts_equation', 'integer', (index, ...accounts) => {
    const bs_tot_equity_and_liab = accountNumeric('bs_tot_equity_and_liab', accounts[index]);
    const bs_tot_assets  = accountNumeric('bs_tot_assets', accounts[index]);
    return bs_tot_equity_and_liab - bs_tot_assets;
  }, 'Differens'),

];

export const derivedTableRowByKey = keyBy<IAccountsTableDerivedRowDefinition>(derivedTableRowDefinitionsList, 'key');

function accountNumeric (key: keyof IAccounts, account: IAccounts | undefined): number {
  if (typeof account === 'undefined') return 0;
  const value = account[key];
  if (typeof value !== 'number' || !isFinite(value) || isNaN(value)) return 0;
  return value;
}

function accountCorporateTaxRate (account: IAccounts | undefined): number {
  const value = account?.['corporate_tax_rate'];
  if (typeof value === 'string') return parseFloat(value) || 0;
  return 0;
}

function getNumberOfMonths (dateFrom: string | Date, dateTo: string | Date): number {
  return Math.ceil(moment(dateTo).add(1, 'days').diff(moment(dateFrom), 'months'));
}

function calculateChange (key: string, resultKey: string): TAccountsTableDerivedRowCalculateFn {
  return (index, ...accounts): number => {
    const curr = accounts[index];
    const prev = accounts[index + 1];
    const currUncomputedValue = accountNumeric(resultKey, curr);
    if (!prev) return currUncomputedValue;
    const currValue = accountNumeric(key, curr);
    const prevValue = accountNumeric(key, prev);
    if (prevValue === 0) return currUncomputedValue;
    return ((currValue / prevValue) - 1) * 100;
  };
}

function calculateSum (...keys: string[]): TAccountsTableDerivedRowCalculateFn {
  return (index, ...accounts): number => {
    let sum = 0;
    for (const key of keys) {
      sum += accountNumeric(key, accounts[index]);
    }
    return sum;
  };
}

// clones an account into a new year, implicitly a continuation of the same length
// as the previous year
export function copyToNewYear (account: IAccounts): IAccounts {
  const copy = cloneDeep(account);
  return setAccountDatesToYearAfter(copy, copy);
}

export function emptyYear (dateFromStr: string): IAccounts {
  const dateFrom = moment(dateFromStr, 'YYYY-MM-DD');
  const dateTo = dateFrom.clone().month(11).date(31);

  const zeroes: Partial<IAccounts> = defaultTableRowDefinitionsList.reduce((account: any, row) => {
    account[row.key] = 0;
    return account;
  }, {});

  return {
    ...zeroes,
    date_from: dateFrom.clone().format('YYYY-MM-DD'),
    date_to: dateTo.clone().format('YYYY-MM-DD'),
    year: dateTo.clone().format('YYYY'),
    year_from: dateFrom.clone().format('YYYY'),
    year_to: dateTo.clone().format('YYYY'),
    n_months: getNumberOfMonths(dateFrom.toDate(), dateTo.toDate()),
  };
}

export function setAccountDatesToYearAfter (account: IAccounts, prevAccount: IAccounts): IAccounts {
  const from = moment(prevAccount.date_to).add(1, 'day');
  const to = moment(prevAccount.date_to).add(1, 'year');
  if ('year' in prevAccount) account.year = to.format('YYYY');
  if ('year_from' in prevAccount) account.year_from = from.format('YYYY');
  if ('year_to' in prevAccount) account.year_to = to.format('YYYY');
  account.date_to = to.format('YYYY-MM-DD');
  account.date_from = from.format('YYYY-MM-DD');
  account.n_months = getNumberOfMonths(from.toDate(), to.toDate());
  return account;
}

export function accountsAreEqual (a?: IAccounts, b?: IAccounts): boolean {
  if (!a || !b) return false;
  return a.year === b.year;
}

// TODO should we really take a list of rows and not always claculate all account rows always?
export function computeAccountValues (rows: IAccountsTableRowDefinition[], accounts: IAccounts[]): IAccounts[] {

  const correct = cloneDeep(accounts);

  const result = immer.produce<IAccounts[]>(accounts, accountsDraft => {
    // we run the calculations in reverse order because some values might carry forward
    // over the accounting years, but it is of course impossible for values to carry backward
    // reversing the order here means that the eldest accounts are calculated first
    const columns = Object.keys(accountsDraft).reverse().map(str => parseInt(str, 0));

    // first, sum up balance sheet and profit/loss sheet
    for (const row of rows) {
      const { key, derived, calculate } = row as IAccountsTableDerivedRowDefinition;
      const isProfitLossKey = key.startsWith('pl_');
      const isBalanceSheetKey = key.startsWith('bs_');
      if (derived && (isProfitLossKey || isBalanceSheetKey)) {
        for (const accountIndex of columns) {
          const account = accountsDraft[accountIndex];
          account[key] = calculate(accountIndex, ...accountsDraft);
        }
      }
    }

    // calculate all non balance sheet/profit loss sheet rows
    for (const row of rows) {
      const { key, derived, calculate } = row as IAccountsTableDerivedRowDefinition;
      const isOtherKey = !key.startsWith('pl_') && !key.startsWith('bs_');
      if (derived && isOtherKey) {
        for (const accountIndex of columns) {
          const account = accountsDraft[accountIndex];
          account[key] = calculate(accountIndex, ...accountsDraft);
        }
      }
    }

  });

  verifyDivergentAccountSums(correct, result);

  return result;
}

function verifyDivergentAccountSums (correctAccounts: IAccounts[], accounts: IAccounts[]) {

  for (const index in correctAccounts) {
    const year = correctAccounts[index].year; // eslint-disable-line
    const correctAccount = correctAccounts[index];
    const account = accounts[index];
    for (const key in correctAccount) {
      if (!key.startsWith('pl_') && !key.startsWith('bs_')) {
        continue;
      }
      const value = account[key as unknown as any];
      const correct = correctAccount[key as unknown as any];
      const diff = Math.abs((parseFloat(String(correct)) || 0) - (parseFloat(String(value)) || 0));
      if (value !== correct && diff > 1) {
        console.warn(`${year}/${key} divergent: was ${value} expected ${correct} diff ${diff}`); // eslint-disable-line
      }
    }

    const { bs_tot_equity_and_liab, bs_tot_assets } = account;
    const diff = ((bs_tot_equity_and_liab || 0) as number) - ((bs_tot_assets || 0) as number);
    if (diff > 1) {
      console.warn(`${year} invalid account equation: equity + liabilities = ${bs_tot_equity_and_liab} and bs_tot_assets = ${bs_tot_assets} diff ${diff}`); // eslint-disable-line
    }
  }
}

// not sure if needed
export function accountDatesToYearRange (fromStr: string, toStr: string): string[] {
  const fromDate = moment(fromStr, 'YYYY-MM-DD');
  const toDate = moment(toStr, 'YYYY-MM-DD');
  const yearRange = Math.ceil(toDate.diff(fromDate, 'year'));
  return range(yearRange + 1).map(index => {
    return fromDate.clone().add(index, 'years').format('YYYY');
  });
}

export function calculateCompanyValue (factor: number, corporateTaxRate: number, bsTotEquity: number, bsUntaxedReserves: number): number {
  const bsAdjustedEquity = bsTotEquity + bsUntaxedReserves * (1 - corporateTaxRate / 100);
  return Math.max(0, Math.round(factor * bsAdjustedEquity / 100) * 100) * 1000;
}

export function accountToNormalizedReturnOnCapitalPercent (account: ICsAccounts): number {
  const { kr_return_on_capital_percent:value, bs_tot_equity } = account;
  if (value === null) return 0;
  const percentage = typeof value === 'number' ? value : parseFloat(value);
  return bs_tot_equity === null || bs_tot_equity <= 0 ? 0 : percentage;
}

export function calculateValueFactor (krReturnOnCapitalPercent: number[]): number {
  const count = Math.min(5, krReturnOnCapitalPercent.length);

  let koef;
  if (count === 5) {
    koef = [3.1, 1.25, 0.4, 0.15, 0.10];
  } else if (count === 4) {
    koef = [3.1, 1.35, 0.4, 0.15];
  } else if (count === 3) {
    koef = [2, 0.6, 0.4];
  } else if (count === 2) {
    koef = [1.5, 0.5];
  } else if (count === 1) {
    koef = [1];
  } else {
    return 0;
  }

  let val = 0;
  for (const index in krReturnOnCapitalPercent) {
    const percentage = krReturnOnCapitalPercent[index];
    val += percentage > 0 ? percentage * koef[index] : 0;
  }

  val = round(val / count, 2);
  if (val < 0.2) {
    return 1;
  }

  const result = 1 + Math.floor(val / 0.2) * 0.009;
  return result;
}

const defaults = defaultTableRowByKey;
const derived = derivedTableRowByKey;

export const allAccountRows: TAccountsTableRowDefinition[] = [
  derived.date_from,
  defaults.date_to,
  derived.year_from,
  derived.year_to,
  derived.year,
  derived.n_months,

  header('Intäkter'),
  defaults.pl_net_sales,
  defaults.pl_change_invent_work_prog,
  defaults.pl_work_perf_own_use_capital,
  defaults.pl_other_opp_income,
  derived.pl_net_operating_income,

  header('Kostnader'),
  defaults.pl_cost_of_sold_goods,
  defaults.pl_gross_profit_loss,
  defaults.pl_raw_mat_and_cons,
  defaults.pl_goods_for_resale,
  defaults.pl_other_ext_costs,
  defaults.pl_personal_costs,
  defaults.pl_depr_and_write_downs,
  defaults.pl_selling_expenses,
  defaults.pl_admin_costs,
  defaults.pl_research_and_dev_costs,
  defaults.pl_item_affect_compare,
  defaults.pl_other_opp_expenses,
  derived.pl_tot_opp_exp,
  derived.pl_operating_result,

  header('Resultat'),
  defaults.pl_result_participation_group,
  defaults.pl_ext_interest_inc,
  defaults.pl_fin_int_group_inc,
  defaults.pl_other_fin_inc,
  defaults.pl_interest_exp_ext,
  defaults.pl_interest_exp_int,
  defaults.pl_other_fin_costs,
  derived.pl_prof_loss_after_fin_items,
  defaults.pl_extraordinary_inc,
  defaults.pl_extraordinary_exp,
  defaults.pl_group_contrib,
  defaults.pl_shareholder_contrib,
  defaults.pl_other_appropriations,
  defaults.pl_tax,
  defaults.pl_minority_intrest_and_prof,
  derived.pl_net_profit_loss,

  header('Tillgångar'),
  defaults.bs_subscribed_capital_unpaid,

  header('Anläggningstillgångar'),
  defaults.bs_capt_exp_for_res_and_dev,
  defaults.bs_patents,
  defaults.bs_goodwill,
  defaults.bs_other_intangible_ass,
  derived.bs_total_intangible_assets,
  defaults.bs_land_build,
  defaults.bs_plant_machine,
  defaults.bs_equip_tools_fix_and_fit,
  defaults.bs_other_mat_depreciation,
  defaults.bs_other_tang_ass_non_dep,
  derived.bs_total_tangible_assets,
  defaults.bs_group_share,
  defaults.bs_acc_recieve_corp_group,
  defaults.bs_loan_co_owners,
  defaults.bs_other_fin_assets,
  derived.bs_total_financial_assets,
  derived.bs_tot_fix_assets,

  header('Omsättningstillgångar'),
  defaults.bs_work_on_contract,
  defaults.bs_inventories,
  derived.bs_total_inventories,
  defaults.bs_accounts_receivable_trade,
  defaults.bs_acc_recieve_group,
  defaults.bs_other_acc_recieve,
  derived.bs_total_accounts_receivable,
  defaults.bs_short_investments,
  defaults.bs_cash_and_bank_balances,
  defaults.bs_other_cur_assets,
  derived.bs_total_turnover_assets,

  spacer,
  derived.bs_tot_assets,

  header('Eget kapital'),
  defaults.bs_issued_share_capital,
  defaults.bs_share_premium_reserve,
  defaults.bs_revaluation_reserve,
  defaults.bs_other_stockholder_equity,
  // derived.bs_profit_loss_brought_fwd,
  defaults.bs_profit_loss_brought_fwd,
  defaults.bs_group_contribution,
  defaults.bs_shareholder_contribution,
  derived.bs_net_profit_loss,
  defaults.bs_minority_interests,
  derived.bs_tot_equity,

  header('Långfristiga skulder'),
  defaults.bs_lt_bond_loan,
  defaults.bs_lt_liab_to_credit_instit,
  defaults.bs_lt_liab_group_ass_comp,
  defaults.bs_other_long_term_liab,
  derived.bs_tot_long_term_debts,

  header('Kortfristiga skulder'),
  defaults.bs_liab_credit_inst,
  defaults.bs_acc_pay_trade,
  defaults.bs_st_liab_group_ass_comp,
  defaults.bs_other_short_term_liab,
  derived.bs_tot_current_liabilities,

  spacer,
  derived.bs_tot_liabilities,

  spacer,
  defaults.bs_provisions,
  defaults.bs_untaxed_reserves,
  defaults.corporate_tax_rate,
  derived.bs_adjusted_equity,
  derived.bs_tot_equity_and_liab,

  header('Skulder + Eget kapital = Tillgångar'),
  derived.accounts_equation,

  header('Lönsamhetsmått'),
  derived.kr_net_margin_percent,
  derived.kr_gross_profit_margin_percent,
  derived.kr_operating_margin_percent,
  derived.kr_profit_margin_percent,
  derived.kr_risk_buffer,

  header('Avkastningsmått'),
  derived.kr_return_on_capital_percent,
  derived.kr_return_on_cap_emp_percent,
  derived.kr_rate_of_return_times,
  defaults.kr_return_on_op_capital, // calculation not correct
  derived.kr_quick_ratio_percent,
  derived.kr_balance_liquidity_percent,
  derived.kr_working_capital,

  header('Långsiktig betalningsförmåga'),
  derived.kr_solidity_percent,
  derived.kr_avg_debt_eq_ratio_percent,
  derived.kr_degree_of_debt,

  header('Verksamhetsmått'),
  derived.kr_inventory_turnover_times,
  derived.kr_capital_turnover_times,
  derived.kr_turnover_per_employee,
  derived.kr_accounts_receivable_turnover_percent,
  derived.kr_change_in_turnover_percent,

  header('Övriga mått'),
  derived.kr_cost_per_employee,
  derived.kr_ebitda,
  derived.kr_opp_result_number_of_employees,
  derived.kr_change_in_number_of_employees,
  defaults.bs_total_dividens_capacity,

  header('Noter'),
  defaults.n_floating_charge,
  defaults.n_real_estate_mortgage,
  defaults.n_other_collateral,
  defaults.n_tot_collateral,
  defaults.n_conditional_equity,
  defaults.n_other_contingent_liab,
  defaults.n_tot_contingent_liab,
  defaults.n_salary_board,
  defaults.n_tantiem_board,
  defaults.n_payroll_overhead,
  defaults.n_dividend,
  defaults.n_accepted_bank_overdraft,
  defaults.n_not_used_bank_overdraft,
  defaults.n_no_employees,

  // The commented rows here are account cells that exist in the raw data but they
  // were never placed anywhere in the old calculate system so we are not sure where
  // they should be put (if anywhere)
  //
  // header('Övriga noter'),
  // defaults.n_employee_salary,
  // defaults.n_performance_bonus_to_other_employees,
  // defaults.n_net_interest_finance,
  // defaults.n_depr_goods,
  // defaults.n_depr_sales,
  // defaults.n_depr_admin,
  // defaults.n_depr_rod,
  // defaults.n_depr_other,
  // defaults.n_depr_unspec,

  // header('Övriga nyckeltal'),
  // defaults.kr_ebitida_margin_percent,
  // defaults.kr_interest_coverage_ebitda,
  // defaults.kr_return_on_total_capital_ebitda_percent,
  // defaults.kr_return_on_working_capital_percent,
  // defaults.kr_degree_of_debt_interest_bearing,
  // defaults.kr_opp_profit_total_net_opp_income_percent,
  // defaults.kr_inventories_turnover_percent,
  // defaults.kr_liquid_assets_turnover_percent,
  // defaults.kr_current_liabilites_turnover_percent,
  // defaults.kr_risk_buffer_on_operation_capital_percent,
  // defaults.kr_du_pont_model_percent,

  // header('Övrig balansräkning'),
  // defaults.bs_machines_inventory,
  // defaults.bs_total_accounts_receivable,

];

function get_kr_avg_debt_eq_ratio_percent  (index: number, ...accounts: IAccounts[]): number {
  const pl_interest_exp_int = accountNumeric('pl_interest_exp_int', accounts[index]);
  const pl_interest_exp_ext = accountNumeric('pl_interest_exp_ext', accounts[index]);
  const bs_provisions = accountNumeric('bs_provisions', accounts[index]);
  const bs_untaxed_reserves = accountNumeric('bs_untaxed_reserves', accounts[index]);
  const bs_tot_current_liabilities = accountNumeric('bs_tot_current_liabilities', accounts[index]);
  const bs_tot_long_term_debts = accountNumeric('bs_tot_long_term_debts', accounts[index]);
  const corporate_tax_rate = accountCorporateTaxRate(accounts[index]);

  const sum_interest_exp = -pl_interest_exp_int + -pl_interest_exp_ext;
  const sum_liabilities = bs_tot_current_liabilities + bs_tot_long_term_debts;
  const denominator = bs_provisions + sum_liabilities + (bs_untaxed_reserves * (corporate_tax_rate / 100));
  if (denominator === 0) return 0;
  return round(sum_interest_exp / denominator * 100, 2);
}

function get_kr_return_on_cap_emp_percent (index: number, ...accounts: IAccounts[]): number {
  const bs_tot_assets = accountNumeric('bs_tot_assets', accounts[index]);
  const pl_prof_loss_after_fin_items = accountNumeric('pl_prof_loss_after_fin_items', accounts[index]);
  const pl_interest_exp_int = accountNumeric('pl_interest_exp_int', accounts[index]);
  const pl_interest_exp_ext = accountNumeric('pl_interest_exp_ext', accounts[index]);
  const pl_other_fin_costs = accountNumeric('pl_other_fin_costs', accounts[index]);

  const sum_fin_exp = -pl_interest_exp_int + -pl_interest_exp_ext + -pl_other_fin_costs;
  const isum = pl_prof_loss_after_fin_items + sum_fin_exp;
  if (bs_tot_assets === 0) return 0;
  return round(isum / bs_tot_assets * 100, 2);
}
