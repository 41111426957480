import React from 'react';
import { CompanyEventRow, CompanyEventTimelineBatchRow } from 'client/companyEvent/types';
import NumberFormat from 'client/components/NumberFormat';
import { isFiniteNumber } from 'client/utils/number';

interface CompanyEventValueChangeProps {
  value: Pick<CompanyEventRow, 'value_prev' | 'value_curr' | 'event_type'>;
}

export function CompanyEventValueChange (props: CompanyEventValueChangeProps) {
  const { value } = props;
  const { event_type:type, value_prev:prev, value_curr:curr } = value;
  if (prev === null || curr === null) return null;
  return (
    <span>
      <CompanyEventValue type={type} value={prev} />
      {' '}→{' '}
      <CompanyEventValue type={type} value={curr} />
      {' '}
      (
      <CompanyEventValueDiff
        prev={prev}
        curr={curr}
        type={type}
      />
      )
    </span>
  );
}


interface CompanyEventTextChangeProps {
  value: Pick<CompanyEventRow, 'text_prev' | 'text_curr' | 'event_type'>;
}

export function CompanyEventTextChange (props: CompanyEventTextChangeProps) {
  const { value } = props;
  const { text_prev:prev, text_curr:curr } = value;
  if (prev === null && curr === null) return null;
  return (
    <span>
      <CompanyEventText value={prev} />
      {' '}→{' '}
      <CompanyEventText value={curr} />
    </span>
  );
}

interface CompanyEventChangeProps {
  value: Pick<CompanyEventRow, 'text_prev' | 'text_curr' | 'value_prev' | 'value_curr' | 'event_type'>;
}

export function CompanyEventChange (props: CompanyEventChangeProps) {
  const { value } = props;
  const { event_type } = value;
  if (eventTypeToValueTypeMap[event_type] === 'text') {
    return <CompanyEventTextChange value={value} />;
  }
  return <CompanyEventValueChange value={value} />;
}

interface CompanyEventTextProps {
  value: CompanyEventRow['text_curr'] | CompanyEventRow['text_prev'] ;
}

export function CompanyEventText (props: CompanyEventTextProps) {
  const { value } = props;
  if (value === null) return <span>(Inget värde)</span>;
  return (
    <span>"{value}"</span>
  );
}

interface CompanyEventCurrProps {
  value: Pick<CompanyEventRow, 'value_curr' | 'text_curr' | 'event_type'>;
}

export function CompanyEventCurr (props: CompanyEventCurrProps) {
  const { value } = props;
  const { event_type:type, value_curr, text_curr } = value;
  if (['int', 'percentage'].includes(eventTypeToValueTypeMap[type])) {
    return <CompanyEventValue value={value_curr} type={type} />;
  }
  return <CompanyEventText value={text_curr} />;
}

interface CompanyEventPrevProps {
  value: Pick<CompanyEventRow, 'value_prev' | 'text_prev' | 'event_type'>;
}

export function CompanyEventPrev (props: CompanyEventPrevProps) {
  const { value } = props;
  const { event_type:type, value_prev, text_prev } = value;
  if (['int', 'percentage'].includes(eventTypeToValueTypeMap[type])) {
    return <CompanyEventValue value={value_prev} type={type} />;
  }
  return <CompanyEventText value={text_prev} />;
}

interface CompanyEventValueProps {
  value: CompanyEventRow['value_curr'] | CompanyEventRow['value_prev'] ;
  type: CompanyEventRow['event_type'];
}

export function CompanyEventValue (props: CompanyEventValueProps) {
  const { value:valueAsString, type } = props;
  const value = valueAsString === null ? NaN : parseFloat(valueAsString);
  if (!isFiniteNumber(value)) return <span>(Inget värde)</span>;

  const formatType = eventTypeToValueTypeMap[type] ?? 'int';
  switch (formatType) {
    default: return <span>{value}</span>;
    case 'int':
      return <NumberFormat format="number" value={value} />;
    case 'percentage':
      return <NumberFormat format="percentage" value={value} />;
    case 'risk_check':
      return <CompanyEventRiskCheckValue value={value} />;
    case 'risk_check_auditor':
      return <CompanyEventRiskCheckAuditorValue value={value} />;
  }
}

interface CompanyEventValueDiffProps {
  prev: CompanyEventRow['value_prev'] ;
  curr: CompanyEventRow['value_curr']
  type: CompanyEventRow['event_type'];
}

export function CompanyEventValueDiff (props: CompanyEventValueDiffProps) {
  const { prev:prevAsString, curr:currAsString, type } = props;
  if (eventTypeToValueTypeMap[type] !== 'int') return null;
  const prev = prevAsString === null ? NaN : parseFloat(prevAsString);
  const curr = currAsString === null ? NaN : parseFloat(currAsString);
  if (!isFiniteNumber(prev) || !isFiniteNumber(curr)) return null;
  const diff = curr - prev;
  return (
    <span>
      {diff > 0 ? '+' : ''}
      <CompanyEventValue type={type} value={String(diff)} />
    </span>
  );
}

interface CompanyEventRiskCheckValueProps {
  value: number;
}

export function CompanyEventRiskCheckValue (props: CompanyEventRiskCheckValueProps) {
  const { value } = props;
  switch (value) {
    default: return <span>{value}</span>;
    case 1: return <span>Ej ändrats inom 6 månader</span>;
    case 2: return <span>Ändrats inom 3-6 månader</span>;
    case 3: return <span>Ändrats inom 3 månader</span>;
  }
}

interface CompanyEventRiskCheckAuditorValueProps {
  value: number;
}

export function CompanyEventRiskCheckAuditorValue (props: CompanyEventRiskCheckAuditorValueProps) {
  const { value } = props;
  switch (value) {
    default: return <span>{value}</span>;
    case 1: return <span>Har revisor</span>;
    case 2: return <span>Förbehåll om att inte ha revisor</span>;
    case 3: return <span>Revisor har lämnat på egen begäran</span>;
  }
}

type ValueFormatType = 'int' | 'percentage' | 'risk_check' | 'text' | 'risk_check_auditor';

const eventTypeToValueTypeMap: Record<CompanyEventRow['event_type'], ValueFormatType> = {
  credit_report_rating: 'int',
  credit_report_kf_debt: 'int',
  credit_report_kf_debt_nr_amal: 'int',
  credit_report_kf_debt_nr_emal: 'int',
  credit_report_kf_debt_sum_amal: 'int',
  credit_report_kf_debt_sum_emal: 'int',
  credit_report_antal_anm: 'int',
  credit_report_antal_anm_amal1: 'int',
  credit_report_antal_anm_amal: 'int',
  credit_report_antal_anm_emal1: 'int',
  credit_report_antal_anm_emal: 'int',
  credit_report_sum_anm: 'int',
  credit_report_sum_anm_amal: 'int',
  credit_report_sum_anm_emal: 'int',
  credit_report_sum_ansokan: 'int',
  credit_report_antal_ansokan: 'int',
  credit_report_limit: 'int',
  credit_report_rating_history_rating: 'int',
  credit_report_rating_history_text: 'text',
  credit_report_risk_prognosis: 'percentage',
  credit_report_risk_check_activity: 'risk_check',
  credit_report_risk_check_address: 'risk_check',
  credit_report_risk_check_auditor: 'risk_check_auditor',
  credit_report_risk_check_board: 'risk_check',
  credit_report_risk_check_name: 'risk_check',
  credit_report_kf_debt_date: 'text',
  credit_report_rating_text: 'text',
  credit_report_company_status: 'text',
  credit_report_company_status_code: 'text',
  credit_report_company_status_date: 'text',
  credit_report_company_name: 'text',
  credit_report_bankruptcy_date: 'text',
  credit_report_bankruptcy_text: 'text',
  credit_report_bankruptcy_type: 'text',
  credit_report_moms: 'text',
  credit_report_moms_nr: 'text',
  credit_report_moms_start_date: 'text',
  credit_report_ftax_deregistration_code: 'text',
  credit_report_ftax_deregistration_reason_text: 'text',
  credit_report_ftax_end_date: 'text',
  credit_report_ftax_start_date: 'text',
  credit_report_directors_changed: 'text',
  credit_report_directors_added: 'text',
  credit_report_directors_removed: 'text',
  credit_report_commentary_changed: 'text',
  credit_report_commentary_added: 'text',
  credit_report_commentary_removed: 'text',
};

// sigh... any changes here have to be synced with
// server/app/View/Components/CompanyEvent/CompanyEventBatchList.php eventTypeToNaturalMap
export const eventTypeToNaturalMap: Record<CompanyEventRow['event_type'], string> = {
  credit_report_rating: 'Rating',
  credit_report_kf_debt: 'Total summa hos KFM',
  credit_report_kf_debt_nr_amal: 'Antal skuldsaldo A-mål',
  credit_report_kf_debt_nr_emal: 'Antal skuldsaldo E-mål',
  credit_report_kf_debt_sum_amal: 'Summa skuldsaldo A-mål',
  credit_report_kf_debt_sum_emal: 'Summa skuldsaldo E-mål',
  credit_report_antal_anm: 'Antal betalningsanmärkningar',
  credit_report_antal_anm_amal1: 'Antal A-mål senaste året',
  credit_report_antal_anm_amal: 'Antal A-mål',
  credit_report_antal_anm_emal1: 'Antal E-mål senaste året',
  credit_report_antal_anm_emal: 'Antal E-mål',
  credit_report_sum_anm: 'Summa betalningsanmärkningar',
  credit_report_sum_anm_amal: 'Summa A-mål',
  credit_report_sum_anm_emal: 'Summa E-mål',
  credit_report_sum_ansokan: 'Summa ansökningar b.f.',
  credit_report_antal_ansokan: 'Antal ansökningar b.f.',
  credit_report_limit: 'Limit',
  credit_report_rating_history_rating: 'Ratinghistorik',
  credit_report_rating_history_text: 'Ratingbeskrivningshistorik',
  credit_report_risk_prognosis: 'Riskprognos',
  credit_report_risk_check_activity: 'Aktivitetstext ändrad',
  credit_report_risk_check_address: 'Adress ändrad',
  credit_report_risk_check_auditor: 'Status på revisor',
  credit_report_risk_check_board: 'Styrelseförändring',
  credit_report_risk_check_name: 'Företagsnamn ändrat',
  credit_report_kf_debt_date: 'Datum för senast registrerade skuldsaldo',
  credit_report_rating_text: 'Ratingbeskrivning',
  credit_report_company_status: 'Statustext',
  credit_report_company_status_code: 'Statuskod',
  credit_report_company_status_date: 'Statusändring',
  credit_report_company_name: 'Företagsnamn',
  credit_report_bankruptcy_date: 'Konkursändring',
  credit_report_bankruptcy_text: 'Konkursstatus',
  credit_report_bankruptcy_type: 'Konkurskod',
  credit_report_moms: 'Registrerad för moms',
  credit_report_moms_nr: 'Momsnr',
  credit_report_moms_start_date: 'Startdatum moms',
  credit_report_ftax_deregistration_code: 'Avregistreringskod för F-skatt',
  credit_report_ftax_deregistration_reason_text: 'Avregistreringsorsak för F-skatt',
  credit_report_ftax_end_date: 'Slutdatum F-skatt',
  credit_report_ftax_start_date: 'Startdatum F-skatt',
  credit_report_directors_changed: 'Styrelseförändring',
  credit_report_directors_added: 'Styrelsetillträde',
  credit_report_directors_removed: 'Styrelseavgång',
  credit_report_commentary_changed: 'Kommentar ändrad',
  credit_report_commentary_added: 'Kommentar tillagd',
  credit_report_commentary_removed: 'Kommentar borttagen',
};

interface CompanyEventTypeProps {
  value: CompanyEventRow['event_type'];
  code?: CompanyEventRow['event_type_code'];
}

export function CompanyEventType (props: CompanyEventTypeProps) {
  const { value, code } = props;
  const natural = eventTypeToNaturalMap[value] ?? value;
  return <span>{natural}{code ? ` (${code})` : ''}</span>;
}

interface CompanyEventTimelineBatchStartProps {
  value: Pick<CompanyEventTimelineBatchRow, 'value_start' | 'text_start' | 'event_type'>;
}

export function CompanyEventTimelineBatchStart (props: CompanyEventTimelineBatchStartProps) {
  const { value } = props;
  const { event_type:type, value_start, text_start } = value;
  if (['int', 'percentage'].includes(eventTypeToValueTypeMap[type])) {
    return <CompanyEventValue value={value_start} type={type} />;
  }
  return <CompanyEventText value={text_start} />;
}

interface CompanyEventTimelineBatchEndProps {
  value: Pick<CompanyEventTimelineBatchRow, 'value_end' | 'text_end' | 'event_type'>;
}

export function CompanyEventTimelineBatchEnd (props: CompanyEventTimelineBatchEndProps) {
  const { value } = props;
  const { event_type:type, value_end, text_end } = value;
  if (['int', 'percentage'].includes(eventTypeToValueTypeMap[type])) {
    return <CompanyEventValue value={value_end} type={type} />;
  }
  return <CompanyEventText value={text_end} />;
}
