import React from 'react';
import LoadingButton, { LoadingButtonProps } from 'client/buttons/LoadingButton';
import { Save } from 'react-feather';

interface SaveButtonProps extends LoadingButtonProps {
  buttonLabel?: string;
}

const SaveButton: React.FC<SaveButtonProps> = React.memo(function SaveButton (props: SaveButtonProps) {
  const { buttonLabel = 'Spara', ...restOfProps } = props;
  return (
    <LoadingButton
      variant="primary"
      {...restOfProps}
    >
      <Save size={18} />
      {' '}{buttonLabel}
    </LoadingButton>
  );
});
export default SaveButton;
