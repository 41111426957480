import React from 'react';
import { Card, Tab, Nav } from 'react-bootstrap';
import { useParams, useHistory } from 'react-router-dom';
import { IValuationReportsGetReportQueueInfoData } from 'client/valuationReport/types';
import { IIndustryReportsGetReportQueueInfoData } from 'client/industryReport/types';
import axios from 'client/axios';
import { useMutation } from '@tanstack/react-query';
import * as requestCallbacks from 'client/utils/requestCallbacks';
import ReportQueueProperties from 'client/reportQueue/ReportQueueProperties';
import ConfirmDeleteButton from 'client/buttons/ConfirmDeleteButton';
import InspectObjectModalButton from 'client/buttons/InspectObjectModalButton';
import TabNavLink from 'client/tab/TabHelpers';
import ErrorAlert from 'client/components/ErrorAlert';
import ReportQueueLogTable from 'client/reportQueue/ReportQueueLogTable';

interface IReportQueueEditInnerPage {
  isRefetching: boolean;
  data: IValuationReportsGetReportQueueInfoData | IIndustryReportsGetReportQueueInfoData;
  Form: React.ReactElement;
}
const ReportQueueEditInnerPage: React.FC<IReportQueueEditInnerPage> = React.memo(function ReportQueueEditInnerPage (props: IReportQueueEditInnerPage) {
  const { data, isRefetching, Form } = props;
  const { tab = 'properties' } = useParams<{tab?: string}>();

  const history = useHistory();

  const deleteMutation = useMutation<void, Error>({
    mutationFn: () => axios.delete(`/api/report_queue/${data.reportQueue.id}`),
    onSuccess: () => {
      requestCallbacks.onSuccess('Den köade rapporten har raderats');
      history.push('/report_queue/ready_not_done');
    },
  });

  return (
    <div>
      <Tab.Container
        defaultActiveKey="ready_not_done"
        activeKey={tab}
        transition={false}
        mountOnEnter
        unmountOnExit
      >
        <Nav className="nav-tabs pt-3 px-3">
          <TabNavLink
            eventKey="properties"
            title="Egenskaper"
          />
          <TabNavLink
            eventKey="form"
            title="Formulär"
          />
          <TabNavLink
            eventKey="log"
            title="Logg"
          />
        </Nav>
        <Tab.Content>
          <Tab.Pane eventKey="properties" className="p-4">
            <ReportQueueProperties
              reportQueue={data.reportQueue}
              isRefetching={isRefetching}
            /> 
          </Tab.Pane>
          <Tab.Pane eventKey="form" className="p-4">
            {Form}
          </Tab.Pane>
          <Tab.Pane eventKey="log" className="p-4">
            <ReportQueueLogTable reportQueueId={data.reportQueue.id} />
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
      <Card.Footer className="p-3">
        <ErrorAlert error={deleteMutation.error} />
        <div className="d-flex gap-2">
          <InspectObjectModalButton object={data} size="lg" className="px-2" />
          <ConfirmDeleteButton
            onConfirmedDelete={deleteMutation.mutateAsync}
            confirmMessage="Är du säker på att du vill radera den köade rapporten?"
            buttonLabel="Radera köad rapport"
          />
        </div>
      </Card.Footer>
    </div>
  );
});
export default ReportQueueEditInnerPage;
