import React, { useCallback } from 'react';
import { Button, Form } from 'react-bootstrap';
import { preventDefaultSubmit } from 'client/utils/form';
import { CompanyMonitoredTableFilterParams } from 'client/companyMonitored/CompanyMonitoredTablePage';
import RefreshButton from 'client/buttons/RefreshButton';
import TableSettingsColumnOrderButton, { TableSettingsColumnOrderButtonProps } from 'client/table/TableSettingsColumnOrderButton';
import { TableFilterInputGroup } from 'client/table/TableUtils';

interface CompanyMonitoredTableFilterFormProps extends Pick<TableSettingsColumnOrderButtonProps, 'columnOrder' | 'setColumnOrder' | 'columnDefinitions'> {
  filterParams: CompanyMonitoredTableFilterParams;
  setFilterParams: React.Dispatch<React.SetStateAction<CompanyMonitoredTableFilterParams>>;
  onRefetch: () => void;
  onReset: () => void;
  isLoading: boolean;
}

export default function CompanyMonitoredTableFilterForm (props: CompanyMonitoredTableFilterFormProps) {
  const {
    isLoading,
    onRefetch,
    onReset,
    filterParams,
    setFilterParams,
    columnDefinitions,
    columnOrder,
    setColumnOrder,
  } = props;

  const onChange = useCallback((ev: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const { name, value } = ev.target;
    setFilterParams({[name]: value});
  }, [filterParams, setFilterParams]);

  return (
    <Form className="d-flex justify-content-between gap-3" onSubmit={preventDefaultSubmit}>
      <TableFilterInputGroup name="org_number" label="Företag" className="flex-grow-1">
        <Form.Control
          isValid={Boolean(filterParams.org_number)}
          name="org_number"
          onChange={onChange}
          placeholder="Oavsett organisationsnummer"
          value={filterParams.org_number ?? ''}
        />
      </TableFilterInputGroup>
      <TableFilterInputGroup name="portfolio_name" label="Portfölj" className="flex-grow-1">
        <Form.Control
          isValid={Boolean(filterParams.portfolio_name)}
          name="portfolio_name"
          onChange={onChange}
          placeholder="Oavsett portfölj"
          value={filterParams.portfolio_name ?? ''}
        />
      </TableFilterInputGroup>
      <div className="d-flex gap-3">
        <Button
          title="Ta bort alla filtreringar"
          onClick={onReset}
          variant="outline-primary"
          className="d-flex align-items-center justify-content-center"
          disabled={isLoading}
        >
          Återställ
        </Button>
        <TableSettingsColumnOrderButton
          columnDefinitions={columnDefinitions}
          columnOrder={columnOrder}
          setColumnOrder={setColumnOrder}
        />
        <RefreshButton onClick={onRefetch} disabled={isLoading} />
      </div>
    </Form>
  );
}

