import React from 'react';
import { Nav, Badge } from 'react-bootstrap';
import { NavLink, useParams, useRouteMatch, generatePath } from 'react-router-dom';

interface TabNavLinkProps {
  eventKey: string;
  title: React.ReactNode;
  count?: null | number;
  urlParamKey?: string;
  urlParamNullKeys?: string[];
}

// this component changes the url param "tab" from react-router-dom
function TabNavLink (props: TabNavLinkProps) {
  const { eventKey, title, count, urlParamKey = 'tab', urlParamNullKeys = [] } = props;
  const { path } = useRouteMatch();
  const params = useParams();
  const nullExtend = urlParamNullKeys.reduce<Record<string, null>>((map, key) => (map[key] = null, map), {});
  const to = generatePath(path, {...params, ...nullExtend, [urlParamKey]: eventKey});
  return (
    <Nav.Item>
      <Nav.Link as={NavLink} to={to}>
        {title}
        {typeof count === 'number' && count > 0 && (
          <Badge className="ms-1">{count}</Badge>
        )}
      </Nav.Link>
    </Nav.Item>
  );
}
export default React.memo(TabNavLink) as React.FC<TabNavLinkProps>;
