import React from 'react';
import { Trash2, XOctagon } from 'react-feather';
import { Link } from 'react-router-dom';
import ObjectId from 'client/components/ObjectId';

interface AnchorWithFlagProps extends React.PropsWithChildren {
  to: string;
  deleted?: boolean;
  inactivated?: boolean;
  disabled?: boolean;
}

export default function AnchorWithFlag (props: AnchorWithFlagProps) {
  const { children, to, deleted = false, disabled = false, inactivated = false } = props;
  return (
    <ObjectId>
      {deleted && (
        <span className="position-relative" style={{top: '-1px'}} title="Objektet är raderat">
          <Trash2 size={16} />
        </span>
      )}
      {inactivated && (
        <span className="position-relative" style={{top: '-1px'}} title="Objektet är inaktiverat">
          <XOctagon size={16} />
        </span>
      )}
      {disabled ? children : (
        <Link to={to} className="text-decoration-none">{children}</Link>
      )}
    </ObjectId>
  );
}
