import React from 'react';
import { IIndustryReport } from 'client/industryReport/types';
import HelperTooltip from 'client/components/HelperTooltip';
import NumberFormat from 'client/components/NumberFormat';
import styled from 'styled-components';
import { CompanyAnchor } from 'client/company/CompanyFormatters';

const NudgeUp = styled.span`
  position: relative;
  top: -1px;
`;

interface IndustryReportCompanyProps {
  report: IIndustryReport;
  inline?: boolean;
}

export const IndustryReportCompany: React.FC<IndustryReportCompanyProps> = (props: IndustryReportCompanyProps) => {
  const { report, inline = false } = props;
  const { report_name, report_org_number:orgNumber } = report;
  return (
    <>
      {report_name}
      {orgNumber && (
        <>
          {' '}
          {inline ? (
            <CompanyAnchor value={orgNumber} />
          ) : (
            <><br /><small><CompanyAnchor value={orgNumber} /></small></>
          )}
        </>
      )}
    </>
  );
};

interface IIndustryReportCompanyCounts {
  title?: string;
  value: IIndustryReport;
}
export function IndustryReportCompanyCounts (props: IIndustryReportCompanyCounts) {
  const { value:industryReport } = props;
  const { count_companies, count_companies_filtered } = industryReport;
  return (
    <>
      <span className="small border rounded px-1 d-inline-flex gap-1">
        <NumberFormat value={count_companies || 0} /> st
        <NudgeUp>
          <HelperTooltip>
            Antal företag i rapportens tillhörande SNI-koder under bokslutsåret
          </HelperTooltip>
        </NudgeUp>
      </span>{' '}
      <span className="small border rounded px-1 d-inline-flex gap-1">
        <NumberFormat value={count_companies_filtered || 0} /> st
        <NudgeUp>
          <HelperTooltip>
            Antal företag i rapportens tillhörande SNI-koder under bokslutsåret efter avdrag enligt kriterier
          </HelperTooltip>
        </NudgeUp>
      </span>
    </>
  );
}
