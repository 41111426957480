import React, { useState, useRef, useMemo, useCallback } from 'react';
import { times } from 'lodash';
import { usePdf } from '@mikecousins/react-pdf';
import HTMLFlipBook from 'react-pageflip';
import classNames from 'classnames';
import { makePdfWrapperManipulationHandlers } from 'client/pdf/utils';
import { Plus, Minus } from 'react-feather';
import { Placeholder } from 'react-bootstrap';
import useGlobalListener from '@restart/hooks/useGlobalListener';
import { ChevronLeft, ChevronRight } from 'react-feather';

type UsePdfReturnValues = ReturnType<typeof usePdf>;

interface PdfPageProps {
  onLoadSuccess?: (pdfDocument: UsePdfReturnValues['pdfDocument']) => unknown;
  zoom: number;
  url: string;
  page: number;
  onPageRenderSuccess: () => void;
}

const PdfPage = React.forwardRef<HTMLDivElement, PdfPageProps>(function PdfPage (props, ref) {
  const { onLoadSuccess, url, page, onPageRenderSuccess } = props;
  const canvasRef = useRef<HTMLCanvasElement>(null);

  usePdf({
    scale: 2, // hardcoded to prevent repaint on zoom
    file: url,
    page,
    canvasRef,
    onDocumentLoadSuccess: onLoadSuccess,
    onPageRenderSuccess,
  });

  return (
    <div ref={ref}>
      <div className="page-shadow" />
      <canvas ref={canvasRef} />
    </div>
  );
});

interface PdfViewProps {
  file: string;
}

export function PdfView (props: PdfViewProps) {
  const { file } = props;
  const [renderedCount, setRenderedCount] = useState(0);
  const instanceRef = useRef<any>();
  const canvasRef = useRef(null);
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const HTMLFlip = HTMLFlipBook as unknown as any;
  const [zoomValue, setZoomValue] = useState<number>(100);
  const pageFlip = instanceRef.current?.pageFlip();

  const isLoading = (totalPages > 0 && renderedCount < totalPages);

  const handleFlip = useCallback((event: { data: number }) => {
    setPage(event.data);
  }, [setPage]);

  const { callbacks, setZoom, setDrag } = useMemo(
    () => {
      if (!canvasRef.current) {
        return {
          setters: {},
          setZoom: () => null,
          setDrag: () => null,
        } as unknown as ReturnType<typeof makePdfWrapperManipulationHandlers>;
      }

      return makePdfWrapperManipulationHandlers(
        {
          innerEl: canvasRef.current,
          onChange: ({ zoom }) => setZoomValue(zoom),
        },
      );
    },
    [canvasRef.current],
  );

  useGlobalListener('keydown', (event) => {
    if (event.key === 'ArrowRight') {
      handleNext();
    } else if (event.key === 'ArrowLeft') {
      handlePrev();
    }
  });

  const resetTransforms = () => {
    setZoom(100);
    setDrag(0, 0);
  };

  const handleSetZoom = (value: number) => {
    if (value === 100) {
      resetTransforms();
      return;
    }
    setZoom(value);
  };

  const handleNext = () => {
    pageFlip.flipNext();
    resetTransforms();
  };
  const handlePrev = () => {
    pageFlip.flipPrev();
    resetTransforms();
  };

  const totalViews = useMemo(() => getTotalViews(totalPages), [totalPages]);
  const isFirstPage = page === 0;
  const isLastPage = page === totalPages - 1;

  return (
    <div
      className={classNames('w-100', {
        'first-page': isFirstPage,
        'last-page': isLastPage,
      })}
    >
      {isLoading && <LoadingPlaceholder />}
      {!isLoading && (
        <>
          <button
            className="clc-pdf-player-button nav-control nav-control-prev"
            onClick={handlePrev}
          >
            <ChevronLeft size={44} />
          </button>
          <button
            className="clc-pdf-player-button nav-control nav-control-next"
            onClick={handleNext}
          >
            <ChevronRight size={44} />
          </button>
        </>
      )}
      <div
        className={classNames('clc-pdf-player-canvas', {
          'opacity-0': isLoading,
          'on-zoom': zoomValue !== 100,
        })}
        ref={canvasRef}
        {...callbacks}
      >
        <HTMLFlip
          ref={instanceRef}
          className="book"
          width={560}
          height={790}
          flippingTime={450}
          showCover
          useMouseEvents={false}
          maxShadowOpacity={0.4}
          showPageCorners={false}
          clickEventForward={true}
          onFlip={handleFlip}
        >
          {times(totalPages, (index) => {
            return (
              <PdfPage
                page={index + 1}
                key={index}
                url={file}
                zoom={zoomValue}
                onPageRenderSuccess={() => {
                  setRenderedCount(v => v + 1);
                }}
                onLoadSuccess={(pdfDocument) => {
                  if (index === 0) {
                    setTotalPages(pdfDocument?._pdfInfo.numPages);
                  }
                }}
              />
            );
          })}
        </HTMLFlip>
      </div>
      <div className={classNames('pdf-viewer-hud', { active: !isLoading })}>
        <input
          className="navigation-slider"
          type="range"
          value={getViewForPage(page + 1)}
          min={1}
          max={totalViews}
          step={1}
          onKeyDown={e => e.preventDefault()}
          onChange={e => {
            const view = parseInt(e.target.value, 10);
            const leftPage = getLeftPageForView(view);
            let newPage = leftPage;
            if (view === totalViews || page >= 1) {
              newPage -= 1;
            }
            pageFlip.flip(newPage);
          }}
        />
        <div className="pdf-viewer-controls">
          <div className="d-flex align-items-center ms-2 px-0 py-0">
            <div className="page-counter p-2 font-monospace">
              <CurrentPageIndicator total={totalPages} page={page} />
            </div>
          </div>
          <div className="d-flex align-items-center gap-2">
            <button
              className="clc-pdf-player-button zoom-button"
              onClick={() => {
                handleSetZoom(zoomValue - 50);
              }}
            >
              <Minus size={24} /> 
            </button>
            <input
              type="range"
              value={zoomValue}
              min={100}
              max={300}
              step={1}
              onKeyDown={e => e.preventDefault()}
              onChange={e => {
                handleSetZoom(parseInt(e.target.value, 10));
              }}
            />
            <button
              className="clc-pdf-player-button zoom-button"
              onClick={() => {
                handleSetZoom(zoomValue + 50);
              }}
            >
              <Plus size={24} /> 
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

function CurrentPageIndicator (props: { page: number; total: number; }) {
  const { page, total } = props;
  if (total <= 0) return null;
  if (page === total - 1) return <>{`${total} / ${total}`}</>;
  if (page === 0) return <>{`1 / ${total}`}</>;

  const naturalPage = page + 1;
  const startPage = naturalPage % 2 === 0 ? naturalPage : naturalPage - 1;
  const endPage = Math.min(startPage + 1, total);

  return <>{`${startPage} - ${endPage} / ${total}`}</>;
}

function getTotalViews (totalPages: number) {
  if (totalPages <= 0) return 0;
  if (totalPages === 1) return 1;

  const middlePages = totalPages - 2;
  const spread = Math.ceil(middlePages / 2);

  return 1 + spread + 1;
}

function getViewForPage (page: number) {
  if (page === 1) return 1;

  return Math.ceil((page - 1) / 2) + 1;
}

function getLeftPageForView (viewIndex: number) {
  if (viewIndex === 1) return 1;

  return (viewIndex - 1) * 2;
}

function LoadingPlaceholder () {
  return (
    <div className="loading-placeholder">
      <Placeholder as="h2" animation="glow" className="mb-3 pt-4 mt-5">
        <Placeholder xs={{span: 6, offset: 5}} />
      </Placeholder>
      <Placeholder as="p" animation="glow" className="mb-0">
        <Placeholder xs={{span: 4, offset: 5}} />
      </Placeholder>
      <Placeholder as="p" animation="glow">
        <Placeholder xs={{span: 5, offset: 5}} />
      </Placeholder>
      <Placeholder as="p" animation="glow" className="mt-5">
        <Placeholder xs={{span: 11, offset: 0}} />
      </Placeholder>
      <Placeholder as="p" animation="glow" className="">
        <Placeholder xs={{span: 8, offset: 0}} />
      </Placeholder>
      <Placeholder as="p" animation="glow" className="">
        <Placeholder xs={{span: 10, offset: 0}} />
      </Placeholder>
      <Placeholder as="p" animation="glow" className="">
        <Placeholder xs={{span: 9, offset: 0}} />
      </Placeholder>
      <Placeholder as="p" animation="glow" className="">
        <Placeholder xs={{span: 12, offset: 0}} />
      </Placeholder>
      <Placeholder as="p" animation="glow" className="">
        <Placeholder xs={{span: 8, offset: 0}} />
      </Placeholder>
      <Placeholder as="p" animation="glow" className="">
        <Placeholder xs={{span: 10, offset: 0}} />
      </Placeholder>
      <Placeholder as="p" animation="glow" className="">
        <Placeholder xs={{span: 9, offset: 0}} />
      </Placeholder>
      <Placeholder as="p" animation="glow" className="">
        <Placeholder xs={{span: 5, offset: 0}} />
      </Placeholder>
    </div>
  );
}
