import React, { useState, useCallback } from 'react';

type StoredValue = Record<string, any> | any[] | string | null;
type UseStorage<T> = [T, React.Dispatch<React.SetStateAction<T>>];

export function useSessionStorage<T extends StoredValue> (key: string, initialValue: T): UseStorage<T> {
  const [storedValue, setStoredValue] = useState<T>(() => {
    try {
      const item = window.sessionStorage.getItem(key);
      const parsed = item ? JSON.parse(item) : initialValue;
      return ensureSameShape(parsed, initialValue) ? parsed : initialValue;
    } catch (error) {
      console.error(error); // eslint-disable-line no-console
      return initialValue;
    }
  });

  const setValue = useCallback((valueOrFunc: React.SetStateAction<T>) => {
    setStoredValue((state) => {
      let valueToStore: T;
      if (typeof valueOrFunc === 'function') {
        const valueFn = valueOrFunc as ((setState: T) => T);
        valueToStore = valueFn(state);
      } else {
        valueToStore = valueOrFunc;
      }

      try {
        window.sessionStorage.setItem(key, JSON.stringify(valueToStore));
      } catch (error) {
        console.error(error); // eslint-disable-line no-console
      }

      return valueToStore;
    });
  }, [setStoredValue]);

  return [storedValue, setValue];
}

export function useLocalStorage<T extends StoredValue> (key: string, initialValue: T): UseStorage<T> {

  const [storedValue, setStoredValue] = useState<T>(() => {
    try {
      const item = window.localStorage.getItem(key);
      const parsed = item ? JSON.parse(item) : initialValue;
      return ensureSameShape(parsed, initialValue) ? parsed : initialValue;
    } catch (error) {
      console.error(error); // eslint-disable-line no-console
      return initialValue;
    }
  });

  const setValue = useCallback((valueOrFunc: React.SetStateAction<T>) => {
    setStoredValue((state) => {
      let valueToStore: T;
      if (typeof valueOrFunc === 'function') {
        const valueFn = valueOrFunc as ((setState: T) => T);
        valueToStore = valueFn(state);
      } else {
        valueToStore = valueOrFunc;
      }

      try {
        window.localStorage.setItem(key, JSON.stringify(valueToStore));
      } catch (error) {
        console.error(error); // eslint-disable-line no-console
      }

      return valueToStore;
    });
  }, [setStoredValue]);

  return [storedValue, setValue];
}

function ensureSameShape (a: StoredValue, b: StoredValue) {
  if (Array.isArray(a) && Array.isArray(b)) {
    return a.length === b.length;
  }
  return ensureHasAllKeysOf(a as any, b as any);
}

function ensureHasAllKeysOf (a: Record<string, any>, b: Record<string, any>): boolean {
  for (const key in b) {
    if (typeof a[key] === 'undefined') return false;
  }
  return true;
}
