import React from 'react';
import { getFormatter, NumberFormatType } from 'client/utils/number';

interface INumberFormat {
  value?: null | string | number;
  format?: NumberFormatType;
}

const NumberFormat: React.FC<INumberFormat> = React.memo(function NumberFormat (props: INumberFormat) {
  const { value, format = 'number' } = props;
  if (value === null || typeof value === 'undefined') return null;
  const number = typeof value === 'string' ? parseFloat(value) : value;
  const formatter = getFormatter(format);
  return (
    <>{formatter.format(number)}</>
  );
});
export default NumberFormat;
