import React, { useCallback } from 'react';
import * as immer from 'immer';
import * as AccountsTableCells from 'client/accounts/AccountsTableCells';
import { TAccountsTableRowDefinition, IAccounts } from 'client/accounts/types';

interface IAccountsTableRow {
  disabled?: boolean;
  row: TAccountsTableRowDefinition;
  accounts: IAccounts[];
  hideEmptyRows: boolean;
  rowFilter: string;
  onCommit?: (newAccounts: IAccounts[]) => void;
}

const AccountsTableRow: React.FC<IAccountsTableRow> = React.memo(function AccountsTableRow (props: IAccountsTableRow) {
  const {
    row,
    accounts,
    hideEmptyRows,
    rowFilter,
    onCommit:onCommitOuter,
    disabled,
  } = props;

  const onCommit = useCallback((accountIndex: number, key: string, value: (string | number)) => {
    const newAccounts = immer.produce<Record<string, any>[]>(accounts, draft => {
      if (!draft) return;
      if (!draft[accountIndex]) return;
      draft[accountIndex][key as keyof IAccounts] = value;
    });
    if (!newAccounts) return;
    if (onCommitOuter) {
      onCommitOuter(newAccounts as IAccounts[]);
    }
  }, [onCommitOuter, accounts]);

  if (rowFilter) {
    if (row.type === 'spacer') return null; // don't render spacers when filtering
    const rowFilterRegExp = new RegExp(`${rowFilter}`, 'gi');
    if (row.title && !rowFilterRegExp.test(row.title) && !rowFilterRegExp.test(row.key)) {
      return null;
    }
  }

  if (row.type === 'spacer') {
    return (
      <tr>
        <th></th>
        {accounts.map((account, index) => <td key={index}>&nbsp;</td>)}
      </tr>
    );
  }

  if (row.type === 'header') {
    return (
      <tr>
        <th className="text-end border-bottom pt-4">{row.title}</th>
        {accounts.map((account, index) => <td className="border-bottom" key={index}>&nbsp;</td>)}
      </tr>
    );
  }

  const initialValues = accounts.map(account => {
    if (row.type === 'date') return -1; // helps typescript.
    const valueKey = row.key.startsWith('_') ? row.key.slice(1) : row.key;
    const value = account[valueKey] as (string | number);
    return value;
  });

  if (hideEmptyRows && initialValues.every(val => val === 0 || val === null) && row.key !== 'accounts_equation') {
    return null;
  }

  return (
    <tr key={row.key} data-key={row.key}>
      <th className="text-end" style={{fontWeight: 'normal'}}>
        {row.title ?? ''} {row.derived && <span className="text-primary">*</span>}
      </th>
      {accounts.map((account, index) => (
        <td key={`${row.key}_${account.date_from}-${account.date_to}`}>
          {row.type === 'date' && (
            <AccountsTableCells.Date
              name={row.key}
              index={index}
              initialValue={account[row.key as keyof IAccounts] as string}
              disabled={disabled || row.derived || row.disabled}
              onCommit={onCommit}
            />
          )}
          {row.type === 'string' && (
            <AccountsTableCells.String
              name={row.key}
              index={index}
              initialValue={initialValues[index] as string}
              onCommit={onCommit}
              disabled={disabled || row.derived || row.disabled}
              derived={row.derived}
            />
          )}
          {['integer', 'numeric', 'percent'].includes(row.type) && (
            <AccountsTableCells.Numeric
              type={row.type as ('integer' | 'numeric' | 'percent')}
              name={row.key}
              index={index}
              initialValue={initialValues[index] as number}
              onCommit={onCommit}
              disabled={disabled || row.derived || row.disabled}
              derived={row.derived}
            />
          )}
        </td>
      ))}
    </tr>
  );
});
export default AccountsTableRow;
