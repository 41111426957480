import { useMemo, useCallback, useEffect } from 'react';
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import ErrorAlert from 'client/components/ErrorAlert';
import UserTableFilterForm from 'client/user/UserTableFilterForm';
import { Helmet } from 'react-helmet';
import { IUser, TUserRole } from 'client/user/types';
import PageHeader from 'client/components/PageHeader';
import useTableState, { OrderDirection } from 'client/hooks/useTableState';
import * as commonColumnDefinitions from 'client/table/commonColumnDefinitions';
import { TableCardFooterWithPagination, TableSpinningOverlay } from 'client/table/TableUtils';
import TableVirtual from 'client/table/TableVirtual';
import { ColumnDefinition } from 'client/table/types';
import DateFormat from 'client/components/DateFormat';
import * as UserFormatters from 'client/user/UserFormatters';
import useAuth from 'client/hooks/useAuth';
import AuthSwitchButton from 'client/buttons/AuthSwitchButton';
import { TableProvider } from 'client/contexts/TableContext';

export interface UserTableFilterParams {
  role?: TUserRole;
  search_term?: string;
  include_inactive?: boolean;
}

interface ListData {
  rows: IUser[];
  total_rows: number;
}

const defaultRows: ListData['rows'] = [];
const defaultFilterParams = {};
const defaultState = {
  orderBy: 'created_at',
  orderDirection: OrderDirection.ASC,
  ...defaultFilterParams,
};

export default function UserTablePage () {
  const {
    setStateMap,
    tablePagination,
    tableSort,
    filterParams,
    filterReset,
    params,
  } = useTableState({
    defaultState,
  });

  const listQuery = useQuery<ListData, Error>({
    queryKey: ['/api/users/list', params],
    placeholderData: keepPreviousData,
  });
  const rows = listQuery.data?.rows ?? defaultRows;

  useEffect(() => {
    tablePagination.onChangeTotalCountOfRows(listQuery.data?.total_rows ?? 0);
  }, [listQuery.data?.total_rows]);

  const onRefetch = useCallback(() => {
    listQuery.refetch();
  }, [listQuery.refetch]);

  const columnDefinitions = useMemo(columnDefinitionFactory, []);

  return (
    <TableProvider tableSort={tableSort}>
      <div className="container-fluid p-4">
        <Helmet>
          <title>Användare</title>
        </Helmet>
        <PageHeader>
          <span>
            Användare
          </span>
          <Link className="btn btn-outline-primary" to="/user/new">
            Ny användare
          </Link>
        </PageHeader>
        <ErrorAlert className="my-3" error={listQuery.error} />
        <Card>
          <Card.Header className="p-3">
            <UserTableFilterForm
              isLoading={listQuery.isLoading || listQuery.isRefetching}
              filterParams={filterParams}
              setFilterParams={setStateMap}
              onRefetch={onRefetch}
              onReset={filterReset}
            />
          </Card.Header>
          <TableSpinningOverlay isLoading={listQuery.isRefetching}>
            <div className="table-responsive">
              <TableVirtual
                className="mb-0 align-middle"
                rows={rows}
                columns={columnDefinitions}
                isInitialLoading={listQuery.isLoading}
              />
            </div>
          </TableSpinningOverlay>
          <TableCardFooterWithPagination
            tablePagination={tablePagination}
            rows={rows}
          />
        </Card>
      </div>
    </TableProvider>
  );
}

const defaultTableColumns = [
  'id',
  'name',
  'email',
  'role',
  'created_at',
  'last_login_at',
  'actions',
];

const columnSortable = [
  'id',
  'name',
  'email',
  'role',
  'created_at',
  'last_login_at',
];

type ColumnDefinitionFactory = () => ColumnDefinition<IUser>[];

const columnDefinitionFactory: ColumnDefinitionFactory = () => [
  commonColumnDefinitions.cell(['id', 'ID'], props => {
    const auth = useAuth();
    return (
      <UserFormatters.UserAnchor
        value={props.row.id}
        inactivated={Boolean(props.row.inactivated_at)}
        disabled={props.row.role === 'admin' && !auth.isUserRole('admin')}
      />
    );
  }),
  commonColumnDefinitions.simple(['name', 'Namn']),
  commonColumnDefinitions.cell(['email', 'E-postadress'], props => (
    <a href={`mailto:${props.row.email}`}>{props.row.email}</a>
  )),
  commonColumnDefinitions.cell(['role', 'Roll'], props => (
    <UserFormatters.UserRole value={props.row.role} />
  )),
  commonColumnDefinitions.cell(['created_at', 'Skapad'], props => (
    <DateFormat value={props.row.created_at} format="YYYY-MM-DD" />
  )),
  commonColumnDefinitions.cell(['last_login_at', 'Senaste inloggning'], props => (
    <DateFormat value={props.row.last_login_at} />
  )),
  commonColumnDefinitions.actions(props => (
    <>
      {props.row.role !== 'admin' && props.row.inactivated_at === null && (
        <div className="d-flex gap-1 flex-wrap align-items-center justify-content-end">
          <AuthSwitchButton
            className="py-0"
            objectType="user"
            objectId={props.row.id}
            size="sm"
            variant="outline-primary"
          />
        </div>
      )}
    </>
  )),
].map(obj => ({
  ...obj,
  show: defaultTableColumns.includes(obj.id),
})).map(obj => columnSortable.includes(obj.id) ? commonColumnDefinitions.sortable(obj) : obj);
