import React, { useMemo, useState, useId, useEffect } from 'react';
import axios from 'client/axios';
import { find, unzipWith, concat } from 'lodash';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { InputGroup, Spinner, Form } from 'react-bootstrap';
import { useDebounce } from 'use-debounce';
import { TChangeValue } from 'client/utils/form';
import { Search } from 'react-feather';

export interface ICompaniesSearchDTO {
  org_number: string;
  company_name: string;
  customer_id: string | null;
}

interface UseCompanySearchOptions {
  search: string | null;
  url?: string;
}

export const useCompanySearch = (options: UseCompanySearchOptions) => {
  const { search, url = '/api/companies/search' } = options;
  return useQuery<ICompaniesSearchDTO[]>({
    queryKey: [url, {term: search}],
    gcTime: Infinity,
    initialData: [],
    enabled: typeof search === 'string' && search.length > 0,
  });
};

interface IOrgNumberToCompanyName {
  value: string;
  children: (props: any) => any;
}
export const OrgNumberToCompanyName: React.FC<IOrgNumberToCompanyName> = React.memo(function OrgNumberToCompanyName (props: IOrgNumberToCompanyName) {
  const { value, children } = props;
  const queryClient = useQueryClient();
  const cachedData = queryClient.getQueriesData({ predicate: (query) => {
    return query.queryKey.includes('/api/companies/search');
  }});
  const companyNameFromCache = (find(unzipWith(cachedData, concat)[1], { org_number: value }) as ICompaniesSearchDTO)?.company_name;
  const { data, isFetching, isFetched } = useCompanySearch({ search: companyNameFromCache ? null : value });
  const name = companyNameFromCache || (isFetched && data && data[0]?.company_name);

  return children({
    value: name || value,
    isLoading: isFetching,
  });
});

type ICompanySearchResult = ICompaniesSearchDTO;

type TOrgNumberSearchResponse = ICompanySearchResult[];

interface IOrgNumberDatalistSearch {
  id?: string;
  size?: 'lg' | 'sm';
  className?: string;
  name: string;
  onChangeValue: TChangeValue<string>;
  defaultInputValue?: string;
}
export const OrgNumberDatalistSearch: React.FC<IOrgNumberDatalistSearch> = React.memo(function OrgNumberDatalistSearch (props: IOrgNumberDatalistSearch) {
  const { id, size, onChangeValue, className, defaultInputValue = '', name } = props;

  const [value, setValue] = useState<string>(defaultInputValue);
  const [chosenResult, setChosenResult] = useState<ICompanySearchResult | null>(null);

  const listId = useId();

  const [debouncedValue] = useDebounce(value, 200);

  const query = useQuery<TOrgNumberSearchResponse>({
    enabled: debouncedValue.length > 0 && chosenResult === null,
    queryKey: ['OrgNumberDatalistSearch', debouncedValue],
    queryFn: ({signal}) => {
      return axios.get('/api/companies/search', {
        signal,
        params: {term: debouncedValue},
      }).then(r => r.data);
    },
  });

  useEffect(() => {
    if (!query.data) return;
    // if the user pastes a complete number into the search input it does not trigger
    // the on change handler which normally handles this part. so we make sure to
    // set the chosen result when the search results come back, if possible
    const selected = query.data.find(c => c.org_number === debouncedValue);
    if (selected) {
      onChangeValue(name, selected.org_number);
      setChosenResult(selected);
    }
  }, [query.data]);

  const datalistItems = useMemo(() => {
    if (!value) return [];
    if (chosenResult) {
      return [chosenResult];
    }
    return query.data ?? [];
  }, [value, query, chosenResult]);

  const onChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = ev.currentTarget;
    setValue(value);
    const searchResults = query.data ?? [];
    const selected = searchResults.find(company => company.org_number === value);
    if (selected) {
      onChangeValue(name, selected.org_number);
      setChosenResult(selected);
    } else {
      onChangeValue(name, '');
      setChosenResult(null);
    }
  };

  const onPaste = (ev: React.ClipboardEvent<HTMLInputElement>) => {
    const pasted = ev.clipboardData.getData('text');
    if (/^[-\d\s]+$/.test(pasted)) {
      // if the users pastes a string of numbers and dashes, we remove all dashes
      ev.preventDefault();
      const filtered = pasted.replace(/\D/g, '');
      ev.currentTarget.value = filtered;
      onChange(ev as unknown as React.ChangeEvent<HTMLInputElement>);
    }
  };

  return (
    <>
      <InputGroup className={className} size={size}>
        <InputGroup.Text className="px-2">
          {(query.isFetching || query.isRefetching) ? (
            <Spinner size="sm" />
          ) : (
            <Search size={16} />
          )}
        </InputGroup.Text>
        <Form.Control
          id={id}
          name={name}
          value={value}
          list={listId}
          onChange={onChange}
          onPaste={onPaste}
        />
      </InputGroup>
      <datalist id={listId}>
        {datalistItems.map(company => (
          <option
            key={company.org_number}
            value={company.org_number}
          >
            {company.company_name}
          </option>
        ))}
      </datalist>
    </>
  );
});
