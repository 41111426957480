import React, { useMemo, useState, useEffect, useRef } from 'react';
import { useQuery } from '@tanstack/react-query';
import useMergedRefs from '@restart/hooks/useMergedRefs';
import ErrorAlert from 'client/components/ErrorAlert';
import BlockSpinner from 'client/spinners/BlockSpinner';
import { Card, Form, Dropdown } from 'react-bootstrap';
import { DropdownMenuProps } from 'react-bootstrap/esm/DropdownMenu';
import RefreshButton from 'client/buttons/RefreshButton';
import { ICsAccountsExtended } from 'client/cs/types';
import * as numberUtils from 'client/utils/number';
import * as Chartist from 'chartist';
import moment from 'moment';
import { allAccountRows } from 'client/accounts/utils';
import classNames from 'classnames';


import 'chartist/dist/index.scss';
import tooltip from 'bootstrap/js/dist/tooltip';

type FigureId = keyof ICsAccountsExtended;

// sample data
const figures: FigureId[] = [
  'bs_cash_and_bank_balances',
  'bs_other_short_term_liab',
  'bs_tot_assets',
  'bs_tot_equity_and_liab',
  'bs_total_dividens_capacity',
  'bs_untaxed_reserves',
  'kr_opp_result_number_of_employees',
  'kr_return_on_capital_percent',
  'kr_return_on_op_capital',
  'kr_solidity_percent',
  'kr_turnover_per_employee',
  'kr_working_capital',
  'pl_net_profit_loss',
  'pl_net_sales',
];

interface CompanyAccountChartCardProps {
  className?: string;
  orgNumber: string;
}

function CompanyAccountsChartCard (props: CompanyAccountChartCardProps) {
  const { className, orgNumber } = props;
  const [selectedKeyFigures, setSelectedKeyFigures] = useState<FigureId[]>([
    'pl_net_sales',
    'pl_net_profit_loss',
  ]);

  const readAccountsQuery = useQuery<ICsAccountsExtended[], Error>({
    queryKey: [`/api/companies/${orgNumber}/accounts`],
  });

  const data = readAccountsQuery.data ? accountsToChartData(readAccountsQuery.data, selectedKeyFigures) : null;

  return (
    <Card className={className}>
      <Card.Header className="d-flex justify-content-between align-items-center">
        <Card.Title className="mb-0" as="h6">
          Nyckeltal
        </Card.Title>
        <RefreshButton onClick={readAccountsQuery.refetch} size="sm" />
      </Card.Header>
      <BlockSpinner isLoading={readAccountsQuery.isLoading} className="m-3" />
      <ErrorAlert error={readAccountsQuery.error} className="m-3" />
      {readAccountsQuery.isSuccess && data && (
        <Card.Body>
          <AccountsBarChart data={data} />
          <ChartLegend
            selected={selectedKeyFigures}
            onChange={(values) => {
              setSelectedKeyFigures(values as FigureId[]);
            }}
          />
        </Card.Body>
      )}
    </Card>
  );
}
export default CompanyAccountsChartCard;

interface AccountBarChartProps {
  data: Chartist.BarChartData;
}

function AccountsBarChart (props: AccountBarChartProps) {
  const { data } = props;
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!data || !ref.current) return;
    
    let baseGridIndex: number | null = null;
    const chart = new Chartist.BarChart(ref.current, data, {
      height: 300,
      chartPadding: {
        right: 0,
        left: 0,
      },
      axisX: { showGrid: false },
      axisY: {
        scaleMinSpace: 30,
        offset: 75,
        labelInterpolationFnc: function (value: any, index: number, values: any[]) {
          if (index === 0 || index === values.length - 1 || value === 0) {
            return numberUtils.scaleFormatter.format(parseFloat(value));
          }
          return '';
        } as any,
      },
      seriesBarDistance: 22,
    }).on('draw', (event) => {
      if (event.type === 'bar') {
        if ((event as any).value.y < 0) event.element.addClass('negative-value');
        const title = numberUtils.numberFormatter.format((event as any).value.y) + ' tkr';
        event.element.attr({
          ['data-bs-toggle']: 'tooltip',
          ['data-bs-placement']: 'top',
          title,
        });
      }

      if (event.type === 'grid') {
        if (baseGridIndex === null) {
          baseGridIndex = (event as any).axis.ticks.indexOf(0);
        }
        if (baseGridIndex === event.index) {
          event.element.addClass('base-grid');
        }
      }
    }).on('created', () => {
      if (ref.current) {
        ref.current.querySelectorAll('[data-bs-toggle="tooltip"]').forEach((el) => {
          tooltip.getInstance(el) || new tooltip(el);
        });
      }
    });

    return () => {
      chart.detach();
    };
  }, [data, ref.current]);

  return (
    <div ref={ref} />
  );
}

interface KeyFigureDropdownMenuProps extends DropdownMenuProps {
  showFilter?: boolean;
}

const KeyFigureDropdownMenuWithSearch = React.forwardRef<HTMLDivElement, KeyFigureDropdownMenuProps>(
  function KeyFigureDropdownMenu (props, outerRef) {
    const {
      children,
      style,
      className,
      show,
      'aria-labelledby': labeledBy,
      showFilter = true,
    } = props;
    const [searchTerm, setSearchTerm] = useState<string>('');
    const ref = useRef<HTMLDivElement>(null);
    const mergedRef = useMergedRefs(outerRef as React.RefObject<HTMLDivElement>, ref);

    useEffect(() => {
      const el = ref.current;
      if (!el) return;
      const rect = el.getBoundingClientRect();
      if (rect.height === 0) return;

      el.style.height = show ? rect.height + 'px' : '';
      el.style.width = show ? rect.width + 'px' : '';

      if (!show) setSearchTerm('');
    }, [show]);

    const filteredChildren = useMemo(() => React.Children.toArray(children).filter((child: any) => {
      return optionIsVisible(child.props.children, searchTerm);
    }), [children, searchTerm]);

    return (
      <div
        ref={mergedRef}
        style={style}
        className={className}
        aria-labelledby={labeledBy}
      >
        {showFilter && (
          <div className="px-3 pb-2 bg-white bo-rder-bottom shadow-sm">
            <Form.Control
              placeholder="Inget filter"
              onChange={(e) => setSearchTerm(e.target.value)}
              size="sm"
              value={searchTerm}
            />
          </div>
        )}
        <ul
          className={classNames('list-unstyled mb-0 dropdown-scrollable-menu', {
            'd-flex align-items-center justify-content-center vh-100': filteredChildren.length === 0,
          })}
        >
          {children}
        </ul>
      </div>
    );
  },
);

interface ChartLegendProps {
  selected: FigureId[];
  onChange: (selected: FigureId[]) => void;
}

function ChartLegend (props: ChartLegendProps & React.HTMLAttributes<HTMLDivElement>) {
  const { selected, onChange } = props;
  const onlyOneSelected = selected.filter(Boolean).length === 1;

  return (
    <div className="d-flex justify-content-center flex-wrap gap-2">
      {Array.from({length: 3}).map((_, i) => (
        <Dropdown
          key={i} 
          onSelect={(eventKey) => {
            const selectedCopy = [...selected];
            selectedCopy[i] = eventKey as FigureId;
            onChange(selectedCopy);
          }}
          className={classNames('chart-legend-item', {
            'chart-legend-item-has-value': Boolean(selected[i]),
          })}
        >
          <Dropdown.Toggle
            variant="outline-secondary"
            className="d-flex align-items-center gap-2 btn-sm"
          >
            <>
              <div
                title={selected[i] ? 'Ta bort nyckeltal' : undefined}
                role="button"
                className={classNames(`chart-legend-sample series-${seriesIdByIndex(i)}`, {
                  'chart-legend-sample-clear': !onlyOneSelected && selected[i],
                })}
                onClick={onlyOneSelected ? undefined : (event) => {
                  event.stopPropagation();
                  const selectedCopy = [...selected];
                  selectedCopy[i] = '' as FigureId;
                  onChange(selectedCopy);
                }}
                aria-disabled={onlyOneSelected && selected ? 'true' : 'false'}
              />
              {selected[i] ? accountColumnTitle(selected[i]) : 'Välj nyckeltal'}
            </>
          </Dropdown.Toggle>

          <Dropdown.Menu as={KeyFigureDropdownMenuWithSearch} showFilter={false}>
            {figures.map((title) => {
              const active = selected[i] === title;
              return (
                <li key={title}>
                <Dropdown.Item
                  eventKey={title}
                  disabled={!active && selected.includes(title)}
                  active={active}
                  className="d-flex align-items-center gap-2"
                >
                  {selected.includes(title) && (
                    <div className={`chart-legend-sample series-${seriesIdByIndex(selected.indexOf(title))}`} />
                  )}
                  <span className="text-truncate">{accountColumnTitle(title)}</span>
                </Dropdown.Item>
                </li>
              );
            })}
          </Dropdown.Menu>
        </Dropdown>
      ))}
    </div>
  );
}

function accountColumnTitle (key: FigureId) {
  return allAccountRows.find(r => r.key === key)?.title;
}

function optionIsVisible (key: FigureId, searchTerm: string) {
  if (!searchTerm) return true;
  const exp = new RegExp(searchTerm.trim(), 'i');
  return exp.test(key);
}

function keyFigure (key: FigureId, index: number, data: Record<FigureId, ICsAccountsExtended[FigureId]>[]) {
  return {
    name: accountColumnTitle(key),
    data: data.map((a: any) => a[key] ?? 0),
    className: `ct-series ct-series-${seriesIdByIndex(index)}`,
  };
}

function accountsToChartData (accounts: ICsAccountsExtended[], keys: FigureId[]): Chartist.BarChartData {
  return {
    labels: accounts.map(a => moment(a.date_to).format('YYYY-MM')),
    series: keys.filter(Boolean).map((key) => keyFigure(key, keys.indexOf(key), accounts)),
  };
}

function seriesIdByIndex (index: number) {
  return ['a', 'b', 'c'][index];
}
