import React from 'react';
import { IValuationReport } from 'client/valuationReport/types';
import { CompanyAnchor } from 'client/company/CompanyFormatters';

interface ValuationReportCompanyProps {
  report: IValuationReport;
  inline?: boolean;
}

export const ValuationReportCompany: React.FC<ValuationReportCompanyProps> = (props: ValuationReportCompanyProps) => {
  const { report, inline = false } = props;
  const { report_name, report_org_number:orgNumber } = report;
  return (
    <>
      {report_name}
      {orgNumber && (
        <>
          {' '}
          {inline ? (
            <CompanyAnchor value={orgNumber} />
          ) : (
            <><br /><small><CompanyAnchor value={orgNumber} /></small></>
          )}
        </>
      )}
    </>
  );
};
