import React, {useMemo} from 'react';
import { Card, CardProps } from 'react-bootstrap';
import { UseQueryResult } from '@tanstack/react-query';
import {ColumnDefinition, FilterDefinition} from 'client/table/types';
import RefreshButton from 'client/buttons/RefreshButton';
import TableVirtual from 'client/table/TableVirtual';
import { TableSpinningOverlay  } from 'client/table/TableUtils';
import classNames from 'classnames';
import {CardBodyErrorAlert} from 'client/card/CardHelpers';
import TableFilterVirtual from 'client/table/TableFilterVirtual';
import {cloneDeep} from 'lodash';

// TODO see if we can avoid "any" here
interface CardSimpleTableProps extends CardProps {
  query: UseQueryResult<any, Error>;
  rows: any[];
  title: string;
  columns: ColumnDefinition<any>[];
  filters?: FilterDefinition[];
  filter?: any;
  setFilter?: React.Dispatch<React.SetStateAction<any>>;
}

export default function CardSimpleTable (props: CardSimpleTableProps) {
  const {
    className,
    query,
    title,
    rows,
    columns,
    children,
    filters,
    filter,
    setFilter,
    ...restOfProps
  } = props;

  // the default filter is the first filter passed as a property when the component mounted
  const defaultFilter = useMemo(() => {
    return cloneDeep(filter);
  }, []);

  const onResetFilter = () => {
    setFilter?.(defaultFilter);
  };

  return (
    <Card className={classNames('border-0', className)} {...restOfProps}>
      <Card.Header className="border border-bottom-0 px-3 py-2">
        <Card.Title as="h6" className="mb-0 p-0 d-flex justify-content-between align-items-center">
          <span>{title}</span>
          <RefreshButton
            size="sm"
            onClick={query.refetch}
            disabled={query.isLoading || query.isRefetching}
          />
        </Card.Title>
      </Card.Header>
      <CardBodyErrorAlert error={query.error} />
      {filters && setFilter && filter && (
        <Card.Body className="border border-bottom-0">
          <TableFilterVirtual
            size="sm"
            isLoading={query.isLoading || query.isRefetching}
            filterDefinitions={filters}
            filterParams={filter}
            setFilterParams={setFilter}
            onReset={onResetFilter}
          />
        </Card.Body>
      )}
      {children}
      <TableSpinningOverlay isLoading={query.isRefetching}>
        <div className="table-responsive">
          <TableVirtual
            isInitialLoading={query.isLoading}
            className="mb-0 border align-middle"
            columns={columns}
            rows={rows}
          />
        </div>
      </TableSpinningOverlay>
    </Card>
  );
}
