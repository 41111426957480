import React from 'react';
import NumberFormat from 'client/components/NumberFormat';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { TableSpinningOverlay  } from 'client/table/TableUtils';
import { IValuationReport } from 'client/valuationReport/types';
import { IIndustryReport } from 'client/industryReport/types';
import { ReportDownloadPDFLink } from 'client/report/ReportFormatters';
import { IReportQueue, IReportQueueWithRelations } from 'client/reportQueue/types';
import { ExternalLink } from 'react-feather';
import LoadingButton from 'client/buttons/LoadingButton';
import { Table, Card } from 'react-bootstrap';
import useAuth from 'client/hooks/useAuth';
import DateFormat from 'client/components/DateFormat';
import ErrorAlert from 'client/components/ErrorAlert';
import InspectObjectModalButton from 'client/buttons/InspectObjectModalButton';
import axios from 'client/axios';
import RoleGuard from 'client/guards/RoleGuard';
import RefreshButton from 'client/buttons/RefreshButton';
import HelperTooltip from 'client/components/HelperTooltip';
import * as IndustryReportFormatters from 'client/industryReport/IndustryReportFormatters';
import ReportQueueOverviewTable from 'client/reportQueue/ReportQueueOverviewTable';

interface IReportQueueProperties {
  reportQueue: IReportQueueWithRelations;
  isRefetching: boolean;
}

type TGenerateReportMutationResult = {success: false} | IReportQueue;

export default function ReportQueueProperties (props: IReportQueueProperties) {
  const { reportQueue, isRefetching } = props;

  const auth = useAuth();
  const queryClient = useQueryClient();

  const generateMutation = useMutation<TGenerateReportMutationResult, Error>({
    mutationKey: ['ReportQueuePropertiesGenerateReport', reportQueue.id],
    mutationFn: () => axios.post(`/api/report_queue/${reportQueue.id}/generate_report`, {
      data: {},
    }).then(response => response.data),
    onSuccess: () => {
      invalidateParentData();
    },
  });

  const invalidateParentData = () => {
    const name = reportQueue.report_type === 'valuation_report' ? 'ValuationReportEditPage' : 'IndustryReportEditPage';
    queryClient.invalidateQueries({
      queryKey: [name, String(reportQueue.id)],
      exact: true,
      refetchType: 'all',
    });
  };

  const onClickGenerate = () => {
    generateMutation.mutate();
  };

  return (
    <Card>
      <Card.Header className="p-3">
        <Card.Title className="mb-0 d-flex justify-content-between">
          <div className="d-flex align-items-center gap-2">
            Egenskaper
          </div>
          <RefreshButton
            size="sm"
            onClick={invalidateParentData}
          />
        </Card.Title>
      </Card.Header>
      <Card.Body>
        <ErrorAlert error={generateMutation.error} />
        <TableSpinningOverlay isLoading={isRefetching}>
          <div className="table-responsive">
            <ReportQueueOverviewTable
              className="mb-1"
              reportQueue={reportQueue}
              bordered
            />
            {(reportQueue?.valuation_report || reportQueue?.industry_report) ? (
              <Table bordered className="mb-1 mt-3">
                <tbody>
                  {reportQueue?.valuation_report && (
                    <ValuationReportProperties report={reportQueue.valuation_report} />
                  )}
                  {reportQueue?.industry_report && (
                    <IndustryReportProperties report={reportQueue?.industry_report} />
                  )}
                </tbody>
              </Table>
            ) : null}
          </div>
        </TableSpinningOverlay>
      </Card.Body>
      <RoleGuard role="admin">
        <Card.Footer className="d-flex gap-3 p-3">
          <LoadingButton
            variant="primary"
            isLoading={generateMutation.isPending}
            disabled={generateMutation.isPending}
            onClick={onClickGenerate}
          >
            Producera rapport
          </LoadingButton>
          <a
            href={`${process.env.REACT_APP_SERVER_URL}/api/report_queue/${reportQueue.id}/render_html?token=${auth.accessToken}`}
            target="_blank"
            rel="noopener noreferrer"
            title="Rendera rapporten och visa den som HTML"
            className="d-inline-flex gap-1 align-items-center"
          >
            Öppna rapport som HTML
            <ExternalLink size={18} />
          </a>
        </Card.Footer>
      </RoleGuard>
    </Card>
  );
}

interface IndustryReportPropertiesProps {
  report: IIndustryReport;
}

function IndustryReportProperties (props: IndustryReportPropertiesProps) {
  const { report } = props;
  return (
    <>
      <tr>
        <th className="align-middle">Branschrapport (senaste produktion)</th>
        <td>
          <InspectObjectModalButton object={report} size="sm" className="d-inline-flex" />
          {' '}
          <ReportDownloadPDFLink type="industry_report" className="py-0" report={report} iconSize={14} />
        </td>
      </tr>
      <tr>
        <th>
          <span className="d-flex align-items-center gap-1">
            Renderad
            <HelperTooltip>
              Tidpunkten då data till rapporten hämtades och PDF:en skapades.
            </HelperTooltip>
          </span>
        </th>
        <td>
          <DateFormat value={report.rendered_at || report.created_at} format="YYYY-MM-DD HH:mm" />
        </td>
      </tr>
      <tr>
        <th>Antal företag</th>
        <td>
          <IndustryReportFormatters.IndustryReportCompanyCounts
            title=""
            value={report}
          />
        </td>
      </tr>
    </>
  );
}

interface ValuationReportPropertiesProps {
  report: IValuationReport;
}

function ValuationReportProperties (props: ValuationReportPropertiesProps) {
  const { report } = props;
  return (
    <>
      <tr>
        <th className="align-middle">Företagsvärdering (senaste produktion)</th>
        <td>
          <InspectObjectModalButton object={report} size="sm" className="d-inline-flex" />
          {' '}
          <ReportDownloadPDFLink type="valuation_report" className="py-0" report={report} iconSize={14} />
        </td>
      </tr>
      <tr>
        <th>
          <span className="d-flex align-items-center gap-1">
            Renderad
            <HelperTooltip>
              Tidpunkten då data till rapporten hämtades och PDF:en skapades.
            </HelperTooltip>
          </span>
        </th>
        <td>
          <DateFormat value={report.rendered_at || report.created_at} format="YYYY-MM-DD HH:mm" />
        </td>
      </tr>
      <tr>
        <th>Värde</th>
        <td>
          <NumberFormat
            value={report.report_valuation ?? 0}
            format="currency"
          />
        </td>
      </tr>
      <tr>
        <th>Värdefaktor</th>
        <td>
          <NumberFormat
            value={report.report_valuation_value_factor ?? 0}
          />
        </td>
      </tr>
    </>
  );
}
