import React, { useQuery } from '@tanstack/react-query';
import { CreditReportRow } from 'client/creditReport/types';
import { CompanyEventRow } from 'client/companyEvent/types';
import { Button, Modal, ModalProps } from 'react-bootstrap';
import BlockSpinner from 'client/spinners/BlockSpinner';
import ErrorAlert from 'client/components/ErrorAlert';
import ObjectInspector from 'client/components/ObjectInspector';

interface CreditReportCompanyEventModalProps extends ModalProps {
  creditReportId: string;
}

type CreditReportCompanyEventsResponse = {
  credit_report_curr: CreditReportRow;
  credit_report_prev: null | CreditReportRow;
  events: Partial<CompanyEventRow>[];
}

export default function CreditReportCompanyEventModal (props: CreditReportCompanyEventModalProps) {
  const { creditReportId, onHide, ...restOfProps } = props;

  const query = useQuery<CreditReportCompanyEventsResponse, Error>({
    queryKey: [`/api/credit_report/${creditReportId}/company_events`],
  });

  const response = query.data;

  return (
    <Modal
      {...restOfProps}
      onHide={onHide}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <div>
            <div className="mb-1">
              Företagshändelser
            </div>
          </div>
        </Modal.Title>
      </Modal.Header>
      <div className="position-relative">
        <ErrorAlert error={query.error} className="m-3" />
        <BlockSpinner className="m-3" isLoading={query.isLoading} />
        {response && (
          <div className="p-3">
            <div style={{overflow: 'auto'}}>
              <ObjectInspector
                object={response}
                expandLevel={1}
              />
            </div>
          </div>
        )}
      </div>
      <Modal.Footer>
        <Button variant="outline-primary" onClick={onHide}>
          Stäng
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
