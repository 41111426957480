import React from 'react';
import {
  CustomerIndustryDashboardReportTable,
  CustomerValuationDashboardReportTable,
} from 'client/customer/CustomerDashboardReportTables';
import { Col, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import CustomerDashboardCompanyMonitoringCard from 'client/customer/CustomerDashboardCompanyMonitoringCard';
import useAuth from 'client/hooks/useAuth';

export default function CustomerDashboardPage () {
  const auth = useAuth();
  const hasMonitoringAccess = typeof auth.customer?.company_monitored_limit === 'number';
  return (
    <div className="container-lg p-4">
      <Helmet>
        <title>Rapporter</title>
      </Helmet>
      <h2>Välkommen till Calculates kundportal!</h2>
      <p className="mb-0">Här nedan ser ni era beställda rapporter.</p>
      <Row>
        <Col xl={12} xxl={6}>
          <CustomerValuationDashboardReportTable className="mt-4" />
        </Col>
        <Col xl={12} xxl={6}>
          <CustomerIndustryDashboardReportTable className="mt-4" />
        </Col>
      </Row>
      {hasMonitoringAccess && (
        <Row>
          <CustomerDashboardCompanyMonitoringCard className="mt-4" />
        </Row>
      )}
    </div>
  );
}
