import React from 'react';
import { Table } from 'react-bootstrap';
import classNames from 'classnames';
import InspectObjectModalButton from 'client/buttons/InspectObjectModalButton';
import { TableSpinningOverlay  } from 'client/table/TableUtils';
import { SniCodeRow } from 'client/sni/types';
import { SniCodeAnchor } from 'client/sni/SniCodeFormatters';

export interface SniCodeTableProps {
  list: SniCodeRow[];
  isLoading: boolean;
  isSuccess: boolean;
}

const SniCodeTable: React.FC<SniCodeTableProps> = React.memo(function SniCodeTable (props: SniCodeTableProps) {
  const { list, isLoading, isSuccess } = props;

  return (
    <div>
      <TableSpinningOverlay isLoading={isLoading}>
        <div className="table-responsive">
          <Table className="mb-0">
            <thead>
              <tr>
                <th>Kod</th>
                <th>Beskrivning</th>
                <th className="text-end">Åtgärd</th>
              </tr>
            </thead>
            <tbody>
              {list.map((item, index) => (
                <tr key={item.sni_code}>
                  <td className={classNames({'border-bottom-0': index === list.length - 1})}>
                    <SniCodeAnchor sniCode={item.sni_code} />
                  </td>
                  <td className={classNames({'border-bottom-0': index === list.length - 1})}>{item.sni_text}</td>
                  <td className={classNames({'border-bottom-0': index === list.length - 1})}>
                    <div className="d-flex gap-1 flex-wrap align-items-center justify-content-end">
                      <InspectObjectModalButton object={item} />
                    </div>
                  </td>
                </tr>
              ))}
              {isSuccess && !list.length && (
                <tr>
                  <td colSpan={3} className="p-2 border-bottom-0">Det finns ingenting här</td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      </TableSpinningOverlay>
    </div>
  );
});
export default SniCodeTable;
