import React from 'react';
import { CompanyMonitoredRow } from 'client/companyMonitored/types';
import ErrorAlert from 'client/components/ErrorAlert';
import {Col, Card, Tab, Nav} from 'react-bootstrap';
import {useQuery} from '@tanstack/react-query';
import RefreshButton from 'client/buttons/RefreshButton';
import * as CompanyMonitoredFormatters from 'client/companyMonitored/CompanyMonitoredFormatters';
import axios from 'client/axios';
import OverlaySpinner from 'client/spinners/OverlaySpinner';
import CompanyMonitoredLogTable from 'client/companyMonitored/CompanyMonitoredLogTable';
import CompanyMonitoredCustomerTable from 'client/companyMonitored/CompanyMonitoredCustomerTable';
import CompanyMonitoredUserTable from 'client/companyMonitored/CompanyMonitoredUserTable';
import CompanyMonitoredUpdateTable from 'client/companyMonitoredUpdate/CompanyMonitoredUpdateTable';
import CompanyMonitoredEventTable from 'client/companyMonitored/CompanyMonitoredEventTable';
import CompanyEventTimelineTableByCompanyCard from 'client/companyEvent/CompanyEventTimelineTableByCompanyCard';
import TabNavLink from 'client/tab/TabHelpers';

interface CompanyMonitoredCardProps {
  orgNumber: string;
  tab?: string;
}

export default function CompanyMonitoredCard (props: CompanyMonitoredCardProps) {
  const { orgNumber, tab = 'customers' } = props;

  const query = useQuery<CompanyMonitoredRow | null, Error>({
    queryKey: [`/api/company_monitored/${orgNumber}`],
    queryFn: context => axios.get(context.queryKey[0] as string).then(response => {
      return response.data;
    }, err => {
      if (err?.response?.status === 404) return null;
      throw err;
    }),
  });

  const data = query.data;

  return (
    <>
      {query.isError && (
        <Col sm={12}>
          <ErrorAlert error={query.error} />
        </Col>
      )}
      <Card>
        <Card.Header>
          <Card.Title as="h6" className="d-flex align-items-center justify-content-between mb-0">
            <div className="d-flex align-items-center gap-1">
              <span className="small">Bolagsbevakning</span>
              <CompanyMonitoredFormatters.StatusLabel value={data} />
            </div>
            <div className="d-flex align-items-center gap-1">
              <RefreshButton
                size="sm"
                onClick={query.refetch}
                disabled={query.isLoading || query.isRefetching}
                className="px-1"
              />
            </div>
          </Card.Title>
        </Card.Header>
        <OverlaySpinner isLoading={query.isLoading || query.isRefetching} />
        <Tab.Container
          defaultActiveKey="customers"
          activeKey={tab}
          transition={false}
          mountOnEnter
          unmountOnExit
        >
          <Nav className="nav-tabs pt-3 px-3">
            <TabNavLink urlParamKey="subTab" eventKey="customers" title="Kunder" />
            <TabNavLink urlParamKey="subTab" eventKey="users" title="Användare" />
            <TabNavLink urlParamKey="subTab" eventKey="event_batch" title="Händelsebatch" />
            <TabNavLink urlParamKey="subTab" eventKey="events" title="Händelser" />
            <TabNavLink urlParamKey="subTab" eventKey="updates" title="Uppdateringar" />
            <TabNavLink urlParamKey="subTab" eventKey="logs" title="Logg" />
          </Nav>
          <Tab.Content>
            <Tab.Pane eventKey="customers" className="p-3">
              <CompanyMonitoredCustomerTable orgNumber={orgNumber} />
            </Tab.Pane>
            <Tab.Pane eventKey="users" className="p-3">
              <CompanyMonitoredUserTable orgNumber={orgNumber} />
            </Tab.Pane>
            <Tab.Pane eventKey="event_batch" className="p-3">
              <CompanyEventTimelineTableByCompanyCard orgNumber={orgNumber} />
            </Tab.Pane>
            <Tab.Pane eventKey="events" className="p-3">
              <CompanyMonitoredEventTable orgNumber={orgNumber} />
            </Tab.Pane>
            <Tab.Pane eventKey="updates" className="p-3">
              <CompanyMonitoredUpdateTable orgNumber={orgNumber} />
            </Tab.Pane>
            <Tab.Pane eventKey="logs" className="p-3">
              <CompanyMonitoredLogTable orgNumber={orgNumber} />
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </Card>
    </>
  );
}
