import { useMemo, useCallback, useState, useEffect, useRef } from 'react';
import { isEmpty, omit, mapValues } from 'lodash';
import { useDebounce } from 'use-debounce';
import { produceSetMap, TProduceSetMapUpdate } from 'client/utils/react';
import useHistoryQuerystring from 'client/hooks/useHistoryQuerystring';
import useTableSorting, { OrderDirection } from 'client/hooks/useTableSorting';
import useTablePagination, { IUseTablePaginationProps } from 'client/hooks/useTablePagination';
import isEqual from 'react-fast-compare';

export { OrderDirection } from 'client/hooks/useTableSorting';

interface TableStateBase {
  [filterKey: string]: string | undefined;
}

export interface UseTableStateProps extends Pick<IUseTablePaginationProps, 'rowsPerPageMax'> {
  defaultTableColumns?: string[];
  defaultState: TableStateBase & {
    orderBy?: string;
    orderDirection?: OrderDirection;
  };
}


const useTableState = (props: UseTableStateProps) => {
  const { defaultState, defaultTableColumns = [], rowsPerPageMax } = props;
  const { parsedSearch, pushSearchUpdate } = useHistoryQuerystring<TableStateBase>();

  const isInited = useRef(false);

  const [state, setState] = useState<TableStateBase>({
    ...pickFilterParams({
      ...defaultState,
      ...parsedSearch,
    }),
  });

  const [tableColumns, setTableColumns] = useState<string[]>(defaultTableColumns);

  const setStateMap = useCallback((updates: TProduceSetMapUpdate) => {
    setState(produceSetMap(updates));
  }, [setState]);

  const filterParams = useMemo(() => pickFilterParams(state), [state]);

  // setFilterParams doesn't do much but it might in the future
  // right now it is only here for naming symmetry with filterParams
  const setFilterParams = useMemo(() => setState, [setState]);

  const [debouncedFilterParams] = useDebounce(filterParams, 500, {equalityFn: isEqual});

  const tablePagination = useTablePagination({
    page: parsedSearch.currentPage ? parseInt(parsedSearch.currentPage, 10) || 1 : undefined,
    rowsPerPage: parsedSearch.perPage ? parseInt(parsedSearch.perPage, 10) || 20 : undefined,
    rowsPerPageMax,
  });

  const tableSort = useTableSorting({
    initialOrderBy: parsedSearch.orderBy || defaultState.orderBy,
    initialOrderDirection: parsedSearch.orderDirection as OrderDirection || defaultState.orderDirection,
  });

  const updateUrlParams = (replace: boolean = false) => {
    pushSearchUpdate({
      orderBy: tableSort.currentOrderBy,
      orderDirection: tableSort.currentOrderDirection,
      ...pickFilterParams(defaultState),
      ...debouncedFilterParams,
      currentPage: String(tablePagination.currentPage),
      perPage: String(tablePagination.rowsPerPage),
    }, replace);
  };

  useEffect(() => {
    // this effect could be replaced with direct calls from setStateMap
    // and in tableSort.onChange callback
    if (tablePagination.currentPage === 1 || !isInited.current) return;

    tablePagination.onChangePage(1);
  }, [
    // stuff for which we need to go back to page 1 when it changes
    tableSort.currentOrderBy,
    tableSort.currentOrderDirection,
    debouncedFilterParams,
  ]);

  useEffect(() => {
    if (!isInited.current) {
      return;
    }

    updateUrlParams();
  }, [
    // state we want to sync with URL
    tablePagination.currentPage,
    tablePagination.rowsPerPage,
    tableSort.currentOrderBy,
    tableSort.currentOrderDirection,
    debouncedFilterParams,
  ]);

  useEffect(() => {
    isInited.current = true;

    if (isEmpty(filterParams)) {
      updateUrlParams(true);
    }
  }, []);

  const filterReset = useCallback(() => {
    setState({
      ...mapValues(filterParams, v => ''), // needs to be empty strings to be removed from URL
      ...pickFilterParams({
        ...defaultState,
      }),
    });
  }, [setState, filterParams, defaultState]);


  // this returns an object that is compatible with the /list endpoints on the server
  // it can be passed straight into "params" when querying the server
  const params = useMemo(() => ({
    ...debouncedFilterParams,
    ...tableSort.params,
    ...tablePagination.params,
  }), [debouncedFilterParams, tableSort.params, tablePagination.params]);

  return {
    state,
    filterParams,
    filterReset,
    setFilterParams,
    setStateMap,
    tablePagination,
    tableSort,
    tableColumns,
    setTableColumns,
    debouncedFilterParams,
    params,
  };
};

export default useTableState;

// everything not related to order and page is considered filters in the table state
function pickFilterParams (state: TableStateBase) {
  return omit(state, ['currentPage', 'perPage', 'orderBy', 'orderDirection']);
}
