import { UserLogType, IUserLog } from 'client/user/types';

interface IUserLogDescription {
  value: IUserLog;
}
export function UserLogDescription (props: IUserLogDescription) {
  const { value:log } = props;
  const { type, data } = log;
  const base = logTypeToDescription(type);

  if (type === 'login') {
    const { ip } = data as unknown as {ip: string};
    return (
      <>
        {base} från {ip}
      </>
    );
  }

  return <>{String(base)}</>;
}

export function logTypeToDescription (type: UserLogType): string {
  switch (type) {
    default: return `Okänd loggtyp: ${String(type)}`;
    case 'created': return 'Användare skapad';
    case 'updated': return 'Användare uppdaterad';
    case 'switch_to_user': return 'Bytt till användare';
    case 'switch_to_customer': return 'Bytt till kund';
    case 'logout': return 'Utloggad';
    case 'login': return 'Inloggad';
    case 'login_failed': return 'Misslyckat inloggningsförsök';
  }
}
