import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { Modal } from 'react-bootstrap';
import axios from 'client/axios';
import { IEmailTemplate } from 'client/emailTemplate/types';
import ErrorAlert from 'client/components/ErrorAlert';
import BlockSpinner from 'client/spinners/BlockSpinner';
import { UpdateEmailTemplateFormFields } from 'client/emailTemplate/EmailTemplateForm';

interface IEmailTemplateRenderData {
  html: string;
  emailTemplate: IEmailTemplate;
  viewName: string | null;
}

export interface EmailTemplateRenderModalProps {
  emailTemplateId: string;
  form?: UpdateEmailTemplateFormFields;
  show: boolean;
  onHide: () => void;
  onExited: () => void;
}

const EmailTemplateRenderModal: React.FC<EmailTemplateRenderModalProps> = React.memo(function InspectObjectModal (props: EmailTemplateRenderModalProps) {
  const {
    show,
    emailTemplateId,
    form,
    onHide,
    onExited,
  } = props;

  const query = useQuery<IEmailTemplateRenderData, Error>({
    queryKey: ['EmailTemplateRenderModal', {emailTemplateId}, form],
    queryFn: () => {
      if (form) {
        return axios.post(`/api/email_templates/${emailTemplateId}/render`, form).then(r => r.data);
      }
      return axios.get(`/api/email_templates/${emailTemplateId}/render`).then(r => r.data);
    },
  });

  const data = query.data || null;

  return (
    <Modal show={show} onHide={onHide} onExited={onExited} size="lg">
      <Modal.Header>
        {data && <Modal.Title>{data.emailTemplate.subject}</Modal.Title>}
      </Modal.Header>
      <Modal.Body className="m-2">
        <ErrorAlert error={query.error} />
        <BlockSpinner isLoading={query.isLoading}  />
        {query.isSuccess && data && (
          <div dangerouslySetInnerHTML={{__html: data.html}} />
        )}
      </Modal.Body>
    </Modal>
  );
});
export default EmailTemplateRenderModal;
