import React from 'react';
import { CompanyAnchor } from 'client/company/CompanyFormatters';

interface ICompanyNameAndAddress {
  company: {
    org_number: string;
    company_name: string | null;
    registered_zipcode: string | null;
    registered_town: string | null;
    registered_street: string | null;
  };
}

const CompanyNameAndAddress: React.FC<ICompanyNameAndAddress> = React.memo(function CompanyNameAndAddress (props: ICompanyNameAndAddress) {
  const { company } = props;
  const {
    org_number,
    company_name,
    registered_zipcode,
    registered_town,
    registered_street,
  } = company;
  return (
    <div>
      <div><strong>{company_name}</strong></div>
      <div className="small"><CompanyAnchor value={org_number} /></div>
      <address className="small mb-0">
        {registered_street && <>{registered_street} <br /></>}
        {registered_zipcode} {registered_town}
      </address>
    </div>
  );
});

export default CompanyNameAndAddress;
