import React, { useMemo, useCallback, useState } from 'react';
import {
  IValuationReportSettingsData,
  IValuationReportValueData,
} from 'client/valuationReport/types';
import LoadingButton from 'client/buttons/LoadingButton';
import { TChangeValue } from 'client/utils/form';
import { ICsAccountsExtended, ICsGroupAccountsExtended } from 'client/cs/types';
import useAuth from 'client/hooks/useAuth';
import {
  calculateValueFactor,
  calculateCompanyValue,
  accountToNormalizedReturnOnCapitalPercent,
} from 'client/accounts/utils';
import { Form, InputGroup } from 'react-bootstrap';
import HelperTooltip from 'client/components/HelperTooltip';

import { numberFormatter } from 'client/utils/number';
import IdProvider from 'client/components/IdProvider';

interface IValuationReportValueSubForm {
  accounts: ICsAccountsExtended[] | ICsGroupAccountsExtended[];
  numberOfAccounts: number;
  overriddenValueFactor: number | null;
  valueData: IValuationReportValueData;
  settingsData: IValuationReportSettingsData;
  onChangeValue: TChangeValue;
}

const ValuationReportValueSubForm: React.FC<IValuationReportValueSubForm> = React.memo(function ValuationReportValueSubForm (props: IValuationReportValueSubForm) {
  const {
    accounts,
    settingsData,
    numberOfAccounts,
    valueData,
    overriddenValueFactor,
    onChangeValue,
  } = props;

  const onChangeValueFactor = useCallback((ev: React.ChangeEvent<HTMLInputElement>) => {
    const { name, valueAsNumber } = ev.target;
    onChangeValue(name, valueAsNumber);
  }, [onChangeValue]);

  const [overriddenValueNumberOfAccounts, setOverriddenValueNumberOfAccounts] = useState(numberOfAccounts);
  const onChangeValueNumberOfAccounts = useCallback((ev: React.ChangeEvent<HTMLInputElement>) => {
    setOverriddenValueNumberOfAccounts(ev.target.valueAsNumber);
  }, [setOverriddenValueNumberOfAccounts]);


  const valueNumberOfAccounts = useMemo(() => {
    if (overriddenValueFactor === null) return numberOfAccounts;
    return overriddenValueNumberOfAccounts;
  }, [numberOfAccounts, overriddenValueNumberOfAccounts, overriddenValueFactor]);

  const onChangeManual = useCallback((ev: React.ChangeEvent<HTMLSelectElement>) => {
    const manual = ev.target.value === 'true';
    const value = manual ? valueData.company_value_factor : null;
    onChangeValue('report_settings.overridden_value_factor', value);
    setOverriddenValueNumberOfAccounts(manual ? valueNumberOfAccounts : numberOfAccounts);
  }, [onChangeValue, setOverriddenValueNumberOfAccounts, valueData]);

  const valueFactor = useMemo(() => {
    if (overriddenValueFactor !== null) {
      return overriddenValueFactor;
    }
    const row: number[] = accounts.slice(0, valueNumberOfAccounts).map(accountToNormalizedReturnOnCapitalPercent);
    return calculateValueFactor(row);
  }, [valueNumberOfAccounts, overriddenValueFactor, accounts]);

  const companyValue = useMemo(() => {
    const lastAccount = accounts[0] ?? {};
    const corporateTaxRate = typeof lastAccount.corporate_tax_rate === 'string' ? parseFloat(lastAccount.corporate_tax_rate) : lastAccount.corporate_tax_rate ?? 0;
    const bsTotEquity = lastAccount.bs_tot_equity || 0;
    const bsUntaxedReserves = lastAccount.bs_untaxed_reserves || 0;
    const companyValue = calculateCompanyValue(valueFactor, corporateTaxRate, bsTotEquity, bsUntaxedReserves);

    return companyValue;
  }, [accounts, valueFactor, settingsData, valueData]);

  const onSubmitCalculateValue = (ev: React.MouseEvent<HTMLButtonElement>) => {
    const row: number[] = accounts.slice(0, valueNumberOfAccounts).map(accountToNormalizedReturnOnCapitalPercent);
    const valueFactor = calculateValueFactor(row);
    onChangeValue('report_settings.overridden_value_factor', valueFactor);
  };

  const auth = useAuth();
  const isAdmin = auth.isUserRole('admin');
  const valueFormDisabled = overriddenValueFactor === null || !isAdmin;
  const valueFormDetailsHidden = !auth.isUserRole(['admin', 'coordinator']);

  return (
    <div className="d-flex align-items-end gap-3 flex-wrap">
      <IdProvider>
        {id => (
          <Form.Group className={valueFormDetailsHidden ? 'd-none' : ''}>
            <Form.Label htmlFor={id}>
              Uträkning{' '}
              <HelperTooltip>
                Med "Manuell" uträkning av bolagsvärdet är det möjligt att själv påverka antal bokslut som ligger till grund för
                beräkningen av värderingsfaktorn, vilken i sin tur avgör bolagsvärdet.
              </HelperTooltip>
            </Form.Label>
            <Form.Select
              id={id}
              disabled={!isAdmin}
              value={overriddenValueFactor === null ? 'false' : 'true'}
              name="manual"
              onChange={onChangeManual}
            >
              <option value="false">Automatisk</option>
              <option value="true">Manuell</option>
            </Form.Select>
          </Form.Group>
        )}
      </IdProvider>
      <IdProvider>
        {id => (
          <Form.Group className={valueFormDetailsHidden ? 'd-none' : ''}>
            <Form.Label htmlFor={id}>
              Antal bokslut{' '}
              <HelperTooltip>
                Antal bokslut som beaktas för att beräkna värderingsfaktorn. Om du gör uträkningen av bolagsvärdet manuellt
                så måste du själv trycka på knappen "Beräkna" för att räkna fram en ny värderingsfaktor varje gång du ändrar detta värde.
              </HelperTooltip>
            </Form.Label>
            <InputGroup>
              <Form.Control
                type="number"
                min={1}
                max={Math.min(5, accounts.length)}
                name="valueNumberOfAccounts"
                id={id}
                value={valueNumberOfAccounts ?? ''}
                onChange={onChangeValueNumberOfAccounts}
                disabled={valueFormDisabled}
                required
              />
              <InputGroup.Text>st</InputGroup.Text>
            </InputGroup>
          </Form.Group>
        )}
      </IdProvider>
      <LoadingButton
        className={valueFormDetailsHidden ? 'd-none' : ''}
        variant="outline-primary"
        disabled={valueFormDisabled}
        onClick={onSubmitCalculateValue}
      >
        Beräkna →
      </LoadingButton>
      <IdProvider>
        {id => (
          <Form.Group className={valueFormDetailsHidden ? 'd-none' : ''}>
            <Form.Label htmlFor={id}>
              Värderingsfaktor{' '}
              <HelperTooltip>
                Värderingsfaktorn multipliceras med justerat eget kapital det senaste bokslutet för att beräkna bolagets värde.
              </HelperTooltip>
            </Form.Label>
            <Form.Control
              type="number"
              name="report_settings.overridden_value_factor"
              id={id}
              value={valueFactor}
              disabled={valueFormDisabled}
              onChange={onChangeValueFactor}
            />
          </Form.Group>
        )}
      </IdProvider>
      <Form.Group>
        <Form.Label>
          Bolagsvärde
        </Form.Label>
        <InputGroup>
          <Form.Control
            value={numberFormatter.format(Math.round(companyValue))}
            disabled
          />
          <InputGroup.Text>
            SEK
          </InputGroup.Text>
        </InputGroup>
      </Form.Group>
    </div>
  );
});
export default ValuationReportValueSubForm;
