export type TAccountsTableRowDefinition = IAccountsTableRowDefinition | IAccountsTableDerivedRowDefinition;

export type TAccountsTableRowType =
    'integer'
  | 'percent'
  | 'numeric'
  | 'date'
  | 'spacer'
  | 'header'
  | 'string'
;

export type TAccountsTableDerivedRowCalculateFn = (index: number, ...accounts: IAccounts[]) => any;

export type TChangeAccountsTableSetting = (key:(keyof IAccountsTableSettings), value: any) => void;

export interface IAccountsTableSettings {
  advanced_editor: boolean;
  empty_year?: IAccounts | null; // deprecated
  remove_years: number;
  add_years?: IAccounts[] | null;
}

export function getDefaultAccountsTableSettings (): IAccountsTableSettings {
  return {
    advanced_editor: false,
    remove_years: 0,
    add_years: [],
  };
}

export interface IAccountsTableRowDefinition {
  key: string;
  type: TAccountsTableRowType;
  title?: string;
  derived?: boolean;
  disabled?: boolean;
}

export interface IAccountsTableDerivedRowDefinition extends IAccountsTableRowDefinition {
  derived: true;
  calculate: TAccountsTableDerivedRowCalculateFn;
}

export type IAccounts = Record<string, string | number | null>;

export type TNumberOfEmployeesIntervals = 
 '0 anställda'
 | '1-4 anställda'
 | '5-9 anställda'
 | '10-19 anställda'
 | '20-49 anställda'
 | '50-99 anställda'
 | '100-199 anställda'
 | '200-499 anställda'
 | '500-999 anställda'
 | '1000-1499 anställda'
 | '1500-1999 anställda'
 | '2000-2999 anställda'
 | '3000-3999 anställda'
 | '4000-4999 anställda'
 | '5000-9999 anställda'
 | '10000- anställda'
;

export const numberOfEmployeesIntervals: TNumberOfEmployeesIntervals[] = [
  '0 anställda',
  '1-4 anställda',
  '5-9 anställda',
  '10-19 anställda',
  '20-49 anställda',
  '50-99 anställda',
  '100-199 anställda',
  '200-499 anställda',
  '500-999 anställda',
  '1000-1499 anställda',
  '1500-1999 anställda',
  '2000-2999 anställda',
  '3000-3999 anställda',
  '4000-4999 anställda',
  '5000-9999 anställda',
  '10000- anställda',
];
