import qs from 'qs';
import { useHistory } from 'react-router-dom';
import isEqual from 'lodash/isEqual';
import omitBy from 'lodash/omitBy';
import pickBy from 'lodash/pickBy';
import useParsedSearch from 'client/hooks/useParsedSearch';

export type ParsedSearchUpdater<T> = (newParsedSearch: T, replace?: boolean) => T;

interface UseHistoryQueryStringHook<T> {
  parsedSearch: T;
  resetSearch: () => void;
  pushSearchUpdate: ParsedSearchUpdater<T>;
}

function useHistoryQuerystring<T> (): UseHistoryQueryStringHook<T> {
  const history = useHistory();
  const parsedSearch = useParsedSearch<T>();

  // if newParsedSearch has any keys with value undefined, they will be omitted from the search string
  // but only if T is an object
  const pushSearchUpdate = (newParsedSearch: T, replace: boolean = false) => {
    if (typeof newParsedSearch === 'object' && newParsedSearch !== null) {
      const newParsedSearchOmitted = omitBy(newParsedSearch, v => typeof v === 'undefined');
      if (!isEqual(newParsedSearchOmitted, parsedSearch)) {
        const update = pickBy({
          ...parsedSearch,
          ...newParsedSearchOmitted,
        }, v => v !== '') as T;
        const search = qs.stringify(update);
        replace ? history.replace({search}) : history.push({search});
        return update;
      }
    } else {
      if (!isEqual(newParsedSearch, parsedSearch)) {
        const update = {...parsedSearch, ...newParsedSearch};
        const search = qs.stringify(update);
        replace ? history.replace({search}) : history.push({search});
        return update;
      }
    }
    return parsedSearch;
  };

  const resetSearch = () => {
    history.push({search: ''});
  };

  return {parsedSearch, pushSearchUpdate, resetSearch};
}

export default useHistoryQuerystring;
