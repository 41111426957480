import React, { useCallback } from 'react';
import { Button, Form } from 'react-bootstrap';
import { preventDefaultSubmit } from 'client/utils/form';
import { CustomerSurveyTableFilterParams } from 'client/customerSurvey/CustomerSurveyTablePage';
import RefreshButton from 'client/buttons/RefreshButton';
import TableSettingsColumnOrderButton, { TableSettingsColumnOrderButtonProps } from 'client/table/TableSettingsColumnOrderButton';
import { TableFilterInputGroup } from 'client/table/TableUtils';

interface CustomerSurveyTableFilterFormProps extends Pick<TableSettingsColumnOrderButtonProps, 'columnOrder' | 'setColumnOrder' | 'columnDefinitions'> {
  filterParams: CustomerSurveyTableFilterParams;
  setFilterParams: React.Dispatch<React.SetStateAction<CustomerSurveyTableFilterParams>>;
  onRefetch: () => void;
  onReset: () => void;
  isLoading: boolean;
}

export default function CustomerSurveyTableFilterForm (props: CustomerSurveyTableFilterFormProps) {
  const {
    isLoading,
    onRefetch,
    onReset,
    filterParams,
    setFilterParams,
    columnDefinitions,
    columnOrder,
    setColumnOrder,
  } = props;

  const onChange = useCallback((ev: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const { name, value } = ev.target;
    setFilterParams({[name]: value});
  }, [filterParams, setFilterParams]);

  return (
    <Form className="d-flex justify-content-between gap-3" onSubmit={preventDefaultSubmit}>
      <TableFilterInputGroup name="id" label="ID" className="flex-grow-1">
        <Form.Control
          isValid={Boolean(filterParams.id)}
          name="id"
          onChange={onChange}
          placeholder="Oavsett ID"
          value={filterParams.id ?? ''}
        />
      </TableFilterInputGroup>
      <TableFilterInputGroup name="customer_id" label="Kund" className="flex-grow-1">
        <Form.Control
          isValid={Boolean(filterParams.customer_id)}
          name="customer_id"
          onChange={onChange}
          placeholder="Oavsett kund-ID"
          value={filterParams.customer_id ?? ''}
        />
      </TableFilterInputGroup>
      <TableFilterInputGroup name="status" label="Status" className="flex-grow-1">
        <Form.Select
          isValid={Boolean(filterParams.status)}
          name="status"
          onChange={onChange}
          value={filterParams.status ?? ''}
        >
          <option value="">Oavsett status</option>
          <option value="unsent">Oskickad</option>
          <option value="sent">Skickad</option>
          <option value="opened">Öppnad</option>
          <option value="answered">Besvarad</option>
          <option value="expired">Utgången</option>
        </Form.Select>
      </TableFilterInputGroup>
      <div className="d-flex gap-3">
        <Button
          title="Ta bort alla filtreringar"
          onClick={onReset}
          variant="outline-primary"
          className="d-flex align-items-center justify-content-center"
          disabled={isLoading}
        >
          Återställ
        </Button>
        <TableSettingsColumnOrderButton
          columnDefinitions={columnDefinitions}
          columnOrder={columnOrder}
          setColumnOrder={setColumnOrder}
        />
        <RefreshButton onClick={onRefetch} disabled={isLoading} />
      </div>
    </Form>
  );
}
