import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { Card, Table } from 'react-bootstrap';
import InspectObjectModalButton from 'client/buttons/InspectObjectModalButton';
import { TableSpinningOverlay  } from 'client/table/TableUtils';
import RefreshButton from 'client/buttons/RefreshButton';
import classNames from 'classnames';
import DateFormat from 'client/components/DateFormat';
import ErrorAlert from 'client/components/ErrorAlert';
import { IEmail } from 'client/email/types';
import ModalOpeningButton from 'client/buttons/ModalOpeningButton';
import EmailRenderModal from 'client/email/EmailRenderModal';
import SendCustomerSurveyEmailButton from 'client/email/SendCustomerSurveyEmailButton';

interface CustomerSurveyEmailsTableProps {
  onSuccessSendEmail?: () => void;
  customerSurveyId: string;
  className?: string;
}

const CustomerSurveyEmailsTable: React.FC<CustomerSurveyEmailsTableProps> = React.memo(function CustomerSurveyEmailsTable (props: CustomerSurveyEmailsTableProps) {
  const { className, customerSurveyId, onSuccessSendEmail:outerOnSuccessSendEmail } = props;

  const query = useQuery<IEmail[], Error>({
    queryKey: [`/api/emails/customer_survey/${customerSurveyId}`],
  });

  const onSuccessSendEmail = () => {
    query.refetch();
    outerOnSuccessSendEmail?.();
  };


  const list = query.data ?? [];

  return (
    <Card className={classNames(className, 'border-0')}>
      <Card.Header className="border border-bottom-0 px-3 py-2">
        <Card.Title as="h6" className="mb-0 p-0 d-flex justify-content-between align-items-center">
          Undersökningens e-postmeddelanden
          <div>
            <RefreshButton
              onClick={query.refetch}
              disabled={query.isLoading || query.isRefetching}
              className="px-2"
            />
          </div>
        </Card.Title>
      </Card.Header>
      {query.error && (
        <Card.Body className="border border-bottom-0">
          <ErrorAlert className="mb-0" error={query.error} />
        </Card.Body>
      )}
      <TableSpinningOverlay isLoading={query.isLoading || query.isRefetching}>
        <div className="table-responsive">
          <Table className="mb-0 border align-middle">
            <thead>
              <tr>
                <th>Ämne</th>
                <th>Mottagare</th>
                <th>Skickat</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {query.isSuccess && !list.length && (
                <tr>
                  <td colSpan={11} className="p-2">Undersökningen har inga e-postmeddelanden</td>
                </tr>
              )}
              {list.map(item => (
                <tr key={item.id}>
                  <td>
                    {item.subject}
                  </td>
                  <td>
                    {item.to_name} - <a href={`mailto:${item.to_address}`}>{item.to_address}</a>
                  </td>
                  <td>
                    <DateFormat format="YYYY-MM-DD HH:mm" value={item.created_at} />
                  </td>
                  <td>
                    <div className="d-flex gap-1 flex-wrap justify-content-end align-items-center">
                      <ModalOpeningButton
                        variant="outline-primary"
                        Modal={EmailRenderModal as React.FC}
                        modalProps={{emailId: item.id}}
                        size="sm"
                        className="py-0"
                      >
                        Visa
                      </ModalOpeningButton>
                      <InspectObjectModalButton object={item} />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </TableSpinningOverlay>
      <Card.Footer className="border border-top-0 d-flex gap-2 p-2">
        <SendCustomerSurveyEmailButton
          size="sm"
          customerSurveyId={customerSurveyId}
          emailType="customerSurvey"
          onSuccess={onSuccessSendEmail}
          variant="outline-primary"
        >
          Skicka e-post med länk till undersökning
        </SendCustomerSurveyEmailButton>
        <SendCustomerSurveyEmailButton
          size="sm"
          customerSurveyId={customerSurveyId}
          emailType="customerSurveyReview"
          onSuccess={onSuccessSendEmail}
          variant="outline-primary"
        >
          Skicka e-post med begäran om recension
        </SendCustomerSurveyEmailButton>
      </Card.Footer>
    </Card>
  );
});
export default CustomerSurveyEmailsTable;
