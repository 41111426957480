import { PropsWithChildren } from 'react';
import { Link, LinkProps } from 'react-router-dom';
import ObjectId from 'client/components/ObjectId';

export interface AnchorProps extends PropsWithChildren<Pick<LinkProps, 'to' | 'target'>> {
  disabled?: boolean;
  className?: string;
}

export default function Anchor (props: AnchorProps) {
  const { className, children, to, target, disabled = false } = props;
  return (
    <ObjectId className={className}>
      {disabled ? children : (
        <Link to={to} target={target} className="text-decoration-none">{children}</Link>
      )}
    </ObjectId>
  );
}
