import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { DownloadCloud, Mail } from 'react-feather';
import DateFormat from 'client/components/DateFormat';
import { Download } from 'react-feather';
import useAuth from 'client/hooks/useAuth';
import classNames from 'classnames';

interface ReportFlagsProps {
  value: {
    emailed_date: string | null | Date;
    viewed_date: string | null | Date;
  };
}
export function ReportFlags (props: ReportFlagsProps) {
  const { value: { emailed_date, viewed_date } } = props;
  return (
    <div className="d-flex align-items-center gap-1">
      {viewed_date && (
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip style={{position: 'fixed'}}>Senast hämtad: <DateFormat value={viewed_date} /></Tooltip>}
        >
          <span className="badge border border-secondary text-secondary p-1">
            <DownloadCloud size={18} />
          </span>
        </OverlayTrigger>
      )}
      {emailed_date && (
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip style={{position: 'fixed'}}>Senast mailad <DateFormat value={emailed_date} /></Tooltip>}
        >
          <span className="badge border border-secondary text-secondary p-1">
            <Mail size={18} />
          </span>
        </OverlayTrigger>
      )}
    </div>
  );
}

interface ReportDownloadPDFLinkProps {
  type: string;
  report: {id: number};
  iconSize?: number;
  className?: string;
}

export function ReportDownloadPDFLink (props: ReportDownloadPDFLinkProps) {
  const { report, type, iconSize = 18, className } = props;
  const auth = useAuth();
  if (!report) return null;
  return (
    <a
      href={`${process.env.REACT_APP_SERVER_URL}/api/pdf/${type}/${report.id}?token=${auth.accessToken}`}
      target="_blank"
      rel="noopener noreferrer"
      title="Ladda hem PDF"
      className={classNames('btn btn-outline-primary btn-sm d-inline-flex align-items-center', className)}
    >
      PDF
      <Download size={iconSize} />
    </a>
  );
}
