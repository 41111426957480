import React from 'react';
import { Button, Modal, ModalProps } from 'react-bootstrap';
import { CreditReportWithRelationsRow } from 'client/creditReport/types';
import * as CreditReportFormatters from 'client/creditReport/CreditReportFormatters';
import CreditReportModalBody from 'client/creditReport/CreditReportModalBody';

// TODO this whole component can probably be trashed and replaced with
// CreditReportLoadingModal. what is one extra network request after all?

export interface CreditReportModalProps extends ModalProps {
  creditReport: CreditReportWithRelationsRow;
}

const CreditReportModal: React.FC<CreditReportModalProps> = React.memo(function CreditReportModal (props: CreditReportModalProps) {
  const { creditReport, onHide, ...restOfProps } = props;
  return (
    <Modal
      {...restOfProps}
      onHide={onHide}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <div>
            <div className="mb-1">
              Kreditupplysning{' '}
              <CreditReportFormatters.StatusLabel value={creditReport.status} />
            </div>
          </div>
          <div className="small" style={{fontSize: '65%'}}>{creditReport.id}</div>
        </Modal.Title>
      </Modal.Header>
      <CreditReportModalBody
        creditReport={creditReport}
      />
      <Modal.Footer>
        <Button variant="outline-primary" onClick={onHide}>
          Stäng
        </Button>
      </Modal.Footer>
    </Modal>
  );
});
export default CreditReportModal;
