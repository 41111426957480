import React from 'react';
import { CompanyMonitoredLogType, CompanyMonitoredLogRow } from 'client/companyMonitored/types';

interface CompanyMonitoredLogDescriptionProps {
  value: CompanyMonitoredLogRow;
}
export function CompanyMonitoredLogDescription (props: CompanyMonitoredLogDescriptionProps) {
  const { value:log } = props;
  const { type, data } = log;
  const base = logTypeToDescription(type, data);
  return <>{String(base)}</>;
}

export function logTypeToDescription (type: CompanyMonitoredLogType, data: CompanyMonitoredLogRow['data']): string {
  switch (type) {
    default: return `Okänd loggtyp: ${String(type)}`;
    case 'created': return 'Skapad';
    case 'updated': {
      const { field, curr } = data;
      if (field === 'is_active') return curr ? 'Aktiverad' : 'Inaktiverad';
      return 'Uppdaterad';
    }
    case 'monitored': return 'Bevakning tillagd';
    case 'unmonitored': return 'Bevakning borttagen';
  }
}

