import React from 'react';
import LogoSvg from 'client/assets/images/Calculate_Orange.svg';
import { Link } from 'react-router-dom';

export default function Logo (props: React.HTMLAttributes<HTMLDivElement>) {
  return (
    <div {...props}>
      <Link to="/">
        <img
          src={LogoSvg}
          alt="Calculate"
          style={{height: '40px'}}
        />
      </Link>
    </div>
  );
}
