import React, {useMemo} from 'react';
import { useQuery } from '@tanstack/react-query';
import * as columnDefs from 'client/table/commonColumnDefinitions';
import { CompanyMonitoredUpdateWithRelationsRow } from 'client/companyMonitoredUpdate/types';
import {ColumnDefinition} from 'client/table/types';
import CardSimpleTable from 'client/card/CardSimpleTable';
import {Link} from 'react-router-dom';
import {Search} from 'react-feather';

interface CompanyMonitoredEventTableProps {
  orgNumber: string;
  className?: string;
}

interface CompanyMonitoredTableRow extends CompanyMonitoredUpdateWithRelationsRow {
  id: string;
}

function CompanyMonitoredEventTable (props: CompanyMonitoredEventTableProps) {
  const { className, orgNumber } = props;

  const query = useQuery<CompanyMonitoredUpdateWithRelationsRow[], Error>({
    queryKey: [`/api/company_monitored/${orgNumber}/updates`],
  });

  const rows: CompanyMonitoredTableRow[] = useMemo(() => {
    return (query.data || []).map(row => ({
      ...row,
      id: [row.org_number, row.change_date, row.change_code].join('-'),
    }));
  }, [query.data]);

  return (
    <div>
      <div className="mb-3">
        <Link
          className="btn btn-outline-primary py-0 d-inline-flex gap-1 align-items-center px-1"
          to={`/company_monitored_update?org_number=${orgNumber}`}
        >
          <Search size={18} />
          Alla uppdateringar
        </Link>
      </div>
      <CardSimpleTable
        className={className}
        query={query}
        title="20 senaste uppdateringar"
        columns={columns}
        rows={rows}
      />
    </div>
  );
}
export default React.memo(CompanyMonitoredEventTable);

const columns: ColumnDefinition<CompanyMonitoredTableRow>[] = [
  columnDefs.date(['change_date', 'Skapad']),
  columnDefs.simple(['change_code', 'Kod']),
  columnDefs.simple(['change_text', 'Text']),
  columnDefs.actions(),
];
