import React from 'react';
import { ObjectInspector as ReactObjectInspector, chromeLight } from 'react-inspector';

const theme: any = {
  ...chromeLight,
  BASE_BACKGROUND_COLOR: 'transparent',
};

interface ObjectInspectorProps extends React.ComponentProps<typeof ReactObjectInspector> {
  object: any;
}

export default function ObjectInspector (props: ObjectInspectorProps) {
  const { object, ...restOfProps } = props;
  return (
    <ReactObjectInspector
      {...restOfProps}
      inner
      theme={theme}
      data={object}
    />
  );
}
