import React from 'react';
import { Card } from 'react-bootstrap';
import { useQuery } from '@tanstack/react-query';
import ErrorAlert from 'client/components/ErrorAlert';
import { Helmet } from 'react-helmet';
import { IEmailTemplate } from 'client/emailTemplate/types';
import EmailTemplatesTable from 'client/emailTemplate/EmailTemplatesTable';
import RefreshButton from 'client/buttons/RefreshButton';
import PageHeader from 'client/components/PageHeader';

const EmailTemplatesTablePage: React.FC = React.memo(function EmailTemplatesTablePage () {

  const query = useQuery<IEmailTemplate[], Error>({
    queryKey: ['/api/email_templates/list'],
  });


  const list = query.data ?? [];

  return (
    <div className="container-lg p-4">
      <Helmet>
        <title>E-postmallar</title>
      </Helmet>
      <PageHeader>E-postmallar</PageHeader>
      <ErrorAlert className="my-3" error={query.error} />
      <Card>
        <Card.Header className="d-flex flex-wrap gap-3 justify-content-end">
          <RefreshButton
            onClick={query.refetch}
            disabled={query.isLoading || query.isRefetching}
            className="px-2"
          />
        </Card.Header>
        <EmailTemplatesTable
          list={list}
          isLoading={query.isLoading || query.isRefetching}
          isSuccess={query.isSuccess}
        />
      </Card>
    </div>
  );
});

export default EmailTemplatesTablePage;
