import React from 'react';
import NumberFormat from 'client/components/NumberFormat';
import HelperTooltip from 'client/components/HelperTooltip';
import styled from 'styled-components';

const NudgeUp = styled.span`
  position: relative;
  top: -1px;
`;

interface BranchCompanyCountsProps  {
  countCompanies: number | null;
  countCompaniesFiltered: number | null;
}
export function BranchCompanyCounts (props: BranchCompanyCountsProps) {
  const { countCompanies, countCompaniesFiltered } = props;
  return (
    <>
      <span className="small border rounded px-1 d-inline-flex gap-1">
        <NumberFormat value={countCompanies || 0} /> st
        <NudgeUp>
          <HelperTooltip>
            Antal företag i tillhörande SNI-koder under bokslutsåret
          </HelperTooltip>
        </NudgeUp>
      </span>{' '}
      <span className="small border rounded px-1 d-inline-flex gap-1">
        <NumberFormat value={countCompaniesFiltered || 0} /> st
        <NudgeUp>
          <HelperTooltip>
            Antal företag i tillhörande SNI-koder under bokslutsåret efter avdrag enligt kriterier
          </HelperTooltip>
        </NudgeUp>
      </span>
    </>
  );
}
