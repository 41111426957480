import {
  ReportQueueLogType,
  IReportQueueLog,
  ReportQueueStatus,
} from 'client/reportQueue/types';
import { StatusLabel } from 'client/reportQueue/ReportQueueFormatters';
import { ReportDownloadPDFLink } from 'client/report/ReportFormatters';

interface IReportQueueUpdatedData {
  field: string;
  prev: string;
  curr: string;
}

interface IReportQueueGeneratedData {
  report_type: string;
  report_id: number;
}

interface IReportQueueLogDescription {
  value: IReportQueueLog;
}
export function ReportQueueLogDescription (props: IReportQueueLogDescription) {
  const { value:log } = props;
  const { type, data:rawData } = log;

  if (type === 'updated') {
    const { field, prev, curr } = rawData as unknown as IReportQueueUpdatedData;
    if (field === 'status') {
      return (
        <>
          Status ändrad från <StatusLabel value={prev as ReportQueueStatus} />
          {' '}till <StatusLabel value={curr as ReportQueueStatus} />
        </>
      );
    }
    return <>{dataField(field)} uppdaterad från {dataValue(field, prev)} till {dataValue(field, curr)}</>;
  } else if (type === 'generated') {
    const { report_id, report_type } = rawData as unknown as IReportQueueGeneratedData; 
    return (
      <>
        Rapport producerad{' '}
        <ReportDownloadPDFLink type={report_type} report={{id: report_id}} />
      </>
    );
  }

  const base = logTypeToDescription(type);
  return <>{String(base)}</>;
}

export function dataField (field: string): string {
  switch (field) {
    default: return `"${field}"`;
    case 'customer_id': return 'Kund-ID';
    case 'status': return 'Status';
    case 'production_date': return 'Produktionsdatum';
    case 'is_done': return 'Klar?';
    case 'is_downloaded': return 'Nedladdad?';
    case 'is_emailed': return 'Mailad?';
    case 'comment': return 'Kommentar';
    case 'order_number': return 'Ordernummer';
    case 'language': return 'Språk';
    case 'deliver_email': return 'Leverera digitalt';
    case 'deliver_printed': return 'Leverera tryckt';
  }
}

export function dataValue (field: string, value: any): string {
  if (typeof value === 'boolean') return value ? 'Ja' : 'Nej';
  if (typeof value === 'number') return String(value);
  switch (field) {
    default: return `"${String(value)}"`;
  }
}

export function logTypeToDescription (type: ReportQueueLogType): string {
  switch (type) {
    default: return `Okänd loggtyp: ${String(type)}`;
    case 'created': return 'Rapportkö skapad';
    case 'deleted': return 'Rapportkö raderad';
    case 'restored': return 'Rapportkö återställd';
    case 'updated': return 'Rapportkö uppdaterad';
  }
}
