import React, { useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { Card, Form, Alert } from 'react-bootstrap';
import IdProvider from 'client/components/IdProvider';
import LoadingButton from 'client/buttons/LoadingButton';
import ErrorFormControlFeedback from 'client/form/ErrorFormControlFeedback';
import axios from 'client/axios';
import ErrorAlert from 'client/components/ErrorAlert';
import { range } from 'lodash';
import { Formik, Form as FormikForm, Field, FormikHelpers } from 'formik';
import { Centered } from 'client/login/LoginUtils';
import * as errorUtils from 'client/utils/errors';
import styled from 'styled-components';
import FormControlTextarea from 'client/form/FormControlTextarea';
import classNames from 'classnames';
import Logo from 'client/components/Logo';

const RatingLabel = styled.label`
width: 50px;
height: 50px;
text-align: center;
display: flex;
justify-content: center;
align-items: center;
font-weight: bold;
font-size: 26px;
border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color);
border-radius: 50%;
&.checked {
  border-color: rgb(160 60 5)!important;
  background: var(--bs-primary) !important;
  color: #fff;
}
&:hover {
  cursor: pointer;
}
`;

interface CustomerSurveyRespondFormProps {
  customerSurveyId: string;
  defaultRating: string;
}

interface FormData {
  rating: string;
  comment: string;
}

const CustomerSurveyRespondForm: React.FC<CustomerSurveyRespondFormProps> = React.memo(function CustomerSurveyRespondForm (props: CustomerSurveyRespondFormProps) {
  const { customerSurveyId, defaultRating } = props;

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [formSuccess, setFormSuccess] = useState<boolean>(false);

  const answerMutation = useMutation<any, Error, FormData>({
    mutationFn: form => axios.post(`/api/customer_survey_respond/${customerSurveyId}`, form).then(r => r.data),
    onSuccess: () => {
      setFormSuccess(true);
    },
  });

  const initialFormValues: FormData = {rating: defaultRating, comment: ''};

  const onSubmit = (form: FormData, helpers: FormikHelpers<FormData>) => {
    setFormSuccess(false);
    setIsLoading(true);
    return answerMutation.mutateAsync(form).then(() => {
    }).catch(err => {
      const errorMap = errorUtils.maybeAxiosErrorToErrorMap(err);
      if (errorMap) {
        helpers.setErrors(errorMap);
        return;
      }
      throw err;
    }).finally(() => {
      setIsLoading(false);
    });
  };

  return (
    <Centered>
      <Formik initialValues={initialFormValues} onSubmit={onSubmit}>
        {formikBag => (
          <FormikForm>
            <Logo className="d-flex justify-content-center mb-4" />
            <Card style={{width: '500px'}}>
              <Card.Body className="pb-1 pt-4 text-center">
                <div className="mb-4">
                  <p>
                    Vi på Calculate ser fram emot din feedback!
                  </p>
                  <hr />
                </div>
                <Form.Group className="mb-4">
                  <Form.Label>
                    Hur pass nöjd är du med Calculate?
                  </Form.Label>
                  <div className="d-flex justify-content-center gap-2 flex-wrap">
                    {range(1, 6).map(i => (
                      <RatingLabel
                        key={i}
                        className={classNames('', formikBag.values.rating === String(i) ? 'checked' : '', {'is-invalid': Boolean(formikBag.errors.rating)})}
                      >
                        {i}
                        <Field
                          className="d-none"
                          type="radio"
                          name="rating"
                          disabled={formSuccess}
                          value={i}
                        />
                      </RatingLabel>
                    ))}
                    <ErrorFormControlFeedback
                      className="d-block w-100"
                      error={formikBag.errors.rating}
                      touched={formikBag.touched.rating}
                    />
                  </div>
                  <Form.Text className="mt-2 d-block">1 = minst nöjd. 5 = mest nöjd.</Form.Text>
                </Form.Group>
                {parseInt(formikBag.values.rating, 10) < 4 && (
                  <IdProvider>
                    {id => (
                      <Form.Group className="mb-3">
                        <Form.Label htmlFor={id}>Frivillig kommentar</Form.Label>
                        <Field
                          as={FormControlTextarea}
                          rows={5}
                          id={id}
                          name="comment"
                          placeholder="Dela gärna med dig av en kommentar"
                          disabled={formSuccess}
                          isInvalid={Boolean(formikBag.errors.comment)}
                        />
                        <ErrorFormControlFeedback
                          error={formikBag.errors.comment}
                          touched={formikBag.touched.comment}
                        />
                      </Form.Group>
                    )}
                  </IdProvider>
                )}
                <ErrorAlert error={answerMutation.error} />
                {formSuccess && (
                  <Alert variant="success">
                    Tack för din feedback. Du kan nu stänga den här sidan.
                  </Alert>
                )}
              </Card.Body>
              <Card.Footer className="d-flex justify-content-center align-items-baseline py-3">
                <LoadingButton
                  type="submit"
                  variant="outline-primary"
                  isLoading={isLoading}
                  disabled={!formikBag.isValid || formikBag.isSubmitting || formSuccess}
                >
                  Skicka in feedback
                </LoadingButton>
              </Card.Footer>
            </Card>
          </FormikForm>
        )}
      </Formik>
    </Centered>
  );
});
export default CustomerSurveyRespondForm;
