import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { IBranch } from 'client/branches/types';
import axios from 'client/axios';
import * as requestCallbacks from 'client/utils/requestCallbacks';
import * as errorUtils from 'client/utils/errors';

export interface IBranchDTO {
  id: string;
  branch_name: string;
  branch_name_en: string;
  sni_codes: string[];
}

export interface IBranchCreateDTO {
  id: string;
  branch_name: string;
  branch_name_en: string;
  sni_codes: string[];
}

export interface IBranchUpdateDTO extends IBranchCreateDTO {}

export const deleteBranch = async (id: string) =>
  (await axios.delete(`/api/branch/${id}`))?.data;

export const createBranch = async (data: IBranchCreateDTO) =>
  (await axios.post('/api/branch', data))?.data;

export const updateBranch = async ({ id, ...rest }: IBranchUpdateDTO) =>
  (await axios.patch(`/api/branch/${id}`, rest))?.data;

export const useBranch = () => {
  const queryClient = useQueryClient();

  const listQuery = useQuery<(IBranch & { searchIndex: string; searchBranch: string; })[]>({
    queryKey: ['/api/branch'],
    select: (data) => data.map((value) => ({
      ...value,
      searchIndex: `${value.id}${value.branch_name}${value.sni_codes.join(',')}`.replace(/\s/, '').toLowerCase(),
      searchBranch: `${value.id}${value.branch_name}`.replace(/\s/, '').toLowerCase(),
    })),
    refetchOnWindowFocus: false,
  });

  const deleteMutation = useMutation({
    mutationFn: deleteBranch, 
    onSuccess: (_: any, id) => {
      queryClient.invalidateQueries({queryKey: ['/api/branch']});
      requestCallbacks.onSuccess('Branschen har raderats');
    },
    onError: (err: AxiosError) => {
      requestCallbacks.onError(errorUtils.axiosErrorToMessage(err));
    },
  });

  const createMutation = useMutation({
    mutationFn: createBranch,
    onSuccess: () => {
      requestCallbacks.onSuccess('Branschen har skapats');
      queryClient.invalidateQueries({queryKey: ['/api/branch']});
    },
    onError: (err: AxiosError) => {
      requestCallbacks.onError(errorUtils.axiosErrorToMessage(err));
    },
  });

  const updateMutation = useMutation({
    mutationFn: updateBranch, 
    onSuccess: (data: any) => {
      queryClient.invalidateQueries({queryKey: ['/api/branch']});
      requestCallbacks.onSuccess('Branschen har uppdaterats');
    },
    onError: (err: AxiosError) => {
      requestCallbacks.onError(errorUtils.axiosErrorToMessage(err));
    },
  });

  return {
    listQuery,
    deleteMutation,
    createMutation,
    updateMutation,
  };
};
