import React, { useState, useEffect, ChangeEvent } from 'react';
import { Form, Modal, Button as BsButton } from 'react-bootstrap';
import { cloneDeep, set } from 'lodash';
import { IBranch } from 'client/branches/types';
import LoadingButton from 'client/buttons/LoadingButton';
import TagForm from 'client/components/TagFormTools';
import { useBranch } from 'client/branches/hooks';
import IdProvider from 'client/components/IdProvider';

type BranchModalProps = {
  isOpened: boolean;
  onClose: () => void;
  initialValues?: Partial<IBranch>;
  isEditMode: boolean;
}

const defaultFormValues = {
  branch_name: '',
  branch_name_en: '',
  id: '',
  sni_codes: [],
};

const BranchModal: React.FC<BranchModalProps> = ({ isOpened, onClose, initialValues, isEditMode }) => {
  const [formData, setFormData] = useState<IBranch>(defaultFormValues);

  const { createMutation, updateMutation } = useBranch();

  useEffect(() => {
    if (isOpened && isEditMode) {
      setFormData((prevState) => ({
        ...prevState,
        ...initialValues,
      }));
    }

    if (isOpened && !isEditMode) {
      setFormData({ ...defaultFormValues, ...initialValues });
    }
  }, [isOpened, initialValues, isEditMode]);

  const onChangeValue = (name: string, value: any) => {
    return setFormData(prevForm => {
      const clone = cloneDeep(prevForm);
      set(clone, name, value);
      return clone;
    });
  };

  const onChange = ({ target: { name, value } }: ChangeEvent<HTMLInputElement>) => {
    onChangeValue(name, value);
  };

  const onSubmit = (ev: React.FormEvent<HTMLFormElement>) => {
    ev.preventDefault();
    if (!ev.currentTarget.checkValidity()) {
      return;
    }
    if (isEditMode) {
      updateMutation.mutateAsync(formData).then(() => {
        onClose();
      });
    } else {
      createMutation.mutateAsync(formData).then(() => {
        onClose();
      });
    }
  };

  return (
    <Modal
      show={isOpened}
      onHide={onClose}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>{isEditMode ? 'Redigera bransch' : 'Skapa bransch'}</Modal.Title>
      </Modal.Header>
      <Form onSubmit={onSubmit}>
        <Modal.Body>
          <div className="d-flex flex-column gap-3">
            <IdProvider>
              {id => (
                <Form.Group>
                  <Form.Label htmlFor={id}>ID</Form.Label>
                  <Form.Control
                    id={id}
                    type="text"
                    name="id"
                    disabled
                    required
                    minLength={1}
                    maxLength={255}
                    value={formData.id}
                  />
                </Form.Group>
              )}
            </IdProvider>
            <IdProvider>
              {id => (
                <Form.Group>
                  <Form.Label htmlFor={id}>Branschnamn</Form.Label>
                  <Form.Control
                    id={id}
                    type="text"
                    name="branch_name"
                    placeholder="Ange ett branschnamn"
                    required
                    minLength={1}
                    maxLength={255}
                    onChange={onChange}
                    value={formData.branch_name}
                  />
                  <Form.Control.Feedback type="invalid">
                    Ett branschnamn måste anges
                  </Form.Control.Feedback>
                </Form.Group>
              )}
            </IdProvider>
            <IdProvider>
              {id => (
                <Form.Group>
                  <Form.Label htmlFor={id}>Branschnamn (engelska)</Form.Label>
                  <Form.Control
                    id={id}
                    type="text"
                    name="branch_name_en"
                    placeholder="Ange branschnamnet på engelska"
                    minLength={1}
                    maxLength={255}
                    onChange={onChange}
                    value={formData.branch_name_en ?? ''}
                  />
                  <Form.Control.Feedback type="invalid">
                    Ange ett giltigt branschnamn på engelska
                  </Form.Control.Feedback>
                </Form.Group>
              )}
            </IdProvider>
            <TagForm
              label="SNI-koder"
              name="sni_codes"
              values={formData.sni_codes}
              onChangeValues={onChangeValue}
              inputProps={{htmlSize: 5, minLength: 5, maxLength: 5, pattern: '^\\d{5}$'}}
              error={!formData.sni_codes.length}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <BsButton variant="outline-primary" onClick={onClose}>
            Avbryt
          </BsButton>
          <LoadingButton
            type="submit"
            isLoading={createMutation.isPending || updateMutation.isPending}
            disabled={createMutation.isPending || updateMutation.isPending}
          >
            {isEditMode ? 'Uppdatera' : 'Skapa'}
          </LoadingButton>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default BranchModal;
