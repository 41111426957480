import React, { useMemo, useState } from 'react';
import RoleGuard from 'client/guards/RoleGuard';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Card } from 'react-bootstrap';
import { UserAnchor } from 'client/user/UserFormatters';
import * as requestCallbacks from 'client/utils/requestCallbacks';
import ConfirmDeleteButton from 'client/buttons/ConfirmDeleteButton';
import { TableSpinningOverlay } from 'client/table/TableUtils';
import RefreshButton from 'client/buttons/RefreshButton';
import axios from 'client/axios';
import classNames from 'classnames';
import ErrorAlert from 'client/components/ErrorAlert';
import { CreditReportFetchAndProcessButton } from 'client/creditReport/CreditReportButtons';
import { CreditReportRow, CreditReportWithRelationsRow } from 'client/creditReport/types';
import * as CreditReportFormatters from 'client/creditReport/CreditReportFormatters';
import LoadingButton from 'client/buttons/LoadingButton';
import TableSettingsColumnOrderButton from 'client/table/TableSettingsColumnOrderButton';
import TableVirtual from 'client/table/TableVirtual';
import { ColumnDefinition } from 'client/table/types';
import * as commonColumnDefinitions from 'client/table/commonColumnDefinitions';
import { TableProvider, useTableContext } from 'client/contexts/TableContext';

interface CompanyCreditReportTableProps {
  orgNumber: string;
  className?: string;
}

interface CreditReportListResponse {
  rows: CreditReportWithRelationsRow[];
  total_rows: number;
}

export default React.memo(function CompanyCreditReportTable (props: CompanyCreditReportTableProps) {
  const { className, orgNumber } = props;

  const query = useQuery<CreditReportListResponse>({
    queryKey: [`/api/credit_report/company/${orgNumber}`, {
      order_by: 'created_at',
    }],
  });

  const createMutation = useMutation<any, Error>({
    mutationFn: () => axios.post<CreditReportRow>(`/api/credit_report/company/${orgNumber}`).then(result => {
      const { id } = result.data;
      return axios.post<CreditReportRow>(`/api/credit_report/${id}/fetchAndProcess`);
    }),
    onSuccess: () => {
      query.refetch();
      requestCallbacks.onSuccess('Kreditupplysningen har skapats');
    },
  });

  const deleteMutation = useMutation<void, Error, string>({
    mutationFn: id => axios.delete(`/api/credit_report/${id}`),
    onSuccess: () => {
      requestCallbacks.onSuccess('Kreditupplysningen har tagits bort');
      onReload();
    },
  });

  const onClickCreate = () => {
    createMutation.mutate();
  };

  const onReload = query.refetch;

  const list = query.data?.rows ?? [];
  const error = query.error || createMutation.error;

  const columnDefinitions = useMemo(columnDefinitionFactory, []);

  const [columnOrder, setColumnOrder] = useState<string[]>(() => columnDefinitions.filter(c => c.show).map(c => c.id));

  return (
    <TableProvider onDelete={deleteMutation.mutateAsync} onReload={onReload}>
      <Card className={classNames(className, 'border-0')}>
        <Card.Header className="border border-bottom-0 px-3 py-2">
          <Card.Title as="h6" className="mb-0 p-0 d-flex justify-content-between align-items-center gap-2">
            <span className="flex-grow-1">Företagets kreditupplysningar</span>
            <TableSettingsColumnOrderButton
              columnDefinitions={columnDefinitions}
              columnOrder={columnOrder}
              setColumnOrder={setColumnOrder}
              size="sm"
            />
            <RefreshButton
              onClick={onReload}
              disabled={query.isLoading || query.isRefetching}
              className="p-1"
              size="sm"
            />
          </Card.Title>
        </Card.Header>
        {error && (
          <Card.Body className="border border-bottom-0">
            <ErrorAlert className="mb-0" error={error} />
          </Card.Body>
        )}
        <TableSpinningOverlay isLoading={query.isRefetching}>
          <div className="table-responsive">
            <TableVirtual
              className="mb-0 border align-middle"
              rows={list}
              columns={columnDefinitions}
              columnOrder={columnOrder}
              isInitialLoading={query.isLoading}
            />
          </div>
        </TableSpinningOverlay>
        <Card.Footer className="d-flex align-items-center border border-top-0 p-2">
          <LoadingButton
            variant="primary"
            size="sm"
            isLoading={createMutation.isPending}
            onClick={onClickCreate}
          >
            Skapa ny kreditupplysning
          </LoadingButton>
        </Card.Footer>
      </Card>
    </TableProvider>
  );
});

type CompanyCreditReportTableContext = {
  onDelete: (id: string) => Promise<any>;
  onReload: () => void;
}

type ColumnDefinitionFactory = () => ColumnDefinition<CreditReportWithRelationsRow>[];

const columnDefinitionFactory: ColumnDefinitionFactory = () => [
  commonColumnDefinitions.cell(['id', 'ID'], props => (
    <CreditReportFormatters.CreditReportModalButton value={props.row} />
  )),
  commonColumnDefinitions.cell(['creator', 'Skapare'], props => (
    <>
      {props.row.creator ? <UserAnchor value={props.row.creator} /> : '-'}
    </>
  )),
  commonColumnDefinitions.date(['created_at', 'Skapad'], 'YYYY-MM-DD HH:mm'),
  commonColumnDefinitions.cell(['status', 'Status'], props => (
    <CreditReportFormatters.StatusLabel value={props.row.status} />
  )),
  commonColumnDefinitions.actions(props => {
    const { row } = props;
    const { onReload, onDelete } = useTableContext<CompanyCreditReportTableContext>();

    return (
      <div className="d-flex gap-1 flex-wrap align-items-center justify-content-end">
        {props.row.status === 'new' && (
          <>
            <CreditReportFetchAndProcessButton
              variant="outline-primary"
              creditReport={row}
              onSuccess={onReload}
              size="sm"
            />
            <RoleGuard role={['admin', 'coordinator']}>
              <ConfirmDeleteButton
                size="sm"
                className="py-0"
                onConfirmedDelete={() => onDelete(row.id)}
                confirmMessage="Är du säker på att du vill ta bort kreditupplysningen?"
              />
            </RoleGuard>
          </>
        )}
        <RoleGuard role={['admin']}>
          <CreditReportFormatters.CreditReportCompanyEventModalButton
            value={props.row.id}
          />
        </RoleGuard>
      </div>
    );
  }),
].map(obj => ({
  ...obj,
  show: true,
}));
