import React, { useMemo } from 'react';
import { TChangeValue } from 'client/utils/form';
import { Card, Row, Col, Form, Alert } from 'react-bootstrap';
import moment from 'moment';
import CustomerSelect from 'client/customer/CustomerSelect';
import { ICsBasicExtended } from 'client/cs/types';
import { ICustomer } from 'client/customer/types';
import { ReportQueueStatusEnum } from 'client/reportQueue/types';
import HelperTooltip from 'client/components/HelperTooltip';
import IdProvider from 'client/components/IdProvider';
import useAuth from 'client/hooks/useAuth';

interface IReportQueueMetaFields {
  form: {
    order_number: string | null;
    production_date: Date | string;
    comment: string;
    status?: ReportQueueStatusEnum;
    [key: string]: any;
  };
  isEditForm?: boolean;
  onChangeValue: TChangeValue;
}
export const ReportQueueMetaFields: React.FC<IReportQueueMetaFields> = React.memo(function ReportQueueMetaFields (props: IReportQueueMetaFields) {
  const { form, onChangeValue, isEditForm } = props;

  const onChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = ev.target;
    onChangeValue(name, value);
  };

  const onChangeSelect = (ev: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = ev.target;
    onChangeValue(name, value);
  };

  const onChangeOrderNumber = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = ev.target;
    onChangeValue(name, value || null);
  };

  const auth = useAuth();
  const isAdminOrCoordinator = auth.isUserRole(['admin', 'coordinator']);

  // this validation routine needs to be kept in sync with the input attribute validation
  const orderNumberIsInvalid: boolean = useMemo(() => {
    const value = form.order_number || '';
    if (isAdminOrCoordinator) {
      if (value) return /\D/.test(value) || value.length < 5;
      return false;
    }
    return !Boolean(value) || /\D/.test(value) || value.length < 5;
  }, [form.order_number, auth]);

  return (
    <Row className="my-3 pb-1">
      <Col md={6} lg={3}>
        <IdProvider>
          {id => (
            <Form.Group>
              <Form.Label htmlFor={id}>
                Ordernummer
              </Form.Label>
              <Form.Control
                id={id}
                name="order_number"
                value={form.order_number || ''}
                onChange={onChangeOrderNumber}
                minLength={5}
                pattern="^\d{5,}$"
                required={!isAdminOrCoordinator}
                isInvalid={orderNumberIsInvalid}
              />
              <Form.Control.Feedback type="invalid">
                Ange ett femsiffrigt ordernummer
              </Form.Control.Feedback>
            </Form.Group>
          )}
        </IdProvider>
      </Col>
      <Col md={6} lg={3}>
        <IdProvider>
          {id => (
            <Form.Group>
              <Form.Label htmlFor={id}>
                Kommentar
              </Form.Label>
              <Form.Control
                id={id}
                name="comment"
                value={form.comment}
                onChange={onChange}
                minLength={0}
              />
              <Form.Control.Feedback type="invalid">
                Ogiltigt värde
              </Form.Control.Feedback>
            </Form.Group>
          )}
        </IdProvider>
      </Col>
      <Col md={6} lg={3}>
        <IdProvider>
          {id => (
            <Form.Group>
              <Form.Label htmlFor={id}>
                Produktionsdatum{' '}
                <HelperTooltip>
                  Datumet då systemet automatiskt kommer att försöka producera rapporten.<br /><br />
                  Om företagets bokslut saknas vid produktion eller något annat villkor ej är uppfyllt så kommer det här datumet
                  automatiskt att skjutas fram.
                </HelperTooltip>
              </Form.Label>
              <Form.Control
                id={id}
                type="date"
                name="production_date"
                value={typeof form.production_date === 'string' ? form.production_date : moment(form.production_date).format('YYYY-MM-DD')}
                onChange={onChange}
                required
                isInvalid={!form.production_date}
              />
              <Form.Control.Feedback type="invalid">
                Ett produktionsdatum måste anges
              </Form.Control.Feedback>
            </Form.Group>
          )}
        </IdProvider>
      </Col>
      {isEditForm && (
        <Col md={6} lg={3}>
          <IdProvider>
            {id => (
              <Form.Group>
                <Form.Label htmlFor={id}>
                  Status
                </Form.Label>
                <Form.Select
                  id={id}
                  name="status"
                  value={form.status ?? 'NEW'}
                  onChange={onChangeSelect}
                  required
                  disabled={!isAdminOrCoordinator && form.status !== 'ERROR' as ReportQueueStatusEnum}
                  isInvalid={!form.status}
                >
                  <option value="NEW">Väntar på datum</option>
                  <option value="WAITING" disabled={!isAdminOrCoordinator}>
                    Väntar på bokslut
                  </option>
                  <option value="READY" disabled={!isAdminOrCoordinator}>
                    Klar
                  </option>
                  <option value="ERROR" disabled={!isAdminOrCoordinator && form.status !== 'ERROR' as ReportQueueStatusEnum}>
                    Fel
                  </option>
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  En giltig status måste anges
                </Form.Control.Feedback>
              </Form.Group>
            )}
          </IdProvider>
        </Col>
      )}
    </Row>
  );
});

interface ISaveOnCustomerCard extends React.PropsWithChildren {
  onChangeValue: TChangeValue;
  name: string;
  customerId: string | null;
  companyData: ICsBasicExtended;
  customerData: Partial<ICustomer> | null;
  setCustomerData: React.Dispatch<React.SetStateAction<Partial<ICustomer> | null>>;
  customerRequired: boolean;
  customerEmailRequired: boolean;
}
export const SaveOnCustomerCard: React.FC<ISaveOnCustomerCard> = React.memo(function SaveOnCustomerCard (props: ISaveOnCustomerCard) {
  const {
    name,
    customerId,
    onChangeValue,
    companyData,
    customerData,
    setCustomerData,
    children,
    customerRequired,
    customerEmailRequired,
  } = props;

  return (
    <Card>
      <Card.Body className="pb-0">
        <CustomerSelect
          name={name}
          customerId={customerId}
          onChangeCustomerId={onChangeValue}
          customerData={customerData}
          setCustomerData={setCustomerData}
          companyData={companyData}
          required={customerRequired}
          emailRequired={customerEmailRequired}
        />
      </Card.Body>
      {children}
    </Card>
  );
});

interface ReportQueueUpdateAlertProps {
}
export const ReportQueueUpdateAlert: React.FC<ReportQueueUpdateAlertProps> = React.memo(function ReportQueueUpdateAlert (props: ReportQueueUpdateAlertProps) {
  return (
    <Alert variant="warning" className="border-warning p-2 small mb-2">
      <strong>OBS!</strong> Den här rapportkön har redan producerat en tillhörande rapport. Att uppdatera inställningar som påverkar rapporten kommer inte att visas i den redan producerade rapporten.
    </Alert>
  );
});
