import { Switch, Route, Redirect } from 'react-router-dom';
import PrivateRoute from 'client/router/PrivateRoute';
import PublicRoute from 'client/router/PublicRoute';
import ReportsRoute from 'client/router/ReportsRoute';
import useAuth from 'client/hooks/useAuth';

import DashboardPage from 'client/dashboard/DashboardPage';
import IndustryReportCreatePage from 'client/industryReport/IndustryReportCreatePage';
import IndustryReportEditPage from 'client/industryReport/IndustryReportEditPage';
import BranchTablePage from 'client/branches/BranchTablePage';
import ReportQueueTablePage from 'client/reportQueue/ReportQueueTablePage';
import ValuationReportCreatePage from 'client/valuationReport/ValuationReportCreatePage';
import ValuationReportEditPage from 'client/valuationReport/ValuationReportEditPage';
import LoginPage from 'client/login/LoginPage';
import SettingsPage from 'client/settings/SettingsPage';
import ResetPasswordPage from 'client/login/ResetPasswordPage';
import ForgotPasswordPage from 'client/login/ForgotPasswordPage';
import CustomerDashboardPage from 'client/customer/CustomerDashboardPage';
import AccountPage from 'client/account/AccountPage';
import UserTablePage from 'client/user/UserTablePage';
import UserCreatePage from 'client/user/UserCreatePage';
import UserEditPage from 'client/user/UserEditPage';
import ReportQueueCreatePage from 'client/reportQueue/ReportQueueCreatePage';
import CustomerTablePage from 'client/customer/CustomerTablePage';
import CustomerEditPage from 'client/customer/CustomerEditPage';
import CustomerCreatePage from 'client/customer/CustomerCreatePage';
import CorporateTaxRateTablePage from 'client/corporateTaxRate/CorporateTaxRateTablePage';
import EmailTemplatesTablePage from 'client/emailTemplate/EmailTemplatesTablePage';
import EmailTemplateEditPage from 'client/emailTemplate/EmailTemplateEditPage';
import EmailTablePage from 'client/email/EmailTablePage';
import CsImportedTablePage from 'client/cs/CsImportedTablePage';
import ErrorAlert from 'client/components/ErrorAlert';
import CompanyTablePage from 'client/company/CompanyTablePage';
import CompanyPage from 'client/company/CompanyPage';
import SniCodeTablePage from 'client/sni/SniCodeTablePage';
import CreditReportTablePage from 'client/creditReport/CreditReportTablePage';
import CustomerSurveyTablePage from 'client/customerSurvey/CustomerSurveyTablePage';
import CustomerSurveyRespondPage from 'client/customerSurvey/CustomerSurveyRespondPage';
import CustomerSurveyEditPage from 'client/customerSurvey/CustomerSurveyEditPage';
import PdfPage from 'client/pdf/PdfPage';
import CompanyMonitoredTablePage from 'client/companyMonitored/CompanyMonitoredTablePage';
import CompanyEventCustomerBatchTablePage from 'client/companyEventBatch/CompanyEventCustomerBatchTablePage';
import CompanyEventUserBatchTablePage from 'client/companyEventBatch/CompanyEventUserBatchTablePage';
import CompanyEventTablePage from 'client/companyEvent/CompanyEventTablePage';
import CompanyMonitoredUpdateTablePage from 'client/companyMonitoredUpdate/CompanyMonitoredUpdateTablePage';

export default function Router () {
  const auth = useAuth();

  if (auth.isCustomer) {
    return (
      <Switch>
        <Route exact path="/survey/:customerSurveyId" component={CustomerSurveyRespondPage} />
        <PrivateRoute exact path="/" component={CustomerDashboardPage} />
        <PrivateRoute exact path="/account" component={AccountPage} />
        <Redirect path="*" to="/" />
      </Switch>
    );
  }

  return (
    <Switch>
      <Route exact path="/survey/:customerSurveyId" component={CustomerSurveyRespondPage} />
      <PublicRoute exact path="/login" component={LoginPage} />
      <PublicRoute exact path="/reset_password/:token" component={ResetPasswordPage} />
      <PublicRoute exact path="/forgot_password" component={ForgotPasswordPage} />
      <PrivateRoute path="/" exact component={DashboardPage} />
      <PrivateRoute exact path="/email_template/:emailTemplateId" component={EmailTemplateEditPage} />
      <PrivateRoute exact path="/email_templates" component={EmailTemplatesTablePage} />
      <PrivateRoute exact path="/emails" component={EmailTablePage} />
      <PrivateRoute exact path="/corporate_tax_rate" component={CorporateTaxRateTablePage} />
      <PrivateRoute exact path="/cs/imported" component={CsImportedTablePage} />
      <PrivateRoute exact path="/account" component={AccountPage} />
      <PrivateRoute exact path="/company_monitored" component={CompanyMonitoredTablePage} />
      <PrivateRoute exact path="/company_monitored_update" component={CompanyMonitoredUpdateTablePage} />
      <PrivateRoute exact path="/company_event" component={CompanyEventTablePage} />
      <PrivateRoute exact path="/company_event_customer_batch" component={CompanyEventCustomerBatchTablePage} />
      <PrivateRoute exact path="/company_event_user_batch" component={CompanyEventUserBatchTablePage} />
      <PrivateRoute exact path="/settings" component={SettingsPage} />
      <PrivateRoute exact path="/valuation_reports/create/:orgNumber?" component={ValuationReportCreatePage} />
      <PrivateRoute exact path="/valuation_reports/:reportQueueId/:tab?" component={ValuationReportEditPage} />
      <PrivateRoute exact path="/branches" component={BranchTablePage} />
      <PrivateRoute exact path="/companies" component={CompanyTablePage} />
      <PrivateRoute exact path="/company/:orgNumber/:tab?/:subTab?" component={CompanyPage} />
      <PrivateRoute exact path="/report_queue/create/:orgNumber?" component={ReportQueueCreatePage} />
      <PrivateRoute exact path="/industry_reports/create/:orgNumber?" component={IndustryReportCreatePage} />
      <PrivateRoute exact path="/industry_reports/:reportQueueId/:tab?" component={IndustryReportEditPage} />
      <PrivateRoute exact path="/report_queue/:tab" component={ReportQueueTablePage} />
      <ReportsRoute exact path="/reports/:reportId/:tab?" />
      <PrivateRoute exact path="/user/new" component={UserCreatePage} />
      <PrivateRoute exact path="/user/:userId/:tab?" component={UserEditPage} />
      <PrivateRoute exact path="/users" component={UserTablePage} />
      <PrivateRoute exact path="/customer/new" component={CustomerCreatePage} />
      <PrivateRoute exact path="/customers" component={CustomerTablePage} />
      <PrivateRoute exact path="/customer/:customerId/:tab?" component={CustomerEditPage} />
      <PrivateRoute exact path="/sni" component={SniCodeTablePage} />
      <PrivateRoute exact path="/credit_report" component={CreditReportTablePage} />
      <PrivateRoute exact path="/customer_survey/:customerSurveyId/:tab?" component={CustomerSurveyEditPage} />
      <PrivateRoute exact path="/customer_survey" component={CustomerSurveyTablePage} />
      <PrivateRoute exact path="/pdf" component={PdfPage} />
      <Route path="*">
        <div className="container-fluid p-4">
          <ErrorAlert error="Sidan hittades inte" />
        </div>
      </Route>
    </Switch>
  );
}
