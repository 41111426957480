import axios from 'client/axios';
import ErrorAlert from 'client/components/ErrorAlert';
import { useMutation } from '@tanstack/react-query';
import { CustomerAnchor } from 'client/customer/CustomerFormatters';
import { ICsBasicExtendedWithRelations } from 'client/cs/types';
import { ConfirmActionModal, useConfirmModalState } from 'client/modals/ConfirmModal';
import { Button } from 'react-bootstrap';
import * as requestCallbacks from 'client/utils/requestCallbacks';
import { ICustomer } from 'client/customer/types';
import { CreateCustomerFormFields } from 'client/customer/CustomerForm';
import { useHistory } from 'react-router-dom';

interface CompanyAsCustomerProps {
  company: Pick<ICsBasicExtendedWithRelations, 'company_name' | 'org_number' | 'customer'>;
}

export default function CompanyAsCustomer (props: CompanyAsCustomerProps) {
  const { company } = props;
  const { confirmAction, props:createCustomerModalProps } = useConfirmModalState();

  const history = useHistory();

  const createMutation = useMutation<ICustomer, Error, CreateCustomerFormFields>({
    mutationFn: data => axios.post('/api/customers', data).then(r => r.data),
    onSuccess: customer => {
      history.push(`/customer/${customer.id}/form`);
      requestCallbacks.onSuccess('Kunden skapades');
    },
  });

  const onCreateClick = () => {
    confirmAction(() => {
      return createMutation.mutateAsync({
        name: company.company_name as string,
        id: company.org_number,
        email: '',
        contact_person: '',
        language: 'sv',
      });
    });
  };

  return (
    <div className="border-bottom py-2 px-3 mb-2 ">
      <div className="small d-flex align-items-center gap-1">
        <span>Kund:</span>
        {company.customer ? (
          <CustomerAnchor value={company.customer} />
        ) : (
          <>
            <Button size="sm" variant="outline-primary" onClick={onCreateClick} className="p-1">
              Skapa företaget som ett kundkort
            </Button>
            <ConfirmActionModal
              {...createCustomerModalProps}
              message="Är du säker på att du vill skapa en kund av detta företag?"
            />
          </>
        )}
      </div>
      <ErrorAlert className="mt-2 py-2 mb-1" error={createMutation.error} />
    </div>
  );
}
