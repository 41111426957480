import React, { useMemo, useCallback, useEffect } from 'react';
import { produceSetMap } from 'client/utils/react';
import { computeAccountValues, allAccountRows } from 'client/accounts/utils';
import { IAccounts, TNumberOfEmployeesIntervals } from 'client/accounts/types';
import { ICsAccountsExtended, ICsGroupAccountsExtended } from 'client/cs/types';
import { IReportQueueFormOptions } from 'client/reportQueue/types';

interface IData {
  groupAccounts: ICsGroupAccountsExtended[] | null;
  mainAccounts: ICsAccountsExtended[];
}

interface IForm {
  form_options: IReportQueueFormOptions;
  report_settings: {
    accounts_date_locked?: boolean;
    accounts_from?: string;
    accounts_to?: string;
    company_accounts_override: boolean;
    company_accounts: ICsAccountsExtended[] | ICsGroupAccountsExtended[] | null;
    company_use_group_accounts: boolean;
  },
}

export interface UseReportQueueFormToolsSettings {
  data: IData;
  form: IForm;
  setForm: React.Dispatch<React.SetStateAction<any>>;
  isEditForm?: boolean;
}

export interface IUseReportQueueFormTools {
  onChangeValue: (name: string, value: any) => void;
  onChangeAccountsOverrideValue: (name: string, checked: boolean) => void;
  onChangeUseGroupAccounts: (name: string, checked: boolean) => void;
  onChangeAccountsTableSettings: (key: string, value: any) => void;
  accounts: ICsAccountsExtended[] | ICsGroupAccountsExtended[] | null;
  originalAccounts: ICsAccountsExtended[] | ICsGroupAccountsExtended[] | null;
}

const useReportQueueFormTools = (settings: UseReportQueueFormToolsSettings): IUseReportQueueFormTools => {
  const {
    data,
    form,
    setForm,
    isEditForm = false,
  } = settings;

  const {
    mainAccounts,
    groupAccounts,
  } = data;

  const onChangeAccountsTableSettings = useCallback((key: string, value: any) => {
    const update = {[`form_options.accounts_table.${key}`]: value};
    setForm(produceSetMap(update));
  }, [setForm]);

  const onChangeValue = useCallback((name: string, value: any) => {
    setForm(produceSetMap({[name]: value}));
  }, [setForm]);

  // these are the accounts we work with.
  // it can be either what is set in the form (report_settings.company_accounts)
  // if we are overiding the accounts, or it can be the default account.
  // the default account (or the form setting) can either be the main accounts
  // or the group accounts depending on if this is a group report or not.
  // originalAccounts is used to keep track of the original when we add or remove account years.
  const [accounts, originalAccounts] = useMemo(() => {
    const {
      company_accounts,
      company_accounts_override,
      company_use_group_accounts,
    } = form.report_settings;
    const originalAccounts = company_use_group_accounts ? groupAccounts : mainAccounts;
    if (company_accounts_override) return [company_accounts, originalAccounts];
    return [originalAccounts, originalAccounts];
  }, [
    form.report_settings.company_accounts,
    form.report_settings.company_accounts_override,
    form.report_settings.company_use_group_accounts,
    mainAccounts,
    groupAccounts,
  ]);

  const onChangeAccountsOverrideValue = useCallback((name: string, checked: boolean) => {
    onChangeValue(name, checked);
    if (checked) {
      const computedAccounts = computeAccountValues(allAccountRows, accounts as unknown as IAccounts[]);
      onChangeValue('report_settings.company_accounts', computedAccounts);
    } else {
      onChangeValue('report_settings.company_accounts', null);
      // these settings need to be reset to defaults when no longer overiding accounts
      onChangeAccountsTableSettings('add_years', []);
      onChangeAccountsTableSettings('remove_years', 0);
    }
  }, [onChangeAccountsTableSettings, onChangeValue, accounts]);

  const onChangeUseGroupAccounts = useCallback((name: string, checked: boolean) => {
    onChangeValue(name, checked);

    // make sure the employees interval setting is updated to use the amount of employees of the last account
    if ('nbr_employees_interval' in form.report_settings) {
      const accounts = checked ? groupAccounts : mainAccounts;
      const employees = accounts?.[0].n_no_employees;
      const nbrEmployeesInterval = employeesToInterval(employees as number);
      onChangeValue('report_settings.nbr_employees_interval', nbrEmployeesInterval);
    }

    if (!form.report_settings.company_accounts_override) return;
    const accounts = checked ? groupAccounts : mainAccounts;
    const computedAccounts = computeAccountValues(allAccountRows, accounts as unknown as IAccounts[]);
    onChangeValue('report_settings.company_accounts', computedAccounts);
  }, [
    onChangeValue,
    groupAccounts,
    mainAccounts,
    (form.report_settings as any)?.number_of_accounts,
    form.report_settings.company_accounts_override,
  ]);

  // this keeps the report settings accounts_from/accounts_to in sync with the last
  // account in the accounts table, but only when adding or removing years
  useEffect(() => {
    // when dates are locked, nothing happens
    if (form.report_settings.accounts_date_locked) return;
    const tableFrom = accounts?.[0]?.date_from;
    const tableTo = accounts?.[0]?.date_to;
    const formFrom = form.report_settings.accounts_from;
    const formTo = form.report_settings.accounts_to;
    if (!tableTo || !tableFrom || !formFrom || !formTo) return;
    const update: any = {};
    if (tableFrom !== formFrom) update['report_settings.accounts_from'] = tableFrom;
    if (tableTo !== formTo) update['report_settings.accounts_to'] = tableTo;
    if (Object.keys(update).length === 0) return;
    setForm(produceSetMap(update));
  }, [
    form.form_options.accounts_table.remove_years,
    form.form_options.accounts_table.add_years,
    form.report_settings.accounts_date_locked,
    accounts?.[0]?.date_from,
    accounts?.[0]?.date_to,
  ]);

  // NOTE: leave this commented for now, we might need to uncomment this if we want the number of accounts
  // to always be set to the maximum number of available accounts depending on the data
  //
  // when we have a valuation report we need to make sure the number of accounts in the report
  // is not set to a greater value than the number of available accounts
  useEffect(() => {
    if (isEditForm) return;
    const reportSettings = form.report_settings as any;
    const numberOfAccounts = reportSettings?.number_of_accounts;
    if (typeof numberOfAccounts === 'number' && Array.isArray(accounts)) {
      const newNumberOfAccounts = Math.min(accounts.length, 3);
      if (newNumberOfAccounts !== numberOfAccounts) {
        onChangeValue('report_settings.number_of_accounts', newNumberOfAccounts);
      }
    }
  }, [accounts, isEditForm]);

  return {
    onChangeValue,
    onChangeAccountsOverrideValue,
    onChangeUseGroupAccounts,
    onChangeAccountsTableSettings,
    accounts,
    originalAccounts,
  };
};

export default useReportQueueFormTools;

function employeesToInterval (numberOfEmployees: number): TNumberOfEmployeesIntervals {
  if (numberOfEmployees >= 10000) return '10000- anställda';
  if (numberOfEmployees >= 5000) return '5000-9999 anställda';
  if (numberOfEmployees >= 4000) return '4000-4999 anställda';
  if (numberOfEmployees >= 3000) return '3000-3999 anställda';
  if (numberOfEmployees >= 2000) return '2000-2999 anställda';
  if (numberOfEmployees >= 1500) return '1500-1999 anställda';
  if (numberOfEmployees >= 1000) return '1000-1499 anställda';
  if (numberOfEmployees >= 500) return '500-999 anställda';
  if (numberOfEmployees >= 200) return '200-499 anställda';
  if (numberOfEmployees >= 100) return '100-199 anställda';
  if (numberOfEmployees >= 50) return '50-99 anställda';
  if (numberOfEmployees >= 20) return '20-49 anställda';
  if (numberOfEmployees >= 10) return '10-19 anställda';
  if (numberOfEmployees >= 5) return '5-9 anställda';
  if (numberOfEmployees >= 1) return '1-4 anställda';
  return '0 anställda';
}
