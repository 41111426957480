import React, {useId, useMemo} from 'react';
import { Form } from 'react-bootstrap';
import { FilterDefinition } from 'client/table/types';
import { TableFilterInputGroup } from 'client/table/TableUtils';
import CompanySearch from 'client/components/CompanySearch';
import { UndefinedInitialDataOptions, useQuery } from '@tanstack/react-query';
import { IUser } from 'client/user/types';
import useFocusControl from 'client/hooks/useFocusControl';
import moment from 'moment';

type IdAndLabel = [string, string];

// a simple string input
export const input = (idAndLabel: IdAndLabel, extendWith = {}): FilterDefinition<string> => ({
  id: idAndLabel[0],
  label: idAndLabel[1],
  Render: props => {
    const { id, label, size, value:outerValue, validate, onChange:onChangeOuter, extraProps = {} } = props;

    const {
      onFocus,
      onChange,
      onBlur,
      value,
    } = useFocusControl(outerValue, onChangeOuter);

    const isInvalid: boolean = useMemo(() => {
      if (value === null || typeof value === 'undefined' || value === '') return false;
      return validate ? !validate(value) : false;
    }, [value, validate]);

    return (
      <TableFilterInputGroup name={id} label={label ?? id} className="flex-grow-1" size={size}>
        <Form.Control
          placeholder="Oavsett värde"
          {...extraProps}
          isInvalid={isInvalid}
          isValid={Boolean(value) && !isInvalid}
          name={id}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          value={value}
        />
      </TableFilterInputGroup>
    );
  },
  ...extendWith,
});

type SelectOption = {value: string, label: string | React.ReactNode};

// a simple select
export const select = (idAndLabel: IdAndLabel, options: SelectOption[], extendWith = {}): FilterDefinition<string> => ({
  id: idAndLabel[0],
  label: idAndLabel[1],
  Render: props => {
    const { id, label, value, size, validate, onChange:onChangeOuter, extraProps = {} } = props;
    const { placeholder = 'Oavsett värde' } = extraProps;

    const onChange = (ev: React.ChangeEvent<HTMLSelectElement>) => {
      onChangeOuter(id, ev.target.value);
    };

    const isInvalid: boolean = useMemo(() => {
      if (value === null || typeof value === 'undefined' || value === '') return false;
      return !options.some(option => option.value === value);
    }, [value, validate]);

    return (
      <TableFilterInputGroup name={id} label={label ?? id} className="flex-grow-1" size={size}>
        <Form.Select
          {...extraProps}
          isInvalid={isInvalid}
          isValid={Boolean(value) && !isInvalid}
          name={id}
          onChange={onChange}
          value={value}
        >
          <option value="">{placeholder}</option>
          {options.map(option => (
            <option key={option.value} value={option.value}>{option.label}</option>
          ))}
        </Form.Select>
      </TableFilterInputGroup>
    );
  },
  ...extendWith,
});

const uuidRegExp = /[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/;

// string-based, validates a uuid v4
export const uuid = (idAndLabel: IdAndLabel, extendWith = {}): FilterDefinition<string> => input(idAndLabel, {
  extraProps: {placeholder: 'Oavsett ID'},
  validate: (value: any) => {
    if (typeof value !== 'string') return false;
    return uuidRegExp.test(value);
  },
  ...extendWith,
});

// a date selector
export const date = (idAndLabel: IdAndLabel, extendWith = {}): FilterDefinition<string> => input(idAndLabel, {
  extraProps: {type: 'date'},
  validate: (value: any) => {
    if (typeof value !== 'string') return false;
    return moment(value, 'YYYY-MM-DD').isValid();
  },
  ...extendWith,
});

// FIXME this input does not get reset when the form resets due to being uncontrolled
export const companySearch = (idAndLabel: IdAndLabel, extendWith = {}): FilterDefinition<string> => ({
  id: idAndLabel[0],
  label: idAndLabel[1],
  Render: props => {
    const { id, label, value, size, onChange, extraProps = {} } = props;

    const onSelect = (orgNumber: string) => {
      onChange(id, orgNumber);
    };

    return (
      <TableFilterInputGroup name={id} label={label} className="flex-grow-1" size={size}>
        <CompanySearch
          {...extraProps}
          className="w-auto flex-grow-1"
          onSelect={onSelect}
          defaultInputValue={value}
        />
      </TableFilterInputGroup>
    );
  },
  ...extendWith,
});

type OptionList = {key: string; label: string}[];

export const inputPlaceholderQuery = (idAndLabel: IdAndLabel, queryOptions: UndefinedInitialDataOptions<OptionList>, extendWith = {}): FilterDefinition<string> => ({
  id: idAndLabel[0],
  label: idAndLabel[1],
  Render: props => {
    const { id, label, size, value:outerValue, onChange:onChangeOuter, extraProps = {} } = props;

    const query = useQuery<OptionList>(queryOptions);

    const listId = useId();
    const list = query.data || [];

    const {
      onFocus,
      onChange,
      onBlur,
      value,
    } = useFocusControl(outerValue, onChangeOuter);

    const isInvalid: boolean = useMemo(() => {
      if (value === null || typeof value === 'undefined' || value === '') return false;
      return !list.some(item => item.key === value);
    }, [value, list]);

    return (
      <TableFilterInputGroup name={id} label={label ?? id} className="flex-grow-1" size={size}>
        <datalist id={listId}>
          {list.map(item => (
            <option key={item.key} value={item.key}>{item.label}</option>
          ))}
        </datalist>
        <Form.Control
          placeholder="Oavsett värde"
          list={listId}
          {...extraProps}
          disabled={query.isLoading}
          isInvalid={isInvalid}
          isValid={Boolean(value) && !isInvalid}
          name={id}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          value={value}
        />
      </TableFilterInputGroup>
    );
  },
  ...extendWith,
});

export const userSearch = (idAndLabel: IdAndLabel, extendWith = {}): FilterDefinition<string> => inputPlaceholderQuery(idAndLabel, {
  queryKey: ['/api/users/list', {limit: 50}],
  select: (data: any) => {
    return data.rows.map((user: IUser) => ({
      key: user.id,
      label: user.name || user.id,
    }));
  },
}, extendWith);
