import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { Card } from 'react-bootstrap';
import RefreshButton from 'client/buttons/RefreshButton';
import { IAccounts } from 'client/accounts/types';
import ErrorAlert from 'client/components/ErrorAlert';
import AccountsTableFixed from 'client/accounts/AccountsTableFixed';

interface CompanyAccountsTableProps {
  orgNumber: string;
  groupAccounts?: boolean;
}
const CompanyAccountsTable: React.FC<CompanyAccountsTableProps> = React.memo(function CompanyAccountsTable (props: CompanyAccountsTableProps) {
  const { orgNumber, groupAccounts = false } = props;

  const query = useQuery<IAccounts[], Error>({
    queryKey: [`/api/companies/${orgNumber}/${groupAccounts ? 'groupAccounts' : 'accounts'}`],
  });

  const accounts = query.data;

  return (
    <Card className="border-0">
      <Card.Header className="border px-3 py-2 d-flex justify-content-between align-items-center">
        <Card.Title as="h6" className="mb-0 p-0 d-flex justify-content-between align-items-center">
          {groupAccounts ? (
            <>Företagets koncernbokslut</>
          ) : (
            <>Företagets bokslut</>
          )}
        </Card.Title>
        <RefreshButton onClick={query.refetch} className="p-1" size="sm" />
      </Card.Header>
      <ErrorAlert error={query.error} className="m-3" />
      <AccountsTableFixed
        isLoading={query.isLoading || query.isRefetching}
        accounts={accounts ?? null}
        limit={5}
      />
    </Card>
  );
});
export default CompanyAccountsTable;
